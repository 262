import { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { omit, isEmpty } from "lodash";
import DisplayColumnsDropdown from "../../../components/Tables/ControlledTable/components/DisplayColumnsDropdown/DisplayColumnsDropdown";
import FilterMenu from "./FilterMenu/FilterMenu";
import Typography from "@material-ui/core/Typography";
import UncontrolledTable from "../../../components/Tables/UncontrolledTable/UncontrolledTable";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

export function Table(props: any) {
  const {
    deviceAssetsMap,
    exportSpreadsheet,
    handleSave,
    filterSearch,
    handleConfirmationModal,
    state,
    setState,
    timeZone,
    data,
    apiUrl,
    token,
    organizationId,
    organization,
  } = props;
  const historyTableColumns = [
    {
      Header: "Device Identifier",
      id: "tag",
      accessor: "tag",
    },
    {
      Header: "Availability",
      id: "status",
      accessor: (row: any) => {
        return row.device?.status || "unassigned";
      },
    },
    {
      Header: "Associated Item",
      id: "assignedTo",
      accessor: (row: any) => {
        return deviceAssetsMap[row.assetId]
          ? deviceAssetsMap[row.assetId].map((ass: any) => ass.tag)[0]
          : "";
      },
      Cell: (data: any) => {
        const row = data.row.original;
        return (
          <div
            className={enableClick(row)}
            onClick={() => {
              const {
                assetId = "",
                batchId = "",
                assetType = "",
              } = deviceAssetsMap[row.assetId][0];
              if (assetId || batchId) {
                const url = batchId
                  ? `/batches/${batchId}/history/${assetType}`
                  : `/assetSnapshot/${assetId}`;
                history.push(url);
              }
            }}
          >
            {deviceAssetsMap[row.assetId]
              ? deviceAssetsMap[row.assetId].map((ass: any) => ass.tag)[0]
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Status",
      id: "lastEvent",
      accessor: "lastEvent",
    },
    {
      Header: "Device Type",
      accessor: "assetType",
      id: "assetType",
    },
    {
      Header: "Battery Life",
      id: "batteryLife",
      accessor: (row: any) => row.propertiesMap?.battery || 0,
      Cell: (data: any) => {
        const row = data.row.original;
        return (
          <div>
            {row.propertiesMap?.battery ? `${row.propertiesMap.battery}%` : ""}
          </div>
        );
      },
    },
    {
      Header: "Speed (m/h)",
      accessor: (row: any) => row.propertiesMap?.speed || 0,
      id: "speed",
    },
    {
      Header: "Altitude (f)",
      accessor: (row: any) => row.propertiesMap?.altitude || 0,
      id: "altitude",
    },
    {
      Header: "Heading (°)",
      accessor: (row: any) => row.propertiesMap?.heading || 0,
      id: "heading",
    },
  ];

  const classes = useStyles();
  const history = useHistory();

  const enableClick = (row: any) => {
    const { assetId = "" } = row;
    if (deviceAssetsMap[assetId]) {
      return "pointer";
    }
    return "";
  };

  const selectAllPageRows = (pageRows: any, deselectPageRows: boolean) => {
    let updatedSelection = {};

    const selectedDevices = state.selectedDevices;

    const reducedPageRows = pageRows.reduce((x: any, y: any) => {
      return {
        ...x,
        [y.assetId]: y,
      };
    }, {});

    if (deselectPageRows) {
      updatedSelection = omit(selectedDevices, Object.keys(reducedPageRows));
    } else {
      updatedSelection = { ...selectedDevices, ...reducedPageRows };
    }

    setState((s: any) => {
      return {
        ...s,
        selectedDevices: updatedSelection,
      };
    });
  };

  const onSelect = (row: any) => {
    setState((s: any) => {
      if (!s.selectedDevices[row.assetId]) {
        return {
          ...s,
          selectedDevices: {
            ...s.selectedDevices,
            [row.assetId]: row,
          },
        };
      }
      delete s.selectedDevices[row.assetId];
      return {
        ...s,
        selectedDevices: s.selectedDevices,
      };
    });
  };

  const [displayColumnOptions, setDisplayColumnOptions] = useState<any>(
    state.filters?.displayColumnOptions ||
      historyTableColumns
        .map((col: any) => {
          return {
            id: col.id,
            header: col.Header,
            checked: true,
          };
        })
        .reduce((x, y) => {
          return { ...x, [y.id]: y };
        }, {})
  );

  const [defaultColumnOrder, setDefaultColumnOrder] = useState<any>(
    state.filters?.defaultColumnOrder
  );

  return (
    <UncontrolledTable
      columns={historyTableColumns.filter((col: any) =>
        displayColumnOptions[col.id]
          ? displayColumnOptions[col.id].checked
          : true
      )}
      data={data}
      totalCountLabel={"Devices"}
      defaultColumnOrder={defaultColumnOrder}
      onColumnDrop={(colOrder: string[]) => {
        handleSave({
          ...state.filters,
          defaultColumnOrder: colOrder,
        });
        setDefaultColumnOrder(colOrder);
      }}
      defaultSort={[{ id: "tag" }]}
      dragEnabled={true}
      isSelect
      onSelect={onSelect}
      selectAllPageRows={selectAllPageRows}
      stickyHeader
      toolbarChildren={
        <Grid
          className={classes.barChildrenContainer}
          container
          alignItems={"center"}
        >
          {/* Open Menu Button */}
          <DisplayColumnsDropdown
            handleSave={(columnOptions: any) => {
              handleSave({
                displayColumnOptions: columnOptions,
              });
            }}
            options={displayColumnOptions}
            setOptions={setDisplayColumnOptions}
          />
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            <FilterMenu
              {...props}
              organization={organization}
              apiUrl={apiUrl}
              token={token}
              organizationId={organizationId}
              saveFilterSettings={handleSave}
              displayColumnOptions={displayColumnOptions}
              defaultColumnOrder={defaultColumnOrder}
              filterSearch={filterSearch}
              handleConfirmationModal={handleConfirmationModal}
              setState={setState}
              state={state}
            />
          </Grid>
          <Grid
            className={`${classes.barChildren} pointer`}
            item
            xs={3}
            xl={2}
            onClick={() => {
              if (!isEmpty(state.selectedDevices)) {
                exportSpreadsheet(
                  Object.keys(state.selectedDevices).map((k: string) => {
                    return state.selectedDevices[k];
                  }),
                  state.deviceAssetsMap,
                  timeZone
                );
              } else {
                handleConfirmationModal(
                  `Please select device(s) for export`,
                  true,
                  "fas fa-times-circle mr-2",
                  5000
                );
              }
            }}
          >
            <Typography className="exportIcon" variant="subtitle1">
              Export
              <i className="fas fa-file-export ml-2"></i>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}
