import ReactSelectCheckboxes from "../../../../../components/Selects/ReactSelectCheckboxes";

export function DeviceType(props: any) {
  const { filters, setFilters } = props;

  return (
    <ReactSelectCheckboxes
      onChange={(e: any) => {
        setFilters((f: any) => {
          return {
            ...f,
            asset_type:
              e && e.length
                ? e.map((el: any) => {
                    return el.value;
                  })
                : [],
          };
        });
      }}
      options={[{ label: "Oyster2", value: "oyster2" }]}
      styles={{
        menuPortal: (styles: any) => ({ ...styles, zIndex: 99999 }),
        multiValueRemove: (styles: any, { data }: any) => ({
          ...styles,
          color: "#b71d1a",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#b71d1a",
            color: "white",
          },
        }),
      }}
      value={
        filters.asset_type && filters.asset_type.length > 0
          ? filters.asset_type.map((t: any) => {
              return {
                label: t,
                value: t,
              };
            })
          : []
      }
    />
  );
}
