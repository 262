import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import ReactSelectCheckboxes from "../../../../Selects/ReactSelectCheckboxes";

export default function ClassificationFilters(props: any) {
  const {
    classifications = {},
    filters = {},
    handleFilterChange = () => null,
  } = props;

  const classificationFilters = Object.keys(classifications?.active || {}).map(
    (k) => {
      const { name, classificationId, children } = classifications.active[k];

      if (!isEmpty(children)) {
        return (
          <Grid item xs={6} key={classificationId}>
            <label>{name}</label>
            <ReactSelectCheckboxes
              options={[
                ...Object.keys(children).map((childKey: string) => {
                  return {
                    label: children[childKey].name,
                    value: children[childKey].classificationId,
                    parentId: classificationId,
                  };
                }),
              ]}
              onChange={(e: any) => {
                handleFilterChange({ [name]: e });
              }}
              value={filters[name]}
            />
          </Grid>
        );
      }
      return null;
    }
  );

  return <>{classificationFilters}</>;
}
