import { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { Dropzone } from "../../components/Dropzone/Dropzone";
import { downloadTemplate, validateAndFormatSpreadsheet } from "./xlsx";
import request from "superagent";
import Loading from "../../components/Loading/Loading";

function ImportUsers(props) {
  const [accepted, setAccepted] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const uploadFile = (file) => {
    setLoading(true);
    const jsonData = {
      memberOf: [""],
      timeZone: { label: "", useDefaultTimeZone: true, value: "" },
    };
    request
      .post(`${props.apiUrl}appUsers/import`)
      .set("auth-token", props.token)
      .field("jsondata", JSON.stringify(jsonData))
      .attach("filedata", file)
      .then((response) => {
        setLoading(false);
        const { success, numAdded, numModified, numFailures } = response.body;
        if (success) {
          props.notificationModal(
            `Success! ${numAdded} users added, ${numModified} users modified, ${numFailures} failed.`,
            false,
            "fas fa-times-check",
            3000
          );
          setFile({});
          setAccepted(false);
          props.onSuccess();
        } else {
          props.notificationModal(
            `Uh-oh! Something went wrong. ${response.body}`,
            true,
            "fas fa-times-circle",
            5000
          );
        }
      });
  };
  return (
    <Form
      onSubmit={(e) => {
        uploadFile(file);
        e.preventDefault();
      }}
    >
      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-1">
          <p>
            Download and fill out Excel file template, and then upload the file
            below:{" "}
            <a
              onClick={(e) => {
                e.preventDefault();
                downloadTemplate(
                  props.consoleRoles,
                  props.mobileRoles,
                  props.groups
                );
              }}
              href="/#"
            >
              UsersTemplate.xlsx
            </a>
          </p>
          {loading ? <Loading color="#5884F5" /> : ""}
          <Dropzone
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
            acceptText="Drag 'n' drop or click to select file (only *.xlsx files will be accepted)"
            callback={(acceptedFiles, accepted) => {
              setAccepted(false);
              validateAndFormatSpreadsheet(acceptedFiles[0], props, (cb) => {
                if (!cb.success) {
                  props.notificationModal(
                    `${cb.message}`,
                    true,
                    "fas fa-times-circle",
                    7000
                  );
                  accepted(false);
                } else {
                  setAccepted(true);
                  setFile(cb.file);
                  accepted(true);
                }
              });
            }}
            clearFile={() => {
              setFile({});
              setAccepted(false);
            }}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-2">
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={accepted ? false : true}
          >
            Submit
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

export default ImportUsers;
