import { useState, useEffect } from "react";

import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import SimpleButton from "../../components/Forms/FieldTypes/Button";
import request from "superagent";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: ".5rem 0",
    zIndex: 666,
  },
  productLevelData: {
    zIndex: 10,
  },
  productImages: {
    zIndex: 9,
  },
  batchLevelData: {
    zIndex: 8,
  },
  itemLevelData: {
    zIndex: 7,
  },
  inputFields: {
    margin: ".5rem 0",
    zIndex: 1,
  },
  addLinkToWebPage: {
    display: "flex",
  },
}));

export default function AssignDataToPCAResponsePage(props) {
  const {
    apiUrl,
    batch,
    handleConfirmationModal,
    modalClose,
    onSuccess,
    product,
    token,
    timeZone,
  } = props;
  const { batchId, propertiesMap = {} } = batch;
  const { template = [] } = propertiesMap;
  const classes = useStyles();

  // These variable names are dependencies for the connect page associated with this.
  const [state, setState] = useState({
    buttonLabel: { error: false, value: "" },
    linkToWebPage: false,
    selectedBatchLevelData: [],
    selectedItemLevelData: [],
    selectedProductImages: [],
    selectedProductLevelData: [],
    webPageUrl: { error: false, value: "" },
  });

  const {
    buttonLabel,
    linkToWebPage = false,
    selectedBatchLevelData,
    selectedItemLevelData,
    selectedProductImages,
    selectedProductLevelData,
    webPageUrl,
  } = state;
  const productLevelDataOptions = () => {
    const { propertiesMap = {} } = product;
    const { customDataFields = [] } = propertiesMap;
    let options = [];
    customDataFields.forEach((element) => {
      const { title, value } = element;
      options.push({ value, label: title });
    });
    return options;
  };
  const productImagesOptions = () => {
    const { images = [] } = product;
    let options = [];

    images.forEach((element) => {
      const { imageId, name, url } = element;
      options.push({ value: imageId, url, label: name });
    });

    return options;
  };
  const batchLevelDataOptions = () => {
    const {
      description,
      destinationLocation = {},
      name,
      originLocation,
      tag,
      timeCreated,
      topologiesList = [],
    } = batch;
    const { facility: originFacility } = originLocation;
    const { facility: destinationFacility } = destinationLocation;
    const unitOfMeasure = topologiesList[0] ? topologiesList[0].assetType : "";

    const timeCreatedString = `${moment
      .utc(timeCreated, "YYYYMMDDHHmmss")
      .tz(timeZone?.value || moment.tz.guess())
      .format("MM/DD/YYYY")}`;

    let options = [
      { label: "Batch ID", value: tag },
      { label: "Batch Name", value: name },
      { label: "Batch Origin", value: originFacility },
      { label: "Final Destination", value: destinationFacility },
      { label: "Date Created", value: timeCreatedString },
      { label: "Batch Description", value: description },
      { label: "Unit of Measure", value: unitOfMeasure },
      // TODO: Ask about Unit of Measure Option. Isnt there more than one unit of measure?
    ];
    return options;
  };
  const itemLevelData = () => {
    const { propertiesMap = {} } = product;
    const { itemLevelDataElements = [] } = propertiesMap;
    let options = [];
    itemLevelDataElements.forEach((element) => {
      const { dataType, id, label } = element;
      options.push({ value: id, label, dataType });
    });
    return options;
  };

  useEffect(() => {
    const element =
      template.find((element) => element.type === "PCACard") || undefined;
    if (element) {
      setState({
        buttonLabel: { error: false, value: element.buttonLabel },
        linkToWebPage: element.linkToWebPage,
        selectedBatchLevelData: element.selectedBatchLevelData,
        selectedItemLevelData: element.selectedItemLevelData,
        selectedProductImages: element.selectedProductImages,
        selectedProductLevelData: element.selectedProductLevelData,
        webPageUrl: { error: false, value: element.webPageUrl },
      });
    }
  }, [template]);

  function handleSubmit() {
    const body = {
      propertiesMap: {
        ...batch.propertiesMap,
        template: [
          {
            buttonLabel: buttonLabel.value,
            selectedBatchLevelData,
            selectedItemLevelData,
            selectedProductImages,
            selectedProductLevelData,
            type: "PCACard",
            linkToWebPage,
            webPageUrl: webPageUrl.value,
          },
        ],
      },
    };

    if (linkToWebPage) {
      if (webPageUrl.value.length === 0) {
        setState((prevState) => ({
          ...prevState,
          webPageUrl: { error: true, value: "" },
        }));
        return null;
      } else if (buttonLabel.value.length === 0) {
        setState((prevState) => ({
          ...prevState,
          buttonLabel: { error: true, value: "" },
        }));
        return null;
      }
    }

    request
      .put(`${apiUrl}batches/${batchId}`)
      .set("auth-token", token)
      .send(body)
      .then(() => {
        const { propertiesMap: productPropertiesMap, productId } = product;
        const productBody = {
          urlBase: process.env.REACT_APP_URL_BASE,
          propertiesMap: {
            ...productPropertiesMap,
            template: [{ type: "PCAResponseCard" }],
          },
        };
        request
          .put(`${apiUrl}products/${productId}`)
          .set("auth-token", token)
          .send(productBody)
          .then(() => {
            onSuccess();
            modalClose();
            handleConfirmationModal("PCA Connect Page Successfully Created");
          });
      });
  }

  return (
    <Grid className="pca-response" container>
      {/* Product Level Data */}
      <Grid
        className={`${classes.root} ${classes.productLevelData}`}
        item
        xs={12}
      >
        <Typography variant="h6">Product Level Data</Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              selectedProductLevelData: value,
            }));
          }}
          options={productLevelDataOptions()}
          value={selectedProductLevelData}
        />
      </Grid>

      {/* Product Images */}
      <Grid className={`${classes.root} ${classes.productImages}`} item xs={12}>
        <Typography variant="h6">Product Images</Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              selectedProductImages: value,
            }));
          }}
          options={productImagesOptions()}
          value={selectedProductImages}
        />
      </Grid>

      {/* Batch Level Data */}
      <Grid
        className={`${classes.root} ${classes.batchLevelData}`}
        item
        xs={12}
      >
        <Typography variant="h6">Batch Level Data</Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              selectedBatchLevelData: value,
            }));
          }}
          options={batchLevelDataOptions()}
          value={selectedBatchLevelData}
        />
      </Grid>

      {/* Item Level Data */}
      <Grid className={`${classes.root} ${classes.itemLevelData}`} item xs={12}>
        <Typography variant="h6">Item Level Data</Typography>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              selectedItemLevelData: value,
            }));
          }}
          options={itemLevelData()}
          value={selectedItemLevelData}
        />
      </Grid>

      {/* Add Link to Web Page */}
      <Grid className={classes.addLinkToWebPage} item xs={12}>
        <Typography variant="h6">Add Additional Engagement Page</Typography>
        <Tooltip
          title="This is where you have the choice to direct user’s to an additional connect page, or external web page."
          placement="right"
          arrow
        >
          <Switch
            checked={linkToWebPage}
            color="primary"
            onChange={() => {
              if (linkToWebPage) {
                setState((prevState) => ({
                  ...prevState,
                  linkToWebPage: !prevState.linkToWebPage,
                  buttonLabel: { error: false, value: "" },
                  webPageUrl: { error: false, value: "" },
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  linkToWebPage: !prevState.linkToWebPage,
                }));
              }
            }}
          />
        </Tooltip>
      </Grid>

      {/* Define Button Label */}
      {linkToWebPage ? (
        <Tooltip
          title="This populates as the text, on the button on the PCA Response Page."
          arrow
        >
          <Grid className={classes.inputFields} item xs={12}>
            <SimpleTextField
              label="Define Button Label"
              error={buttonLabel.error}
              value={buttonLabel.value}
              onChange={(event) => {
                event.persist();
                setState((prevState) => ({
                  ...prevState,
                  buttonLabel: { value: event.target.value },
                }));
              }}
            />
          </Grid>
        </Tooltip>
      ) : null}

      {/* Web Page URL */}
      {linkToWebPage ? (
        <Tooltip
          title="This is the url for either the connect page, or webpage where a user will be directed to, on pressing the button on the PCA response page."
          arrow
        >
          <Grid className={classes.inputFields} item xs={12}>
            <SimpleTextField
              label="Engagement Page URL"
              error={webPageUrl.error}
              value={webPageUrl.value}
              onChange={(event) => {
                event.persist();
                setState((prevState) => ({
                  ...prevState,
                  webPageUrl: { value: event.target.value },
                }));
              }}
            />
          </Grid>
        </Tooltip>
      ) : null}

      {/* Submit Button */}
      <Grid className={classes.inputFields} item xs={12}>
        <SimpleButton
          onClick={() => {
            handleSubmit();
          }}
        />
      </Grid>
    </Grid>
  );
}
