import ReactSelectCheckboxes from "../../../../../components/Selects/ReactSelectCheckboxes";

export function Available(props: any) {
  const { filters, setFilters } = props;

  return (
    <ReactSelectCheckboxes
      onChange={(e: any) => {
        setFilters((f: any) => {
          return {
            ...f,
            status: e,
          };
        });
      }}
      options={[
        { label: "Assigned", value: "assigned" },
        { label: "Unassigned", value: "unassigned" },
      ]}
      value={filters.status}
    />
  );
}
