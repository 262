import { useHistory } from "react-router-dom";

import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";

import UncontrolledTable from "../../../../components/Tables/UncontrolledTable/UncontrolledTable";

export default function MapTable(props: any) {
  const history = useHistory();

  const { facilities, zones, mapTableData } = props;

  const timeZone = props.timeZone?.value || null;

  const handleClick = (row: any) => {
    const url = row.original.batchId
      ? `/batches/${row.original.batchId}/history/${
          row.original.assetType ? row.original.assetType : ""
        }`
      : `/assetSnapshot/${row.original.assetId}`;
    history.push(url);
  };

  const mapTableColumns = [
    {
      Header: "Date/Time",
      id: "timeOfLog",
      accessor: (row: any) => row.timeOfLogLong || row.timeOfLog || "DNP",

      Cell: (data: any) => {
        const { timeOfLogLong } = data.row.original;

        return (
          <div
            className={"pointer"}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Asset Tag",
      accessor: (row: any) => (row.tag ? row.tag : "DNP"),
      id: "tag",
      Cell: (data: any) => {
        const { tag } = data.row.original;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {tag ? tag : "DNP"}
          </div>
        );
      },
    },

    {
      Header: "Last Event",
      id: "lastEvent",
      accessor: (row: any) => (row.lastEvent ? row.lastEvent : "DNP"),
      Cell: (data: any) => {
        const { lastEvent } = data.row.original;
        return (
          <div
            className={`pointer`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {lastEvent ? lastEvent : "DNP"}
          </div>
        );
      },
    },

    {
      Header: "Facility Name",
      id: "facility",
      accessor: (row: any) =>
        !row.latitude || !row.longitude
          ? "DNP"
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : "Not Registered",

      Cell: (data: any) => {
        const { facilityId, latitude, longitude } = data.row.original;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {!latitude || !longitude
              ? "DNP"
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
    },

    {
      Header: "Zone",
      id: "zone",
      accessor: (row: any) => zones[row.zone?.zoneId]?.name || "",
      Cell: (data: any) => {
        const { zone = {} } = data.row.original;
        const { zoneId = "" } = zone;
        return (
          <div
            className={"pointer"}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {zoneId && zones[zoneId] ? zones[zoneId].name : ""}
          </div>
        );
      },
    },
    {
      Header: "Bin Location",
      id: "binLocation",
      accessor: (row: any) => row.binLocation || "",
      Cell: (data: any) => {
        const { binLocation = "" } = data.row.original;
        return (
          <div
            className={"pointer"}
            onClick={() => {
              handleClick(data.row);
            }}
          >
            {binLocation ? binLocation : ""}
          </div>
        );
      },
    },
  ];

  return (
    <UncontrolledTable
      data={mapTableData ? mapTableData : []}
      columns={mapTableColumns}
      stickyHeader
      defaultSort={[{ id: "timeOfLog" }]}
      totalCountLabel={"Events"}
      assetStatusMap={true}
    />
  );
}
