import React from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { parseUserInfo } from "../utils";
import { searchAssets, searchBins } from "../api";
import AsyncReactSelectCheckboxes from "../../../../components/Selects/AsyncReactSelectCheckbox";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "../../../../components/Forms/FieldTypes/DatePicker";
import Loading from "../../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReactSelectCheckboxes from "../../../../components/Selects/ReactSelectCheckboxes";
import states from "./states.json";
import ClassificationFilters from "./Classifications";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.main,
    marginLeft: "1rem",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props) {
  const {
    classifications,
    defaultColumnOrder,
    displayColumnOptions,
    setState,
    state,
    usersMap,
  } = props;
  const {
    category: displayAssetCategory = {},
    binLocation: displayBinLocation = {},
    event = {},
    facility = {},
    state: displayState = {},
    user = {},
    zone = {},
  } = displayColumnOptions;
  const { availableZones, filters, lists } = state;
  const {
    assetCategories,
    assetIds,
    binLocation,
    events,
    locals,
    locations,
    users,
    zones,
  } = filters;
  const { eventTypes, facilityArray, assetCategoriesList } = lists;
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.instructions ? true : false);
  const [instructions, setInstructions] = React.useState(
    props.instructions ? true : false
  );
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFilterChange = (update) => {
    setState((s) => {
      return {
        ...s,
        filters: {
          ...s.filters,
          ...update,
          start: 0,
        },
        selectedAssets: {},
      };
    });
  };

  const handleClose = (fromSave) => {
    binLocationOptionsPageRef.current = 0;
    if (fromSave && state.showInstructions) {
      setState((s) => {
        return {
          ...s,
          showInstructions: false,
        };
      });
    }
    setOpen(false);
  };

  const closeInstructions = () => {
    setInstructions(false);
  };

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions
  ) => {
    const { apiUrl, token, organizationId, setConfirm } = props;

    const results = await searchAssets(
      { apiUrl, token, organizationId },
      inputValue,
      300,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results) => {
      if (results.error) {
        return setConfirm({
          modalShow: true,
          text: results.error,
          error: true,
        });
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  // ref for incrementing start location in solr query for bin location options
  // having to do this because we are not able to use the length of the options returned to determine start location, as we are constructing distinct sets of options from indistinct results.
  const binLocationOptionsPageRef = React.useRef(0);

  const asyncBinLocationLoadOptions = async (
    inputValue = "",
    loadedOptions
  ) => {
    const { apiUrl, token, organizationId, setConfirm } = props;
    const limit = 300;

    const results = await searchBins(
      { apiUrl, token, organizationId },
      inputValue,
      limit,
      binLocationOptionsPageRef.current
    ).then((results) => {
      if (results.error) {
        return setConfirm({
          modalShow: true,
          text: results.error,
          error: true,
        });
      }

      // increment page ref...
      binLocationOptionsPageRef.current += limit;

      const options =
        results.assetHistories && results.assetHistories.length
          ? [
              ...new Set(
                results.assetHistories
                  .filter((history) => history.binLocation)
                  .map((history) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
          : [];

      // create distinct union of options and loaded options, using lodash _.unionWith to use deep comparison between objects in arrays.
      const union =
        loadedOptions.length > 0
          ? _.unionWith(options, loadedOptions, _.isEqual)
          : options;

      return {
        options: union,
        // I am incrementing a ref by a constant limit outside the scope of the function, which is used to determine the page start location in the data. When that number, the "start" field, is greater than the results.count, we return a false hasMore and stop searching.
        hasMore: binLocationOptionsPageRef.current < results.count,
      };
    });
    return results;
  };

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={handleClickOpen}>
        <Typography className="exportIcon" variant="subtitle1">
          {`Filters`}
          <i className="fas fa-filter ml-2"></i>
        </Typography>
      </span>

      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="filters"
        open={open}
      >
        {instructions ? (
          <>
            <DialogTitle id="instructions">
              Please Select your Filters
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item>
                  <p>
                    To view your Asset History, click on the "Filters" button (
                    <i className="fas fa-filter"></i>
                    ), set your filters and then click the "save" button to save
                    your filters.{" "}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeInstructions}>OK</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="filters" onClose={handleClose}>
              Filters
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={1}>
                {/* Asset Tag(s) */}
                <Grid item xs={12}>
                  <label>Asset Tag</label>
                  <AsyncReactSelectCheckboxes
                    loadOptions={asyncAssetIdSearchLoadOptions}
                    onChange={(e) => {
                      handleFilterChange({ assetIds: e });
                    }}
                    value={assetIds}
                  />
                </Grid>

                {/* Asset Category */}
                {displayAssetCategory.checked ? (
                  <Grid item xs={12}>
                    <label>Asset Category</label>
                    <ReactSelectCheckboxes
                      classNamePrefix="category-select"
                      options={
                        assetCategoriesList && assetCategoriesList.length
                          ? [
                              ...assetCategoriesList.map((cat) => {
                                return {
                                  label: cat.label,
                                  value: cat.id,
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          filters: {
                            ...state.filters,
                            assetCategories: e,
                          },
                        });
                      }}
                      value={assetCategories}
                    />
                  </Grid>
                ) : null}

                {/* Event */}
                {event.checked ? (
                  <Grid item xs={12}>
                    <label>Event</label>
                    <ReactSelectCheckboxes
                      options={
                        eventTypes && eventTypes.length
                          ? [
                              ...eventTypes.map((type) => {
                                return {
                                  label: type,
                                  value: type,
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e) => {
                        handleFilterChange({ events: e });
                      }}
                      value={events}
                    />
                  </Grid>
                ) : null}

                {/* User Name */}
                {user.checked ? (
                  <Grid item xs={12}>
                    <label>User</label>
                    <ReactSelectCheckboxes
                      options={
                        usersMap && Object.keys(usersMap).length
                          ? [
                              ...Object.keys(usersMap)
                                .map((key) => {
                                  return {
                                    label: parseUserInfo(usersMap[key]),
                                    value: key,
                                  };
                                })
                                .filter((user) => user.label !== ""),
                              {
                                label: "User, Android",
                                value: "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7",
                              },
                              {
                                label: "User, iOS",
                                value: "53ef1d1f-3e47-46e7-b444-18170051486f",
                              },
                            ].sort((a, b) => a.label.localeCompare(b.label))
                          : []
                      }
                      onChange={(e) => {
                        handleFilterChange({ users: e });
                      }}
                      value={users}
                    />
                  </Grid>
                ) : null}

                {/* Facility */}
                {facility.checked ? (
                  <Grid item xs={12}>
                    <label style={{ marginTop: "1vh" }}>Facility Name</label>
                    <ReactSelectCheckboxes
                      options={
                        facilityArray && facilityArray.length
                          ? [
                              ...facilityArray.map((facility) => {
                                return {
                                  label: facility.name,
                                  value: facility,
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e) => {
                        handleFilterChange({ locations: e, locale: null });
                      }}
                      value={locations}
                    />
                  </Grid>
                ) : null}

                {/* State */}
                {displayState.checked ? (
                  <Grid item xs={12}>
                    <label style={{ marginTop: "1vh" }}>State</label>
                    <ReactSelectCheckboxes
                      isDisabled={
                        locations === null ||
                        locations === undefined ||
                        locations.length === 0
                          ? false
                          : true
                      }
                      options={states ? states : []}
                      onChange={(e) => {
                        handleFilterChange({ locals: e });
                      }}
                      value={locals}
                    />
                  </Grid>
                ) : null}

                {/* Bin Location */}
                {displayBinLocation.checked ? (
                  <Grid item xs={12}>
                    <label style={{ marginTop: "1vh" }}>Bin Location</label>
                    <AsyncReactSelectCheckboxes
                      loadOptions={asyncBinLocationLoadOptions}
                      onChange={(e) => {
                        handleFilterChange({ binLocation: e || null });
                      }}
                      value={binLocation || null}
                    />
                  </Grid>
                ) : null}

                {/* Zones */}
                {zone.checked ? (
                  <Grid item xs={12}>
                    <label style={{ marginTop: "1vh" }}>Zones</label>
                    <ReactSelectCheckboxes
                      options={availableZones ? availableZones : []}
                      onChange={(e) => {
                        handleFilterChange({ zones: e });
                      }}
                      value={zones}
                    />
                  </Grid>
                ) : null}

                <ClassificationFilters
                  classifications={classifications}
                  state={state}
                  setState={setState}
                  handleFilterChange={handleFilterChange}
                  displayColumnOptions={displayColumnOptions}
                />

                {/* Start Date */}
                <Grid item xs={6}>
                  <label style={{ marginTop: "1vh" }}>Select Start Date</label>
                  <DatePicker
                    format="yyyy/MM/DD"
                    label="Start Date"
                    onChange={(date) => {
                      if (
                        state.filters.endDate &&
                        date > state.filters.endDate
                      ) {
                        alert("Can't set start date later than end date");
                        return;
                      }
                      handleFilterChange({ startDate: date });
                    }}
                    size="small"
                    value={state.filters.startDate}
                  />
                </Grid>

                {/* End Date */}
                <Grid item xs={6}>
                  {" "}
                  <label style={{ marginTop: "1vh" }}>Select End Date</label>
                  <DatePicker
                    format="yyyy/MM/DD"
                    label="End Date"
                    onChange={(date) => {
                      if (
                        state.filters.startDate &&
                        date < state.filters.startDate
                      ) {
                        alert("Can't set end date earlier than start date");
                        return;
                      }
                      handleFilterChange({ endDate: date });
                    }}
                    size="small"
                    value={state.filters.endDate}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {loading ? <Loading color="#5884F5" opaque /> : ""}

              <Button
                className={`${classes.clearFiltersButton} ${classes.button}`}
                onClick={() => {
                  const classificationKeys = Object.keys(
                    classifications?.active || {}
                  ).reduce((x, y) => {
                    return {
                      ...x,
                      [y]: null,
                    };
                  }, {});
                  setState({
                    ...state,
                    filters: {
                      ...state.filters,
                      assetCategories: null,
                      assetIds: null,
                      assetType: null,
                      binLocation: null,
                      endDate: null,
                      events: null,
                      locals: null,
                      locations: null,
                      startDate: null,
                      users: null,
                      zones: null,
                      ...classificationKeys,
                      start: 0,
                    },
                  });
                }}
                variant="contained"
              >
                Clear Filters
              </Button>
              <div style={{ flex: "1 0 0" }} />
              <Button
                className={classes.button}
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className={`${classes.saveButton} ${classes.button}`}
                color="primary"
                id={"eventActivitySaveFilters"}
                onClick={() => {
                  setLoading(true);
                  // spreading filters with displayColumnOptions and defaultColumnOrder to save the state of both, as displayColumnOptions doesnt update in filters
                  props
                    .handleSave({
                      ...state.filters,
                      displayColumnOptions,
                      defaultColumnOrder,
                    })
                    .then(() => {
                      setLoading(false);
                      handleClose(true);
                    });
                }}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
