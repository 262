import { isEmpty } from "lodash";
import chroma from "chroma-js";
import ReactSelectCheckboxes from "../../../../../components/Selects/ReactSelectCheckboxes";

export function LastStatus(props: any) {
  const { filters, setFilters, organization } = props;

  const eventTypes =
    organization.eventTypesMap && !isEmpty(organization.eventTypesMap)
      ? Object.keys(organization.eventTypesMap).map((k: string) => {
          return {
            label: k,
            value: k,
            color: organization.eventTypesMap[k].color || null,
            ...organization.eventTypesMap[k],
          };
        })
      : [];

  return (
    <ReactSelectCheckboxes
      onChange={(e: any) => {
        setFilters((f: any) => {
          return {
            ...f,
            last_event:
              e && e.length
                ? e.map((el: any) => {
                    return el.value;
                  })
                : [],
          };
        });
      }}
      options={eventTypes}
      styles={{
        menuPortal: (styles: any) => ({ ...styles, zIndex: 99999 }),
        option: (
          styles: any,
          { data, isDisabled, isFocused, isSelected }: any
        ) => {
          const color = data.color ? chroma(data.color) : "";
          if (!color) {
            return {
              ...styles,
            };
          }
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? color.alpha(0.1).css()
              : null,
            color: isDisabled
              ? "#ccc"
              : isSelected
              ? chroma.contrast(color, "black") < 7
                ? "white"
                : "black"
              : data.color,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
              ...styles[":active"],
              backgroundColor:
                !isDisabled &&
                (isSelected ? data.color : color.alpha(0.3).css()),
            },
          };
        },
        multiValue: (styles: any, { data }: any) => {
          const color = data.color ? chroma(data.color) : "";
          if (!color) {
            return {
              ...styles,
            };
          }
          return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
          };
        },
        multiValueRemove: (styles: any, { data }: any) => ({
          ...styles,
          color: "#b71d1a",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#b71d1a",
            color: "white",
          },
        }),
      }}
      value={
        filters.last_event && filters.last_event.length > 0
          ? filters.last_event.map((e: any) => {
              return {
                label: e,
                value: e,
              };
            })
          : []
      }
    />
  );
}
