import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { Tooltip, Switch, IconButton } from "@material-ui/core";

function Batches(props) {
  const { state, dispatchState } = props;

  const [addType, setAddType] = useState("");
  const [view, setView] = useState({
    isAddType: false,
  });
  const [pca, setPca] = useState({
    allBatches:
      state.globalSettings.pcaSettings.allBatches === true ? true : false,
    default: state.globalSettings.pcaSettings.default === true ? true : false,
  });
  return (
    <>
      <div className="row">
        <div className="col md-6">
          <h4>
            <Tooltip title="A magnitude of a quantity by which items are assessed or grouped.  Examples include “each”, “box”, “carton”, “pallet”, “barrel”, etc.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Units of Measure{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddType: !view.isAddType,
                })
              }
            >
              {!view.isAddType ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddType ? (
              <SimpleList
                noSelectHighlight
                items={state.globalSettings.assetTypes.map((type) => {
                  return {
                    id: type,
                    label: type,
                  };
                })}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "assetType",
                    payload: {
                      id: id,
                      label: id,
                    },
                  });
                }}
                onClickRemove={(id) => {
                  setView({
                    ...view,
                    isAddType: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetTypes",
                    payload: state.globalSettings.assetTypes.filter(
                      (type) => type !== id
                    ),
                    removeSelected: "assetType",
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Add new Unit of Measure"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Unit of Measure",
                    onChange: (e) => {
                      setAddType({
                        ...addType,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetTypes",
                    payload: state.globalSettings.assetTypes.concat([
                      addType.label.trim(),
                    ]),
                  });
                }}
                goBack={() => {
                  setView({
                    isAddType: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col md-6">
          <h4>
            <Tooltip title="Product Certificate Authority digitally signs your CQRs so that your customers can authenticate that the product they're examining is a genuine product from your company. This is an optional service provided by LocatorX.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Product Certificate Authority{" "}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            <div className="col xs-12">
              PCA Applied to All Batches
              <Switch
                checked={pca.allBatches}
                onChange={(e) => {
                  setPca({
                    allBatches: e.target.checked,
                    default: false,
                  });

                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "pcaSettings",
                    payload: {
                      allBatches: e.target.checked,
                      default: false,
                    },
                  });
                }}
                color="primary"
                name="allBatches"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            {!pca.allBatches ? (
              <div className="col xs-12">
                PCA Defaulted On
                <Switch
                  checked={pca.default}
                  onChange={(e) => {
                    setPca({
                      ...pca,
                      default: e.target.checked,
                    });
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "pcaSettings",
                      payload: {
                        ...pca,
                        default: e.target.checked,
                      },
                    });
                  }}
                  color="primary"
                  name="default"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Batches;
