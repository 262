import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { Tooltip } from "@material-ui/core";

export default function Facilities(props) {
  const { state, dispatchState } = props;

  const [addType, setAddType] = useState("");
  const [radius, setRadius] = useState(
    state.globalSettings.defaultFacilityRadius
  );

  const [view, setView] = useState({
    isAddType: false,
    isEditRadius: false,
  });

  return (
    <>
      <div className="row">
        <div className="col md-6">
          <h4>
            <Tooltip title="User-identified facility naming convention.  Examples include “Headquarters”, “Warehouse”, “Room 207”, “Customer”, etc.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Facility Types{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddType: !view.isAddType,
                })
              }
            >
              {!view.isAddType ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddType ? (
              <SimpleList
                noSelectHighlight
                items={state.globalSettings.locationTypes.map((type) => {
                  return {
                    id: type,
                    label: type,
                  };
                })}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "locationType",
                    payload: {
                      id: id,
                      label: id,
                    },
                  });
                }}
                onClickRemove={(id) => {
                  setView({
                    isAddType: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "locationTypes",
                    payload: state.globalSettings.locationTypes.filter(
                      (type) => type !== id
                    ),
                    removeSelected: "locationType",
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Add new Facility Type"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Facility Type",
                    onChange: (e) => {
                      setAddType({
                        ...addType,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "locationTypes",
                    payload: state.globalSettings.locationTypes.concat([
                      addType.label.trim(),
                    ]),
                  });
                }}
                goBack={() => {
                  setView({
                    isAddType: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col md-6">
          <h4>
            <Tooltip title="Set default minimum radius (meters) for all newly created or imported facilities.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Default Radius{" "}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isEditRadius ? (
              <SimpleList
                noSelectHighlight
                items={[
                  {
                    id: "radius",
                    label:
                      state.globalSettings.defaultFacilityRadius + " meters",
                  },
                ]}
                onClickEdit={() => {
                  setView({
                    ...view,
                    isEditRadius: true,
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={`Edit default radius`}
                submitBtnText={"Submit"}
                fields={[
                  {
                    id: "radiusSlider",
                    label: "Radius (meters)",
                    isSlider: true,
                    value: parseInt(radius),
                    step: 10,
                    min: 10,
                    max: 1600,
                    onChange: (e) => {
                      setRadius(e);
                    },
                  },
                  {
                    id: "radius",
                    label: "Radius (meters)",
                    inputProps: { min: 10, max: 1600, step: 10 },
                    type: "number",
                    value: radius,
                    onChange: (e) => {
                      setRadius(e.target.value);
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "defaultFacilityRadius",
                    payload: radius,
                  });
                  setView({
                    ...view,
                    isEditRadius: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditRadius: false,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
