import { isEqual, isEmpty } from "lodash";
import { difference } from "./utils";

const saveGlobalSettings = async (props, globalSettings, globalSettingsRef) => {
  const { organization, organizationId, apiUrl, token, userId } = props;

  const {
    classifications: classificationsFromRef,
    formRealActions: formRealActionsRef,
    mobileRoles: mobileRolesRef,
    inactiveClassifications = {},
  } = globalSettingsRef?.current || {};

  const { classifications, formRealActions } = globalSettings;

  let mobileRoles = { ...globalSettings.mobileRoles };

  //  build logic to cycle through and call CRUD functions on classifications instead of saving global  settings
  if (!isEqual(classificationsFromRef, classifications)) {
    return saveClassifications(
      { organizationId, apiUrl, token, userId },
      classificationsFromRef,
      classifications,
      inactiveClassifications
    );
  }

  if (
    !isEqual(formRealActionsRef, formRealActions) ||
    !isEqual(mobileRolesRef, mobileRoles)
  ) {
    // If a formReaclAction has been changed. We Iterate through mobileRoles
    Object.keys(mobileRoles).forEach((role) => {
      // We iterate through each roles Actions
      if (mobileRoles[role] && mobileRoles[role].actions) {
        Object.keys(mobileRoles[role].actions).forEach((action) => {
          if (
            mobileRoles[role].actions[action] &&
            mobileRoles[role].actions[action].formId &&
            // Check to see if the action is in our formRealActions group. If so, make sure the right realAction is applied
            formRealActions[mobileRoles[role].actions[action].formId]
          ) {
            // Grab the appropiate realAction
            const realAction =
              formRealActions[mobileRoles[role].actions[action].formId]
                .realAction === "none"
                ? null
                : formRealActions[mobileRoles[role].actions[action].formId]
                    .realAction;

            // Assign the real action to the proper action
            mobileRoles[role].actions[action].realAction = realAction;
          }
        });
      }
    });
  }

  const payload = {
    eventTypesMap: globalSettings.eventTypesMap,
    rolesMap: {
      mobile: mobileRoles,
    },
    assetCategoriesList: globalSettings.assetCategoriesList,
    customAssetFieldsList: globalSettings.customAssetFieldsList,
    assetTypes: globalSettings.assetTypes,
    propertiesMap: {
      ...organization.propertiesMap,
      defaultFacilityRadius: globalSettings.defaultFacilityRadius,
      formRealActions: globalSettings.formRealActions,
      itemLevelDataElements: globalSettings.itemLevelDataElements,
      locationTypes: globalSettings.locationTypes,
      pcaSettings: globalSettings.pcaSettings,
      productTypes: globalSettings.productTypes,
      zoneCategories: globalSettings.zoneCategories,
    },
  };

  const results = await fetch(`${apiUrl}organizations/${organizationId}`, {
    method: "PUT",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return results;
};

const saveUserSettings = async (props, userSettings) => {
  const { apiUrl, token, userId } = props;
  const { userInfo, consoleSettings } = userSettings;
  const { email, changePassword, timeZone } = userInfo;
  // if the user is changing their password, fire off function that checks to make sure that their old password is correct
  if (changePassword) {
    let csmToken = "";

    // if prod, use secure CSM token method
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
      const origin = (process.env.REACT_APP_HOST || window.location.href)
        .split("/")
        .slice(0, 3)
        .join("/");

      const apiLabUrl = process.env.REACT_APP_API_LAB_ENDPOINT;

      csmToken = await getCsmToken(apiLabUrl, origin);
    } else {
      // else in dev / staging / testing, use csm token from environment variable
      csmToken = process.env.REACT_APP_CSM_TOKEN;
    }

    const loginPayload = {
      csmToken: csmToken,
      email: changePassword.oldEmail,
      password: changePassword.oldPassword,
    };

    const results = await fetch(`${apiUrl}auth`, {
      method: "POST",
      headers: {
        "auth-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.success) {
          const payload = {
            email: email,
            propertiesMap: {
              timeZone: timeZone,
              consoleSettings: consoleSettings,
            },
            password: changePassword.newPassword,
          };
          const results = await fetch(`${apiUrl}appUsers/${userId}`, {
            method: "PUT",
            headers: {
              "auth-token": token,
              "content-type": "application/json",
            },
            body: JSON.stringify(payload),
          })
            .then((res) => res.json())
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.log(err);
              return err;
            });

          return results;
        } else {
          return res;
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return results;
  } else {
    const payload = {
      email: email,
      propertiesMap: {
        consoleSettings: consoleSettings,
        timeZone: timeZone,
      },
    };

    const results = await fetch(`${apiUrl}appUsers/${userId}`, {
      method: "PUT",
      headers: {
        "auth-token": token,
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return results;
  }
};

const resetUserWidgetSettings = async (props) => {
  const { apiUrl, token, userId } = props;
  let myHeaders = new Headers();
  myHeaders.append("auth-token", token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    propertiesMap: {
      widgetSettings: null,
    },
  });

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
  };

  fetch(`${apiUrl}appUsers/${userId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

// works for parent and child classifications, just make sure parentId is truthy
const createClassification = async (props, classification) => {
  const payload = JSON.stringify({
    name: classification.name || classification.label,
    organizationId: props.organizationId,
    parentId: classification.parentId || null,
    classificationChildren: classification.classificationChildren || null,
  });

  const results = await fetch(`${props.apiUrl}classifications`, {
    method: "POST",
    headers: {
      "auth-token": props.token,
      "Content-Type": "application/json",
    },
    body: payload,
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

const updateClassification = async (props, classification) => {
  const { classificationId = "" } = classification;
  const results = await fetch(
    `${props.apiUrl}classifications/${classificationId}`,
    {
      method: "PUT",
      headers: {
        "auth-token": props.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(classification),
    }
  )
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

const deleteClassification = async (props, classificationId) => {
  const results = await fetch(
    `${props.apiUrl}classifications/${classificationId}`,
    {
      method: "PUT",
      headers: {
        "auth-token": props.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: "inactive",
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const saveClassifications = async (
  props,
  classificationsFromRef,
  classifications,
  inactiveClassifications
) => {
  const mapAndCall = Object.keys(classifications).map(async (key) => {
    // build children list for post
    const classificationChildren =
      Object.keys(classifications[key]?.children || {}).map((childKey) => {
        return {
          ...classifications[key].children[childKey],
        };
      }) || [];

    // If key is null, put "inactive" to endpoint
    if (
      classifications[key] === null &&
      classificationsFromRef[key]?.classificationId
    ) {
      return await deleteClassification(
        props,
        classificationsFromRef[key].classificationId
      )
        .then((res) => res)
        .catch((err) => {
          return { error: err };
        });
    }

    // if new children keys, it may be new children
    if (
      classifications[key]?.children &&
      !isEqual(
        classifications[key]?.children,
        classificationsFromRef[key]?.children
      )
    ) {
      const diff = difference(
        classifications[key]?.children || {},
        classificationsFromRef[key]?.children || {}
      );

      if (!isEmpty(diff)) {
        // If new children keys are different from previous children keys, PUT children from diff and POST children not in diff
        const iterateAndPost = Object.keys(diff).map(async (childKey) => {
          // new one
          if (!classificationsFromRef[key]?.children[childKey]) {
            // check if child class is in inactive list...  PUT update if it is.
            if (
              classificationsFromRef[key]?.inactiveChildren &&
              classificationsFromRef[key].inactiveChildren[childKey]
            ) {
              return await updateClassification(props, {
                ...classificationsFromRef[key].inactiveChildren[childKey],
                status: "active",
              })
                .then((res) => res)
                .catch((err) => {
                  return { error: err };
                });
            } else {
              // else, create new child class
              return await createClassification(props, {
                ...diff[childKey],
                parentId: classifications[key].classificationId,
              })
                .then((res) => res)
                .catch((err) => {
                  return { error: err };
                });
            }
          } else if (diff[childKey] === null) {
            const { classificationId = "" } =
              classificationsFromRef[key].children[childKey];
            return await deleteClassification(props, classificationId)
              .then((res) => res)
              .catch((err) => {
                return { error: err };
              });
          }
        });
        return Promise.all(iterateAndPost).then((res) => res);
      }
    }
    // if key not in classificationsRef and is not a deletion (null), it may be a new classification.
    if (!classificationsFromRef[key] && classifications[key] !== null) {
      // if key is in inactive classifications, then we just need to PUT "active" to endpoint
      if (inactiveClassifications[key]) {
        return await updateClassification(props, {
          ...inactiveClassifications[key],
          status: "active",
        })
          .then((res) => res)
          .catch((err) => {
            return { error: err };
          });
      }

      // If classification ID is not in classifications, then it is a new root classification. POST new root classification.
      else {
        return await createClassification(props, {
          ...classifications[key],
          classificationChildren: classificationChildren,
        })
          .then((res) => res)
          .catch((err) => {
            return { error: err };
          });
      }
    }
  });

  return Promise.all(mapAndCall).then(async (res) => {
    if (res.some((response) => response?.error || response?.err)) {
      const { error = "" } = res.find((el) => el && el.error);
      return {
        error: error,
      };
    } else {
      return await fetch(`${props.apiUrl}classifications`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": props.token,
        },
      })
        .then((res) => res.json())
        .then(async (res) => {
          // TODO remove after full redux integration
          // clear displayColumnsData everywhere
          return await resetDisplayColumnOptions(props).then((columnsReset) => {
            return {
              success: true,
              classifications: res,
            };
          });
        })
        .catch((err) => {
          console.log(err);
          return {
            error: err,
          };
        });
    }
  });
};

const resetDisplayColumnOptions = async (props) => {
  const payload = {
    propertiesMap: {
      assetSnapshotSettings: {
        displayColumnOptions: null,
      },
      assetHistoryTableSettings: {
        displayColumnOptions: null,
      },
      assetStatusTableSettings: {
        displayColumnOptions: null,
      },
    },
  };
  const results = await fetch(`${props.apiUrl}appUsers/${props.userId}`, {
    method: "PUT",
    headers: {
      "auth-token": props.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

const getCsmToken = async (apiUrl, url) => {
  // REACT_APP_CSM_TOKEN is now the handshake token needed to get the CSM token
  const body = {
    csmToken: process.env.REACT_APP_CSM_HANDSHAKE_TOKEN,
    url: url,
  };
  const results = await fetch(`${apiUrl}data/csm/getCredential`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.csmToken;
    })

    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });
  return results;
};

export { saveGlobalSettings, saveUserSettings, resetUserWidgetSettings };
