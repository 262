import React from "react";
import { forgotPasswordRequest } from "./utils/api";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: any) => ({
  button: {
    backgroundColor: "#3D5BA9",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#5884F5",
      color: "#FFF",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  input: {
    margin: "2rem 0",
  },
  icon: {
    color: theme.palette.success.main,
    marginBottom: "1rem",
    textAlign: "center",
  },
  fontWeight: {
    fontWeight: 600,
  },
  root: {
    width: "100%",
  },
}));

export default function GrabEmail(props: any) {
  const classes = useStyles();
  const { setState, state = {} } = props;
  const { fieldValues = {}, resetPasswordRequestComplete } = state;
  const { userName = {} } = fieldValues;

  function validateFields() {
    let error: Boolean = false;
    if (userName.value.length === 0) {
      error = true;
      setState((prevState: any) => ({
        ...prevState,
        fieldValues: {
          ...prevState.fieldValues,
          userName: {
            ...prevState.fieldValues.userName,
            error: true,
          },
        },
      }));
    }

    if (!error) {
      forgotPasswordRequest(props);
    }
  }

  const content = resetPasswordRequestComplete ? (
    <Grid container>
      <Grid className={classes.icon} item xs={12}>
        <i className="far fa-check-circle fa-5x" />
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={`${classes.fontWeight} ${classes.textCenter} ${classes.input}`}
          variant="h4"
        >
          Check your Email
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={`${classes.textCenter} pointer`}
          onClick={() => {
            setState((prevState: any) => ({
              ...prevState,
              grabEmail: false,
              resetPasswordRequestComplete: false,
            }));
          }}
          variant="subtitle1"
        >
          Back to Login
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className={`${classes.fontWeight} ${classes.textCenter}`}
          variant="h4"
        >
          Forgot Password
        </Typography>
      </Grid>
      <Grid className={classes.input} item xs={12}>
        <FormControl className={classes.root}>
          <SimpleTextField
            error={userName.error}
            label="Enter Email"
            id="userName"
            onChange={(event: any) => {
              setState((prevState: any) => ({
                ...prevState,
                fieldValues: {
                  ...prevState.fieldValues,
                  userName: {
                    error: false,
                    value: event.target.value,
                  },
                },
              }));
            }}
            value={userName.value}
            variant="standard"
          />
        </FormControl>
      </Grid>
      <Grid className={classes.input} item xs={12}>
        <Button
          className={classes.button}
          onClick={() => {
            validateFields();
          }}
          variant="contained"
        >
          Submit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={`${classes.textCenter} pointer`}
          onClick={() => {
            setState((prevState: any) => ({
              ...prevState,
              grabEmail: false,
            }));
          }}
          variant="subtitle1"
        >
          Go back
        </Typography>
      </Grid>
    </Grid>
  );

  return content;
}
