import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "../../../components/Forms/FieldTypes/DatePicker";

import Loading from "../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,

    color: "white",
  },
  filterButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    marginLeft: "1rem !important",
  },
  filterOption: {
    color: theme.palette.text.secondary,
    marginTop: "1vh",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DeviceLogFilterMenu(props: any) {
  const { radiosAndNodes = {}, setState, state } = props;
  const [filterState, setFilterState] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // We set the initial filters using useEffect for the instance that a user does not

  // navigate away from the page, but uses the "Tag Look up" bar to go to another snapshot page

  useEffect(() => {
    setFilterState({ ...state.deviceLogFilters });
  }, [state.deviceLogFilters]);

  return (
    <>
      <span onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <div className="exportIcon">
          Filters
          <i className="fas fa-filter ml-2"></i>
        </div>
      </span>
      <Dialog
        aria-labelledby="filters"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Device Identifier */}
            {/* <Grid item xs={12}>
              <label className={classes.filterOption}>Device Identifier</label>
              <ReactSelectCheckboxes
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    devices: e,
                  }));
                }}
                options={
                  devices
                    ? [
                        ...Object.keys(devices).map((device: any) => {
                          const { assetId, tag } = devices[device];
                          return {
                            label: tag,
                            value: assetId,
                          };
                        }),
                      ]
                    : [{ label: "", value: "" }]
                }
                value={filterState.devices}
              />
            </Grid> */}

            {/* Type */}
            <Grid item xs={12}>
              <label className={classes.filterOption}>Type</label>
              <ReactSelectCheckboxes
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    types: e,
                  }));
                }}
                options={[
                  { label: "M", value: "M" },
                  { label: "R", value: "R" },
                ]}
                value={filterState.types}
              />
            </Grid>

            {/* Node */}
            <Grid item xs={12}>
              <label className={classes.filterOption}>Node</label>
              <ReactSelectCheckboxes
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    nodes: e,
                  }));
                }}
                options={[
                  ...Object.keys(radiosAndNodes.nodes).map((item: any) => ({
                    label: item,
                    value: item,
                  })),
                ]}
                value={filterState.nodes}
              />
            </Grid>

            {/* Radio ID */}
            <Grid item xs={12}>
              <label className={classes.filterOption}>Radio ID</label>
              <ReactSelectCheckboxes
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    radios: e,
                  }));
                }}
                options={[
                  ...Object.keys(radiosAndNodes.radios).map((item: any) => ({
                    label: item,
                    value: item,
                  })),
                ]}
                value={filterState.radios}
              />
            </Grid>

            {/* Start Date */}
            <Grid item xs={6}>
              <label className={classes.filterOption}>Select Start Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="Start Date"
                onChange={(date: any) => {
                  if (filterState.endDate && date > filterState.endDate) {
                    alert("Can't set start date later than end date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    startDate: date,
                  }));
                }}
                size="small"
                value={filterState.startDate}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={6}>
              <label className={classes.filterOption}>Select End Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="End Date"
                onChange={(date: any) => {
                  if (filterState.startDate && date < filterState.startDate) {
                    alert("Can't set end date earlier than start date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    endDate: date,
                  }));
                }}
                size="small"
                value={filterState.endDate}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}
          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilterState({
                assetIds: null,
                endDate: null,
                events: null,
                locals: null,
                locations: null,
                nodes: null,
                radios: null,
                startDate: null,
                types: null,
                users: null,
                zones: null,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.filterButton} ${classes.button}`}
            onClick={() => {
              setLoading(true);
              setState((prevState: any) => ({
                ...prevState,
                selectedAssets: {},
                deviceLogFilters: {
                  ...prevState.deviceLogFilters,
                  ...filterState,
                  start: "0",
                },
                showInstructions: false,
              }));

              setLoading(false);
              handleClose();
            }}
            variant="contained"
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
