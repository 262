const OriginAddressCells = ["__EMPTY_4", "__EMPTY_5", "__EMPTY_6"];

const OriginLatLongCells = ["__EMPTY_7", "__EMPTY_8"];

const DestinationLatLongCells = ["__EMPTY_13", "__EMPTY_14"];

const validateLat =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
const validateLong =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

export const validateAndFormatSpreadsheet = (spreadsheet, props, response) => {
  const { organization = {} } = props;
  const { assetTypes = [] } = organization;
  const { propertiesMap = {} } = organization;
  const { productTypes = [] } = propertiesMap;

  let success = true;
  let message = "";

  const XLSX = require("xlsx");

  const readFile = (file, onLoadCallback) => {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsArrayBuffer(file);
  };

  readFile(spreadsheet, (e) => {
    // use result in callback...
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });

    const sheet_name_list = workbook.SheetNames;
    const parsedSheets = sheet_name_list.map((sheet) => {
      return XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    });

    let checker = (arr, target) => target.every((v) => arr.includes(v));

    parsedSheets.forEach((sheet, idx) => {
      const addressKeys = Object.keys(sheet[1]);

      // Validate that batch ID is specified
      if (!sheet[1]["__EMPTY"]) {
        return (
          (success = false),
          (message = `Missing Batch ID in ${sheet_name_list[idx]}.`)
        );
      }

      // Validate there is at least one product and one asset specified
      if (
        !sheet[4] ||
        !sheet[4]["Batch"] ||
        !sheet[4]["Origin"] ||
        !sheet[4]["__EMPTY"] ||
        !sheet[4]["__EMPTY_4"]
      ) {
        return (
          (success = false),
          (message = `Please make sure there is at least one Product Name, Product Category, Product Code, and Unit of Measure in ${sheet_name_list[idx]}.`)
        );
      }
      // Validate that batch origin exists, and lat long is correct on origin and desitination
      if (
        !checker(addressKeys, OriginAddressCells) &&
        !checker(addressKeys, OriginLatLongCells)
      ) {
        return (
          (success = false),
          (message = `Please make sure you add either a full address or latitude/longitude for the batch origin location in ${sheet_name_list[idx]}.`)
        );
      }
      if (
        sheet[1][OriginLatLongCells[0]] &&
        (!validateLat.test(sheet[1][OriginLatLongCells[0]]) ||
          !validateLong.test(sheet[1][OriginLatLongCells[1]]))
      ) {
        return (
          (success = false),
          (message = `Please be sure to add a correct latitude and longitude for the batch origin location in ${sheet_name_list[idx]}.`)
        );
      }
      if (
        sheet[1][DestinationLatLongCells[0]] &&
        (!validateLat.test(sheet[1][DestinationLatLongCells[0]]) ||
          !validateLong.test(sheet[1][DestinationLatLongCells[1]]))
      ) {
        return (
          (success = false),
          (message = `Please be sure to add a correct latitude and longitude for the batch destination location in ${sheet_name_list[idx]}.`)
        );
      }

      // from sheet[4] and every row after, check that product category and unit of measure exits in organization
      for (let i = 4; i < sheet.length; i++) {
        if (
          sheet[i].Origin &&
          (sheet[i].Batch || sheet[i]["__EMPTY"]) &&
          // TODO refactor after database backfill for productTypes
          !productTypes
            .map((t) => {
              if (typeof t === "string") {
                return t;
              }
              return t.id;
            })
            .includes(sheet[i].Origin)
        ) {
          return (
            (success = false),
            (message = `Please be sure to specify a product category that already exists in your organization in row ${
              i + 3
            } in ${
              sheet_name_list[idx]
            }. You can do so in the "settings" pane of the console.`)
          );
        }
        if (
          sheet[i]["__EMPTY_4"] &&
          !assetTypes.includes(sheet[i]["__EMPTY_4"])
        ) {
          return (
            (success = false),
            (message = `Please be sure to specify a unit of measure that already exists in your organization in row ${
              i + 3
            } in ${
              sheet_name_list[idx]
            }. You can do so in the "settings" pane of the console.`)
          );
        }
      }
    });

    const fileName = `BatchImports.xlsx`;

    // // write ArrayBuffer of file
    const rawFile = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    // construct javascript File object from Array Buffer, send in response callback
    const file = new File([rawFile], fileName);

    file.path = fileName;

    response({
      success: success,
      message: message,
      file: success && file ? file : "",
    });
  });
};
