import React from "react";
import request from "superagent";
import { Form, Col } from "react-bootstrap";
import NotificationModal from "../../components/Modals/NotificationModal";
import locatorx from "../../assets/locatorx.png";
import background from "../../assets/background.jpg";
import styled from "styled-components";
import RequiredField from "../../components/Forms/RequiredField/RequiredField";
import { Link } from "react-router-dom";

const CreateUserContainer = styled.div`
  background: url(${background}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(50, 53, 92, 0.6);
  }
`;

const SignUpFormRow = styled.div`
  height: 100vh;
`;

const SignupFormContainer = styled.div`
  background-color: rgb(50, 53, 92);
`;

const FormContainer = styled.div`
  cursor: pointer;
`;

export default class UserInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token,
      user: {},
      newPassword: "",
      verifyPassword: "",
      validated: false,
      confirmationText: "",
      modalShow: false,
      notificationIcon: "",
      notificationColor: "",
      accepted: false,
    };
  }
  componentDidMount() {
    request
      .get(`${this.props.apiUrl}appUsers/token/${this.state.token}`)
      .then((response) => {
        this.setState({
          user: response.body.appUser,
        });
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const { newPassword, verifyPassword } = this.state;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
    } else if (newPassword !== verifyPassword) {
      this.setState({
        modalShow: true,
        confirmationText: "Password does not Match",
        notificationColor: "bg-danger",
        notificationIcon: "fas fa-times-circle",
      });
    } else if (form.checkValidity() === true) {
      let invitation = {
        newPassword,
        verifyPassword,
      };
      request
        .post(
          `${this.props.apiUrl}appUsers/${this.state.token}/acceptInvitation`
        )
        .send(invitation)
        .then((response) => {
          if (response.body.success) this.setState({ accepted: true });
          else
            this.setState({
              modalShow: true,
              confirmationText: `There was an error: ${response.body.error}`,
              notificationColor: "bg-danger",
              notificationIcon: "fas fa-times-circle",
            });
        });
    }
  }

  render() {
    const {
      newPassword,
      verifyPassword,
      validated,
      confirmationText,
      modalShow,
      notificationIcon,
      notificationColor,
      accepted,
    } = this.state;
    let modalClose = () => this.setState({ modalShow: false });

    return (
      <CreateUserContainer className="create-user">
        <NotificationModal
          confirmationModalShow={modalShow}
          modalClose={modalClose}
          confirmationText={confirmationText}
          icon={notificationIcon}
          color={notificationColor}
        />
        <div className="container ">
          <SignUpFormRow className="row justify-content-center align-items-center">
            <SignupFormContainer className="col-md-6 col-sm-8 col-xs-11 rounded shadow py-4 text-white">
              <div className="my-4">
                <span>
                  <center>
                    <img alt="company" src={locatorx} />
                  </center>
                </span>
              </div>
              {!accepted ? (
                <h4>
                  <center>User Invitation</center>
                </h4>
              ) : (
                ""
              )}
              <Form
                noValidate
                validated={validated}
                onSubmit={(event) => {
                  this.handleSubmit(event);
                }}
                className="form-horizontal px-3 d-flex flex-column justify-content-center align-items-center"
              >
                {!accepted ? (
                  <Form.Row>
                    <Form.Group as={Col} md="12">
                      <Form.Label className="mt-2 mb-0">Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="newPassword"
                        placeholder="Password"
                        value={newPassword}
                        onChange={(event) => {
                          this.setState({ newPassword: event.target.value });
                        }}
                      />
                      <RequiredField />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                      <Form.Label className="mt-2 mb-0">
                        Verify Password
                      </Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="verifyPassword"
                        placeholder="Verify Password"
                        value={verifyPassword}
                        onChange={(event) => {
                          this.setState({ verifyPassword: event.target.value });
                        }}
                      />
                      <RequiredField />
                    </Form.Group>
                    <FormContainer className="w-100 d-flex flex-column justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="btn btn-lg btn-secondary"
                      >
                        Submit
                      </button>
                    </FormContainer>
                  </Form.Row>
                ) : (
                  <Form.Row>
                    <div className="col-md-12 text-center">
                      <h5>Thank you for activating your LXConnect account.</h5>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12 pointer text-center mt-3">
                      <Link
                        className="btn btn-lg btn-secondary font-weight-bold"
                        to={"/login"}
                      >
                        Login
                      </Link>
                    </div>
                  </Form.Row>
                )}
              </Form>
            </SignupFormContainer>
          </SignUpFormRow>
        </div>
      </CreateUserContainer>
    );
  }
}
