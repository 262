import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import request from "superagent";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import SimpleCheckbox from "../../components/Forms/FieldTypes/Checkbox";
import SimpleButton from "../../components/Forms/FieldTypes/Button";
import { isArray } from "lodash";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  select: {
    width: "100%",
    marginBottom: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function EditUser(props) {
  const [state, setState] = useState({
    email: { error: false, id: "email", value: "" },
    firstName: { error: false, id: "firstName", value: "" },
    jobTitle: { error: false, id: "jobTitle", value: "" },
    lastName: { error: false, id: "lastName", value: "" },
    memberOf: [],
    consoleRole: { error: false, id: "consoleRole", value: "" },
    userType: { error: false, id: "userType", value: "" },
    mobileRole: { error: false, id: "mobileRole", value: "" },
    notes: { error: false, id: "notes", value: "" },
    organization: { error: false, id: "organization", value: "" },
    password: { error: false, id: "password", value: "" },
    phone: { error: false, id: "phone", value: "" },
    readOnly: false,
    resetPassword: false,
  });

  const { groups, readOnly, isCreate, consoleRoles, mobileRoles } = props;
  const classes = useStyles();
  const {
    email,
    firstName,
    jobTitle,
    lastName,
    memberOf = [],
    consoleRole,
    userType,
    mobileRole,
    notes,
    password,
    phone,
    resetPassword = false,
  } = state;

  const organizationGroupOptions = (organizationGroups) => {
    let groupOptions = [];
    organizationGroups.forEach((item) => {
      groupOptions.push({
        value: item.name,
        label: item.name,
      });
    });
    return groupOptions;
  };

  useEffect(() => {
    const { selectedUser = {}, mobileRoles = {}, consoleRoles = {} } = props;
    const {
      email = "",
      firstName = "",
      lastName = "",
      propertiesMap = {},
      roles = [],
    } = selectedUser;

    // userType, e.g., "Asset", "Product", or "Asset/Product", is called consoleRole in the propertiesMap
    const {
      consoleRole: userType = "",
      jobTitle = "",
      memberOf = [""],
      notes = "",
      phone = "",
    } = propertiesMap;

    // console and mobile roles are stored in the roles array on main user column
    const usersConsoleRole = roles.filter((role) => consoleRoles[role])[0];
    const usersMobileRole = roles.filter((role) => mobileRoles[role])[0];

    const memberOfFormatted = isArray(memberOf) ? memberOf : [memberOf];

    setState((prevState) => ({
      ...prevState,
      consoleRole: { error: false, id: "consoleRole", value: usersConsoleRole },
      email: { error: false, id: "email", value: email },
      firstName: { error: false, id: "firstName", value: firstName },
      jobTitle: { error: false, id: "jobTitle", value: jobTitle },
      lastName: { error: false, id: "lastName", value: lastName },
      memberOf: memberOfFormatted,
      mobileRole: { error: false, id: "mobileRole", value: usersMobileRole },
      notes: { error: false, id: "notes", value: notes },
      password: { error: false, id: "password", value: "" },
      phone: { error: false, id: "phone", value: phone },
      propertiesMap,
      userType: { error: false, id: "userType", value: userType },
    }));
  }, [props]);

  function onChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        error: false,
        id: event.target.id,
        value: event.target.value,
      },
    }));
  }

  function buildPassword() {
    // taken from https://codepen.io/olchyk98/pen/ZMXXgQ?editors=0010
    let a = "",
      b = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%&?*",
      c = 13;
    for (let ma = 0; ma < c; ma++) {
      a += b[Math.floor(Math.random() * b.length)];
    }
    setState((prevState) => ({
      ...prevState,
      password: { error: false, id: "password", value: a },
    }));
  }

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [
      email,
      firstName,
      lastName,
      mobileRole,
      // organization,
      consoleRole,
      userType,
    ];

    fieldsToValidate.forEach((element) => {
      const { id, value } = element;

      if (!value || value.length === 0) {
        isValidated = false;
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true },
          };
        });
      }
    });
    if (isValidated) {
      handleSubmit();
    }
  }

  function handleSubmit(event) {
    const browserTimeZone = moment.tz.guess();
    const {
      apiUrl,
      selectedUser = {},
      notificationModal,
      onHide,
      onSuccess,
      token,
    } = props;
    const {
      email,
      firstName,
      lastName,
      mobileRole,
      password,
      phone,
      propertiesMap,
      resetPassword,
      userType,
      consoleRole,
    } = state;
    const { appUserId } = selectedUser;

    const consoleNoAccess =
      consoleRole !== "No Console Access" &&
      consoleRole !== "No LXConnect Access";

    const mobileNoAccess = mobileRole !== "No Mobile Access";

    let body = {
      appUserId,
      contacts: [{ type: "phone", value: phone.value }],
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      permissions: [],
      propertiesMap,
      roles: [consoleRole.value, mobileRole.value],
    };

    // Sets the users time zone
    propertiesMap.timeZone = {
      label: browserTimeZone,
      useDefaultTimeZone: true,
      value: browserTimeZone,
    };

    // Sets the users console user type, e.g., "Product" or "Asset" or "Asset/Product". This is still stored as consoleRole in the users' propertiesMap
    propertiesMap.consoleRole = userType.value;

    // set user job title, memberOf, notes, phone number...
    propertiesMap.jobTitle = state.jobTitle.value;
    propertiesMap.memberOf = state.memberOf;
    propertiesMap.phone = state.phone.value;
    propertiesMap.notes = state.notes.value;

    // Sets the password in the request body if reset password is true
    if (resetPassword === true) {
      body.password = password.value;
    }

    // Deals with the situation that a user selects no console and no mobile access
    if (!consoleNoAccess && !mobileNoAccess) {
      notificationModal(
        'User may not simultaneously have "Console No Access" and "Mobile No Access". Please change roles',
        true,
        "fas fa-times-circle",
        4000
      );
      return null;
    }

    // Deals with console permissions
    if (consoleNoAccess) {
      body.permissions.push("console");
    }

    // Deals with mobile permissions
    if (mobileNoAccess) {
      body.permissions.push("mobile");
    }

    let req = null;
    if (props.isCreate) {
      req = request.post(`${apiUrl}appUsers`);
    } else {
      req = request.put(`${apiUrl}appUsers/${appUserId}`);
    }

    req
      .set("auth-token", token)
      .send(body)
      .then((response) => {
        if (response.body.success) {
          onHide();
          notificationModal(
            "User Successfully Updated",
            false,
            "fas fa-check-circle"
          );
          onSuccess();
        } else {
          notificationModal(
            `Error: ${response.body.error}`,
            true,
            "fas fa-times-circle"
          );
        }
      });
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      {/* First Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={firstName.error}
          id="firstName"
          label="First Name"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={firstName.value}
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={lastName.error}
          label="Last Name"
          id="lastName"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={lastName.value}
        />
      </Grid>

      {/* Email */}
      <Grid item xs={6}>
        <SimpleTextField
          error={email.error}
          label="Email"
          id="email"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={email.value}
        />
      </Grid>

      {/* Phone Number */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Phone Number - optional"
          id="phone"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={phone.value}
        />
      </Grid>

      {/* Job Title */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Job Title - optional"
          id="jobTitle"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={jobTitle.value}
        />
      </Grid>

      {/* User's type, e.g., Product, Asset, Asset/Product */}
      <Grid item xs={6}>
        <SimpleSelect
          error={userType.error}
          helperText={userType.error ? "Required Field" : ""}
          label="User Type"
          id="userType"
          onChange={(event) => {
            event.persist();
            onChange({ target: { id: "userType", value: event.target.value } });
          }}
          readOnly={readOnly}
          value={userType.value}
          options={[
            { value: "Asset/Product", label: "Asset/Product" },
            { value: "Asset", label: "Asset-Warehouse" },
            { value: "Asset-Operations", label: "Asset-Operations" },
            { value: "Product", label: "Product" },
          ]}
          variant="outlined"
        />
      </Grid>

      {/* User's LXConnect Role, e.g., Admin, Lite, etc*/}
      <Grid item xs={6}>
        <SimpleSelect
          error={consoleRole.error}
          label="LXConnect Role"
          helperText={consoleRole.error ? "Required Field" : ""}
          id="consoleRole"
          onChange={(event) => {
            event.persist();
            onChange({
              target: { id: "consoleRole", value: event.target.value },
            });
          }}
          readOnly={readOnly}
          value={consoleRole.value}
          options={Object.keys(consoleRoles).map((element) => {
            if (element === "No Console Access") {
              return { label: "No LXConnect Access", value: element };
            }
            return { label: element, value: element };
          })}
          variant="outlined"
        />
      </Grid>

      {/* Mobile */}
      <Grid item xs={6}>
        <SimpleSelect
          error={mobileRole.error}
          helperText={mobileRole.error ? "Required Field" : ""}
          label="Mobile Role"
          id="mobile"
          onChange={(event) => {
            event.persist();
            onChange({
              target: { id: "mobileRole", value: event.target.value },
            });
          }}
          readOnly={readOnly}
          value={mobileRole.value}
          options={Object.keys(mobileRoles).map((element) => {
            return { label: element, value: element };
          })}
          variant="outlined"
        />
      </Grid>

      {/* Organization */}
      <Grid item xs={6}>
        {memberOf
          ? memberOf.map((item, index) => (
              <SimpleSelect
                classes={classes}
                label="Organization"
                id="organization"
                key={index}
                onChange={(event) => {
                  event.persist();
                  setState((prevState) => {
                    let newState = prevState;
                    newState.memberOf[index] = event.target.value;
                    return {
                      ...newState,
                    };
                  });
                }}
                readOnly={readOnly}
                value={item}
                options={organizationGroupOptions(groups)}
                variant="outlined"
              />
            ))
          : null}
      </Grid>

      {/* Add Organization Button */}
      {!readOnly ? (
        <Grid item xs={6}>
          <SimpleButton
            color="primary"
            content="Add Organization"
            variant="contained"
            onClick={() => {
              setState((prevState) => {
                prevState.memberOf.push("");
                return { ...prevState };
              });
            }}
          />
        </Grid>
      ) : null}

      {/* Notes */}
      <Grid item xs={12}>
        <SimpleTextField
          label="Notes - optional"
          id="notes"
          multiline
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={notes.value}
        />
      </Grid>

      {/* Generate new password checkbox - Only shows up when isEdit is true */}
      {!readOnly && !isCreate ? (
        <Grid item xs={12}>
          <SimpleCheckbox
            name="generate-password"
            label="Generate New Password"
            checked={resetPassword}
            onChange={() => {
              buildPassword();
              setState((prevState) => ({
                ...prevState,
                resetPassword: !prevState.resetPassword,
              }));
            }}
          />
        </Grid>
      ) : null}

      {/* Generate new password field */}
      {resetPassword ? (
        <Grid item xs={12}>
          <SimpleTextField
            id="password"
            label="Password"
            onChange={(event) => {
              event.persist();
              onChange(event);
            }}
            readOnly={readOnly}
            required
            value={password.value}
          />
        </Grid>
      ) : null}

      {/* Submit Button */}
      {!readOnly ? (
        <Grid item xs={12}>
          <SimpleButton
            color="primary"
            content="Submit"
            onClick={validateFields}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
