import { useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";

import Loading from "../../../Loading/Loading";

import Filters from "./components/Filters";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.main,
  },
}));

export default function FilterMenu(props: any) {
  const {
    apiUrl,
    classifications,
    facilities,
    filterDefaults,
    handleSaveWidgetSettings,
    initFilters,
    organization,
    organizationId,
    searchAssets,
    setAlertModal,
    setView,
    timeZone,
    token,
    zones,
  } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    ...initFilters,
  });

  const eventTypesArray =
    organization && organization.eventTypesMap
      ? Object.keys(organization.eventTypesMap).sort()
      : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a: any, b: any) => {
        return (a.name || "").localeCompare(b.name || "");
      })
    : [];

  const zonesArray = Object.keys(zones).map((k) => {
    return { label: zones[k].name, value: zones[k].zoneId };
  });

  return (
    <>
      <Grid id="overviewMapFilterView" container spacing={3}>
        <Filters
          filters={filters}
          setFilters={setFilters}
          apiUrl={apiUrl}
          organizationId={organizationId}
          setAlertModal={setAlertModal}
          token={token}
          facilityArray={facilityArray}
          eventTypesArray={eventTypesArray}
          zonesArray={zonesArray}
          classifications={classifications}
          timeZone={timeZone}
        />
      </Grid>
      <hr />
      <Grid container justify={"space-between"} spacing={3}>
        {loading ? <Loading color="#5884F5" opaque /> : ""}

        <Grid item>
          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              const classificationKeys = Object.keys(
                classifications?.active || {}
              ).reduce((x, y) => {
                return {
                  ...x,
                  [y]: null,
                };
              }, {});

              setFilters({
                ...filters,
                assetIds: null,
                assetType: null,
                lastEvents: null,
                locations: null,
                binLocation: null,
                locals: null,
                zones: null,
                ...classificationKeys,
                ...filterDefaults,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            onClick={() => {
              setView({
                mapView: true,
              });
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            id={"overviewMapSaveFilters"}
            className={`${classes.button} ${classes.saveButton}`}
            color="primary"
            onClick={() => {
              setLoading(true);
              handleSaveWidgetSettings("overviewMapSettings", filters);
              searchAssets(filters).then((res: boolean) => {
                setLoading(false);
                setView({
                  mapView: true,
                });
              });
            }}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
