import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { cloneDeep, isEqual } from "lodash";

import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  LinearInterpolator,
  Layer,
  Source,
} from "react-map-gl";
import { easeCubic } from "d3-ease";
import DatePicker from "../../Forms/FieldTypes/DatePicker";
import Select from "react-select";

import moment from "moment";
import { Tooltip } from "@material-ui/core";

import { heatmapLayer } from "./map-style";
import Loading from "../../Loading/Loading";
import { getProductScans } from "../api";
import "./heat-map-style.css";

function HeatMap(props) {
  const { products, token, apiUrl, handleWidgetSettings } = props;
  const userPropertiesMap = cloneDeep(
    useSelector((state) => state.user.userPropertiesMap.propertiesMap),
    isEqual
  );

  const { widgetSettings = {} } = userPropertiesMap;

  const mapToken =
    "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

  const [showSettings, setShowSettings] = useState(false);

  const [viewport, setViewport] = useState({});

  const scansToGeoJson = (scans) => {
    let features = [];
    /// dnp, or, did not provide refers to scans in which the user's location was not provided, becuase the user had location services turned off on their device or for some other reason
    let dnpCount = 0;
    scans.forEach((scan) => {
      let lat = scan.latitude;
      let long = scan.longitude;
      if (lat !== 0 && long !== 0) {
        let obj = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [long, lat],
          },
          properties: {},
        };
        features.push(obj);
      } else {
        dnpCount++;
      }
    });

    return {
      geoJSON: {
        type: "FeatureCollection",
        features: features,
      },
      dnpCount: dnpCount,
    };
  };

  // create new date for today and a week ago for default date range
  let currentTime = new Date();
  let sixMonthsAgo = new Date();
  sixMonthsAgo.setDate(currentTime.getDate() - 180);

  let initFilters = widgetSettings["heatMap"]
    ? widgetSettings["heatMap"]
    : {
        startDate: sixMonthsAgo,
        endDate: currentTime,
        product: {
          value: products && products.length ? products[0].productId : "",
          label: products && products.length ? products[0].name : "",
        },
      };

  // make sure incoming dates are formatted correctly
  initFilters.startDate = new Date(initFilters.startDate);
  initFilters.endDate = new Date(initFilters.endDate);

  const [state, setState] = useState({
    darkMode: true,
    geoJSON: {
      type: "FeatureCollection",
      features: [],
    },
    dnpCount: 0,
    totalScans: 0,
    filters: {
      ...initFilters,
    },
    products: products,
    mounted: true,
  });

  useEffect(() => {
    if (state.filters.product.value) {
      getProductScans({ apiUrl, token }, state.filters).then((scans) => {
        const features = scansToGeoJson(scans);
        const totalScans = features.geoJSON.features.length + features.dnpCount;
        setState((s) => {
          return {
            ...s,
            geoJSON: features.geoJSON,
            totalScans: totalScans,
            dnpCount: features.dnpCount,
          };
        });
      });
    }
  }, [apiUrl, token, state.filters]);

  useEffect(() => {
    if (
      state.geoJSON &&
      state.geoJSON.features &&
      state.geoJSON.features.length
    ) {
      setViewport({
        zoom: 4,
        width: "100%",
        height: "100%",
        latitude: state.geoJSON.features[0].geometry.coordinates[1],
        longitude: state.geoJSON.features[0].geometry.coordinates[0],
        transitionDuration: 1800,
        transitionInterpolator: new LinearInterpolator(),
        transitionEasing: easeCubic,
      });
    }
  }, [state.geoJSON]);

  const handleFilterChange = async () => {
    if (state.filters.product) {
      handleWidgetSettings("heatMap", state.filters);
    }
  };

  const fullscreenControlStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const darkModeStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    padding: "10px",
  };

  return (
    <>
      {state.mounted && state.filters ? (
        <>
          <div>
            <i
              className={
                showSettings
                  ? "fa fa-times fa-lg widget-cog"
                  : "fa fa-cog fa-lg widget-cog"
              }
              onClick={() => {
                if (showSettings) {
                  handleFilterChange();
                }
                setShowSettings(!showSettings);
              }}
            ></i>

            {!showSettings ? (
              <>
                <h5 className="widget-header widgetTitle">
                  Customer Engagement Scans
                </h5>
                <span style={{ lineHeight: "2.5" }}>
                  {state.filters.product && state.filters.product.label
                    ? `${state.filters.product.label} | `
                    : null}
                  {state.filters.startDate
                    ? moment(state.filters.startDate).format("YYYY/MM/DD")
                    : null}{" "}
                  -{" "}
                  {state.filters.endDate
                    ? moment(state.filters.endDate).format("YYYY/MM/DD")
                    : null}
                </span>
                <Tooltip
                  title={
                    state.dnpCount && state.dnpCount > 1
                      ? `Location Data Not Provided For ${state.dnpCount} Scans.`
                      : state.dnpCount && state.dnpCount === 1
                      ? `Location Data Not Provided For ${state.dnpCount} Scan.`
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    {state.totalScans > 1
                      ? ` | ${state.totalScans} scans`
                      : state.totalScans === 1
                      ? ` | 1 scan`
                      : ` | No scans`}
                  </span>
                </Tooltip>
              </>
            ) : null}

            <div
              className="settings-layer"
              style={showSettings ? {} : { display: "none" }}
            >
              <h3>Customize Heat Map</h3>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    {state.filters ? (
                      <>
                        <label>Select Product</label>
                        <Select
                          isSearchable
                          isClearable
                          options={
                            products && products.length
                              ? [
                                  ...products.map((product) => {
                                    return {
                                      label: product.name,
                                      value: product.productId,
                                    };
                                  }),
                                ]
                              : []
                          }
                          onChange={(e) => {
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                product: e,
                              },
                            });
                          }}
                          value={
                            state.filters && state.filters.product
                              ? state.filters.product
                              : null
                          }
                        />
                      </>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    {state.filters.startDate ? (
                      <>
                        <label>Select Start Date</label>
                        <DatePicker
                          format="yyyy/MM/DD"
                          label="Start Date"
                          onChange={(date) => {
                            if (
                              state.filters.endDate &&
                              date > state.filters.endDate
                            ) {
                              alert("Can't set start date later than end date");
                              return;
                            }

                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                startDate: date,
                              },
                            });
                          }}
                          size="small"
                          value={state.filters.startDate}
                        />
                      </>
                    ) : null}
                    <br />
                    {state.filters.endDate ? (
                      <>
                        <label>Select End Date</label>
                        <DatePicker
                          format="yyyy/MM/DD"
                          label="End Date"
                          onChange={(date) => {
                            if (
                              state.filters.startDate &&
                              date < state.filters.startDate
                            ) {
                              alert(
                                "Can't set end date earlier than start date"
                              );
                              return;
                            }
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                endDate: date,
                              },
                            });
                          }}
                          size="small"
                          value={state.filters.endDate}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map-container">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={mapToken}
              width="100%"
              height="100%"
              mapStyle={
                state.darkMode
                  ? "mapbox://styles/mapbox/dark-v10"
                  : "mapbox://styles/mapbox/light-v10"
              }
              style={{
                border: "rgba(50, 53, 93, 0.514) solid 2px",
                borderRadius: "4px",
                display: showSettings ? "none" : null,
              }}
              onViewportChange={(viewport) => setViewport(viewport)}
            >
              <div style={fullscreenControlStyle}>
                <FullscreenControl />
              </div>
              <div style={navStyle}>
                <NavigationControl />
              </div>
              <div style={scaleControlStyle}>
                <ScaleControl />
              </div>
              <div style={darkModeStyle}>
                <i
                  className={
                    state.darkMode
                      ? "fa fa-sun darkmode-icon"
                      : "fa fa-moon darkmode-icon"
                  }
                  style={
                    state.darkMode ? { color: "#FFC854" } : { color: "#32355B" }
                  }
                  onClick={() => {
                    setState({ ...state, darkMode: !state.darkMode });
                  }}
                ></i>
              </div>
              <Source type="geojson" data={state.geoJSON}>
                <Layer {...heatmapLayer} />
              </Source>
            </ReactMapGL>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default HeatMap;
