import * as Icons from "@material-ui/icons";

const fetchIcons = async (iconType: string, searchFilter: any) => {
  let iconArray = await Object.keys(Icons)
    .map((iconKey: string) => {
      return {
        displayName: iconKey,
        label: iconKey,
        value: iconKey,
        ...(Icons as any)[iconKey],
      };
    })
    .filter((icon) => {
      if (iconType === "Filled") {
        // MUI icons have their types within their names. Filled is the default type and therefore doesn't have a type included in its name, so to find all filled type we just search for icons without the other types in their name
        return ["Outlined", "Rounded", "TwoTone", "Sharp"].every(
          (type) => !icon.displayName.includes(type)
        );
      } else {
        return icon.displayName.includes(iconType);
      }
    });

  if (searchFilter?.value) {
    return iconArray.filter((icon) => icon.displayName === searchFilter.value);
  } else {
    return iconArray;
  }
};

const fetchIcon = (icon: string) => {
  const iconKey: string =
    Object.keys(Icons).find((iconKey) => iconKey === icon) || "";
  return iconKey
    ? {
        displayName: iconKey,
        label: iconKey,
        value: iconKey,
        ...(Icons as any)[iconKey],
      }
    : null;
};

export { fetchIcons, fetchIcon };
