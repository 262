const XLSX = require("xlsx");
const moment = require("moment");
const { saveAs } = require("file-saver");

// function getJsDateFromExcel(excelDate: number) {
//   // JavaScript dates can be constructed by passing milliseconds
//   // since the Unix epoch (January 1, 1970) example: new Date(12312512312);

//   // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1 (Google "excel leap year bug")
//   // 2. Convert to milliseconds.
//   // try + 2 instead of +1 if you run into off by one errors
//   return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
// }

export const downloadTemplate = (props: any, setLoading: any) => {
  setLoading(true);
  const { newBatch = {}, organization = {} } = props;

  const {
    name = "",
    tag = "",
    originLocation = "",
    destinationLocation = "",
    description = "",
    product = {},
  } = newBatch;

  const payload = {
    organizationId: organization.organizationId,
    batch: name
      ? {
          name: name,
          tag: tag,
          description: description,
        }
      : {
          tag: tag,
          description: description,
        },
    product: {
      productId: product.id,
    },
    origin: {
      facilityId: originLocation,
    },
    destination: destinationLocation
      ? {
          facilityId: destinationLocation,
        }
      : {},
  };

  fetch(`${props.apiUrl}batches/exportILDEspreadsheet`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": props.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.blob())
    .then((blob) => {
      setLoading(false);
      saveAs(blob, "ILDEImportTemplate.xlsx");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const validateAndFormatSpreadsheet = (
  spreadsheet: any,
  props: any,
  response: any
) => {
  const { itemLevelDataElements = [], assetTypes = [] } = props;

  const itemLevelDataElementsMap =
    itemLevelDataElements && itemLevelDataElements.length
      ? itemLevelDataElements
          .map((f: any) => {
            return {
              ...f,
              id: f.id,
            };
          })
          .reduce((x: any, y: any) => {
            return {
              ...x,
              [y.id]: y,
            };
          }, {})
      : {};

  let success = true;
  let message = "";

  const readFile = (file: any, onLoadCallback: any) => {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsArrayBuffer(file);
  };

  readFile(spreadsheet, (e: any) => {
    // use result in callback...
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });

    const sheet_name_list = workbook.SheetNames;
    const parsedSheet = sheet_name_list.map((sheet: string) => {
      return XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    })[0];

    const headerSheet = parsedSheet[3];

    // returns key in spreadsheet and dataType e.g. {"__EMPTY_9" : "Date"}
    const dataElementKeysAndDataTypes: any = Object.keys(headerSheet)
      .map((key: string, idx: number) => {
        const field = headerSheet[key];
        const mappedItem = itemLevelDataElementsMap[field];

        if (mappedItem && mappedItem.dataType) {
          const dataType = mappedItem.dataType;
          return {
            key: key,
            dataType: dataType,
            id: field,
          };
        } else {
          return null;
        }
      })
      .filter((el) => el !== null)
      .reduce((x: any, y: any) => {
        return {
          ...x,
          [y.key]: y,
        };
      }, {});

    // helper functions for validating ILDE data
    const currencyCheck = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    const dateCheck = (d: any) => {
      return moment(d).isValid();
    };
    // const dateFormat = (d: any) => {
    //   return moment(getJsDateFromExcel(d)).format("MM/DD/YYYY");
    // };

    // memory object for checking that item tags are unique within the spreadsheet
    const uniqueAssetObj: any = {};

    // begin at the fourth row, where applicable data begins
    parsedSheet.slice(4).forEach((row: any, idx: number) => {
      // Check for first unit of measure
      if (idx === 0 && !row["__EMPTY_4"]) {
        return (
          (success = false),
          (message = `Error Found on row ${
            idx + 7
          }; please make there is at least one unit of measure specified.`)
        );
      }

      const unitOfMeasure = row["__EMPTY_4"];
      // check all unit of measures and make sure they exist on the org
      if (unitOfMeasure && !assetTypes.includes(unitOfMeasure)) {
        return (
          (success = false),
          (message = `Error Found on row ${idx + 7}; please make sure ${
            row["__EMPTY_4"]
          } is a valid unit of measure that exists on your organization (case sensitive!)`)
        );
      }

      // // check item tag is not EMPTY
      // if (!row["__EMPTY_5"]) {
      //   return (
      //     (success = false),
      //     (message = `Error Found on row ${
      //       idx + 7
      //     }; please make there an item tag is specified on this row!`)
      //   );
      // }
      // check item tag isn't already present in spreadsheet
      if (row["__EMPTY_5"] && uniqueAssetObj[row["__EMPTY_5"]]) {
        return (
          (success = false),
          (message = `Error Found on row ${idx + 7}; item tag ${
            row["__EMPTY_5"]
          } already used elsewhere in the spreadsheet; please make sure item tags are unique!`)
        );
      }

      Object.keys(dataElementKeysAndDataTypes).forEach((key) => {
        const dataType = dataElementKeysAndDataTypes[key].dataType;
        const data = row[key] || false;

        if (!data) {
          return (
            (success = false),
            (message = `Error Found on row ${idx + 7}; data element ${
              dataElementKeysAndDataTypes[key].id
            } is missing a value!`)
          );
        } else if (dataType === "Numeric" && isNaN(parseFloat(data))) {
          return (
            (success = false),
            (message = `Error Found on row ${
              idx + 7
            }; ${data} is not a valid number.`)
          );
        } else if (dataType === "Currency" && !currencyCheck.test(data)) {
          return (
            (success = false),
            (message = `Error Found on row ${
              idx + 7
            }; ${data} is not a valid currency.`)
          );
        } else if (dataType === "Date" && !dateCheck(data)) {
          return (
            (success = false),
            (message = `Error Found on row ${
              idx + 7
            }; ${data} is not a valid date.`)
          );
        }
        // else if (dataType === "Date" && dateCheck(data)) {
        //   // format from excel if date type
        //   row[key] = dateFormat(data);
        // }
      });

      // row passes all validation checks, store tag on memory object
      uniqueAssetObj[row["__EMPTY_5"]] = true;
    });

    // write ArrayBuffer of file
    const rawFile = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    // construct javascript File object from Array Buffer, send in response callback
    const fileName = `AssetImports.xlsx`;
    const file: any = new File([rawFile], fileName);
    file.path = fileName;

    response({
      success: success,
      message: message,
      file: success && file ? file : "",
    });
  });
};
