import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";

import { BatchInformationTable, ProductInformationTable } from "../Tables";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  overview: {
    width: "100%",
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: "30vh",
    maxHeight: "30vh",
  },
  filter: {
    textAlign: "left",
    color: theme.palette.text.secondary,
    width: "100%",
    margin: "auto",
  },
  card: {},
}));

function Overview(props: any) {
  const classes = useStyles();

  const { facilityMap } = props.state.lists;

  return (
    <Box className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.overview} elevation={0}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h4 style={{ textAlign: "left" }}>Product Information</h4>
                <ProductInformationTable state={props.state} />
              </Grid>
              <Grid item xs={12}>
                <h4 style={{ textAlign: "left" }}>Batch Information</h4>
                <BatchInformationTable
                  timeZone={props.timeZone}
                  state={props.state}
                  facilityMap={facilityMap}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Overview;
