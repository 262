import { useState } from "react";
import { Grid } from "@material-ui/core";
import { Dropzone } from "../../../components/Dropzone/Dropzone";
import { downloadTemplate, validateAndFormatSpreadsheet } from "./xlsx";
import Loading from "../../../components/Loading/Loading";
import GenerateCQR from "./GenerateCQR";
import { isEmpty } from "lodash";

// This import module doesn't use the file itself when posting to the API, instead, it parses and reads the file and sends JSON data to assets/bulk. Keeping file here since dropzone expects it, as it helps with add / remove logic.
export default function ImportAssets(props: any) {
  const {
    setImportAssetsModalTitle,
    apiUrl,
    token,
    setConfirm,
    onSuccess,
    organization,
    facilities,
    classifications,
    availableDevices,
  } = props;

  const [accepted, setAccepted] = useState(false);
  const [file, setFile] = useState({});
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [showGenerateCQR, setShowGenerateCQR] = useState(false);
  const [assetIds, setAssetIds] = useState({});
  const uploadFile = (file: any) => {
    setLoading(true);

    fetch(`${apiUrl}assets/bulk`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        const { success, numAssets } = response;

        if (success && numAssets > 0) {
          const assetCollection: any = {};

          // We iterate through the assets and push them into the assetCollection.
          // This is used to generateCQRs
          response.assets.forEach((asset: any) => {
            assetCollection[asset.assetId] = asset;
          });

          setConfirm({
            modalShow: true,
            text: `${numAssets} assets added.`,
            error: false,
          });
          setAssetIds(assetCollection);
          setFile({});
          setPayload({});
          setAccepted(false);
          setShowGenerateCQR(true);
          setImportAssetsModalTitle("Generate CQRs");
          onSuccess();
        } else {
          setConfirm({
            modalShow: true,
            text: `Uh-oh! Something went wrong. Did you follow the example spreadsheet?`,
            error: true,
          });
        }
      });
  };

  return !showGenerateCQR ? (
    <form
      onSubmit={(e) => {
        uploadFile(file);
        e.preventDefault();
      }}
    >
      <Grid container>
        {organization?.assetCategoriesList &&
        organization?.assetCategoriesList?.length &&
        !isEmpty(facilities) &&
        !isEmpty(organization.eventTypesMap) ? (
          <>
            <Grid item className="text-center mt-1">
              <p>
                Download and fill out Excel file template, and then upload the
                file below:{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadTemplate(
                      organization,
                      classifications,
                      facilities,
                      availableDevices
                    );
                  }}
                  href="/#"
                >
                  AssetImportTemplate.xlsx
                </a>
                <br />
                <sub>
                  *Additional Fields appear that are added in Global Settings.
                </sub>
              </p>
              {loading ? <Loading color="#5884F5" /> : ""}
              <Dropzone
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
                acceptText="Drag 'n' drop or click to select file (only *.xlsx files will be accepted)"
                callback={(acceptedFiles: any, accepted: Function) => {
                  setAccepted(false);
                  validateAndFormatSpreadsheet(
                    acceptedFiles[0],
                    props,
                    (cb: any) => {
                      if (!cb.success) {
                        setConfirm({
                          modalShow: true,
                          text: `${cb.message}`,
                          error: true,
                        });
                        accepted(false);
                      } else {
                        setPayload(cb.payload);
                        setAccepted(true);
                        setFile(cb.file);
                        accepted(true);
                      }
                    },
                    availableDevices
                  );
                }}
                clearFile={() => {
                  setFile({});
                  setPayload({});
                  setAccepted(false);
                }}
              />
            </Grid>
            <Grid item xs={12} className="text-center mt-2">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={accepted ? false : true}
              >
                Submit
              </button>
            </Grid>
          </>
        ) : (
          <Grid item>
            <p>
              It doesn't look like you have your organization settings
              configured yet. Ask an admin to navigate to the facilities page to
              create at least one facility, and then to go to the global
              settings page and create at least one asset category and event
              before importing assets.
            </p>
          </Grid>
        )}
      </Grid>
    </form>
  ) : (
    <GenerateCQR {...props} assetIds={assetIds} />
  );
}
