import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone/builds/moment-timezone-with-data";

function NotificationBar(props) {
  const {
    dismissNotification,
    isNew = true,
    propertiesMap = {},
    status = "",
    timeZone,
    userNotificationId,
  } = props;

  const {
    assetId,
    assetType,
    assetTag,
    batchId = "",
    timeOfLog,
    timeOfLogLong,
    triggerMap = {},
  } = propertiesMap;

  const { event } = triggerMap;

  const usersTimeAndDate = timeOfLogLong
    ? moment
        .utc(timeOfLogLong, "x")
        .tz(timeZone)
        .format("YYYY-MM-DD hh:mm:ss a")
    : moment
        .utc(timeOfLog, "YYYY-MM-DD HH:mm")
        .tz(timeZone)
        .format("YYYY-MM-DD hh:mm a") || "DNP";

  return (
    <div className="col-md-12 p-3">
      <div className="alertOne border p-2 d-flex justify-content-between pointer">
        <Link
          className="d-flex w-100"
          to={`${
            !batchId
              ? `/assetSnapshot/${assetId}`
              : `/batches/${batchId}/history/${assetType}`
          }`}
          onClick={() => {
            dismissNotification(userNotificationId, "read");
          }}
        >
          {isNew ? (
            <div className={`my-auto  ${status === "sent" ? "w-25" : ""}`}>
              {status === "sent" ? (
                <div className="rounded bg-primary text-white shadow mr-2 w-75 mx-auto text-center shadow">
                  <div className="alert-icon font-weight-bold my-auto">NEW</div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="w-75">
            {assetTag} {event} {usersTimeAndDate}
          </div>
        </Link>
        <div className="my-auto">
          <button
            className="close-button"
            type="button"
            onClick={() => {
              dismissNotification(userNotificationId, "dismissed");
            }}
          >
            <i
              className={`fas fa-times-circle ${
                isNew ? "text-danger" : "text-warning"
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotificationBar;
