import { omit, transform, isObject, isEqual } from "lodash";

const initGlobalSettings = (props) => {
  const {
    classifications = {},
    location = {},
    mobileRoles = {},
    organization = {},
  } = props;
  const { state = {} } = location;
  const { redirect = false, product, isCreate } = state;

  const {
    assetCategoriesList = [],
    assetTypes = [],
    customAssetFieldsList = [],
    eventTypesMap = {},
    propertiesMap = {},
  } = organization;

  const {
    defaultFacilityRadius = 400,
    formRealActions = {},
    itemLevelDataElements = [],
    locationTypes = [],
    pcaSettings = {},
    productTypes = [],
    zoneCategories = [],
  } = propertiesMap;

  if (redirect) {
    product.propertiesMap.customDataFields = product.customDataFields;
  }

  return {
    assetCategoriesList,
    assetTypes,
    classifications: classifications.active || {},
    customAssetFieldsList,
    defaultFacilityRadius,
    eventTypesMap,
    formRealActions,
    inactiveClassifications: classifications.inactive || {},
    isCreate,
    itemLevelDataElements,
    locationTypes,
    mobileRoles,
    pcaSettings,
    product,
    productTypes,
    redirect,
    zoneCategories,
  };
};

const initUserSettings = (props, consoleSettings) => {
  const timeZone = props.timeZone;

  const { email = "" } = props || localStorage.getItem("userEmail");

  const userInfo = {
    email: email,
    timeZone: timeZone,
  };

  return { userInfo, consoleSettings };
};

const settingsReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_GLOBAL": {
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          [action.setting]: action.payload,
          settingsUpdated: true,
        },
        selected: action.removeSelected
          ? {
              ...omit(state.selected, [action.removeSelected]),
            }
          : state.selected,
      };
    }
    case "CHANGE_USER": {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.setting]: action.payload,
          settingsUpdated: true,
        },
        selected: action.removeSelected
          ? {
              ...omit(state.selected, [action.removeSelected]),
            }
          : state.selected,
      };
    }
    case "SET_SELECTED": {
      return {
        ...state,
        selected: {
          [action.label]: action.payload,
        },
      };
    }
    case "RESET_GLOBAL": {
      return {
        ...state,
        globalSettings: {
          ...action.payload,
          settingsUpdated: false,
        },
        selected: {},
      };
    }
    case "RESET_USER": {
      return {
        ...state,
        userSettings: {
          ...action.payload,
          settingsUpdated: false,
        },
        selected: {},
      };
    }
    case "RESET_SETTINGS_UPDATED": {
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          settingsUpdated: false,
        },
        userSettings: {
          ...state.userSettings,
          settingsUpdated: false,
        },
      };
    }

    default:
      return state;
  }
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export { initGlobalSettings, initUserSettings, settingsReducer, difference };
