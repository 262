import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { Tooltip } from "@material-ui/core";

function Assets(props) {
  const { state, dispatchState } = props;

  const [view, setView] = useState({
    isAddField: false,
    isAddCategory: false,
    isEditCategory: false,
  });

  const [addField, setAddField] = useState({});
  const [addDataType, setAddDataType] = useState("");
  const [addCategory, setAddCategory] = useState({});
  const [addRequiredField, setAddRequiredField] = useState({});

  return (
    <>
      <div className="row">
        <div className="col md-6" id="customDataElements">
          <h4>
            <Tooltip title="Asset- or product-specific data elements you wish to be available to assign to individual items.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Custom Data Elements{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => {
                setAddRequiredField({});
                setView({
                  ...view,
                  isAddField: !view.isAddField,
                });
              }}
            >
              {!view.isAddField ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddField ? (
              <SimpleList
                noSelectHighlight
                isColor
                items={state.globalSettings.customAssetFieldsList}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "assetField",
                    payload: {
                      ...state.globalSettings.customAssetFieldsList.filter(
                        (f) => f.id === id
                      )[0],
                    },
                  });
                }}
                onClickRemove={(id) => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "customAssetFieldsList",
                    payload: state.globalSettings.customAssetFieldsList.filter(
                      (f) => f.id !== id
                    ),
                    removeSelected: "assetField",
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetCategoriesList",
                    payload: state.globalSettings.assetCategoriesList.map(
                      (cat) => {
                        return {
                          ...cat,
                          requiredFields: cat.requiredFields.filter(
                            (f) => f.id !== id
                          ),
                        };
                      }
                    ),
                    removeSelected: "assetCategory",
                  });
                  setView({
                    ...view,
                    isEditCategory: false,
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Add new Data Element"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Name",
                    onChange: (e) => {
                      setAddField({
                        ...addField,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                  {
                    id: "dataType",
                    label: "Data Type",
                    isSelect: true,
                    options: [
                      { value: "Alphanumeric", label: "Alphanumeric" },
                      { value: "Numeric", label: "Number" },
                      { value: "Date", label: "Date" },
                      { value: "Currency", label: "Currency" },
                    ],
                    value: addDataType,
                    onChange: (e) => {
                      setAddDataType(e.target.value);
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "customAssetFieldsList",
                    payload: state.globalSettings.customAssetFieldsList.concat([
                      {
                        id: addField.label.trim(),
                        label: addField.label.trim(),
                        dataType: addDataType,
                      },
                    ]),
                    removeSelected: "assetField",
                  });
                  const updatedCatFields =
                    state.globalSettings.assetCategoriesList.map(
                      async (cat) => {
                        return {
                          ...cat,
                          requiredFields: cat.requiredFields.concat([
                            {
                              id: addField.label.trim(),
                              label: addField.label.trim(),
                              dataType: addDataType,
                              required: false,
                            },
                          ]),
                        };
                      }
                    );

                  Promise.all(updatedCatFields).then((completed) => {
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "assetCategoriesList",
                      payload: completed,
                      removeSelected: "assetCategory",
                    });
                    setView({
                      ...view,
                      isEditCategory: false,
                      isAddField: false,
                    });
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddField: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col md-6" id="assetCategories">
          <h4>
            <Tooltip title="A grouping mechanism (e.g. GL account number) or asset type (e.g. “Computer Equipment”) for your assets.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Asset Categories{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddCategory: !view.isAddCategory,
                })
              }
            >
              {!view.isAddCategory && !view.isEditCategory ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : view.isAddCategory ? (
                <CloseIcon style={{ color: "#b71d1a" }} />
              ) : null}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddCategory && !view.isEditCategory ? (
              <SimpleList
                noSelectHighlight
                items={state.globalSettings.assetCategoriesList}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "assetCategory",
                    payload: {
                      ...state.globalSettings.assetCategoriesList.filter(
                        (f) => f.id === id
                      )[0],
                    },
                  });
                }}
                onClickRemove={(id) => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetCategoriesList",
                    payload: state.globalSettings.assetCategoriesList.filter(
                      (f) => f.id !== id
                    ),
                    removeSelected: "assetCategory",
                  });
                }}
                onClickEdit={() => {
                  setView({
                    ...view,
                    isEditCategory: true,
                  });
                }}
              />
            ) : !view.isEditCategory && view.isAddCategory ? (
              <SimpleForm
                headerText={"Add new Category"}
                submitBtnText={"Add"}
                fields={
                  state.globalSettings.customAssetFieldsList &&
                  state.globalSettings.customAssetFieldsList.length
                    ? [
                        {
                          id: "label",
                          label: "Name",
                          onChange: (e) => {
                            setAddCategory({
                              ...addCategory,
                              [e.target.id]: e.target.value,
                            });
                          },
                        },
                        ...state.globalSettings.customAssetFieldsList.map(
                          (f, idx) => {
                            return {
                              key: `${idx} - ${f.id}`,
                              id: f.id,
                              label: `Require ${f.label}`,
                              isCheckbox: true,
                              checked:
                                addRequiredField && addRequiredField[f.id]
                                  ? addRequiredField[f.id].required
                                  : false,
                              onChange: (e) => {
                                setAddRequiredField({
                                  ...addRequiredField,
                                  [f.id]: {
                                    required:
                                      addRequiredField[f.id] &&
                                      addRequiredField[f.id].required
                                        ? !addRequiredField[f.id].required
                                        : true,
                                    label: f.label,
                                    id: f.id,
                                    dataType: f.dataType,
                                  },
                                });
                              },
                            };
                          }
                        ),
                      ]
                    : [
                        {
                          id: "label",
                          label: "Name",
                          onChange: (e) => {
                            setAddCategory({
                              ...addCategory,
                              [e.target.id]: e.target.value,
                            });
                          },
                        },
                      ]
                }
                onSubmit={() => {
                  const formattedRequiredFields =
                    state.globalSettings.customAssetFieldsList.map((f) => {
                      return {
                        id: f.id,
                        label: f.label,
                        required: addRequiredField[f.id]
                          ? addRequiredField[f.id].required
                          : false,
                        dataType: f.dataType,
                      };
                    });

                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetCategoriesList",
                    payload: state.globalSettings.assetCategoriesList.concat([
                      {
                        id: addCategory.label.trim(),
                        label: addCategory.label.trim(),
                        requiredFields: formattedRequiredFields,
                      },
                    ]),
                  });

                  setView({
                    ...view,
                    isAddCategory: false,
                  });

                  setAddRequiredField({});
                }}
                goBack={() => {
                  setAddRequiredField({});
                  setView({
                    ...view,
                    isAddCategory: false,
                  });
                }}
              />
            ) : view.isEditCategory && state.selected.assetCategory ? (
              <SimpleForm
                headerText={`Edit ${state.selected.assetCategory.label}`}
                submitBtnText={"Submit"}
                fields={
                  state.selected.assetCategory.requiredFields &&
                  state.selected.assetCategory.requiredFields.length
                    ? [
                        ...state.selected.assetCategory.requiredFields.map(
                          (f, idx) => {
                            return {
                              key: `${idx} - ${f.id}`,
                              id: f.id,
                              label: `Require ${f.label}`,
                              isCheckbox: true,
                              checked:
                                state.selected.assetCategory.requiredFields.filter(
                                  (r) => r.id === f.id
                                )[0].required,

                              onChange: (e) => {
                                dispatchState({
                                  type: "SET_SELECTED",
                                  label: "assetCategory",
                                  payload: {
                                    ...state.selected.assetCategory,
                                    requiredFields:
                                      state.selected.assetCategory.requiredFields.map(
                                        (f2) => {
                                          if (f2.id === f.id) {
                                            return {
                                              ...f2,
                                              required: !f2.required,
                                            };
                                          }
                                          return f2;
                                        }
                                      ),
                                  },
                                });
                              },
                            };
                          }
                        ),
                      ]
                    : []
                }
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "assetCategoriesList",
                    payload: state.globalSettings.assetCategoriesList.map(
                      (cat) => {
                        if (cat.id === state.selected.assetCategory.id) {
                          return {
                            ...state.selected.assetCategory,
                          };
                        }
                        return cat;
                      }
                    ),
                    removeSelected: "assetCategoriesList",
                  });
                  setView({
                    ...view,
                    isEditCategory: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditCategory: false,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Assets;
