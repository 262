import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { isEmpty } from "lodash";
import moment from "moment-timezone";

import { Grid, makeStyles } from "@material-ui/core";

import { showAlert } from "../../../../globalStore/slices/alert/alertSlice";
import ControlledTable from "../../../../components/Tables/ControlledTable/ControlledTable";

import FilterMenu from "./FilterMenu/FilterMenu";
import { fetchShortedItems } from "./api";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
}));

export default function ItemsShortedTable(props: any) {
  const {
    timeZone,
    organization,
    classifications,
    organizationId,
    token,
    apiUrl,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatchGlobal = useDispatch();

  const onClick = (row: any) => {
    const { assetId = "" } = row;
    if (assetId) {
      const url = `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

  const Lot_ID_Column = !isEmpty(classifications?.active)
    ? Object.keys(classifications.active)
        .filter(
          (k) => classifications.active[k]?.name.toLowerCase() === "lot id"
        )
        .map((classKey) => {
          const { classificationId, name } = classifications.active[classKey];

          return {
            Header: name,
            accessor: (row: any) => {
              if (row.classificationItemMap) {
                const mapKey = Object.keys(row.classificationItemMap).filter(
                  (k) =>
                    row.classificationItemMap[k].parentId === classificationId
                )[0];
                return row.classificationItemMap[mapKey]?.name || "";
              }
              return "";
            },
            id: name,
            disableSortBy: true,
            Cell: (data: any) => {
              const { classificationItemMap = {} } = data.row.original;

              const mapKey = Object.keys(classificationItemMap).filter(
                (k) => classificationItemMap[k].parentId === classificationId
              )[0];

              return (
                <div
                  className="pointer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => onClick(data.row.original)}
                >
                  {classificationItemMap[mapKey]?.name || ""}
                </div>
              );
            },
          };
        })[0]
    : null;

  // some table columns in this table must read from the grandparent of the child item...
  // The 'ancestors' field is a set of UUIDs that are positioned in a way that the last item in the set is the root of the hierarchy tree (e.g., the grandparent, and the first item would be the parent, etc.)
  // The ancestorsList field is a list of the assets ancestors in no reliable order. Cross-referencing the assetIDs in this list with the UUIDs in the 'ancestors' field allows us to determine where in the hierarchy each ancestor is in the ancestorList.
  const tableColumns = [
    {
      Header: "MO Shorted",
      id: "MO",
      disableSortBy: true,
      accessor: (row: any) => {
        if (!row.ancestorList || !row.ancestors) {
          return "";
        } else {
          // progenitor is the last item, the root of the hierarchy tree, in this case, the grandparent
          const progenitor = row.ancestorList.filter(
            (ancestor: any) =>
              ancestor.assetId === row.ancestors[row.ancestors.length - 1]
          )[0];
          return progenitor.tag || "";
        }
      },
      Cell: (data: any) => {
        const row = data.row.original;

        const progenitor =
          !row.ancestorList || !row.ancestors
            ? ""
            : row.ancestorList.filter(
                (ancestor: any) =>
                  ancestor.assetId === row.ancestors[row.ancestors.length - 1]
              )[0];

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick({ assetId: progenitor?.assetId || null })}
          >
            {progenitor.tag || ""}
          </div>
        );
      },
    },
    {
      Header: "Item #",
      accessor: "tag",
      id: "tag",
      Cell: (data: any) => {
        const { tag = "" } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {tag || ""}
          </div>
        );
      },
    },
    {
      Header: "Item Project Name",
      id: "itemProjectName",
      disableSortBy: true,
      accessor: (row: any) => row.propertiesMap?.description || "",
      Cell: (data: any) => {
        const { propertiesMap = {} } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {propertiesMap?.description ? propertiesMap.description : ""}
          </div>
        );
      },
    },
    {
      Header: "Date Needed",
      id: "timeNeeded",
      accessor: (row: any) => {
        if (!row.ancestorList || !row.ancestors) {
          return "";
        } else {
          // progenitor is the last item, the root of the hierarchy tree, in this case, the grandparent
          const progenitor = row.ancestorList.filter(
            (ancestor: any) =>
              ancestor.assetId === row.ancestors[row.ancestors.length - 1]
          )[0];

          return progenitor?.timeNeeded
            ? moment(progenitor.timeNeeded, "YYYYMMDDHHmmss").format(
                "MM/DD/YYYY"
              )
            : "";
        }
      },
      Cell: (data: any) => {
        const row = data.row.original;

        const progenitor =
          !row.ancestorList || !row.ancestors
            ? ""
            : row.ancestorList.filter(
                (ancestor: any) =>
                  ancestor.assetId === row.ancestors[row.ancestors.length - 1]
              )[0];

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick({ assetId: progenitor?.assetId || null })}
          >
            {progenitor?.timeNeeded
              ? moment(progenitor.timeNeeded, "YYYYMMDDHHmmss").format(
                  "MM/DD/YYYY"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Quantity Shorted",
      disableSortBy: true,
      id: "quantityShorted",
      accessor: (row: any) => row.quantity?.quantityShorted || "",

      Cell: (data: any) => {
        const { quantity = {} } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {quantity?.quantityShorted ? quantity.quantityShorted : ""}
          </div>
        );
      },
    },
  ];
  // if Lot ID is configured in classifications, splice it into the tableColumns list
  if (Lot_ID_Column) {
    tableColumns.splice(3, 0, Lot_ID_Column);
  }

  const [view, setView] = useState({
    filterView: false,
  });

  const filterDefaults = {
    assetIds: [],
    category: "MO",
    needDate: null,
    needDateEnd: null,
    limit: 25,
    sorted: [
      {
        id: "tag",
        desc: true,
      },
    ],
    start: 0,

    tz: timeZone,
  };

  const [state, setState] = useState({
    filters: filterDefaults,
    assets: [],
    count: 0,
    page: 0,
  });

  const callbackFetchShortedItems = useCallback(
    () =>
      fetchShortedItems(props, state.filters).then((res) => {
        if (res.error) {
          return dispatchGlobal(showAlert({ type: "error", text: res.error }));
        }
        setState((s: any) => {
          return {
            ...s,
            assets: res.assets || [],
            count: res.count || 0,
          };
        });
      }),
    [dispatchGlobal, props, state.filters]
  );

  useEffect(() => {
    callbackFetchShortedItems();
  }, [state.filters, callbackFetchShortedItems]);

  return (
    <>
      <FilterMenu
        setState={setState}
        state={state}
        filterDefaults={filterDefaults}
        setView={setView}
        view={view}
        apiUrl={apiUrl}
        token={token}
        organizationId={organizationId}
        organization={organization}
      />
      <Grid container id={"itemsShortedTable"}>
        <Grid item xs={12}>
          <h5>Items Shorted</h5>
          <ControlledTable
            state={state}
            setState={setState}
            data={state.assets || []}
            columns={tableColumns}
            defaultSort={[{ id: "tag", desc: true }]}
            controlledCount={state.count || 0}
            controlledPageCount={Math.ceil(state.count / state.filters?.limit)}
            controlledPageIndex={state.page}
            controlledPageSize={state.filters?.limit}
            totalLabel={"Total Items"}
            toolbarChildren={
              <Grid
                className={classes.barChildrenContainer}
                container
                alignItems={"center"}
              >
                <Grid className={classes.barChildren} item xs={3} xl={2}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setView((v: any) => {
                        return {
                          filterView: !v.filterView,
                        };
                      })
                    }
                  >
                    <div className="exportIcon">
                      {`Filters`}
                      <i className="fas fa-filter ml-2"></i>
                    </div>
                  </span>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
