import request from "superagent";

const escapeCharacters = (input) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const getAssetByTags = async (props, tag, start = 0) => {
  const { apiUrl, token, organizationId } = props;

  const assetTag = tag ? `tag:${escapeCharacters(tag)}*` : "";
  const body = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: assetTag,
      sort: `tag asc`,
      start: start,
    },
    limit: 300,
  };
  const results = await request
    .post(`${apiUrl}assets/search`)
    .set("auth-token", token)
    .send(body)
    .then((response) => {
      return response.body;
    });

  return results;
};

// export const getAssetByCategories = async (props, category) => {
//   const { apiUrl, token, organizationId } = props;
//   const body = {
//     solrQuery: {
//       q: `current_owner_id:${organizationId}`,
//       fq: `category:${escapeCharacters(category)}*`,
//     },
//     limit: 300
//   };
//   const results = await request
//     .post(`${apiUrl}assets/search`)
//     .set("auth-token", token)
//     .send(body)
//     .then((response) => {
//       return response.body;
//     });
//   return results;
// };
