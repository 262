import { useState, useEffect } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  makeStyles,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Select from "react-select";

import { fetchIcons, fetchIcon } from "./IconsStorage";

type Props = {
  icon: string;
  setIcon: (s: string) => any;
};

const useStyles = makeStyles({
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  iconDisplayText: {
    fontSize: 14,
    whiteSpace: "nowrap",
    width: "75px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 10,
    backgroundColor: "#FAFAFA",
  },
  footerItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px",
  },
});

export default function Iconpicker(props: Props) {
  const classes = useStyles();

  const { icon, setIcon } = props;

  const [iconOptions, setIconOptions] = useState<any[]>([]);
  const [SelectedIcon, setSelectedIcon] = useState<any>(
    icon ? fetchIcon(icon) : null
  );

  const [iconType, setIconType] = useState("Filled");
  const [searchFilter, setSearchFilter] = useState({});

  useEffect(() => {
    fetchIcons(iconType, searchFilter).then((res) => {
      setIconOptions(res);
    });
  }, [iconType, searchFilter]);

  return (
    <Grid container spacing={1}>
      <Grid style={{ width: "80%" }} item>
        <Select
          isSearchable
          isClearable
          placeholder={
            <div>
              <SearchIcon color="primary" /> Search {iconType} Icons
            </div>
          }
          value={searchFilter}
          options={iconOptions}
          onChange={(e) => {
            console.log(e);
            setSearchFilter(e);
          }}
        />
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="iconType"
            name="iconType"
            value={iconType}
            onChange={(event) => setIconType(event.target.value)}
            row
          >
            <FormControlLabel
              value="Filled"
              control={<Radio />}
              label="Filled"
            />
            <FormControlLabel
              value="Outlined"
              control={<Radio />}
              label="Outlined"
            />
            <FormControlLabel
              value="Rounded"
              control={<Radio />}
              label="Rounded"
            />
            <FormControlLabel
              value="TwoTone"
              control={<Radio />}
              label="Two tone"
            />
            <FormControlLabel value="Sharp" control={<Radio />} label="Sharp" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item style={{ marginBottom: "100px", width: "100%" }}>
        {iconOptions.map((Icon) => (
          <IconButton
            size="small"
            onClick={() => setSelectedIcon(Icon)}
            classes={{ label: classes.iconButtonLabel }}
          >
            <Icon fontSize={"large"} />
            <div className={classes.iconDisplayText}>{Icon.displayName}</div>
          </IconButton>
        ))}
      </Grid>
      <Grid item xs={12} className={classes.footerContainer}>
        <div className={classes.footerItems}>
          <Typography>
            Selected Icon:{" "}
            {SelectedIcon ? (
              <>
                <SelectedIcon /> {SelectedIcon.displayName}
              </>
            ) : (
              "None selected."
            )}{" "}
          </Typography>
          <div style={{ marginRight: "25px" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSelectedIcon(null);
              }}
            >
              CLEAR
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setIcon(SelectedIcon?.displayName || "");
              }}
            >
              SET ICON
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
