import React, { useEffect, useState } from "react";

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns,
  useColumnOrder,
} from "react-table";

import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";

import { useStyles } from "./components/styles";

import {
  StyledTableHead,
  BodyRow,
  BodyCell,
  IndeterminateCheckbox,
} from "./components/StyledComponents";

import HeaderGroups from "./components/DnDHeaderGroups";

import TableToolbar from "./components/TableToolbar";

import TablePaginationActions from "./components/TablePaginationActions";

import HorizontalTabs from "../../Tabs/HorizontalTabs";
import TreeTable from "./components/TreeTable";

export default function TabbedTables(props) {
  const {
    activeTab = 0,
    children,
    customControl,
    hideTotalEvents,
    useGlobalFilters = true,
  } = props;

  const [activeTable, setActiveTable] = useState({
    controlledCount: 0,
    data: [],
    globalFilter: "",
    globalFilterLabel: "",
    label: "",
    setGlobalFilter: () => {},
    tableHeader: "",
    toolbarChildren: [],
  });

  const Tab = ({
    changePage,
    changeRowsPerPage,
    clearSelected,
    columns,
    controlledCount,
    controlledPageCount,
    controlledPageIndex,
    controlledPageSize,
    data,
    defaultColumnOrder,
    defaultSort,
    isSelect,
    language,
    onColumnDrop,
    onSelect,
    saveFilterSettings,
    selectAll,
    setState,
    state,
    stickyHeader,
  }) => {
    const classes = useStyles();
    const {
      headerGroups,
      prepareRow,
      page,
      gotoPage,
      setPageSize,
      toggleSortBy,
      allColumns,
      setColumnOrder,
      // state: { selectedRowIds, globalFilter },
    } = useTable(
      {
        columns,
        data,
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            let r1 = row1.values[columnName] || "";
            let r2 = row2.values[columnName] || "";
            // Intl Collator can help us sort diacritics and other idiosyncracies across languages, supported on 95% of browsers (including IE). Setting "sensitivity" to "base" ignores case sensitivity, and "numeric" to "true" sorts alphanumerically
            return new Intl.Collator(language || undefined, {
              numeric: true,
              sensitivity: "base",
            }).compare(r1, r2);
            // try locale compare if intl collator acts up... intl collator is better performance, however.
            // return r1.localeCompare(r2, language || undefined, {
            //   numeric: true,
            //   sensitivity: "base",
            // });
          },
          number: (row1, row2, columnName) => {
            let r1 = row1.values[columnName];
            let r2 = row2.values[columnName];
            return r1 > r2 ? -1 : r1 < r2 ? 1 : 0;
          },
        },
        // autoResetPage defaults to false if manual pagination is turned on, but delcaring it here just in case :)
        autoResetPage: false,
        manualPagination: true,
        initialState: {
          sortBy: React.useMemo(() => defaultSort, [defaultSort]),
          columnOrder: React.useMemo(
            () => defaultColumnOrder,
            [defaultColumnOrder]
          ),
          // columnOrder: defaultColumnOrder,
        },
        pageSize: controlledPageSize,
        pageIndex: controlledPageIndex,
        pageCount: controlledPageCount,
      },
      useResizeColumns,
      useFlexLayout,
      useColumnOrder,
      useGlobalFilter,
      useSortBy,
      usePagination,
      !isSelect ? "" : useRowSelect,
      // hook into the table instance, e.g., instance.allColumns
      (instance) => {
        // if isSelect has been passed down through props, enable checkboxes on table
        if (isSelect) {
          instance.allColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // see https://react-table.tanstack.com/docs/api/useRowSelect to view how row select props are controlled in the react table
              // in this configuration, I am extending the default onChange behavior to include a function that may or may not be passed down from a parent component, e.g., controlling some HOC state
              Header: ({
                getToggleAllRowsSelectedProps,
                toggleAllRowsSelected,
                isAllRowsSelected,
              }) => {
                const { onChange } = {
                  ...getToggleAllRowsSelectedProps(),
                };

                return (
                  <IndeterminateCheckbox
                    onChange={(e) => {
                      if (selectAll) {
                        selectAll();
                      }
                      onChange(e);
                      toggleAllRowsSelected(!isAllRowsSelected);
                    }}
                    className={classes.headerCheckbox}
                    checked={isAllRowsSelected}
                  />
                );
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => {
                const rowSelectedProps = { ...row.getToggleRowSelectedProps() };
                return (
                  <IndeterminateCheckbox
                    foo="bar"
                    onChange={(e) => {
                      if (onSelect) {
                        onSelect(row.original);
                      }
                      rowSelectedProps.onChange(e);
                    }}
                    className={classes.checkbox}
                    checked={rowSelectedProps.checked}
                  />
                );
              },
              width: "50px!important",
            },
            ...columns,
          ]);
        }
      }
    );

    const handleChangePage = (event, newPage) => {
      gotoPage(newPage);
      changePage(newPage);
      if (saveFilterSettings) {
        // saveFilterSettings({
        //   ...state.filters,
        //   start: newPage * state.filters.limit,
        // });
      }
      if (clearSelected) {
        clearSelected();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      const limit = Number(event.target.value);
      const previousRowsPerPage = controlledPageSize;

      // if the total number of items is greater than the current page size, fire effect
      if (
        controlledCount > limit ||
        previousRowsPerPage < controlledCount ||
        previousRowsPerPage > limit
      ) {
        setPageSize(limit);
        // on changing row size, go straight back to the first page
        gotoPage(0);
        changeRowsPerPage(limit);
        if (saveFilterSettings) {
          saveFilterSettings({
            ...state.filters,
            limit: limit,
            start: 0,
          });
        }

        if (clearSelected) {
          clearSelected();
        }
      }
      return null;
    };

    const handleSortBy = (columnId, desc) => {
      toggleSortBy(columnId, desc);

      // if the total number of items is greater than the current page size, fire effect
      if (controlledCount > controlledPageSize) {
        setState((s) => {
          return {
            ...s,
            filters: {
              ...s.filters,
              sorted: [{ id: columnId, desc: desc }],
            },
          };
        });
        if (clearSelected) {
          clearSelected();
        }
      }

      if (saveFilterSettings) {
        saveFilterSettings({
          ...state.filters,
          sorted: [{ id: columnId, desc: desc }],
        });
      }
    };

    // hack to make default columns work

    React.useEffect(() => {
      setColumnOrder(defaultColumnOrder);
    }, [defaultColumnOrder, setColumnOrder]);

    // Render UI
    return (
      <>
        <TableContainer className={classes.tabbedTableContainer}>
          <Table stickyHeader={stickyHeader || false}>
            <StyledTableHead>
              <HeaderGroups
                headerGroups={headerGroups}
                allColumns={allColumns}
                handleSortBy={handleSortBy}
                setColumnOrder={setColumnOrder}
                classes={classes}
                isSelect={isSelect}
                onColumnDrop={onColumnDrop}
              />
            </StyledTableHead>
            <TableBody style={{ position: "relative", zIndex: 0 }}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <BodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <BodyCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </BodyCell>
                      );
                    })}
                  </BodyRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* pagination component is meant to be child of table > tableBody > tr. Creating a new table component below to keep separate from main table */}
        <div className={classes.paginationContainer}>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  classes={{
                    toolbar: classes.paginationToolbar,
                    spacer: classes.paginationSpacer,
                    caption: classes.paginationCaption,
                    select: classes.paginationSelect,
                  }}
                  style={{ borderBottom: "none" }}
                  rowsPerPageOptions={[10, 25, 50, 100, 250]}
                  colSpan={3}
                  count={controlledCount}
                  rowsPerPage={controlledPageSize}
                  page={
                    controlledCount <= 0 ||
                    controlledCount <= controlledPageSize
                      ? 0
                      : controlledPageIndex
                  }
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },

                    native: false,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </>
    );
  };

  useEffect(() => {
    // Sets the initial table up
    setActiveTable((prevState) => ({
      ...prevState,
      ...children[activeTab].props,
    }));
  }, [activeTab, children]);

  return (
    <div className={props.className}>
      <TableToolbar
        controlledCount={activeTable.controlledCount}
        hideTotalEvents={hideTotalEvents}
        globalFilter={activeTable.globalFilter || ""}
        globalFilterLabel={activeTable.globalFilterLabel}
        numSelected={0}
        preGlobalFilteredRowCount={activeTable.data.length}
        // setGlobalFilter={activeTable.setGlobalFilter}
        setGlobalFilter={(value) => {
          setActiveTable((prevState) => ({
            ...prevState,

            globalFilter: value || undefined,
          }));
        }}
        tableHeader={activeTable.tableHeader}
        toolbarChildren={activeTable.toolbarChildren}
        useGlobalFilters={useGlobalFilters}
      />
      <HorizontalTabs
        customControl={customControl}
        customValue={activeTab}
        fullWidth={false}
        useBox={true}
        useIndicator={false}
      >
        {props.children.map((item) => {
          return (
            <div
              key={item.props.label}
              label={item.props.label}
              onClick={() => {
                setActiveTable((prevState) => ({
                  ...prevState,
                  ...item.props,
                }));
              }}
            >
              {item.props.type === "table"
                ? Tab(item.props)
                : TreeTable(item.props)}
            </div>
          );
        })}
      </HorizontalTabs>
    </div>
  );
}
