import { useEffect, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    paddingLeft:'15px',
    paddingRight:'15px'
  },
}));

export default function Forms(props) {
  const { dispatchState, state } = props;
  const [forms, setForms] = useState([]);
  const classes = useStyles();
  const realActionOptions = [
    {
      value: "none",
      label: "None",
    },
    {
      value: "topology",
      label: "Topology",
    },
    {
      value: "associate",
      label: "Associate",
    },
    {
      value: "dissociate",
      label: "Dissociate",
    },
    {
      value: "changeOwnership",
      label: "Change Ownership",
    },
    {
      value: "assembly",
      label: "Assembly",
    },
    {
      value: "origin",
      label: "Origin",
    },
    {
      value: "adoption",
      label: "Adoption",
    },
    {
      value: "pick",
      label: "Pick",
    },
    {
      value: "stow",
      label: "Stow",
    },
  ];

  useEffect(() => {
    const getForms = async () => {
      const forms = await fetch(`${props.apiUrl}forms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": props.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          return res.forms ? res.forms : [];
        })
        .catch((err) => {
          console.log(err);
        });
      return forms;
    };
    getForms().then((res) => {
      const formsMap = {};
      res.forEach((item) => (formsMap[item.formId] = item));
      setForms(formsMap);
    });
  }, [props]);

  return (
    <Grid container>
      <Grid className={classes.row} item xs={6}>
        <h4>
          {/* Going to wait for wording before adding this. Commenting out for now */}
          <Tooltip title="Select here to choose the form that you would like displayed on LXLogisticsPro for your configured event. Please contact LocatorX Client Success for assistance configuring forms.">
            <i className="fas fa-info-circle fa-sm"></i>
          </Tooltip>{" "}
          Forms{" "}
        </h4>
        <hr />
        <div style={{ maxHeight: "50vh", overflow: "auto" }}>
          <SimpleList
            isColor
            items={Object.keys(forms)
              .map((form) => {
                return {
                  id: form,
                  label: forms[form].name,
                };
              })
              .filter((el) => el !== null)}
            onClick={(id) => {
              dispatchState({
                type: "SET_SELECTED",
                label: "formRealAction",
                payload: {
                  name: id,
                },
              });
            }}
          />
        </div>
      </Grid>
      <Grid className={classes.row} item xs={6}>
        <h4>
          <Tooltip title="Choose the action that the form should execute in LXConnect. Please contact LocatorX Client Success for assistance configuring forms.">
            <i className="fas fa-info-circle fa-sm"></i>
          </Tooltip>{" "}
          Form Detail
        </h4>
        <hr />
        <div style={{ maxHeight: "50vh", overflow: "auto" }}>
          {!state.selected || !state.selected.formRealAction ? (
            <p>Select an form to view/edit its detail</p>
          ) : (
            <>
              <Grid container>
                <Grid xs={12} item>
                  <SimpleSelect
                    label="Real Action"
                    options={realActionOptions}
                    onChange={(event) => {
                      dispatchState({
                        type: "SET_SELECTED",
                        label: "formRealAction",
                        payload: {
                          ...state.selected.formRealAction,
                          realAction: event.target.value,
                        },
                      });
                      dispatchState({
                        type: "CHANGE_GLOBAL",
                        setting: "formRealActions",
                        payload: {
                          ...state.globalSettings.formRealActions,
                          [state.selected.formRealAction.name]: {
                            realAction: event.target.value,
                          },
                        },
                      });
                    }}
                    value={
                      state.globalSettings.formRealActions[
                        state.selected?.formRealAction.name
                      ]
                        ? state.globalSettings.formRealActions[
                            state.selected?.formRealAction.name
                          ].realAction
                        : "none"
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
