import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function SimpleTextField(props) {
  const {
    className,
    error = false,
    errorText = "Required Field",
    helperText,
    id,
    InputProps = {},
    label = "",
    multiline = false,
    onChange,
    readOnly = false,
    required,
    type = "",
    value,
    variant = "outlined",
  } = props;
  const classes = useStyles();
  return (
    <TextField
      InputLabelProps={{ required: false }}
      className={`${classes.root} ${className}`}
      error={error}
      required={required}
      helperText={error ? errorText : helperText}
      id={id}
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      label={label}
      multiline={multiline}
      onChange={(event) => {
        onChange(event);
      }}
      type={type}
      value={value}
      variant={variant}
    />
  );
}
