import React from "react";
import { Form, Col } from "react-bootstrap";
import request from "superagent";
import _ from "lodash";

import StateSelect from "../../../../components/Selects/StateSelect";
import RequiredField from "../../../../components/Forms/RequiredField/RequiredField";

import { validateLat, validateLong } from "../utils/utils";
import { putCentroid } from "../API/API";
import { editPreflightCheck } from "../API/preflight";

export default function EditFacility(props: any) {
  const facilityClone = _.cloneDeep(props.facility);

  const [editState, setEditState] = React.useState<any>({
    facilityId: props.match.params.facilityId,
    validated: false,
    ...facilityClone,
    latValid: true,
    longValid: true,
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !editState.latValid ||
      !editState.longValid
    ) {
      props.notificationModal(
        `Please make sure all required fields are filled out properly.`,
        true
      );
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const { apiUrl, facility, notificationModal, onHide, onSuccess, token } =
        props;

      const { location, facilityType, name, propertiesMap, facilityId } =
        editState;

      let body = {
        location,
        facilityType,
        name,
        propertiesMap,
      };

      const req = request.put(`${apiUrl}facilities/${facilityId}`);

      // Remove facility ID from location object, as it interferes with geocode lookup logic on the backend
      if (body.location.facilityId) {
        delete body.location.facilityId;
      }
      // if the user is sending an empty lat/long, then they must have deleted the lat long and are entering a new address; strip out lat/lon from PUT body, as it interferes with geocode lookup as well
      if (!body.location.latitude || !body.location.longitude) {
        delete body.location.latitude;
        delete body.location.longitude;
        delete body.location.lat1000;
        delete body.location.lon1000;
      }
      // if lat/lon is being sent, construct a centroid string for preflight check, else set to null... preflight will check for centroid string and remove it if null
      const centroidString =
        body.location.latitude && body.location.longitude
          ? `POINT(${body.location.longitude} ${body.location.latitude})`
          : null;

      editPreflightCheck(
        { apiUrl, token },
        {
          // if name hasn't been changed, don't run a check on it
          name: body.name !== facility.name ? body.name : null,
          centroidString: centroidString || null,
          radius: 0.01,
          location: body.location.address1
            ? {
                address1: body.location.address1,
                city: body.location.city,
                state: body.location.state,
              }
            : null,
          facilityId: facilityId,
        }
      ).then((res: any) => {
        if (res.error) {
          notificationModal(`${res.error}`, true);
        } else {
          req
            .set("auth-token", token)
            .send(body)
            .then((response) => {
              if (response.body.success) {
                // update centroid / radius automatically if prev lat/lon differs from updated lat/lon
                const resLat = response.body.facility.location.latitude;
                const resLon = response.body.facility.location.longitude;

                if (
                  parseFloat(resLat).toFixed(5) !==
                    parseFloat(facility.location.latitude).toFixed(5) ||
                  parseFloat(resLon).toFixed(5) !==
                    parseFloat(facility.location.longitude).toFixed(5)
                ) {
                  putCentroid(
                    { ...props, facilityId: facilityId },
                    {
                      latitude: {
                        value: resLat,
                      },
                      longitude: {
                        value: resLon,
                      },
                      // putCentroid function divides incoming radius by 1000, as it is expecting meters but writes to API in kilometers.
                      radius: 400,
                    }
                  ).then((res) => {
                    if (res?.error) {
                      notificationModal(
                        `Facility data updated, but there was an error when updating centroid / radius for ${body.name}, message: ${res.error}`,
                        true
                      );
                    } else {
                      setTimeout(() => {
                        onHide();
                        notificationModal(
                          "Facility Succesfully Updated",
                          false
                        );
                      }, 150);
                      onSuccess();
                    }
                  });
                } else {
                  setTimeout(() => {
                    onHide();
                    notificationModal("Facility Succesfully Updated", false);
                  }, 150);
                  onSuccess();
                }
              } else {
                notificationModal(
                  `There was an error when updating your facility, message: ${response.body.error}`,
                  true
                );
              }
            });
        }
      });
    }
    setEditState({ ...editState, validated: true });
  };

  const { locationTypes = [] } = props;
  const {
    location = {},
    facilityType = "",
    name = "",
    propertiesMap = {},
    validated,
  } = editState;
  const {
    address1,
    city = "",
    postalCode = "",
    state = "",
    latitude,
    longitude,
  } = location;
  const { locationDetails = "" } = propertiesMap;

  return (
    <Form
      className="container-fluid"
      noValidate
      onSubmit={(event: any) => {
        handleSubmit(event);
      }}
      validated={validated}
    >
      <Form.Row>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Facility Name</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="name"
            onChange={(event: any) => {
              setEditState({ ...editState, name: event.target.value });
            }}
            required
            value={name || ""}
          />
          <RequiredField label="Required Field" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Facility Type</Form.Label>
          <Form.Control
            as="select"
            className="custom-select bg-white"
            name="facilityType"
            onChange={(event: any) => {
              setEditState({ ...editState, facilityType: event.target.value });
            }}
            required
            value={facilityType || ""}
          >
            <option value="" disabled>
              Select
            </option>
            {locationTypes.map((element: string, index: number) => {
              return (
                <option key={index} value={element}>
                  {element}
                </option>
              );
            })}
          </Form.Control>
          <RequiredField label="Required Field" />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label className="mt-2 mb-0">Address</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="address1"
            onChange={(event: any) => {
              let newAddress = editState.location || {};
              newAddress.address1 = event.target.value;
              setEditState({ ...editState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={address1 || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">City</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="city"
            onChange={(event: any) => {
              let newAddress = editState.location || {};
              newAddress.city = event.target.value;
              setEditState({ ...editState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={city || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <StateSelect
            onChange={(state: string) => {
              let newAddress = editState.location || {};
              newAddress.state = state;
              setEditState({ ...editState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            requiredFieldMessage={"Required Field if no Lat/Long set"}
            state={state || ""}
          />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Zip Code</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="postalCode"
            onChange={(event: any) => {
              let newAddress = editState.location || {};
              newAddress.postalCode = event.target.value;
              setEditState({ ...editState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={postalCode || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Latitude</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="latitude"
            isInvalid={
              editState.location?.latitude?.length > 0 &&
              !validateLat.test(
                parseFloat(editState.location?.latitude).toFixed(5)
              )
            }
            required={
              location.latitude?.length > 0 || location.longitude?.length > 0
                ? true
                : false
            }
            onChange={(event: any) => {
              const hasLength = event.target.value?.length > 0;

              const latValid = hasLength
                ? validateLat.test(parseFloat(event.target.value).toFixed(5))
                : true;

              setEditState({
                ...editState,
                location: {
                  ...editState.location,
                  latitude: hasLength ? event.target.value : null,
                  lat1000: hasLength
                    ? parseInt(event.target.value) * 1000
                    : null,
                },
                latValid: latValid,
              });
            }}
            value={latitude || ""}
          />
          <RequiredField label="Please enter a properly formatted latitude" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Longitude</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="longitude"
            isInvalid={
              editState.location?.longitude?.length > 0 &&
              !validateLong.test(
                parseFloat(editState.location?.longitude).toFixed(5)
              )
            }
            required={
              location.latitude?.length > 0 || location.longitude?.length > 0
                ? true
                : false
            }
            onChange={(event: any) => {
              const hasLength = event.target.value?.length > 0;
              const longValid = hasLength
                ? validateLong.test(parseFloat(event.target.value).toFixed(5))
                : true;
              setEditState({
                ...editState,
                location: {
                  ...editState.location,
                  longitude: hasLength ? event.target.value : null,
                  lon1000: hasLength
                    ? parseInt(event.target.value) * 1000
                    : null,
                },
                longValid: longValid,
              });
            }}
            value={longitude || ""}
          />
          <RequiredField label="Please enter a properly formatted longitude" />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label className="mt-2 mb-0">
            Facility Details - optional
          </Form.Label>
          <Form.Control
            className="form-control bg-white"
            onChange={(event: any) => {
              let newPropertiesMap = editState.propertiesMap || {};
              newPropertiesMap.locationDetails = event.target.value;
              setEditState({ ...editState, propertiesMap: newPropertiesMap });
            }}
            name="locationDetails"
            value={locationDetails || ""}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-1">
          <button type="submit" className="btn btn-primary w-100">
            Submit
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
