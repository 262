import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
  },
  label: {
    fontWeight:'500',
    marginBottom:'.2rem'
  }
}));

export default function SelectWithExternalLabel(props) {
  const classes = useStyles();
  const {
    disabled,
    error,
    helperText,
    id,
    inputProps,
    label,
    margin,
    onChange,
    options,
    readOnly,
    required,
    size,
    style,
    type,
    value,
    variant,
  } = props;

  return (
    <>
      {label ? (
        <div className={classes.label}>
          {label ? label : "Select"}
        </div>
      ) : null}
      <FormControl
        className={
          props.classes?.select ? props.classes.select : classes.select
        }
        error={error}
        size={size}
        variant={variant ? variant : "standard"}
      >
        <Select
          disabled={disabled}
          id={id}
          inputProps={{ readOnly: readOnly, ...inputProps }}
          margin={margin}
          onChange={(e) => {
            onChange(e);
          }}
          required={required}
          style={style}
          type={type}
          value={value || ""}
        >
          {options && options.length ? (
            options.map((option, idx) => (
              <MenuItem value={option.value} key={`${idx} - ${option.value}`}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>None</MenuItem>
          )}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </>
  );
}
