import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  simpleButton: {
    width: "100%"
  }
}));

export default function SimpleButton(props) {
  const classes = useStyles();
  const { color = "primary", content = "Submit", disabled = false, onClick, variant = "contained" } = props;
  return (
    <div className={classes.root}>
      <Button
        className={classes.simpleButton}
        color={color}
        disabled={disabled}
        onClick={() => {
          onClick();
        }}
        variant={variant}
      >
        {content}
      </Button>
    </div>
  );
}
