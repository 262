import Select from "react-select";

function ReactSelect(props) {
  return (
    <Select
      isDisabled={props.disabled}
      className={props.className}
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      options={props.options && props.options.length ? props.options : []}
      onChange={props.onChange}
      value={props.value}
      menuPortalTarget={props.menuPortalTarget}
      isMulti={props.isMulti}
      styles={props.styles}
    />
  );
}

export default ReactSelect;
