import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddAssetModal from "./AddAssetModal/AddAssetModal";
import { onSubmit } from "./AddAssetModal/utils";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0 2rem",
    minWidth: "7rem",
  },
}));

export default function AddAssetButton(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    modalOpen: false,
    alertModalOpen: false,
    alertModalSeverity: "success",
    alertModalContent: "",
  });
  const handleAlertModal = (content) => {
    const {
      alertModalContent,
      alertModalSeverity = "success",
      alertModalTitle,
    } = content;

    setState({
      ...state,
      alertModalContent,
      alertModalOpen: true,
      alertModalSeverity,
      alertModalTitle,
    });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={state.alertModalOpen}
        onClose={state.closeModal}
      >
        <MuiAlert
          onClose={state.closeModal}
          severity={state.alertModalSeverity}
        >
          {state.alertModalContent}
        </MuiAlert>
      </Snackbar>
      <AddAssetModal
        {...props}
        handleAlertModal={handleAlertModal}
        handleClose={() => {
          setState((prevState) => ({ ...prevState, modalOpen: false }));
          setTimeout(() => {
            setState((prevState) => ({ ...prevState, alertModalOpen: false }));
          }, 3000);
        }}
        modalOpen={state.modalOpen}
        onSubmit={onSubmit}
      />
      <Button
        className={classes.button}
        onClick={() => {
          setState((prevState) => ({ ...prevState, modalOpen: true }));
        }}
        variant="contained"
        size="small"
        color="primary"
      >
        ADD ASSET
      </Button>
    </div>
  );
}
