import { getBatchData, getBatchAssets, getProductData } from "./api";
import { getDevices } from "../../utils/apiCalls";
import { getAppUser, getZonesData } from "./api";
import { buildDeviceAssetsMap } from "../devices/utils/utils";

export const naturalSort = (a: any, b: any, language?: any) => {
  // Intl Collator can help us sort diacritics and other idiosyncracies across languages, supported on 95% of browsers (including IE). Setting "sensitivity" to "base" ignores case sensitivity, and "numeric" to "true" sorts alphanumerically
  return new Intl.Collator(language || undefined, {
    numeric: true,
    sensitivity: "base",
  }).compare(a, b);
};

export const init = async (props: any) => {
  const { organization = {}, facilities, usersMap, batchIdFromParams } = props;

  const appUser = await getAppUser(props);
  const { propertiesMap = {} } = appUser;

  const { timeZone = {} } = propertiesMap;

  const eventTypes =
    organization && organization.eventTypesMap
      ? [
          ...Object.keys(organization.eventTypesMap),
          "origin",
          "scan",
          "leaveGeoFence",
          "enterGeoFence",
        ].sort((a: any, b: any) => a.localeCompare(b))
      : ["origin", "scan", "leaveGeoFence", "enterGeoFence"];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      })
    : [];

  // The "limit", "start", and "sort" filters are controlled from the "rows", "page" index, and column sorting headers on the table itself, not the filter drop down
  let initFilters = {
    assets: null,
    assetId: null,
    binLocations: null,
    endDate: null,
    events: null,
    defaultColumnOrder: [],
    type: null,
    limit: 25,
    locations: [],
    locals: [],
    startDate: null,
    sorted: [
      {
        id: "timeOfLog",
        desc: true,
      },
    ],
    zones: null,
    ...(propertiesMap?.batchHistoryTableSettings || {}),
  };

  let stateObj = {};

  await getBatchData({ ...props }, batchIdFromParams).then(
    async (batchRes: any) => {
      if (batchRes.error || !batchRes.batch) {
        return (stateObj = {
          error: "No batch data found. This batch may have been deleted.",
        });
      } else {
        const batchData = batchRes.batch;
        await getBatchAssets({ ...props }, batchIdFromParams).then(
          async (assetRes: any) => {
            if (assetRes.error || !assetRes.assets) {
              return (stateObj = {
                error: "No items found for this batch!",
              });
            } else {
              const assetsArray = assetRes.assets.sort((a: any, b: any) => {
                if (a.tag && b.tag) {
                  return naturalSort(a.tag, b.tag);
                } else {
                  return -1;
                }
              });

              await getProductData({ ...props }, batchData).then(
                async (productRes: any) => {
                  if (productRes.error) {
                    return (stateObj = {
                      error: "No product data found for this batch!",
                    });
                  } else {
                    const productArr = productRes;
                    let availableDevices: any[] = [];
                    let availableZones: any[] = [];
                    let allDevices: any[] = [];
                    let deviceAssetsMap: any[] = [];

                    await getZonesData({ ...props }).then(async (response) => {
                      const zoneData = response.zones;
                      zoneData.forEach((item: any) => {
                        availableZones.push({
                          label: item.name,
                          value: item.zoneId,
                        });
                      });
                    });

                    await getDevices({ ...props }).then(async (response) => {
                      allDevices = response.assets;
                      response.assets.forEach((item: any) => {
                        if (item.device.status !== "assigned") {
                          availableDevices.push(item);
                        }
                      });
                      deviceAssetsMap = await buildDeviceAssetsMap(
                        props,
                        response.assets
                      );
                    });

                    const assetTypes = [
                      ...new Set(
                        assetsArray.map((a: any) => a.assetType).sort()
                      ),
                    ];

                    return (stateObj = {
                      filters: {
                        ...initFilters,
                        start: 0,
                        tz: timeZone,
                      },
                      page: 0,
                      lists: {
                        allDevices,
                        assetTypes: assetTypes,
                        availableDevices,
                        availableZones,
                        deviceAssetsMap,
                        eventTypes: eventTypes,
                        facilityArray: facilityArray,
                        assetsArray: assetsArray,
                        facilityMap: facilities || {},
                        eventTypesMap: organization.eventTypesMap || {},
                        usersMap,
                      },
                      batchData: {
                        ...batchData,
                      },
                      productData: productArr,
                      userPropertiesMap:
                        appUser.propertiesMap?.batchHistoryTableSettings,
                    });
                  }
                }
              );
            }
          }
        );
      }
    }
  );
  return stateObj;
};

export const refreshBatchAndDeviceData = (props: any) => {
  const { setModal, batchIdFromParams } = props;

  getBatchAssets({ ...props }, batchIdFromParams)
    .then((assetRes: any) => {
      if (assetRes.error || !assetRes.assets) {
        setModal({
          modalShow: true,
          text: "There was a problem refreshing assets",
          isError: true,
        });
      } else {
        const assetsArray = assetRes.assets.sort((a: any, b: any) => {
          if (a.tag && b.tag) {
            return naturalSort(a.tag, b.tag);
          } else {
            return -1;
          }
        });
        getDevices({ ...props })
          .then(async (response) => {
            let availableDevices: any[] = [];
            let allDevices: any[] = response.assets;
            let deviceAssetsMap: any[] = await buildDeviceAssetsMap(
              props,
              response.assets
            );
            response.assets.forEach((item: any) => {
              if (item.device.status !== "assigned") {
                availableDevices.push(item);
              }
            });

            props.setState((prevState: any) => ({
              ...prevState,
              lists: {
                ...prevState.lists,
                allDevices,
                availableDevices,
                assetsArray,
                deviceAssetsMap,
              },
            }));
          })
          .catch(() => {
            setModal({
              modalShow: true,
              text: "There was a problem refreshing devices",
              isError: true,
            });
          });
      }
    })
    .catch(() => {
      setModal({
        modalShow: true,
        text: "There was a problem refreshing batches",
        isError: true,
      });
    });
};

export const parseUserInfo = (row: any, firstLast = false) => {
  if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
    return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""}${
      row.lastName || ""
    }`;
  }
  if (row.appUserId && (row.firstName || row.lastName)) {
    return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""}${
      row.firstName || ""
    }`;
  } else {
    return ``;
  }
  // else if (row.appUserId === "53ef1d1f-3e47-46e7-b444-18170051486f") {
  //   return `iOS User`;
  // } else if (row.appUserId === "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7") {
  //   return `Android User`;
  // } else {
  //   return ``;
  // }
};
