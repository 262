import { useRef } from "react";
import { unionWith, isEqual } from "lodash";
import DatePicker from "../../../../Forms/FieldTypes/DatePicker";

import AsyncReactSelectCheckboxes from "../../../../Selects/AsyncReactSelectCheckbox";
import ReactSelectCheckboxes from "../../../../Selects/ReactSelectCheckboxes";

import { searchAssets, searchBins } from "../../utils";
import states from "./states.json";

import { Grid } from "@material-ui/core";

import ZoomSlider from "./ZoomSlider";
import DateSelectionRadios from "./DateSelectionRadios";
import LimitSlider from "./LimitSlider";
import ClassificationFilters from "./ClassificationsFilters";

export default function Standardfilters(props: any) {
  const {
    apiUrl,
    token,
    organizationId,
    setAlertModal,
    setFilters,
    filters,
    eventTypesArray,
    facilityArray,
    zonesArray,
    classifications,
    timeZone,
  } = props;

  const handleFilterChange = (update: any) => {
    setFilters((f: any) => {
      return {
        ...f,
        ...update,
      };
    });
  };

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions: any
  ) => {
    const results = await searchAssets(
      { apiUrl, token, organizationId },
      inputValue,
      300,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results) => {
      if (results.error) {
        return setAlertModal({
          alertModalContent: results.error,
          alertModalOpen: true,
          alertModalSeverity: "error",
        });
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset: any) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  // ref for incrementing start location in solr query for bin location options
  // having to do this because we are not able to use the length of the options returned to determine start location, as we are constructing distinct sets of options from indistinct results.
  const binLocationOptionsPageRef = useRef(0);

  const asyncBinLocationLoadOptions = async (
    inputValue = "",
    loadedOptions: any
  ) => {
    const limit = 300;

    const results = await searchBins(
      { apiUrl, token, organizationId },
      inputValue,
      limit,
      binLocationOptionsPageRef.current
    ).then((results) => {
      if (results.error) {
        return setAlertModal({
          alertModalContent: results.error,
          alertModalOpen: true,
          alertModalSeverity: "error",
        });
      }

      // increment page ref...
      binLocationOptionsPageRef.current += limit;

      const options =
        results.assetHistories && results.assetHistories.length
          ? [
              ...new Set(
                results.assetHistories
                  .filter((history: any) => history.binLocation)
                  .map((history: any) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
          : [];

      // create distinct union of options and loaded options, using lodash _.unionWith to use deep comparison between objects in arrays.
      const union =
        loadedOptions.length > 0
          ? unionWith(options, loadedOptions, isEqual)
          : options;

      return {
        options: union,
        // I am incrementing a ref by a constant limit outside the scope of the function, which is used to determine the page start location in the data. When that number, the "start" field, is greater than the results.count, we return a false hasMore and stop searching.
        hasMore: binLocationOptionsPageRef.current < results.count,
      };
    });
    return results;
  };

  return (
    <>
      <ZoomSlider filters={filters} setFilters={setFilters} />
      <LimitSlider filters={filters} setFilters={setFilters} />
      {/* Asset Tag */}
      <Grid item xs={6}>
        <label>Asset Tag</label>
        <AsyncReactSelectCheckboxes
          id={"overviewMapAssetFilter"}
          loadOptions={asyncAssetIdSearchLoadOptions}
          onChange={(e: any) => {
            handleFilterChange({ assetIds: e });
          }}
          value={filters.assetIds}
        />
      </Grid>

      {/* Events */}

      <Grid item xs={6}>
        <label>Event</label>
        <ReactSelectCheckboxes
          id={"overviewMapEventFilter"}
          options={
            eventTypesArray && eventTypesArray.length
              ? [
                  ...eventTypesArray.map((type: string) => {
                    return {
                      label: type,
                      value: type,
                    };
                  }),
                ]
              : []
          }
          onChange={(e: any) => {
            handleFilterChange({ lastEvents: e });
          }}
          value={filters.lastEvents}
        />
      </Grid>

      {/* Facilities */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Facility Name</label>
        <ReactSelectCheckboxes
          id={"overviewMapFacilityFilter"}
          options={
            facilityArray && facilityArray.length
              ? [
                  ...facilityArray.map((facility: any) => {
                    return {
                      label: facility.name,
                      value: facility,
                    };
                  }),
                ]
              : []
          }
          onChange={(e: any) => {
            handleFilterChange({ locations: e, locale: null });
          }}
          value={filters.locations}
        />
      </Grid>

      {/* States */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>State</label>
        <ReactSelectCheckboxes
          isDisabled={
            filters.locations === null ||
            filters.locations === undefined ||
            filters.locations.length === 0
              ? false
              : true
          }
          onChange={(e: any) => {
            handleFilterChange({ locals: e });
          }}
          options={states}
          value={filters.locals}
        />
      </Grid>

      {/* Bin Locations */}

      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Bin Location</label>
        <AsyncReactSelectCheckboxes
          loadOptions={asyncBinLocationLoadOptions}
          onChange={(e: any) => {
            handleFilterChange({ binLocation: e || null });
          }}
          value={filters.binLocation || null}
        />
      </Grid>

      {/* Zones */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Zones</label>
        <ReactSelectCheckboxes
          options={zonesArray ? zonesArray : []}
          onChange={(e: any) => {
            handleFilterChange({ zones: e });
          }}
          value={filters.zones}
        />
      </Grid>

      <ClassificationFilters
        classifications={classifications}
        filters={filters}
        handleFilterChange={handleFilterChange}
      />

      <DateSelectionRadios
        filters={filters}
        setFilters={setFilters}
        timeZone={timeZone}
      />

      {/* Start Date */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Select Start Date</label>
        <DatePicker
          disabled={filters.dateSelection !== "custom"}
          format="yyyy/MM/DD"
          label="Start Date"
          onChange={(date: any) => {
            if (filters.endDate && date > filters.endDate) {
              alert("Can't set start date later than end date");
              return;
            }

            handleFilterChange({ startDate: new Date(date) });
          }}
          size="small"
          value={filters.startDate}
        />
      </Grid>

      {/* End Date */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Select End Date</label>
        <DatePicker
          disabled={filters.dateSelection !== "custom"}
          format="yyyy/MM/DD"
          label="End date"
          onChange={(date: any) => {
            if (filters.startDate && date < filters.startDate) {
              alert("Can't set end date earlier than start date");
              return;
            }
            handleFilterChange({ endDate: new Date(date) });
          }}
          size="small"
          value={filters.endDate}
        />
      </Grid>
    </>
  );
}
