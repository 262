import { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { Dropzone } from "../../components/Dropzone/Dropzone";
import { validateAndFormatSpreadsheet } from "./xlsx";
import request from "superagent";
import Loading from "../../components/Loading/Loading";

export default function ImportBatches(props) {
  const [accepted, setAccepted] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const uploadFile = (file) => {
    setLoading(true);

    request
      .post(`${props.apiUrl}assets/import`)
      .set("auth-token", props.token)
      .attach("filedata", file)
      .then((response) => {
        setLoading(false);
        const { success, numAdded, numFailures } = response.body;
        if (success && !numFailures && numAdded > 0) {
          props.notificationModal(
            `Success! ${numAdded} assets added, ${numFailures} failed.`,
            false,
            3000
          );
          setFile({});
          setAccepted(false);
          setTimeout(() => {
            props.onHide();
          }, 3000);
          props.onSuccess();
        } else {
          props.notificationModal(
            `Uh-oh! Something went wrong. Did you follow the example spreadsheet?`,
            true,
            5000
          );
        }
      });
  };

  return (
    <Form
      onSubmit={(e) => {
        uploadFile(file);
        e.preventDefault();
      }}
    >
      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-1">
          <p>
            Download and fill out Excel file template, and then upload the file
            below:{" "}
            <a href={`${process.env.PUBLIC_URL}/BatchImportTemplate.xlsx`}>
              BatchImportTemplate.xlsx
            </a>
          </p>
          <p>
            To see an example import spreadsheet that imports multiple batches
            using multiple spreadsheets, download the example file:{" "}
            <a href={`${process.env.PUBLIC_URL}/BatchImportExample.xlsx`}>
              BatchImportExample.xlsx
            </a>
          </p>
          {loading ? <Loading color="#5884F5" /> : ""}
          <Dropzone
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
            acceptText="Drag 'n' drop or click to select file (only *.xlsx files will be accepted)"
            callback={(acceptedFiles, accepted) => {
              setAccepted(false);

              validateAndFormatSpreadsheet(acceptedFiles[0], props, (cb) => {
                if (!cb.success) {
                  props.notificationModal(`${cb.message}`, true, 7000);
                  accepted(false);
                } else {
                  setAccepted(true);
                  setFile(cb.file);
                  accepted(true);
                }
              });
            }}
            clearFile={() => {
              setFile({});
              setAccepted(false);
            }}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-2">
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={accepted ? false : true}
          >
            Submit
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
