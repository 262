import { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import MaterialUiButton from "../../../Buttons/MaterialUiButton/MaterialUiButton";
import MomentUtils from "@date-io/moment";
import SimpleTextField from "../../../Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    margin: ".5rem 0",
    padding: "0 .5rem",
  },
  inputField: {
    width: "100%",
  },
}));

export default function AssetContent(props) {
  const {
    closeModal,
    handleAlertModal,
    onSubmit,
    organization,
    parentProps,
    setGenerateCQR,
    setNewAsset,
  } = props;

  const { customAssetFieldsList = [] } = organization;

  // TODO: Put this back into the desctructuring above. Only doing this while we work on
  // the dev server
  const assetCategoriesList =
    organization[process.env.REACT_APP_ASSET_CATEGORIES];

  const [
    {
      assetTag,
      category,
      description,
      note,
      selectedFacility,
      status,
      timeCreatedUser,
      timeNeeded,
    },
    setState,
  ] = useState({
    assetTag: { error: false, id: "assetTag", value: "" },
    category: { error: false, id: "category", value: "" },
    description: { error: false, id: "description", value: "" },
    note: { id: "note", value: "" },
    selectedFacility: { error: false, id: "selectedFacility", value: "" },
    status: { error: false, id: "status", value: "" },
    timeCreatedUser: { id: "timeCreatedUser", value: "" },
    timeNeeded: { id: "timeNeeded", value: "" },
  });
  const [availableEvents, setAvailableEvents] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [classifications, setClassifications] = useState({
    ...props.classifications,
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const classes = useStyles();

  useEffect(() => {
    const { facilities, organization = {} } = props;
    const {
      eventTypesMap = {},
      assetCategoriesList = [],
      customAssetFieldsList = [],
    } = organization;

    const availableEventItems = [];
    const categoryOptionItems = [];
    const facilityOptionItems = [];
    const fields = {};

    customAssetFieldsList.forEach((asset) => {
      fields[asset.id] = {
        ...asset,
        required: false,
        error: false,
        value: "",
      };
    });

    // Set Facility Options
    Object.keys(facilities)
      .sort((a, b) => {
        return facilities[a].name?.localeCompare(facilities[b].name);
      })
      .forEach((element) => {
        const { name, facilityId } = facilities[element];
        facilityOptionItems.push({ label: name, value: facilityId });
      });

    // Set Category Options
    [...assetCategoriesList]
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      .forEach((element) => {
        categoryOptionItems.push({ id: element.id });
      });

    // Set Availble Events
    Object.keys(eventTypesMap)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        if (element === "Tag Destroyed") {
          return null;
        } else {
          availableEventItems.push({ value: element });
        }
      });

    if (Object.keys(fields).length > 0) {
      setCustomFields(fields);
    }

    setAvailableEvents(availableEventItems);
    setCategoryOptions(categoryOptionItems);
    setFacilityOptions(facilityOptionItems);
  }, [props]);

  function validateFields() {
    let isValidated = true;

    const fieldsToValidate = [
      assetTag,
      category,
      description,
      selectedFacility,
      status,
    ];

    fieldsToValidate.forEach((element) => {
      const { value, id } = element;

      if (value.length === 0) {
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true, id, value },
          };
        });
        isValidated = false;
      }
    });

    Object.keys(customFields).forEach((element) => {
      const { value, id, required } = customFields[element];
      if (required && value?.length === 0) {
        isValidated = false;
        setCustomFields((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true },
          };
        });
      }
    });

    if (isValidated) {
      const selectedClassifications = [];

      // Handles Classifications
      Object.keys(classifications.active).forEach((item) => {
        if (
          classifications.active[item].selectedValue !== null &&
          classifications.active[item].selectedValue !== undefined &&
          classifications.active[item].selectedValue.length > 0
        ) {
          selectedClassifications.push(
            classifications.active[item].selectedValue
          );
        }
      });

      onSubmit(
        parentProps,
        handleAlertModal,
        {
          assetTag,
          category,
          description,
          note,
          selectedClassifications,
          selectedFacility,
          status,
          timeCreatedUser,
          timeNeeded,
        },
        customFields
      ).then((response) => {
        // Defaults the values
        setState({
          assetTag: {
            error: false,
            id: "assetTag",
            value: "",
          },
          category: {
            value: "",
            error: false,
            id: "category",
          },
          description: {
            error: false,
            id: "description",
            value: "",
          },
          note: { id: "note", value: "" },
          selectedFacility: {
            error: false,
            id: "selectedFacility",
            value: "",
          },
          status: {
            error: false,
            id: "status",
            value: "",
          },
          timeCreatedUser: {
            id: "timeCreatedUser",
            value: "",
          },
          timeNeeded: {
            id: "timeNeeded",
            value: "",
          },
        });

        // Delivers the assetId to the parent component and tells it to switch
        // to the next layout
        setNewAsset(response);
        setGenerateCQR(true);
      });
    }
  }

  return (
    <>
      <DialogContent>
        <Grid container>
          {/* Asset Tag */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={assetTag.error}
              helperText={assetTag.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Asset Tag"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  assetTag: {
                    ...prevState.assetTag,
                    value,
                    error:
                      prevState.assetTag.error && value.length > 0
                        ? false
                        : prevState.assetTag.error,
                  },
                }));
              }}
              value={assetTag.value}
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={description.error}
              helperText={description.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Description"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  description: {
                    ...prevState.description,
                    value,
                    error:
                      prevState.description.error && value.length > 0
                        ? false
                        : prevState.description.error,
                  },
                }));
              }}
              value={description.value}
              variant="outlined"
            />
          </Grid>

          {/* Category */}
          <Grid className={classes.inputContainer} item xs={4}>
            <Autocomplete
              id="category"
              options={categoryOptions}
              onChange={(event, value) => {
                if (value) {
                  const { id = "" } = value;
                  // Sets the value in state
                  setState((prevState) => ({
                    ...prevState,
                    category: {
                      ...prevState.category,
                      value: id,
                      error:
                        prevState.category.error && id.length > 0
                          ? false
                          : prevState.category.error,
                    },
                  }));

                  // Checks custom fields to see if any fields need to switched to required
                  // The else statement sets everything to default. In the case they switch
                  // to a category that does not have any custom fields set.
                  const isValidCustomCategory = assetCategoriesList.find(
                    (category) => {
                      return category.id === id;
                    }
                  );

                  if (isValidCustomCategory) {
                    const { id: categoryId = "", requiredFields = [] } =
                      isValidCustomCategory;
                    if (categoryId === id) {
                      setCustomFields((prevState) => {
                        let newState = { ...prevState };
                        requiredFields.forEach((field) => {
                          const { id: fieldId, required } = field;
                          newState[fieldId] = {
                            ...newState[fieldId],
                            required,
                            error: false,
                          };
                        });
                        return newState;
                      });
                    }
                  }
                }
              }}
              getOptionLabel={(option) => option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={category.error}
                  helperText={category.error ? "Required Field" : ""}
                  label="Category"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* Facility */}
          <Grid className={classes.inputContainer} item xs={4}>
            <Autocomplete
              id="facility"
              options={facilityOptions}
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    selectedFacility: {
                      ...prevState.selectedFacility,
                      value: itemValue,
                      error:
                        prevState.selectedFacility.error && itemValue.length > 0
                          ? false
                          : prevState.selectedFacility.error,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={selectedFacility.error}
                  helperText={selectedFacility.error ? "Required Field" : ""}
                  label="Facility"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* Status */}
          <Grid className={classes.inputContainer} item xs={4}>
            <Autocomplete
              id="status"
              options={availableEvents}
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    status: {
                      ...prevState.status,
                      value: itemValue,
                      error:
                        prevState.status.error && itemValue.length > 0
                          ? false
                          : prevState.status.error,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={status.error}
                  helperText={status.error ? "Required Field" : ""}
                  label="Status"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {/* timeCreatedUser */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeCreatedUser`}
          >
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeCreatedUser`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={"Create Date"}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeCreatedUser: {
                      ...prevState.timeCreatedUser,
                      value,
                    },
                  }));
                }}
                value={
                  timeCreatedUser.value?.length > 0
                    ? timeCreatedUser.value
                    : null
                }
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* timeNeeded */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeNeeded`}
          >
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeNeeded`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={"Need Date"}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeNeeded: {
                      ...prevState.timeNeeded,
                      value,
                    },
                  }));
                }}
                value={timeNeeded.value?.length > 0 ? timeNeeded.value : null}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* Custom Fields */}
          {Object.keys(customFields).map((element, index) => {
            if (customFields[element].id) {
              let {
                dataType,
                error = false,
                id,
                label = "",
                value = "",
              } = customFields[element];

              switch (dataType) {
                case "Currency":
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      key={`${id}-${index}`}
                      xs={4}
                    >
                      <FormControl
                        className={classes.inputField}
                        error={error}
                        variant="outlined"
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-amount">
                        Amount
                      </InputLabel> */}
                        <CurrencyTextField
                          currencySymbol=""
                          error={error}
                          helperText={`${error ? "Required Field" : ""}`}
                          label={label}
                          onChange={(event, value) => {
                            setCustomFields((prevState) => ({
                              ...prevState,
                              [id]: { ...prevState[id], error: false, value },
                            }));
                          }}
                          outputFormat="string"
                          value={value}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  );
                case "Date":
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      xs={4}
                      key={`${id}-${index}`}
                    >
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        variant="outlined"
                      >
                        <KeyboardDatePicker
                          autoOk
                          allowKeyboardControl={false}
                          className={classes.inputField}
                          disableToolbar
                          format="MM/DD/yyyy"
                          helperText={error ? "Required Field" : ""}
                          id={`date-picker-inline-${id}`}
                          inputVariant="outlined"
                          error={error}
                          invalidDateMessage={`${
                            error ? "Required Field" : ""
                          }`}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          label={label}
                          onChange={(event) => {
                            const value = event
                              ? event.format("MM/DD/yyyy")
                              : "";
                            setCustomFields((prevState) => ({
                              ...prevState,
                              [id]: {
                                ...prevState[id],
                                error: false,
                                value,
                              },
                            }));
                          }}
                          value={value.length > 0 ? value : null}
                          variant="inline"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  );
                default:
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      key={`${id}-${index}`}
                      xs={4}
                    >
                      <TextField
                        className={classes.inputField}
                        error={error}
                        helperText={error ? "Required Field" : ""}
                        label={label}
                        onChange={(event) => {
                          const value = event.target ? event.target.value : "";
                          setCustomFields((prevState) => ({
                            ...prevState,
                            [id]: { ...prevState[id], value, error: false },
                          }));
                        }}
                        type={`${dataType === "Numeric" ? "number" : ""}`}
                        value={value}
                        variant="outlined"
                      />
                    </Grid>
                  );
              }
            }
            return null;
          })}

          {/* Classifications */}
          {Object.keys(classifications.active).map((parent, index) => {
            const { children, classificationId, name } =
              classifications.active[parent];
            const childrenOptions = [];

            // Populates the select options
            Object.keys(children).forEach((child) => {
              childrenOptions.push({
                label: children[child].name,
                parentId: classificationId,
                value: children[child].classificationId,
              });
            });

            return (
              <Grid
                className={classes.inputContainer}
                item
                key={classificationId}
                xs={4}
              >
                <Autocomplete
                  id={name}
                  key={`${name}-${index}`}
                  options={childrenOptions}
                  onChange={(event, value) => {
                    if (value) {
                      const { value: itemValue = "" } = value;
                      setClassifications((prevState) => ({
                        ...prevState,
                        active: {
                          ...prevState.active,
                          [name]: {
                            ...prevState.active[name],
                            selectedValue: itemValue,
                          },
                        },
                      }));
                    } else {
                      setClassifications((prevState) => ({
                        ...prevState,
                        active: {
                          ...prevState.active,
                          [name]: {
                            ...prevState.active[name],
                            selectedValue: value,
                          },
                        },
                      }));
                    }
                  }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label={name} variant="outlined" />
                  )}
                />
              </Grid>
            );
          })}

          {/* Asset Details */}
          <Grid className={classes.inputContainer} item xs={12}>
            <SimpleTextField
              multiline
              id="outlined-basic"
              label="Asset Details - optional"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  note: { ...prevState.note, label: value, value: value },
                }));
              }}
              variant="outlined"
              value={note.label}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          className={classes.inputContainer}
          justify="space-between"
          container
        >
          <MaterialUiButton
            color="cancel"
            label="Cancel"
            onClick={() => {
              const fields = {};
              customAssetFieldsList.forEach((asset) => {
                fields[asset.id] = {
                  ...asset,
                  error: false,
                  required: false,
                  value: "",
                };
              });

              if (Object.keys(fields).length > 0) {
                setCustomFields(fields);
              }
              closeModal();
            }}
            variant="outlined"
          />
          <MaterialUiButton
            color="primary"
            label="submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Grid>
      </DialogActions>
    </>
  );
}
