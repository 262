import { useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";

import DatePicker from "../../../../../components/Forms/FieldTypes/DatePicker";

import ModalDialog from "../../../../../components/Modals/ModalDialog/ModalDialog";
import ReactSelectCheckboxes from "../../../../../components/Selects/ReactSelectCheckboxes";
import AsyncReactSelectCheckboxes from "../../../../../components/Selects/AsyncReactSelectCheckbox";

import { eventSelectStyles } from "./eventSelectStyles";
import { searchMOAssets } from "../api";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.main,
  },
}));

export default function FilterMenu(props: any) {
  const classes = useStyles();

  const {
    setState: setStateFromProps,
    state: stateFromProps,
    filterDefaults,
    setView,
    view,
    organization,
    organizationId,
    apiUrl,
    token,
  } = props;

  const { eventTypesMap = {} } = organization;

  const [filters, setFilters] = useState(stateFromProps.filters);

  const eventTypesOptions = (
    eventTypesMap ? Object.keys(eventTypesMap).sort() : []
  ).map((type) => {
    return {
      label: type,
      value: type,
      color: eventTypesMap[type].color || "",
    };
  });

  const handleFilterChange = (update: any) => {
    setFilters((f: any) => {
      return {
        ...f,
        ...update,
      };
    });
  };

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions: any
  ) => {
    const results = await searchMOAssets(
      { apiUrl, token, organizationId },
      inputValue,
      300,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results: any) => {
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset: any) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  return (
    <ModalDialog
      title={"Current MO's Filters"}
      open={view.filterView}
      handleClose={() => setView({ filterView: !view.filterView })}
      content={
        <Grid id="assemblyStatusFilterView" container spacing={3}>
          {/* MO numbers */}
          <Grid item xs={12}>
            <label>Search by MO</label>
            <AsyncReactSelectCheckboxes
              classNamePrefix="asset-tag"
              loadOptions={asyncAssetIdSearchLoadOptions}
              onChange={(e: any) => {
                handleFilterChange({ assetIds: e });
              }}
              value={filters.assetIds}
            />
          </Grid>

          {/* Status */}
          <Grid item xs={12}>
            <label>Status</label>
            <ReactSelectCheckboxes
              id={"eventActivityEventFilterSelect"}
              maximumSelectionLength={7}
              styles={eventSelectStyles(filters.customColors)}
              options={eventTypesOptions}
              onChange={(e: any) => {
                handleFilterChange({
                  lastEvents: e,
                });
              }}
              value={filters.lastEvents}
            />
          </Grid>

          {/* Start Date */}
          <Grid item xs={6}>
            <label>Select Create Date (Start)</label>
            <DatePicker
              format="yyyy/MM/DD"
              label="Start Date"
              onChange={(date: any) => {
                if (filters.createDateEnd && date > filters.createDateEnd) {
                  alert("Can't set start date later than end date");
                  return;
                }
                handleFilterChange({ createDate: new Date(date) });
              }}
              size="small"
              value={filters.createDate}
            />
          </Grid>

          {/* End Date */}
          <Grid item xs={6}>
            <label>Select Create Date (End)</label>
            <DatePicker
              format="yyyy/MM/DD"
              label="End Date"
              onChange={(date: any) => {
                if (filters.createDate && date < filters.createDate) {
                  alert("Can't set end date earlier than start date");
                  return;
                }
                handleFilterChange({ createDateEnd: new Date(date) });
              }}
              size="small"
              value={filters.createDateEnd}
            />
          </Grid>

          {/* Need date start */}
          <Grid item xs={6}>
            <label>Select Need Date (Start)</label>
            <DatePicker
              format="yyyy/MM/DD"
              label="Start Date"
              onChange={(date: any) => {
                if (filters.needDateEnd && date > filters.needDateEnd) {
                  alert("Can't set start date later than end date");
                  return;
                }
                handleFilterChange({ needDate: new Date(date) });
              }}
              size="small"
              value={filters.needDate}
            />
          </Grid>

          {/* End Date */}
          <Grid item xs={6}>
            <label>Select Need Date (End)</label>
            <DatePicker
              format="yyyy/MM/DD"
              label="End Date"
              onChange={(date: any) => {
                if (filters.needDate && date < filters.needDate) {
                  alert("Can't set end date earlier than start date");
                  return;
                }
                handleFilterChange({ needDateEnd: new Date(date) });
              }}
              size="small"
              value={filters.needDateEnd}
            />
          </Grid>
        </Grid>
      }
      dialogActions={
        <Grid container justify={"space-between"} spacing={3}>
          <Grid item>
            <Button
              className={`${classes.clearFiltersButton} ${classes.button}`}
              onClick={() => {
                setFilters({
                  ...filterDefaults,
                });
              }}
              variant="contained"
            >
              Clear Filters
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => {
                setView({
                  filterView: false,
                });
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={`${classes.button} ${classes.saveButton}`}
              color="primary"
              onClick={() => {
                setStateFromProps((s: any) => {
                  return {
                    ...s,
                    filters: {
                      ...s.filters,
                      ...filters,
                    },
                  };
                });
                setView({ filterView: false });
              }}
              variant="contained"
            >
              Set Filters
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}
