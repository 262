import { useState, useEffect } from "react";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  Button,
  withStyles,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import Loading from "../../../../components/Loading/Loading";

import { Filters } from "../../interface";

import {
  FilterByTag,
  Available,
  LastStatus,
  DeviceType,
} from "./filterComponents/index";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.main,
    marginLeft: "1rem",
  },
}));

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props: any) {
  const {
    displayColumnOptions,
    defaultColumnOrder,
    state,
    saveFilterSettings,
    filterSearch,
    organization,
  } = props;
  const {
    assetType = {},
    lastEvent = {},
    status = {},
    tag = {},
  } = displayColumnOptions;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [filters, setFilters] = useState<Filters>();
  useEffect(() => {
    setFilters(
      state?.filters || {
        tag: null,
        external_id: null,
        assets: [],
        status: null,
        asset_type: [],
        last_event: [],
        battery: {
          equality: null,
          level: null,
        },
      }
    );
  }, [state?.filters, open]);
  const handleSave = () => {
    // spreading filters with displayColumnOptions and defaultColumnOrder to save the state of both, as displayColumnOptions doesnt update in filters

    saveFilterSettings({
      ...filters,
      displayColumnOptions,
      defaultColumnOrder,
    });

    filterSearch(filters);
  };

  return (
    <>
      <Tooltip title="Select filters" placement="top-start">
        <span style={{ cursor: "pointer" }} onClick={handleClickOpen}>
          <div className="exportIcon">
            Filters
            <i className="fas fa-filter ml-2"></i>
          </div>
        </span>
      </Tooltip>

      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="filters"
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Device */}
            {tag.checked ? (
              <Grid item xs={12}>
                <label>Device Identifier</label>
                <FilterByTag
                  {...props}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Grid>
            ) : null}

            {/* Availability */}
            {status.checked ? (
              <Grid item xs={12}>
                <label>Availability</label>
                <Available filters={filters} setFilters={setFilters} />
              </Grid>
            ) : null}

            {/* Status */}
            {lastEvent.checked ? (
              <Grid item xs={12}>
                <label>Status</label>
                <LastStatus
                  filters={filters}
                  setFilters={setFilters}
                  organization={organization}
                />
              </Grid>
            ) : null}

            {/* Device Type */}
            {assetType.checked ? (
              <Grid item xs={12}>
                <label>Device Type</label>
                <DeviceType filters={filters} setFilters={setFilters} />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}

          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilters({
                tags: null,
                assets: [],
                status: null,
                asset_type: [],
                last_event: [],
                battery: {
                  equality: null,
                  level: null,
                },
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.button} ${classes.saveButton}`}
            color="primary"
            onClick={() => {
              setLoading(true);
              handleSave();
              setTimeout(() => {
                setLoading(false);
                handleClose();
              }, 300);
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
