import UncontrolledTable from "../../../../components/Tables/UncontrolledTable/UncontrolledTable";
import ActionButtons from "../../../../components/Buttons/ActionButtons";

export default function FacilityTable(props: any) {
  const { state = {}, setDialog, dialog, permissions, setView } = props;

  const { editLocation, deleteLocation } = permissions;

  const tableColumns = [
    {
      Header: "Facility Name",
      accessor: "name",
      id: "name",
      Cell: (data: any) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row.original,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.original.name}
          </div>
        );
      },
    },
    {
      Header: "Facility Type",
      accessor: "facilityType",
      id: "facilityType",
      Cell: (data: any) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row.original,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.original.facilityType}
          </div>
        );
      },
    },
    {
      Header: "City",
      accessor: (row: any) => row.location?.city || "",
      id: "city",
      Cell: (data: any) => {
        const row = data.row;
        const { location = {} } = row.original;
        const { city = "" } = location;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row.original,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {city}
          </div>
        );
      },
    },
    {
      Header: "State",
      accessor: (row: any) => row.location?.state || "",
      id: "state",
      Cell: (data: any) => {
        const row = data.row;
        const { location = {} } = row.original;
        const { state = "" } = location;
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row.original,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {state}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "export",
      id: "export",
      disableSortBy: true,
      Cell: (data: any) => {
        const row = data.row;
        const buttonIcons: any = [];
        [
          "Zones",
          "Facility Area",
          "Edit Facilities",
          "Delete Facilities",
        ].forEach((element) => {
          switch (element) {
            case "Zones":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-object-group",
                  title: "Facility Zones",
                  action: () => {
                    setView({
                      facility: row.original,
                      viewZones: true,
                    });
                  },
                });
              }
              break;
            case "Facility Area":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-vector-square",
                  title: "Facility Area",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row.original,
                      dialogTitle: "Facility Area",
                    });
                  },
                });
              }
              break;
            case "Edit Facilities":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-edit",
                  title: "Edit",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row.original,
                      dialogTitle: "Edit Facility",
                    });
                  },
                });
              }
              break;
            case "Delete Facilities":
              if (deleteLocation) {
                buttonIcons.push({
                  icon: "fas fa-trash-alt",
                  title: "Delete",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row.original,
                      dialogTitle: "Delete Facility",
                    });
                  },
                });
              }
              break;
            default:
              break;
          }
        });
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  return (
    <UncontrolledTable
      stickyHeader
      totalCountLabel={"Facilities"}
      data={state.facilities}
      defaultSort={[{ id: "name" }]}
      columns={tableColumns}
    />
  );
}
