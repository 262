import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";

function MaterialConfirmationModal(props) {
  const {
    closeModal,
    content,
    modalOpen,
    severity = "success",
    title,
    variant = "filled",
  } = props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        closeModal();
      }}
      open={modalOpen || false}
    >
      <Alert severity={severity} variant={variant}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {content}
      </Alert>
    </Dialog>
  );
}

export default MaterialConfirmationModal;
