import { MouseEvent, Component, createElement } from "react";
import * as Icons from "@material-ui/icons/";

type IconType = typeof import("@material-ui/icons/index");

interface DynamicIconProps {
  iconName: string;
  style?: any;
  onClick?: ((event: MouseEvent) => void) | undefined;
  onMouseEnter?: ((event: MouseEvent) => void) | undefined;
}

export default class DynamicIcon extends Component<DynamicIconProps> {
  render() {
    return createElement(Icons[this.props.iconName! as keyof IconType], {
      style: this.props.style || {},
      onClick: this.props.onClick || undefined,
      onMouseEnter: this.props.onMouseEnter || undefined,
    });
  }
}
