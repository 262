import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SimpleButton from "../../../components/Forms/FieldTypes/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { assignSelectedAssetsToDevice } from "../api";
import { exportSpreadsheet } from "../../devices/utils/xlsx";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "1rem",
  },
  itemTitle: {
    marginBottom: "auto",
    marginTop: "auto",
    textAlign: "left",
  },
}));

export default function AssignDevices(props: any) {
  const classes = useStyles();
  const [state, setState] = useState({
    assetOptions: [],
    assignDevices: false,
    deviceOptions: [],
    selectedAssets: [],
    selectedAssetsHash: {},
  });
  const selectedAssetsHash: Record<string, any> = state.selectedAssetsHash;
  const {
    assetsArray = [],
    allDevices = [],
    deviceAssetsMap = {},
  } = props.state.lists;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const assetSelector = (
    <Grid item xs={12}>
      <Autocomplete
        disableCloseOnSelect
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        id="asset-selector"
        multiple
        onChange={(event, newInputValue) => {
          let assetHash: Record<string, any> = {};
          newInputValue.forEach((item: any) => {
            assetHash[item.value] = {
              ...item,
              deviceId: null,
              deviceLabel: null,
              error: false,
              errorText: "",
            };
          });
          setState((prevState: any) => ({
            ...prevState,
            selectedAssets: newInputValue,
            selectedAssetsHash: assetHash,
          }));
        }}
        options={state.assetOptions}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select" />
        )}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              color="primary"
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </>
        )}
        value={state.selectedAssets}
      />
    </Grid>
  );
  const assignDeviceTab = (
    <>
      <Grid item xs={12}>
        <span
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() => {
            exportSpreadsheet(
              Object.keys(allDevices).map((k) => {
                return allDevices[k];
              }),
              deviceAssetsMap,
              props.timeZone
            );
          }}
        >
          <div className="exportIcon">
            Download Spreadsheat with Device Information and Associated Item
            Tags
            <i className="fas fa-file-export ml-2"></i>
          </div>
        </span>
      </Grid>
      {Object.keys(state.selectedAssetsHash).map((item: any, index: number) => {
        const { error, errorText, label, value } = selectedAssetsHash[item];
        return (
          <Grid className={classes.itemContainer} item key={value} xs={12}>
            <Grid className={classes.itemTitle} item xs={3}>
              <Typography>{label}</Typography>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                getOptionLabel={(option: { label: string }) => option.label}
                onChange={(event, newInputValue: any) => {
                  if (newInputValue) {
                    setState((prevState) => {
                      const newSelectedAssetHash: Record<string, any> =
                        prevState.selectedAssetsHash;
                      newSelectedAssetHash[item].deviceId = newInputValue.value;
                      newSelectedAssetHash[item].error = false;
                      newSelectedAssetHash[item].errorText = "";
                      newSelectedAssetHash[item].deviceLabel =
                        newInputValue.label;
                      return {
                        ...prevState,
                        selectedAssetsHash: newSelectedAssetHash,
                      };
                    });
                  } else {
                    setState((prevState) => {
                      const newSelectedAssetHash: Record<string, any> =
                        prevState.selectedAssetsHash;
                      newSelectedAssetHash[item].deviceId = null;
                      return {
                        ...prevState,
                        selectedAssetsHash: newSelectedAssetHash,
                      };
                    });
                  }
                }}
                options={state.deviceOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    label="Select Device"
                    helperText={error ? errorText : ""}
                    variant="outlined"
                  />
                )}
                size="small"
                style={{ width: 300 }}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );

  useEffect(() => {
    const assetOptions = assetsArray
      .filter((a: any) => {
        if (props.state.filters?.type?.value) {
          return a.assetType === props.state.filters.type.value;
        }
        return a;
      })
      .map((asset: any, idx: number) => {
        return {
          hasDevice: asset.parentId ? true : false,
          label: asset.tag,
          value: asset.assetId,
        };
      });

    const deviceOptions = props.availableDevices.map(
      (
        item: { assetId: string; tag: string; device: { status: string } },
        index: number
      ) => {
        if (item.device.status !== "assigned") {
          return {
            value: item.assetId,
            label: item.tag ? item.tag : `No Device Tag ${index}`,
          };
        }
        return null;
      }
    );

    setState((prevState) => ({ ...prevState, assetOptions, deviceOptions }));
  }, [assetsArray, props.availableDevices, props.state.filters]);

  function checkForAvailableDevices() {
    if (props.availableDevices.length < state.selectedAssets.length) {
      props.setModal({
        modalShow: true,
        text: "You have selected more items than there are available devices. Please adjust your selections.",
        isError: true,
      });
    } else if (
      state.selectedAssets.some((element: any) => element.hasDevice === true)
    ) {
      props.setModal({
        modalShow: true,
        text: "You have selected one or more assets that already has a device assigned to it.",
        isError: true,
      });
    } else {
      setState((prevState: any) => ({
        ...prevState,
        assignDevices: true,
      }));
    }
  }

  function validateFields() {
    let error = false;

    Object.keys(selectedAssetsHash).forEach((item) => {
      const selectedAsset = selectedAssetsHash[item];
      const deviceId = selectedAsset.deviceId || null;
      const hasDuplicate =
        Object.keys(selectedAssetsHash).find(
          (asset) =>
            item !== asset && selectedAssetsHash[asset].deviceId === deviceId
        ) || "";

      // We check to verify there are no null deviceId's
      if (deviceId === null) {
        error = true;
        setState((prevState) => ({
          ...prevState,
          selectedAssetsHash: {
            ...prevState.selectedAssetsHash,
            [item]: {
              ...selectedAsset,
              error: true,
              errorText: "Please select a device",
            },
          },
        }));

        // We then check that no assets have the same device attached to them
      } else if (hasDuplicate.length > 0) {
        error = true;
        setState((prevState) => ({
          ...prevState,
          selectedAssetsHash: {
            ...prevState.selectedAssetsHash,
            [item]: {
              ...selectedAsset,
              error: true,
              errorText: "Another Item has selected this device",
            },
          },
        }));
      }
    });

    if (!error) {
      assignSelectedAssetsToDevice(props, selectedAssetsHash).then(
        (assetsThatDidNotAssociateWithADevice) => {
          if (assetsThatDidNotAssociateWithADevice.length === 0) {
            props.setModal({
              modalShow: true,
              text: "Item(s) Successfully Assigned",
              isError: false,
            });
            props.setTabNavigation(0);
            props.onSuccess();
          }
        }
      );
    }
  }

  return (
    <Grid container>
      {state.assignDevices ? assignDeviceTab : assetSelector}

      <Grid className={classes.buttonContainer} item xs={12}>
        {/* Assign Devices Button */}
        {!state.assignDevices ? (
          <SimpleButton
            content="Assign Devices"
            disabled={state.selectedAssets.length === 0}
            onClick={() => {
              checkForAvailableDevices();
            }}
          />
        ) : null}

        {/* Submit and Cancel Buttons */}
        {state.assignDevices ? (
          <Grid className={classes.buttonContainer} item xs={12}>
            <Grid item xs={4}>
              <SimpleButton
                content="Cancel"
                onClick={() => {
                  setState((prevState: any) => {
                    let newSelectedAssetsHash = prevState.selectedAssetsHash;

                    // We reset any previous errors we might of thrown up
                    Object.keys(newSelectedAssetsHash).forEach((item) => {
                      newSelectedAssetsHash[item].error = false;
                      newSelectedAssetsHash[item].errorText = "";
                    });

                    return {
                      ...prevState,
                      assignDevices: false,
                      selectedAssetsHash: newSelectedAssetsHash,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SimpleButton
                content="Submit"
                onClick={() => {
                  validateFields();
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
