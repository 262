import { Filters } from "../interface";
import { buildDeviceAssetsMap } from "./utils";
const escapeCharacters = (input: string) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export async function searchDevices(
  props: any,
  input: Filters = {},
  start: number = 0,
  limit: number = 300
) {
  const { apiUrl, token, organizationId } = props;
  const {
    tags = [],
    external_id = "",
    status = [],
    last_event = [],
    // battery = {},
    asset_type = [],
  } = input;

  const eventArray =
    last_event && last_event.length > 0
      ? (last_event as any)
          ?.map((el: any) => {
            return `last_event:${escapeCharacters(el)}`;
          })
          .join(" OR ")
      : null;

  const statusString = status?.length
    ? "(" +
      status
        .map((item: any) => {
          if ((item as any) === "unassigned") {
            return `NOT {!tuple}device.status:assigned`;
          }
          return `{!tuple}device.status:${item.value}` || "";
        })
        .join(" OR ") +
      ")"
    : "";

  // let batteryFilter = null;

  // if (battery?.level && battery.equality) {
  //   let battLevel = parseInt(battery.level as string);
  //   switch (battery.equality) {
  //     case "=":
  //       batteryFilter = `${battLevel}`;
  //       break;
  //     case ">":
  //       batteryFilter = `[${battLevel + 1} TO *]`;
  //       break;
  //     case "≥":
  //       batteryFilter = `[${battLevel} TO *]`;
  //       break;
  //     case "<":
  //       batteryFilter = `[* TO ${battLevel - 1}]`;
  //       break;
  //     case "≤":
  //       batteryFilter = `[* TO ${battLevel}]`;
  //       break;
  //     default:
  //       batteryFilter = `${battLevel}`;
  //   }
  // }

  let tagString = "";
  if (tags && tags.length) {
    let tagSet = tags.map((e: any) => {
      return `tag:${escapeCharacters(e.value)}`;
    });
    tagString = tagSet.join(" OR ");
  }

  const deviceTypeArray =
    asset_type && asset_type.length > 0
      ? (asset_type as any)
          ?.map((el: any) => {
            return `asset_type:${escapeCharacters(el)}`;
          })
          .join(" OR ")
      : null;

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        `${tagString ? `${tagString}` : ``}`,
        `{!tuple}device.platform:*`,
        // tag ? `tag:${escapeCharacters(tag)}` : ``,
        external_id
          ? `{!tuple}device.external_id:${escapeCharacters(external_id)}`
          : ``,
        statusString,
        eventArray ? eventArray : ``,
        // batteryFilter ? `{!tuple}properties_map.battery:${batteryFilter}` : ``,
        deviceTypeArray ? deviceTypeArray : ``,
      ],
      sort: `time_of_log asc, tag desc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function init(props: any) {
  // get filters if saved on the users profile, else dont pass in filter arg
  const appUser = await getAppUser(props);
  const initFilters = appUser?.propertiesMap?.deviceTableSettings || {
    defaultColumnOrder: [],
  };

  const results = await searchDevices(props, initFilters).then(async (res) => {
    if (res.error) {
      return { error: res.error };
    }
    const deviceList = res.assets;
    const numDevices = res.count || 0;

    const deviceAssetsMap = await buildDeviceAssetsMap(props, deviceList);

    return {
      deviceAssetsMap,
      numDevices,
      deviceList,
      initFilters,
    };
  });

  return results;
}

const getAppUser = async (props: any) => {
  const results = await fetch(`${props.apiUrl}appUsers/${props.userId}`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.appUser;
    });

  return results;
};

export const saveFilterSettings = async (props: any, filters: any) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      deviceTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
