import { useState, useEffect, useRef, useCallback } from "react";

import { isEqual, omit } from "lodash";
import moment from "moment-timezone";
import request from "superagent";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { thunks } from "../../globalStore/slices/organization/organizationSlice";

import ActionButtons from "../../components/Buttons/ActionButtons";
import CenteredModal from "../../components/Modals/CenteredModal";
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import NotificationModal from "../../components/Modals/NotificationModal";
import UncontrolledTable from "../../components/Tables/UncontrolledTable/UncontrolledTable";

import AssignDataToPCAResponsePage from "./AssignDataToPCAResponsePage";
import ViewBatchInformation from "./ViewBatchInformationModal/ViewBatchInformation";
import SelectCQR from "./SelectCQR";
import ViewProductInformation from "./ViewProductInformation";
import ImportBatches from "./importBatches";

import { handleExport } from "./utils";
import "./Batches.css";

export default function Batches(props) {
  // OK to destructure, as any updates to batches will trigger a re-render anyway...
  const { facilities, organization, products } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const batches = useSelector((state) => state.organization.batches, isEqual);
    
  const [state, setState] = useState({
    assetTypes: organization.assetTypes,
    products: products,
    //batches: Object.keys(batches).map((k) => batches[k]),
    productTypes: organization?.productTypes?.filter((t) => t !== null),
    facilities: facilities,

    confirmationModalShow: false,
    confirmationText: "",
    createCQRIcon: false,
    deleteBatchesIcon: false,
    error: false,
    errorModalShow: false,
    errorModalText: "",
    isProcessing: false,
    loading: false,
    modal: "",
    modalShow: false,
    modalTitle: "",
    selectedBatch: {},
    selectedBatches: {},
    viewBatchInformation: false,
    viewProductInformation: false,
  });

  const handleState = (stateUpdate) => {
    setState((s) => {
      return {
        ...s,
        ...stateUpdate,
      };
    });
  };

  const { retrieveBatches: retrieveBatchesThunk } = thunks.batches;

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  // onSuccess function retrieves batches, patch/diff with redux store, and resets location state
  const retrieveBatchesAndProducts = () => {
    dispatchGlobal(retrieveBatchesThunk());
    location.state = {};
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveBatchesThunk());
      }
    },
    [dispatchGlobal, retrieveBatchesThunk]
  );

  const mounted = useRef(false);

  useEffect(() => {
    const { state: routerState = {} } = props.location || {};

    const { modal = "", modalShow = false, modalTitle = "" } = routerState;

    if (modalShow) {
      setState((s) => {
        return {
          ...s,
          modal,
          modalTitle,
          modalShow,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This cleans up the quickLinks and modal behavior
    return props.history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [props, init]);

  const selectAllPageRows = (pageRows, deselectPageRows) => {
    let updatedSelection = {};

    const selectedBatches = state.selectedBatches;

    const reducedPageRows = pageRows.reduce((x, y) => {
      return {
        ...x,
        [y.batchId]: y,
      };
    }, {});
    if (deselectPageRows) {
      updatedSelection = omit(selectedBatches, Object.keys(reducedPageRows));
    } else {
      updatedSelection = { ...selectedBatches, ...reducedPageRows };
    }
    handleState({ selectedBatches: updatedSelection });
  };

  const modelSelect = (value, modal, modalShow, modalTitle) => {
    handleState({
      selectedBatch: value,
      modal: modal,
      modalShow: modalShow,
      modalTitle: modalTitle,
    });
  };

  const handleBatchDelete = () => {
    const batchId = state.selectedBatch.batchId;
    handleState({
      loading: true,
      modalShow: false,
      confirmationModalShow: false,
    });
    request
      .delete(`${props.apiUrl}batches/${batchId}`)
      .set("auth-token", props.token)
      .then((res) => {
        if (res.status === 200) {
          handleState({ modalShow: false, loading: false });
          retrieveBatchesAndProducts();
          handleConfirmationModal("Batch Successfully Deleted");
        }
      })
      .catch((err) => {
        handleState({ modalShow: false, loading: false });
        retrieveBatchesAndProducts();
        handleConfirmationModal("Batch Successfully Deleted");
      });
  };

  const handleConfirmationModal = (
    confirmationText,
    error = false,
    timeout = 1750
  ) => {
    handleState({ confirmationModalShow: true, confirmationText, error });
    setTimeout(function () {
      handleState({ confirmationModalShow: false, deleteErorr: false });
    }, timeout);
  };

  const selectRow = (rowValue) => {
    const newSelectedBatches = state.selectedBatches;
    if (newSelectedBatches[rowValue.batchId]) {
      delete newSelectedBatches[rowValue.batchId];
    } else {
      newSelectedBatches[rowValue.batchId] = rowValue;
    }
    handleState({ selectedBatches: newSelectedBatches });
  };

  const { apiUrl, token, timeZone } = props;

  const {
    assetTypes,
    confirmationModalShow,
    confirmationText,
    error,
    errorModalShow,
    errorModalText,
    loading,
    modal,
    modalShow,
    modalTitle,
    productTypes,
    selectedBatch,
    viewBatchInformation,
    viewProductInformation,
  } = state;

  const modalClose = () =>
    handleState({
      modalShow: false,
      confirmationModalShow: false,
      viewProductInformation: false,
    });

  const tableColumns = [
    {
      Header: "Batch ID",
      id: "name",
      accessor: (row) => {
        return row.tag ? row.tag : row.name ? row.name : "";
      },
      Cell: (data) => {
        const row = data.row;

        return (
          <div
            className="pointer batchId"
            onClick={() => {
              handleState({
                selectedBatch: row.original,
                viewBatchInformation: true,
              });
            }}
          >
            {row.original.tag
              ? row.original.tag
              : row.original.name
              ? row.original.name
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Products",
      accessor: (row) => {
        const { topologiesList = [], topologyList = {} } = row;

        const { products = {} } = state;

        const productArray = [
          ...new Set(
            topologiesList.map((top) => {
              return products[top.productId]
                ? products[top.productId].name
                : "";
            })
          ),
        ];

        const productArrayString =
          productArray && productArray.length > 0
            ? productArray.join(", ")
            : productArray && productArray.length
            ? productArray.join("")
            : "";

        const { productId = "" } = topologyList;

        return productArrayString
          ? productArrayString
          : products[productId]
          ? products[productId].name
          : "";
      },
      id: "product",
      Cell: (data) => {
        const row = data.row;
        const { topologiesList = [], topologyMap = {} } = row.original;

        const { products = {} } = state;

        const productArray = [
          ...new Set(
            topologiesList.map((top) => {
              return products[top.productId]
                ? products[top.productId].name
                : "";
            })
          ),
        ];

        const productArrayString =
          productArray && productArray.length > 0
            ? productArray.join(", ")
            : productArray && productArray.length
            ? productArray.join("")
            : "";

        const { productId = "" } = topologyMap;

        return (
          <div
            className="pointer productId"
            onClick={() => {
              handleState({
                selectedBatch: row.original,
                viewBatchInformation: true,
                viewProductInformation: true,
              });
            }}
          >
            {productArrayString
              ? productArrayString
              : products[productId]
              ? products[productId].name
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Batch Origin",
      id: "originLocation",
      accessor: (row) => {
        const { originLocation = {} } = row;

        const { facilityId = "", facility = "" } = originLocation;

        const label = facilities[facilityId]
          ? facilities[facilityId].name
          : facilities[facility]
          ? facilities[facility].name
          : "";

        return label;
      },
    },
    {
      Header: "Date Created",
      id: "timeCreated",
      accessor: (row) => {
        if (row.timeCreated) {
          const timeCreatedUnix = `${moment
            .utc(row.timeCreated, "YYYYMMDDHHmmss")
            .tz(timeZone?.value || moment.tz.guess())
            .format("x")}`;
          return timeCreatedUnix;
        }
        return "";
      },
      Cell: (data) => {
        const row = data?.row?.original;
        if (row?.timeCreated) {
          const timeCreated = `${moment
            .utc(row.timeCreated, "YYYYMMDDHHmmss")
            .tz(timeZone?.value || moment.tz.guess())
            .format("MM/DD/YYYY")}`;
          return <div>{timeCreated || ""}</div>;
        }
        return "";
      },
    },
    {
      Header: "Actions",
      accessor: "export",
      id: "export",
      disableSortBy: true,
      Cell: (data) => {
        const row = data.row;
        const buttonIcons = [
          {
            icon: "fas fa-map-marker-alt",
            title: "View Event Log",
            action: () => {
              const { batchId } = row.original;
              props.history.push(`/batches/${batchId}/history/`);
            },
          },
        ];

        if (props.userRoles["Create CQR"]) {
          buttonIcons.push({
            icon: "fas fa-bolt",
            title: "Generate CQR",
            action: () =>
              modelSelect(row.original, "Generate", true, "Generate CQR"),
          });
        }

        if (props.userRoles["Delete Batches"]) {
          buttonIcons.push({
            icon: "fas fa-trash-alt",
            title: "Delete",
            action: () =>
              modelSelect(
                row.original,
                "confirmationModal",
                true,
                "Delete Batch"
              ),
          });
        }

        if (props.usersConsoleRole !== "Lite") {
          buttonIcons.push({
            icon: "fas fa-mobile-alt",
            title: "Assign Data to PCA Response Page",
            action: () => {
              const { propertiesMap = {} } = row.original;
              const { pcaId } = propertiesMap;
              if (pcaId) {
                modelSelect(
                  row.original,
                  "Assign Data to PCA Response Page",
                  true,
                  "Assign Data to PCA Response Page"
                );
              } else {
                handleState({
                  errorModalShow: true,
                  errorModalText:
                    "This batch is not a part of the PCA, A PCA response cannot be assigned.",
                });
              }
            },
          });
        }

        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  const switchModal = (modal) => {
    switch (modal) {
      case "View Product Information":
        return (
          <ViewProductInformation
            onHide={modalClose}
            batch={selectedBatch}
            facilities={facilities}
            readOnly={true}
            products={products}
            productTypes={productTypes}
          />
        );
      case "Generate":
        return (
          <SelectCQR
            organizationId={props.organizationId}
            onHide={modalClose}
            onSuccess={retrieveBatchesAndProducts}
            token={props.token}
            apiUrl={props.apiUrl}
            assetTypes={assetTypes}
            selectedBatch={selectedBatch}
          />
        );
      case "confirmationModal":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${
              selectedBatch.tag ? selectedBatch.tag : selectedBatch.name
            }? This action cannot be undone.`}
            handleSubmit={() => {
              handleBatchDelete();
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Import Batches":
        return (
          <ImportBatches
            onHide={modalClose}
            onSuccess={retrieveBatchesAndProducts}
            token={props.token}
            apiUrl={props.apiUrl}
            facilities={props.facilities}
            organization={organization}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Assign Data to PCA Response Page": {
        const { topologiesList = [] } = selectedBatch;
        const productId = topologiesList[0].productId || "";
        return (
          <AssignDataToPCAResponsePage
            apiUrl={apiUrl}
            timeZone={timeZone}
            batch={selectedBatch}
            handleConfirmationModal={handleConfirmationModal}
            modalClose={modalClose}
            onSuccess={retrieveBatchesAndProducts}
            product={products[productId]}
            token={token}
          />
        );
      }
      default:
        return;
    }
  };

  return (
    <div className="batches-page">
      <NotificationModal
        confirmationModalShow={errorModalShow}
        modalClose={() => {
          handleState({ errorModalShow: false });
        }}
        confirmationText={errorModalText}
        color="bg-danger"
        icon="fas fa-times-circle mr-2"
      />

      <ViewBatchInformation
        timeZone={props.timeZone}
        products={products}
        facilities={facilities}
        apiUrl={apiUrl}
        token={token}
        viewProductInformation={viewProductInformation}
        handleClose={() => {
          handleState({ viewBatchInformation: false });

          // We set this timeout to give the modal some time to close before defaulting values
          setTimeout(() => {
            handleState({ viewProductInformation: false });
          }, 500);
        }}
        isOpen={viewBatchInformation}
        selectedBatch={selectedBatch}
      />

      <div className={`container-fluid mt-3 ${loading ? "opacity-50" : ""}`}>
        <CenteredModal
          show={modalShow}
          content={switchModal(modal)}
          label={modalTitle}
          onHide={modalClose}
        />
        <MaterialConfirmationModal
          content={confirmationText}
          closeModal={modalClose}
          modalOpen={confirmationModalShow}
          severity={error ? "error" : "success"}
          variant="filled"
        />
        <div className="row mt-4 mb-3">
          <div className="col-12 col-md-6 text-md-left text-center">
            <h3>Batches</h3>
          </div>
          {props.usersConsoleRole !== "Lite" ? (
            <div className="col-12 col-md-6 text-md-right text-center">
              <button
                onClick={() =>
                  handleState({
                    modal: "Import Batches",
                    modalShow: true,
                    modalTitle: "Import Batches",
                  })
                }
                type="button"
                className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
              >
                Import Batches
              </button>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-12">
            <UncontrolledTable
              stickyHeader
              totalCountLabel={"Batches"}
              data={Object.keys(batches).map((batch) => batches[batch]) || []}
              columns={tableColumns}
              isSelect={true}
              selectAllPageRows={selectAllPageRows}
              onSelect={selectRow}
              defaultSort={[{ id: "timeCreated", desc: true }]}
              toolbarChildren={
                <span
                  className={"exportIcon"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleExport(state, props, handleConfirmationModal)
                  }
                >
                  Export
                  <i className="fas fa-file-export ml-2"></i>
                </span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
