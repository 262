import CustomDataFields from "../../components/CustomDataFields/CustomDataFields";
import Grid from "@material-ui/core/Grid";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";

export default function ProductInformation(props) {
  const {
    addProductLevelDataElement,
    customDataFields = [],
    description = {},
    name = {},
    onChange,
    onRemove,
    productCategoryOptions,
    productType = {},
    readOnly,
    setAddProductLevelDataElement,
    setState,
    tag = {},
  } = props;
  const categoryOptions = productCategoryOptions();

  return (
    <>
      {/* Product Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={name.error}
          id="name"
          label="Product Name"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={name.value}
        />
      </Grid>

      {/* Product Code */}
      <Grid item xs={6}>
        <SimpleTextField
          error={tag.error}
          id="tag"
          label="Product Code"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={tag.value}
        />
      </Grid>

      {/* Product Category */}
      <Grid item xs={6}>
        <SimpleSelect
          error={productType.error}
          helperText={productType.error ? "Required Field" : ""}
          label="Product Category"
          id="productType"
          onChange={(event) => {
            event.persist();
            onChange({
              target: { id: "productType", value: event.target.value },
            });
          }}
          readOnly={readOnly}
          value={categoryOptions.length > 0 ? productType.value : ""}
          options={categoryOptions}
          variant="outlined"
        />
      </Grid>

      {/* Product Description */}
      <Grid item xs={12}>
        <SimpleTextField
          id="description"
          label={
            readOnly ? `Product Description` : `Product Description - optional`
          }
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={description.value}
        />
      </Grid>

      {/* Custom Data Fields are handled here */}
      {customDataFields.length > 0 || addProductLevelDataElement ? (
        <CustomDataFields
          addField={addProductLevelDataElement}
          customDataFields={customDataFields}
          onRemove={(element) => {
            onRemove(element);
          }}
          readOnly={readOnly}
          setAddProductLevelDataElement={setAddProductLevelDataElement}
          updateCustomFields={(customDataFields) =>
            setState((prevState) => ({ ...prevState, customDataFields }))
          }
        />
      ) : null}
    </>
  );
}
