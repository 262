import { useState, useEffect, useRef, useCallback } from "react";
import request from "superagent";
import { useLocation } from "react-router-dom";
import { cloneDeep, isEqual } from "lodash";

import ActionButtons from "../../components/Buttons/ActionButtons";
import CenteredModal from "../../components/Modals/CenteredModal";
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import EditUser from "./EditUser";
import ImportUsers from "./ImportUsers";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";

import UncontrolledTable from "../../components/Tables/UncontrolledTable/UncontrolledTable";

import { useDispatch, useSelector } from "react-redux";
import { thunks } from "../../globalStore/slices/organization/organizationSlice";

import "./styles.css";
export default function Users(props) {
  const usersMap = cloneDeep(
    useSelector((state) => state.organization.usersMap, isEqual)
  );
  const { childOrganizations, organization, consoleRoles, mobileRoles } =
    useSelector((state) => state.organization, isEqual);

  const [state, setState] = useState({
    organization: organization,
    groups:
      Object.keys(childOrganizations).map((k) => childOrganizations[k]) || [],

    checked: {},
    confirmationModalShow: false,
    modal: "",
    modalShow: false,
    modalTitle: "",
    confirmationText: "",

    isCreate: false,
    isProcessing: false,
    selectedUsers: {},
  });

  const handleState = (updatedState) => {
    setState((s) => {
      return {
        ...s,
        ...updatedState,
      };
    });
  };

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveAppUsers: retrieveAppUsersThunk } = thunks.appUsers;

  // onSuccess function retrieves child organizations, patch/diff with redux store, and resets location state
  const retrieveUsers = () => {
    dispatchGlobal(retrieveAppUsersThunk());
    location.state = {};
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveAppUsersThunk());
      }
    },
    [dispatchGlobal, retrieveAppUsersThunk]
  );

  const mounted = useRef(false);

  useEffect(() => {
    const { state: routerState = {} } = props.location || {};
    const {
      modal = "",
      modalShow = false,
      modalTitle = "",
      isCreate = false,
    } = routerState;

    setState((s) => {
      return {
        ...s,
        isCreate,
      };
    });

    if (modalShow) {
      setState((s) => {
        return {
          ...s,
          modal,
          modalTitle,
          modalShow,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This cleans up the quickLinks and modal behavior
    return props.history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [props, init]);

  const handleUserDelete = (appUserId) => {
    request
      .delete(`${props.apiUrl}appUsers/${appUserId}`)
      .set("auth-token", props.token)
      .then((res) => {
        if (res.status === 200) {
          retrieveUsers();
          handleConfirmationModal("User Deleted");
          handleState({ modalShow: false, selectedUser: {} });
        }
      });
  };

  const handleConfirmationModal = (
    confirmationText,
    color,
    icon,
    delay = 900
  ) => {
    handleState({
      confirmationModalShow: true,
      confirmationText,
      notificationModalColor: color,
      notificationModalIcon: icon,
    });
    setTimeout(function () {
      handleState({ confirmationModalShow: false, deleteErorr: false });
    }, delay);
  };

  const { apiUrl, token } = props;
  const {
    confirmationModalShow,
    confirmationText,
    groups = [],
    isCreate,
    modal,
    modalTitle,
    notificationModalColor,
    readOnly,
    selectedUser,
  } = state;

  const { propertiesMap = {} } = organization;

  const modalClose = () =>
    handleState({
      confirmationModalShow: false,
      modalShow: false,
      selectedUser: {},
      readOnly: false,
    });

  const tableColumns = [
    {
      Header: "First Name",
      accessor: "firstName",
      id: "firstName",
      Cell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedUser: row.original,
                modalShow: true,
                readOnly: true,
                modalTitle: "User Information",
                modal: "Edit or Create User",
              });
            }}
          >
            {row.original.firstName}
          </div>
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      id: "lastName",
      Cell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedUser: row.original,
                modalShow: true,
                readOnly: true,
                modalTitle: "User Information",
                modal: "Edit or Create User",
              });
            }}
          >
            {row.original.lastName}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      id: "email",
      Cell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedUser: row.original,
                modalShow: true,
                readOnly: true,
                modalTitle: "User Information",
                modal: "Edit or Create User",
              });
            }}
          >
            {row.original.email}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "export",
      id: "export",
      disableSortBy: true,
      Cell: (data) => {
        const row = data.row;
        const buttonIcons = [];
        if (props.userRoles["Edit Users"]) {
          buttonIcons.push({
            action: () => {
              handleState({
                isCreate: false,
                modal: "Edit or Create User",
                modalShow: true,
                modalTitle: "Edit User",
                selectedUser: row.original,
              });
            },
            icon: "fas fa-edit",
            title: "Edit",
          });
        }
        if (props.userRoles["Delete Users"]) {
          buttonIcons.push({
            icon: "fas fa-trash-alt",
            title: "Delete",
            action: () => {
              handleState({
                modal: "Delete User",
                modalShow: true,
                modalTitle: "Delete User",
                selectedUser: row.original,
              });
            },
          });
        }

        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  // This controls which modal the <CenterModal /> displays
  const switchModal = (modal) => {
    switch (modal) {
      case "Edit or Create User":
        return (
          <EditUser
            consoleRoles={consoleRoles}
            mobileRoles={mobileRoles}
            apiUrl={apiUrl}
            groups={groups}
            isCreate={isCreate}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveUsers}
            organization={organization}
            readOnly={readOnly}
            roles={propertiesMap.roles}
            selectedUser={selectedUser}
            token={token}
          />
        );
      case "Delete User":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedUser.firstName} ${selectedUser.lastName}? This action cannot be
              undone.`}
            handleSubmit={() => {
              handleUserDelete(selectedUser.appUserId);
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Import Users":
        return (
          <ImportUsers
            apiUrl={apiUrl}
            token={token}
            groups={groups}
            consoleRoles={consoleRoles}
            mobileRoles={mobileRoles}
            organization={organization}
            roles={propertiesMap.roles}
            notificationModal={handleConfirmationModal}
            onSuccess={retrieveUsers}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="container-fluid mt-3">
      <CenteredModal
        content={switchModal(modal)}
        label={modalTitle}
        onHide={modalClose}
        show={state.modalShow}
      />
      <MaterialConfirmationModal
        content={confirmationText}
        closeModal={modalClose}
        modalOpen={confirmationModalShow}
        severity={notificationModalColor ? "error" : "success"}
        variant="filled"
      />

      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>Users</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          {props.userRoles["Create Users"] ? (
            <>
              <button
                onClick={() =>
                  handleState({
                    isCreate: true,
                    modal: "Edit or Create User",
                    modalShow: true,
                    modalTitle: "Add User",
                  })
                }
                type="button"
                className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
              >
                Add User
              </button>
              <button
                onClick={() =>
                  handleState({
                    // isCreate: true,
                    modal: "Import Users",
                    modalShow: true,
                    modalTitle: "Import Users",
                  })
                }
                type="button"
                className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
              >
                Import Users
              </button>
            </>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <UncontrolledTable
            stickyHeader
            totalCountLabel={"Users"}
            data={Object.keys(usersMap).map((k) => usersMap[k]) || []}
            columns={tableColumns}
            defaultSort={[{ id: "firstName" }]}
          />
        </div>
      </div>
    </div>
  );
}
