import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { isEmpty } from "lodash";
import { Tooltip } from "@material-ui/core";

export default function Classifications(props: any) {
  const { state, dispatchState } = props;

  const [view, setView] = useState<any>({
    isAddClass: false,
    isAddChildren: false,
  });

  const [addClass, setAddClass] = useState<any>({});
  const [addChild, setAddChild] = useState<any>();

  return (
    <>
      <div className="row">
        <div className="col col-md-6" id="classifications">
          <h4>
            <Tooltip title="Configure classifications for your assets. Classifications will be assigned upon asset creation and will appear in the Asset Status, Asset History, and Asset Snapshot tables.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Classifications{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddClass: !view.isAddClass,
                })
              }
            >
              {!view.isAddClass ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddClass ? (
              <SimpleList
                items={Object.keys(state.globalSettings?.classifications || {})
                  .filter((el) => el !== null)
                  .map((uuid) => {
                    if (state.globalSettings.classifications[uuid]) {
                      return {
                        id: uuid,
                        label: state.globalSettings.classifications[uuid].name,
                        ...state.globalSettings.classifications[uuid],
                      };
                    } else {
                      return null;
                    }
                  })
                  .filter((el) => el !== null)}
                onClick={(id: string) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "classification",
                    payload: {
                      label: state.globalSettings.classifications[id].name,
                      id: id,
                      ...state.globalSettings.classifications[id],
                    },
                  });
                }}
                onClickRemove={(id: string) => {
                  setView({
                    ...view,
                    isAddChildren: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "classifications",
                    payload: {
                      ...state.globalSettings.classifications,
                      [id]: null,
                    },
                    removeSelected: "classification",
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Create New Classification"}
                submitBtnText={"Create"}
                fields={[
                  {
                    id: "label",
                    label: "Classification Name",
                    onChange: (e: any) => {
                      setAddClass({
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  const label = addClass.label.trim();
                  if (!label) {
                    return null;
                  }
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "classifications",
                    payload: {
                      ...state.globalSettings.classifications,
                      [label]: {
                        id: label,
                        label: label,
                        name: label,
                        children: {},
                      },
                    },
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddClass: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col col-md-6" id="classificationValues">
          <h4>
            <Tooltip title="Values to assign under your classification. For example, if your classification was 'Publisher', you could assign 'Penguin Random House', 'Simon and Schuster' and 'Macmillian' under this classification, or if your classification was 'Shipping Weight', you could assign values such as 'less than 50 lbs', 'more than 50 lbs', etc.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Values
            {state.selected.classification ? (
              <IconButton
                style={{ padding: "0px" }}
                onClick={() =>
                  setView({
                    ...view,
                    isAddChildren: !view.isAddChildren,
                  })
                }
              >
                {state.selected.classification &&
                !state.selected.classification
                  .classificationId ? null : !view.isAddChildren ? (
                  <AddIcon style={{ color: "#3D5BA9" }} />
                ) : (
                  <CloseIcon style={{ color: "#b71d1a" }} />
                )}
              </IconButton>
            ) : null}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddChildren ? (
              <>
                {!state.selected.classification ? (
                  <p>Select a classification to add values.</p>
                ) : state.selected.classification &&
                  !state.selected.classification.classificationId ? (
                  <p>
                    Please save new classifications before creating
                    classification values.
                  </p>
                ) : state.selected.classification &&
                  !isEmpty(state.selected.classification.children) ? (
                  <>
                    <p>
                      <strong>{state.selected.classification.label}</strong>{" "}
                      values
                    </p>
                    <SimpleList
                      noSelectHighlight
                      onClickRemove={(id: string) => {
                        const children = state.selected.classification.children;

                        dispatchState({
                          type: "SET_SELECTED",
                          label: "classification",
                          payload: {
                            ...state.selected.classification,
                            children: {
                              ...children,
                              [id]: null,
                            },
                          },
                        });

                        dispatchState({
                          type: "CHANGE_GLOBAL",
                          setting: "classifications",
                          payload: {
                            ...state.globalSettings.classifications,
                            [state.selected.classification.id]: {
                              ...state.selected.classification,
                              children: {
                                ...children,
                                [id]: null,
                              },
                            },
                          },
                        });
                      }}
                      items={
                        state.selected.classification &&
                        state.selected.classification.children
                          ? Object.keys(state.selected.classification.children)
                              .filter(
                                (childId) =>
                                  state.selected.classification.children[
                                    childId
                                  ] !== null
                              )
                              .map((childId) => {
                                return {
                                  id: childId,
                                  classificationId:
                                    state.selected?.classification?.children[
                                      childId
                                    ]?.classificationId || childId,
                                  label:
                                    state.selected?.classification?.children[
                                      childId
                                    ]?.label ||
                                    state.selected?.classification?.children[
                                      childId
                                    ]?.name ||
                                    "Name not found",
                                };
                              })
                          : []
                      }
                    />
                  </>
                ) : (
                  <p>
                    No values set for {state.selected?.classification?.label}
                  </p>
                )}
              </>
            ) : (
              <SimpleForm
                headerText={`Add value${
                  state.selected.classification &&
                  state.selected.classification.label
                    ? ` for ${state.selected.classification.label}`
                    : null
                }`}
                submitBtnText={"Add Value"}
                fields={[
                  {
                    id: "label",
                    label: "Value",
                    onChange: (e: any) => {
                      setAddChild({
                        id: e.target.value,
                        label: e.target.value,
                        value: e.target.value,
                        parentId:
                          state.selected.classification?.classificationId ||
                          null,
                      });
                    },
                  },
                ]}
                onSubmit={(e: any) => {
                  const updatedChildren = {
                    ...state.selected.classification,
                    children: {
                      ...state.selected.classification.children,
                      [addChild.id]: {
                        id: addChild.id,
                        label: addChild.label,
                        name: addChild.label,
                        parentId: addChild.parentId,
                      },
                    },
                  };

                  dispatchState({
                    type: "SET_SELECTED",
                    label: "classification",
                    payload: updatedChildren,
                  });

                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "classifications",
                    payload: {
                      ...state.globalSettings.classifications,
                      [state.selected.classification.id]: updatedChildren,
                    },
                  });

                  setView({
                    ...view,
                    isAddChildren: false,
                  });

                  setAddChild("");
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddChildren: false,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
