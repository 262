import XLSX from "xlsx";
import moment from "moment-timezone";

import _ from "lodash";

interface ExportFunc {
  (devices: any, deviceAssetsMap: any, timeZone: any): Promise<boolean | null>;
}
export const exportSpreadsheet: ExportFunc = async (
  devices,
  deviceAssetsMap,
  timeZone
) => {
  const json = devices.map(async (deviceData: any) => {
    let { propertiesMap = {}, assetId = "" } = deviceData;
    const assignedAssetsData = deviceAssetsMap[assetId] || [];

    let assignedAssetTags: any = assignedAssetsData.length
      ? assignedAssetsData
          .map((d: any) => d.tag || null)
          .filter((el: any) => el !== null)
      : [];

    if (!_.isEmpty(propertiesMap)) {
      propertiesMap = Object.keys(propertiesMap)
        .map((k) => {
          if (k === "battery") {
            return { label: "Battery Life", value: `${propertiesMap[k]}%` };
          }
          if (k === "altitude") {
            return { label: "Altitude (f)", value: propertiesMap[k] };
          }

          if (k === "speed") {
            return { label: "Speed (m/h)", value: propertiesMap[k] };
          }
          if (k === "heading") {
            return { label: "Heading (°)", value: propertiesMap[k] };
          }
          return { label: k, value: propertiesMap[k] };
        })
        .filter(
          (e) =>
            e.label === "Heading (°)" ||
            e.label === "Altitude (f)" ||
            e.label === "Speed (m/h)" ||
            e.label === "Battery Life"
        )
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        })
        .reduce((x: any, y: any) => {
          return {
            ...x,
            [y.label]: y.value,
          };
        }, {});
    }
    return {
      "Device Identifier": deviceData.tag ? deviceData.tag : "",
      Availability: deviceData.device?.status ? deviceData.device.status : "",
      "Associated Item":
        assignedAssetTags && assignedAssetTags.length
          ? assignedAssetTags.join(", ")
          : "",
      Status: deviceData.lastEvent ? deviceData.lastEvent : "",
      "Device Type": deviceData.assetType ? deviceData.assetType : "",

      ...propertiesMap,
    };
  });

  const currentTime = moment()
    .tz(
      timeZone && timeZone.value
        ? timeZone.value
        : timeZone
        ? timeZone
        : moment.tz.guess()
    )
    .format("YYYY-MM-DD");

  return Promise.all(json).then((data) => {
    const fileName = `Devices_Export_${currentTime}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName);
    return true;
  });
};
