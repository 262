export const saveUserWidgetSettings = async (props, widget, settings) => {
  const { apiUrl, token, userId } = props;

  const payload = JSON.stringify({
    propertiesMap: {
      widgetSettings: {
        [widget]: settings,
      },
    },
  });

  return await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: payload,
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => console.log(err));
};
