import { Slider } from "@material-ui/core";

function valuetext(value) {
  return `${value}`;
}

export default function LimitSlider(props) {
  const {
    defaultValue = 400,
    step = 100,
    min = 100,
    max = 1000,
    onChange,
    value,
  } = props;
  return (
    <Slider
      defaultValue={defaultValue}
      getAriaValueText={valuetext}
      valueLabelDisplay="auto"
      step={step}
      marks
      min={min}
      max={max}
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
    />
  );
}
