import { useState } from "react";
import VerticalTabs from "../../../components/Tabs/VerticalTabs";
import * as Tab from "./Tabs/index";

import WarningDialog from "../ConfirmUnsavedChanges/WarningDialog";

function GlobalSettings(props) {
  const {
    userRoles = {},
    handleConfirmationModal,
    handleSaveSettings,
    state,
    dispatchState,
  } = props;

  const tabArr = [
    "Mobile Roles",
    "Assets",
    "Classifications",
    "Products",
    "Batches",
    "Facilities",
    "Zones",
    "Event/Status",
  ]
    .filter((tab) => {
      if (
        (tab === "Assets" && !userRoles["View Asset Settings"]) ||
        (tab === "Classifications" && !userRoles["View Asset Settings"]) ||
        (tab === "Products" && !userRoles["View Products"]) ||
        (tab === "Batches" && !userRoles["View Batches"])
      ) {
        return null;
      }
      return tab;
    })
    .filter((tab) => tab !== null);

  const tabIdx = parseInt(props.match?.params?.tab || 0);

  const [showConfirm, setShowConfirm] = useState({
    show: false,
    idx: tabIdx,
  });
  const [value, setValue] = useState(tabIdx);

  const handleChange = (event, idx) => {
    if (showConfirm.idx !== idx && state.globalSettings.settingsUpdated) {
      props.tabRef.current = { label: tabArr[showConfirm.idx] };
      setShowConfirm({
        show: true,
        idx: idx,
      });
    } else {
      props.tabRef.current = { label: tabArr[idx] };
      setShowConfirm({
        show: false,
        idx: idx,
      });
      setValue(idx);
    }
  };

  const handleDiscard = () => {
    const idx = showConfirm.idx;
    setShowConfirm({ show: false, idx: idx });
    setValue(idx);
    dispatchState({
      type: "RESET_GLOBAL",
      payload: props.globalSettingsRef.current,
    });
  };

  return (
    <>
      <VerticalTabs
        panelStyle={{ width: "62vw" }}
        containerStyle={{ width: "80vw", height: "65vh" }}
        handleChange={handleChange}
        value={value}
      >
        <Tab.Mobile
          handleConfirmationModal={handleConfirmationModal}
          label="Mobile Roles"
          {...props}
        />

        {userRoles["View Asset Settings"] ? (
          <Tab.Assets label="Assets" {...props} />
        ) : null}

        {userRoles["View Asset Settings"] ? (
          <Tab.Classifications label="Classifications" {...props} />
        ) : null}

        {userRoles["View Products"] ? (
          <Tab.Products label="Products" {...props} />
        ) : null}

        {userRoles["View Batches"] ? (
          <Tab.Batches label="Batches" {...props} />
        ) : null}

        <Tab.Locations label="Facilities" {...props} />
        <Tab.Zones label="Zones" {...props} />
        <Tab.Events
          label="Event/Status"
          handleConfirmationModal={handleConfirmationModal}
          {...props}
        />
        <Tab.Forms 
          {...props}
          label="Forms"
        />
      </VerticalTabs>

      <WarningDialog
        open={showConfirm.show}
        title={`You have unsaved changes in ${props.tabRef?.current?.label}.`}
        subHeader="Would you like to save or discard your changes?"
        closeModal={() => setShowConfirm({ show: false })}
        onSave={() => {
          const idx = showConfirm.idx;
          handleSaveSettings(false, true, idx);
          setShowConfirm({ show: false, idx: idx, label: "" });
        }}
        onDiscard={handleDiscard}
      />
    </>
  );
}

export default GlobalSettings;
