import React, { useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { parseUserInfo } from "../utils";
import { searchBins } from "../api";
import AsyncReactSelectCheckboxes from "../../../components/Selects/AsyncReactSelectCheckbox";
import chroma from "chroma-js";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import Loading from "../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";
import states from "./states.json";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  filterButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    marginLeft: "1rem !important",
  },
  filterOption: {
    color: theme.palette.text.secondary,
    marginTop: "1vh",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props: any) {
  const {
    displayColumnOptions,
    eventTypes,
    eventTypesMap,
    facilityArray,
    setState,
    state,
    usersMap,
  } = props;
  const { availableZones } = state;
  const {
    binLocation: displayBinLocations = {},
    event = {},
    facility = {},
    state: displayState = {},
    user = {},
    zone = {},
  } = displayColumnOptions;
  const [filterState, setFilterState] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    binLocationOptionsPageRef.current = 0;
    setOpen(false);
  };

  // ref for incrementing start location in solr query for bin location options
  // having to do this because we are not able to use the length of the options returned to determine start location, as we are constructing distinct sets of options from indistinct results.
  const binLocationOptionsPageRef = React.useRef(0);

  const asyncBinLocationLoadOptions = async (
    inputValue = "",
    loadedOptions: any
  ) => {
    const { apiUrl, token, organizationId, setConfirm } = props;
    const limit = 300;

    const results = await searchBins(
      { apiUrl, token, organizationId },
      inputValue,
      limit,
      binLocationOptionsPageRef.current
    ).then((results: any) => {
      if (results.error) {
        return setConfirm({
          modalShow: true,
          text: results.error,
          error: true,
        });
      }

      // increment page ref...
      binLocationOptionsPageRef.current += limit;

      const options =
        results.assetHistories && results.assetHistories.length
          ? [
              ...new Set(
                results.assetHistories
                  .filter((history: any) => history.binLocation)
                  .map((history: any) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
          : [];

      // create distinct union of options and loaded options, using lodash _.unionWith to use deep comparison between objects in arrays.
      const union =
        loadedOptions.length > 0
          ? _.unionWith(options, loadedOptions, _.isEqual)
          : options;

      return {
        options: union,
        // I am incrementing a ref by a constant limit outside the scope of the function, which is used to determine the page start location in the data. When that number, the "start" field, is greater than the results.count, we return a false hasMore and stop searching.
        hasMore: binLocationOptionsPageRef.current < results.count,
      };
    });
    return results;
  };

  // We set the initial filters using useEffect for the instance that a user does not
  // navigate away from the page, but uses the "Tag Look up" bar to go to another snapshot page
  useEffect(() => {
    setFilterState({ ...state.filters });
  }, [state.filters]);

  return (
    <>
      <span onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <div className="exportIcon">
          {`Filters`}
          <i className="fas fa-filter ml-2"></i>
        </div>
      </span>
      <Dialog
        aria-labelledby="filters"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Event */}
            {event.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Event</label>
                <ReactSelectCheckboxes
                  styles={{
                    menuPortal: (styles: any) => ({
                      ...styles,
                      zIndex: 99999,
                    }),
                    option: (
                      styles: any,
                      { data, isDisabled, isFocused, isSelected }: any
                    ) => {
                      const color = data.color ? chroma(data.color) : "";
                      if (!color) {
                        return {
                          ...styles,
                        };
                      }
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                          ? data.color
                          : isFocused
                          ? color.alpha(0.1).css()
                          : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                          ? chroma.contrast(color, "black") < 7
                            ? "white"
                            : "black"
                          : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled &&
                            (isSelected ? data.color : color.alpha(0.3).css()),
                        },
                      };
                    },
                    multiValue: (styles: any, { data }: any) => {
                      const color = data.color ? chroma(data.color) : "";
                      if (!color) {
                        return {
                          ...styles,
                        };
                      }
                      return {
                        ...styles,
                        backgroundColor: color.alpha(0.1).css(),
                      };
                    },
                    multiValueRemove: (styles: any, { data }: any) => ({
                      ...styles,
                      color: "#b71d1a",
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#b71d1a",
                        color: "white",
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      events: e,
                    }));
                  }}
                  options={
                    eventTypes && eventTypes.length
                      ? eventTypes.map((event: any, idx: number) => {
                          return {
                            color: eventTypesMap[event]?.color || "black",
                            label: event,
                            value: event,
                          };
                        })
                      : []
                  }
                  value={filterState.events}
                />
              </Grid>
            ) : null}

            {/* Facility Name */}
            {facility.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Facility Name</label>
                <ReactSelectCheckboxes
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      locale: null,
                      locations: e,
                    }));
                  }}
                  options={
                    facilityArray && facilityArray.length
                      ? [
                          ...facilityArray.map((facility: any) => {
                            return {
                              label: facility.name,
                              value: facility,
                            };
                          }),
                        ]
                      : []
                  }
                  value={filterState.locations}
                />
              </Grid>
            ) : null}

            {/* State */}
            {displayState.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>State</label>
                <ReactSelectCheckboxes
                  options={states ? states : []}
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      locals: e,
                    }));
                  }}
                  value={filterState.locals}
                />
              </Grid>
            ) : null}

            {/* Users */}
            {user.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>User</label>
                <ReactSelectCheckboxes
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      users: e,
                    }));
                  }}
                  options={
                    usersMap && Object.keys(usersMap).length
                      ? [
                          ...Object.keys(usersMap)
                            .map((key) => {
                              return {
                                label: parseUserInfo(usersMap[key]),
                                value: key,
                              };
                            })
                            .filter((user) => user.label !== ""),
                          {
                            label: "User, Android",
                            value: "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7",
                          },
                          {
                            label: "User, iOS",
                            value: "53ef1d1f-3e47-46e7-b444-18170051486f",
                          },
                        ].sort((a: any, b: any) =>
                          a.label.localeCompare(b.label)
                        )
                      : []
                  }
                  value={filterState.users}
                />
              </Grid>
            ) : null}

            {/* Bin Locations */}
            {displayBinLocations.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Bin Location</label>
                <AsyncReactSelectCheckboxes
                  loadOptions={asyncBinLocationLoadOptions}
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      binLocation: e || null,
                    }));
                  }}
                  value={filterState.binLocation || null}
                />
              </Grid>
            ) : null}

            {/* Zones */}
            {zone.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Zones</label>
                <ReactSelectCheckboxes
                  options={availableZones ? availableZones : []}
                  onChange={(e: any) => {
                    setFilterState((prevState: any) => ({
                      ...prevState,
                      zones: e,
                    }));
                  }}
                  value={filterState.zones}
                />
              </Grid>
            ) : null}

            {/* Start Date */}
            <Grid item xs={6}>
              <label className={classes.filterOption}>Select Start Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="Start Date"
                onChange={(date: any) => {
                  if (filterState.endDate && date > filterState.endDate) {
                    alert("Can't set start date later than end date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    startDate: date,
                  }));
                }}
                size="small"
                value={filterState.startDate}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={6}>
              {" "}
              <label className={classes.filterOption}>Select End Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="End Date"
                onChange={(date: any) => {
                  if (filterState.startDate && date < filterState.startDate) {
                    alert("Can't set end date earlier than start date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    endDate: date,
                  }));
                }}
                size="small"
                value={filterState.endDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}

          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilterState({
                assetIds: null,
                endDate: null,
                events: null,
                locals: null,
                locations: null,
                startDate: null,
                users: null,
                zones: null,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.filterButton} ${classes.button}`}
            onClick={() => {
              setLoading(true);
              setState({
                ...state,
                selectedAssets: {},
                filters: {
                  ...filterState,
                  start: "0",
                },
                showInstructions: false,
              });
              setLoading(false);
              handleClose();
            }}
            variant="contained"
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
