import { useState, useEffect } from "react";
import _ from "lodash";
import BatchInformationTab from "./BatchInformationTab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ProductInformationTab from "./ProductInformationTab";
import request from "superagent";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    fontWeight: "bold",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </div>
  );
}

export default function ViewBatchInformation(props) {
  const {
    apiUrl,
    handleClose,
    isOpen = false,
    selectedBatch = {},
    token,
    viewProductInformation,
  } = props;
  const { batchId = "" } = selectedBatch;
  const classes = useStyles();
  const [state, setState] = useState({});
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (batchId) {
      request
        .get(`${apiUrl}batches/${batchId}`)
        .set("auth-token", token)
        .then((response) => {
          let batch = { ...response.body.batch };
          let {
            destinationLocation = {},
            originLocation = {},
            propertiesMap = {},
            topologiesList = [],
            topologyMap = {},
          } = batch;

          // function below breaks up batch children and throws them into the brokenUpBatchChildren array
          let brokenUpBatchChildren = [];
          let totalItemCount = 1;

          const breakUpBatchChildren = (top) => {
            if (Array.isArray(top) && top.length) {
              top.map((element) => {
                if (element.assetType && element.count) {
                  brokenUpBatchChildren.push({
                    assetType: element.assetType,
                    count: element.count,
                    productId: element.productId ? element.productId : "",
                    totalItemCount: totalItemCount * element.count,
                  });
                  totalItemCount *= element.count;
                }
                if (element.child) {
                  breakUpBatchChildren(element.child);
                }
                return null;
              });
            } else {
              if (top.assetType && top.count) {
                brokenUpBatchChildren.push({
                  assetType: top.assetType,
                  count: top.count,
                  productId: top.productId ? top.productId : "",
                  totalItemCount: totalItemCount * top.count,
                });
                totalItemCount = totalItemCount * top.count;
              }
              if (top.child) {
                breakUpBatchChildren(top.child);
              }
              return null;
            }
          };
          if (
            (!topologiesList || topologiesList.length === 0) &&
            !_.isEmpty(topologyMap)
          ) {
            breakUpBatchChildren(topologyMap);
          } else {
            breakUpBatchChildren(topologiesList);
          }

          setState({
            ...batch,
            ...propertiesMap,
            ...topologiesList[0],
            batchOrigin: {
              value: originLocation.facilityId
                ? originLocation.facilityId
                : originLocation.facility,
              location: { ...originLocation },
            },
            children: brokenUpBatchChildren,
            finalDestination: {
              value: destinationLocation.facilityId,
              location: { ...destinationLocation },
            },
            topologiesList,
            mounted: true,
          });
        })
        .catch((err) => {
          console.log("error: " + err.message + " " + err.response);
        });
      setValue(viewProductInformation ? 1 : 0);
    }
  }, [apiUrl, batchId, viewProductInformation, token]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      aria-labelledby="Batch Information"
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      open={isOpen}
    >
      {state.mounted ? (
        <DialogContent>
          <Tabs
            aria-label="simple tabs example"
            indicatorColor="primary"
            onChange={handleChange}
            value={value}
          >
            <Tab
              label={
                <Typography className={classes.tabHeader} variant="subtitle1">
                  Batch Information
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography className={classes.tabHeader} variant="subtitle1">
                  Product Information
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <BatchInformationTab {...props} batchInfo={{ ...state }} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProductInformationTab {...props} children={state.children} />
          </TabPanel>
        </DialogContent>
      ) : null}

      <DialogActions>
        <Button color="primary" onClick={handleClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
