import React from "react";
import moment from "moment-timezone";
import _ from "lodash";

import { Box, Grid, makeStyles } from "@material-ui/core";
import {
  utc_to_local_12_hour_clock,
  utc_to_local_24_hour_clock,
} from "../../utils/date_time_utils";
import { formatLatLon } from "../../utils/lat_lon_utils";
import ControlledTable from "../../components/Tables/ControlledTable/ControlledTable";
import SimpleTable from "../../components/Tables/SimpleTable/SimpleTable";
import DisplayColumnsDropdown from "../../components/Tables/ControlledTable/components/DisplayColumnsDropdown/DisplayColumnsDropdown";
import FilterMenu from "./FilterMenu/FilterMenu";
const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

export function Table(props: any) {
  const {
    apiUrl,
    assetsArray,
    facilities,
    handleExport,
    handleSave,
    itemLevelDataElements,
    match,
    organizationId,
    page,
    searchHistories,
    setInfoModal,
    setLoading,
    setModal,
    setState,
    state,
    token,
    zones,
    timeZone,
  } = props;

  const { batchId } = match.params;

  const historyTableColumns = [
    {
      Header: "Date/Time",
      accessor: (row: any) =>
        row.timeOfLogLong ||
        utc_to_local_24_hour_clock(row.timeOfLog, timeZone) ||
        "DNP",
      id: "timeOfLog",
      Cell: (data: any) => {
        const { timeOfLog, propertiesMap } = data.row.original;

        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {utc_to_local_12_hour_clock(timeOfLog, timeZone) || "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Unit of Measure",
      accessor: "assetType",
      id: "assetType",
      Cell: (data: any) => {
        const { assetType, propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {assetType || ""}
          </div>
        );
      },
    },
    {
      Header: "Item Tag",
      accessor: (row: any) => row.assetTag || "DNP",
      id: "assetTag",
      Cell: (data: any) => {
        const { assetTag, propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {assetTag || "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Event",
      accessor: (row: any) => row.event || "DNP",
      id: "event",
      Cell: (data: any) => {
        const { event, propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {event || "DNP"}
          </div>
        );
      },
    },
    {
      Header: "User",
      accessor: (row: any) => {
        return parseUserInfo(row);
      },
      id: "user",
      // disableSortBy: true,
      Cell: (data: any) => {
        const row = data.row.original;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {userInfo}
          </div>
        );
      },
    },
    {
      Header: "Facility Name",
      id: "facility",
      accessor: (row: any) => {
        const { facilityId, latitude, longitude } = row;
        return !latitude || !longitude
          ? "DNP"
          : facilityId && facilities[facilityId] && facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
      disableSortBy: true,
      Cell: (data: any) => {
        const { facilityId, propertiesMap, latitude, longitude } =
          data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {!latitude || !longitude
              ? "DNP"
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
    },
    {
      Header: "Zone",
      id: "zone",
      disableSortBy: true,
      accessor: (row: any) => zones[row.zone?.zoneId]?.name || "",
      Cell: (data: any) => {
        const { zone = {} } = data.row.original;
        const { zoneId = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {zoneId && zones[zoneId] ? zones[zoneId].name : ""}
          </div>
        );
      },
    },
    {
      Header: "Bin Location",
      id: "binLocation",
      accessor: (row: any) => row.binLocation || "",
      Cell: (data: any) => {
        const { binLocation = "" } = data.row.original;

        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {binLocation ? binLocation : ""}
          </div>
        );
      },
    },
    {
      Header: "City, State",
      accessor: (row: any) =>
        `${row.city}${row.state !== undefined ? `, ${row.state}` : ""}`,
      id: "cityState",
      Cell: (data: any) => {
        const {
          city = "DNP",
          state = "DNP",
          propertiesMap,
        } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {`${city}${state !== undefined ? `, ${state}` : ""}`}
          </div>
        );
      },
    },
    {
      Header: "Latitude, Longitude",
      accessor: (row: any) => {
        const { latitude, longitude } = row;
        return `${latitude || "DNP"}, ${longitude || "DNP"}`;
      },
      id: "latLong",
      Cell: (data: any) => {
        const { latitude, longitude, propertiesMap } = data.row.original;

        const formattedLatLong =
          latitude && longitude ? formatLatLon(latitude, longitude) : "DNP";

        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap?.note ||
              propertiesMap?.formData ||
              itemLevelDataElements
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {formattedLatLong}
          </div>
        );
      },
    },
  ];

  const classes = useStyles();

  const onClick = (rowInfo: any) => {
    const { event, propertiesMap = {}, timeOfLog, assetId } = rowInfo;
    const asset = assetsArray.filter((ass: any) => {
      return ass.assetId === assetId;
    })[0];
    let dataElements: any = [];
    if (asset.propertiesMap) {
      Object.keys(asset.propertiesMap).forEach((key) => {
        if (itemLevelDataElements[key]) {
          dataElements.push({
            key: key,
            value: asset.propertiesMap[key],
            dataType: itemLevelDataElements[key].dataType,
          });
        }
      });
    }

    if (
      propertiesMap.note ||
      propertiesMap.formData ||
      dataElements.length > 0
    ) {
      const content = (
        <div className="float-left w-100">
          <div className="my-2">
            {propertiesMap.note ? (
              <>
                <span className="font-weight-bold">Notes: </span>
                <p style={{ whiteSpace: "pre-wrap" }}>{propertiesMap.note}</p>
              </>
            ) : null}
            {propertiesMap.formData && propertiesMap.formData.length
              ? propertiesMap.formData
                  .filter((f: any) => f.fieldKey !== "note")
                  .map((f: any, idx: number) => (
                    <React.Fragment key={`${f.fieldKey || ""} - ${idx}`}>
                      <span className="mr-2 font-weight-bold">
                        {_.startCase(f.fieldKey ? f.fieldKey : "NO KEY")}{" "}
                      </span>
                      <p>{f.fieldValue ? f.fieldValue : "NO VALUE"}</p>
                    </React.Fragment>
                  ))
              : null}
            {dataElements && dataElements.length > 0
              ? dataElements.map((dl: any, idx: number) => (
                  <React.Fragment key={`${dl.key || ""} - ${idx}`}>
                    <span className="mr-2 font-weight-bold">
                      {_.startCase(dl.key ? dl.key : "NO KEY")}{" "}
                    </span>
                    {dl.value && dl.dataType === "Date" ? (
                      <p>
                        {moment
                          .utc(xlSerialToJsDate(dl.value))
                          .format("MM/DD/YYYY") || dl.value}
                      </p>
                    ) : (
                      <p>{dl.value ? dl.value : "NO VALUE"}</p>
                    )}
                  </React.Fragment>
                ))
              : null}
          </div>
        </div>
      );

      setInfoModal({
        modalShow: true,
        title: `${event || ``} ${utc_to_local_12_hour_clock(
          timeOfLog,
          timeZone?.value || null
        )}`,
        content: content ? content : "",
      });
    }
  };

  const parseUserInfo = (row: any, firstLast: boolean = false) => {
    if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
      return `${row.firstName || ""}${
        row.firstName && row.lastName ? " " : ""
      }${row.lastName || ""}`;
    }
    if (row.appUserId && (row.firstName || row.lastName)) {
      return `${row.lastName || ""}${
        row.firstName && row.lastName ? ", " : ""
      }${row.firstName || ""}`;
    } else {
      return ``;
    }
  };

  function xlSerialToJsDate(xlSerial: any) {
    return new Date(
      -2209075200000 + (xlSerial - (xlSerial < 61 ? 0 : 1)) * 86400000
    );
  }

  const [displayColumnOptions, setDisplayColumnOptions] = React.useState<any>(
    state.filters?.displayColumnOptions ||
      historyTableColumns
        .map((col: any) => {
          return {
            id: col.id,
            header: col.Header,
            checked: true,
          };
        })
        .reduce((x, y) => {
          return { ...x, [y.id]: y };
        }, {})
  );

  return (
    <ControlledTable
      data={state.histories?.assetHistories || []}
      columns={historyTableColumns.filter((col: any) =>
        displayColumnOptions && displayColumnOptions[col.id]
          ? displayColumnOptions[col.id].checked
          : true
      )}
      controlledPageCount={Math.ceil(
        parseInt(state.histories?.count) / parseInt(state.filters?.limit)
      )}
      controlledCount={state.histories?.count || 0}
      controlledPageIndex={page}
      controlledPageSize={state.filters.limit}
      setState={setState}
      state={state}
      stickyHeader
      toolbarChildren={
        <Grid
          className={classes.barChildrenContainer}
          container
          alignItems={"center"}
        >
          {/* Open Menu Button */}
          <DisplayColumnsDropdown
            handleSave={(columnOptions: any) => {
              handleSave({
                displayColumnOptions: columnOptions,
              });
            }}
            options={displayColumnOptions}
            setOptions={setDisplayColumnOptions}
          />
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            {state && state.lists && state.filters ? (
              <FilterMenu
                apiUrl={apiUrl}
                displayColumnOptions={displayColumnOptions}
                organizationId={organizationId}
                setState={setState}
                state={state}
                token={token}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            <Box
              className="exportIcon"
              style={{
                textAlign: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
              onClick={() => {
                setLoading({ loading: true });
                // warn user if filters return >= 25000 asset histories
                if (state.histories.count >= 25000) {
                  if (
                    !window.confirm(
                      `Can not export information for more than 25,000 events at once. This export will contain information for the first 25,000 out of ${state.histories.count} events. Do you still wish to continue?`
                    )
                  ) {
                    setLoading({ loading: false });
                    return;
                  }
                }

                // set isExport bool to true to ignore limit && start location on table
                searchHistories(
                  { apiUrl, organizationId, token },
                  batchId,
                  state?.filters,
                  true
                ).then((res: any) => {
                  if (res.error) {
                    setModal({
                      modalShow: true,
                      text: `Uh-oh! Something went wrong while fetching item data... ${res.error}`,
                      isError: true,
                    });
                    setLoading({ loading: false });
                  } else {
                    handleExport(
                      state,
                      setModal,
                      res.assetHistories,
                      itemLevelDataElements,
                      state.lists.assetsArray,
                      zones
                    );
                    setLoading({ loading: false });
                  }
                });
              }}
            >
              Export
              <i className="fas fa-file-export ml-2"></i>
            </Box>
          </Grid>
        </Grid>
      }
      defaultColumnOrder={state.filters.defaultColumnOrder}
      onColumnDrop={(colOrder: string[]) => {
        if (!_.isEqual(colOrder, state.filters.defaultColumnOrder)) {
          handleSave({ defaultColumnOrder: colOrder });
        }
      }}
      defaultSort={state.filters?.sorted || [{ id: "timeOfLog" }]}
    />
  );
}

export function ProductInformationTable(props: any) {
  const { state } = props;

  return (
    <SimpleTable
      data={state.productData ? [...state.productData] : []}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Product Name
            </div>
          ),
          accessor: "name",
          id: "name",
          disableSortBy: true,
          Cell: (data: any) => {
            const row = data.row.original;
            const name = row.name ? row.name : "";
            return <div>{name ? name : "N/A"}</div>;
          },
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Product Code
            </div>
          ),
          disableSortBy: true,
          accessor: "tag",
          id: "tag",
          Cell: (data: any) => {
            const row = data.row.original;
            const tag = row.tag ? row.tag : "";
            return <div>{tag ? tag : "N/A"}</div>;
          },
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Product Type
            </div>
          ),
          disableSortBy: true,
          accessor: "productType",
          id: "productType",
          Cell: (data: any) => {
            const row = data.row.original;
            const type = row.productType
              ? row.productType
              : row.propertiesMap?.productType
              ? row.propertiesMap.productType
              : "";
            return <div>{type ? type : "N/A"}</div>;
          },
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Product Description
            </div>
          ),
          disableSortBy: true,

          accessor: "description",
          id: "description",
          Cell: (data: any) => {
            const row = data.row.original;
            const { description = "" } = row;
            return <div>{description ? description : "N/A"}</div>;
          },
        },
      ]}
    />
  );
}

export function BatchInformationTable(props: any) {
  const { state, facilityMap, timeZone } = props;

  return (
    <SimpleTable
      data={state.batchData ? [state.batchData] : []}
      columns={[
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Batch ID
            </div>
          ),
          accessor: "name",
          id: "name",
          Cell: (data: any) => {
            const row = data.row;
            const { name = "", tag = "" } = row.original;
            return <div>{tag ? tag : name}</div>;
          },
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Date Created
            </div>
          ),
          accessor: "timeCreated",
          id: "timeCreated",
          Cell: (data: any) => {
            const row = data.row;
            return (
              <div>
                {moment
                  .utc(row.original.timeCreated, "YYYYMMDDHHmmss")
                  .tz(timeZone?.value || moment.tz.guess())
                  .format("MM/DD/YYYY")}
              </div>
            );
          },
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: "left",
              }}
            >
              Batch Origin
            </div>
          ),
          accessor: "originLocation",
          id: "originLocation",
          Cell: (data: any) => {
            const row = data.row;
            const { originLocation = {} } = row.original;
            const { facilityId = "" } = originLocation;
            const label = facilityMap[facilityId]
              ? facilityMap[facilityId].name
              : "N/A";

            return <div>{label}</div>;
          },
        },
      ]}
    />
  );
}
