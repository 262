import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { Tooltip } from "@material-ui/core";

function Products(props) {
  const { state, dispatchState } = props;

  const [addType, setAddType] = useState({});
  const [addRequiredField, setAddRequiredField] = useState({});

  const [addDataElement, setAddDataElement] = useState({});

  const [addDataType, setAddDataType] = useState("");
  const [view, setView] = useState({
    isAddType: false,
    isEditType: false,
    isAddDataElement: false,
    isEditDataElement: false,
  });
  return (
    <>
      <div className="row">
        <div className="col md-6">
          <h4>
            <Tooltip title="A grouping mechanism for your products (e.g. “Laptops” or “Desktops”) for computer products you manufacture.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Product Categories{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddType: !view.isAddType,
                })
              }
            >
              {!view.isAddType ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddType && !view.isEditType ? (
              <SimpleList
                noSelectHighlight
                items={state.globalSettings.productTypes.map((type) => {
                  // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects
                  if (typeof type === "string") {
                    return {
                      label: type,
                      id: type,
                    };
                  }
                  return type;
                })}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "productType",
                    payload: {
                      ...state.globalSettings.productTypes
                        .filter((f) => {
                          // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects
                          if (typeof f === "string" && id === f) {
                            return true;
                          }
                          return f.id === id;
                        })
                        .map((el) => {
                          return {
                            id: el.id || el,
                            label: el.label || el.id || el,
                            requiredFields:
                              el.requiredFields ||
                              state.globalSettings.itemLevelDataElements ||
                              [],
                          };
                        })[0],
                    },
                  });
                }}
                onClickRemove={(id) => {
                  setView({
                    ...view,
                    isAddType: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "productTypes",
                    payload: state.globalSettings.productTypes.filter(
                      (type) => {
                        // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects

                        if (typeof type === "string") {
                          return type !== id;
                        }
                        return type.id !== id;
                      }
                    ),
                    removeSelected: "productType",
                  });
                }}

                // onClickEdit={() => {
                //   setView({
                //     ...view,
                //     isEditType: true,
                //   });
                // }}
                // TODO if we intend to ever set custom elements on products, or make certain fields required for products, uncomment the code above
              />
            ) : !view.isEditType && view.isAddType ? (
              <SimpleForm
                headerText={"Add new Product Category"}
                submitBtnText={"Add"}
                fields={
                  // state.globalSettings.itemLevelDataElements &&
                  // state.globalSettings.itemLevelDataElements.length
                  // TODO if we intend to ever set custom elements on products, or make certain fields required for products, uncomment the code above
                  false
                    ? [
                        {
                          id: "label",
                          label: "Name",
                          onChange: (e) => {
                            setAddType({
                              ...addType,
                              [e.target.id]: e.target.value,
                            });
                          },
                        },
                        ...state.globalSettings.itemLevelDataElements.map(
                          (f, idx) => {
                            return {
                              key: `${idx} - ${f.id}`,
                              id: f.id,
                              label: `Require ${f.label}`,
                              isCheckbox: true,
                              checked:
                                addRequiredField && addRequiredField[f.id]
                                  ? addRequiredField[f.id].required
                                  : false,
                              onChange: (e) => {
                                setAddRequiredField({
                                  ...addRequiredField,
                                  [f.id]: {
                                    required:
                                      addRequiredField[f.id] &&
                                      addRequiredField[f.id].required
                                        ? !addRequiredField[f.id].required
                                        : true,
                                    label: f.label,
                                    id: f.id,
                                    dataType: f.dataType,
                                  },
                                });
                              },
                            };
                          }
                        ),
                      ]
                    : [
                        {
                          id: "label",
                          label: "Product Category",
                          onChange: (e) => {
                            setAddType({
                              ...addType,
                              [e.target.id]: e.target.value,
                            });
                          },
                        },
                      ]
                }
                onSubmit={() => {
                  const formattedRequiredFields = state.globalSettings.itemLevelDataElements.map(
                    (f) => {
                      return {
                        id: f.id,
                        label: f.label,
                        required: addRequiredField[f.id]
                          ? addRequiredField[f.id].required
                          : false,
                        dataType: f.dataType,
                      };
                    }
                  );

                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "productTypes",
                    payload: state.globalSettings.productTypes.concat([
                      {
                        id: addType.label.trim(),
                        label: addType.label.trim(),
                        requiredFields: formattedRequiredFields,
                      },
                    ]),
                  });

                  setView({
                    ...view,
                    isAddType: false,
                  });

                  setAddRequiredField({});
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddType: false,
                  });
                }}
              />
            ) : view.isEditType && state.selected.productType ? (
              <SimpleForm
                headerText={`Edit ${
                  state.selected.productType.label ||
                  state.selected.productType.id
                }`}
                submitBtnText={"Submit"}
                fields={
                  state.selected.productType.requiredFields &&
                  state.selected.productType.requiredFields.length
                    ? [
                        ...state.selected.productType.requiredFields.map(
                          (f, idx) => {
                            return {
                              key: `${idx} - ${f.id}`,
                              id: f.id,
                              label: `Require ${f.label}`,
                              isCheckbox: true,
                              checked: state.selected.productType.requiredFields.filter(
                                (r) => r.id === f.id
                              )[0].required,

                              onChange: (e) => {
                                dispatchState({
                                  type: "SET_SELECTED",
                                  label: "productType",
                                  payload: {
                                    ...state.selected.productType,
                                    requiredFields: state.selected.productType.requiredFields.map(
                                      (f2) => {
                                        if (f2.id === f.id) {
                                          return {
                                            ...f2,
                                            required: !f2.required,
                                          };
                                        }
                                        return f2;
                                      }
                                    ),
                                  },
                                });
                              },
                            };
                          }
                        ),
                      ]
                    : []
                }
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "productTypes",
                    payload: state.globalSettings.productTypes.map((cat) => {
                      if (cat.id && cat.id === state.selected.productType.id) {
                        return {
                          ...state.selected.productType,
                        };
                      }
                      // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects
                      else if (
                        typeof cat === "string" &&
                        cat === state.selected.productType.id
                      ) {
                        return {
                          ...state.selected.productType,
                        };
                      } else {
                        return cat;
                      }
                    }),
                    removeSelected: "productType",
                  });
                  setView({
                    ...view,
                    isEditType: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditType: false,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="col md-6">
          <h4>
            <Tooltip title="Item Level Data Elements are categories of data that are unique to each item in your production. These vary with each unique production unit. In general, these are aspects like serial number, or any other piece of data that is unique to an item.  Add them here for use on a Connect Page with LocatorX’s Product Certificate Authority, where you can provide customers with additional information that they can use to identify their items when scanned in LX Productscan.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Item Level Data Elements{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => {
                setAddDataElement({});
                setView({
                  ...view,
                  isAddDataElement: !view.isAddDataElement,
                });
              }}
            >
              {!view.isAddDataElement ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddDataElement && !view.isEditDataElement ? (
              <SimpleList
                noSelectHighlight
                isColor
                items={state.globalSettings.itemLevelDataElements}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "itemLevelDataElement",
                    payload: {
                      ...state.globalSettings.itemLevelDataElements.filter(
                        (f) => f.id === id
                      )[0],
                    },
                  });
                }}
                onClickEdit={() => {
                  setView({
                    ...view,
                    isEditDataElement: true,
                  });
                }}
                onClickRemove={(id) => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "itemLevelDataElements",
                    payload: state.globalSettings.itemLevelDataElements.filter(
                      (f) => f.id !== id
                    ),
                    removeSelected: "itemLevelDataElement",
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "productTypes",
                    payload: state.globalSettings.productTypes.map((cat) => {
                      // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects
                      if (typeof cat === "string") {
                        return {
                          id: cat,
                          label: cat,
                          requiredFields: [],
                        };
                      }
                      return {
                        ...cat,
                        requiredFields: cat.requiredFields.filter(
                          (f) => f.id !== id
                        ),
                      };
                    }),
                    removeSelected: "productType",
                  });
                  setView({
                    ...view,
                    isEditType: false,
                  });
                }}
              />
            ) : view.isAddDataElement && !view.isEditDataElement ? (
              <SimpleForm
                headerText={"Add new Data Element"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Name",
                    onChange: (e) => {
                      setAddDataElement({
                        ...addDataElement,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                  {
                    id: "dataType",
                    label: "Data Type",
                    isSelect: true,
                    options: [
                      { value: "Alphanumeric", label: "Alphanumeric" },
                      { value: "Numeric", label: "Number" },
                      { value: "Date", label: "Date" },
                      { value: "Currency", label: "Currency" },
                    ],
                    value: addDataType,
                    onChange: (e) => {
                      setAddDataType(e.target.value);
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "itemLevelDataElements",
                    payload: state.globalSettings.itemLevelDataElements.concat([
                      {
                        id: addDataElement.label.trim(),
                        label: addDataElement.label.trim(),
                        dataType: addDataType,
                      },
                    ]),
                    removeSelected: "itemLevelDataElement",
                  });
                  const updatedCatFields = state.globalSettings.productTypes.map(
                    async (cat) => {
                      // TODO refactor after database backfill for turning productTypes from array of strings to array of type object{value:string, id: string, requiredFields:array of objects
                      if (typeof cat === "string") {
                        return {
                          id: cat,
                          label: cat,
                          requiredFields: [
                            {
                              id: addDataElement.label.trim(),
                              label: addDataElement.label.trim(),
                              dataType: addDataType,
                              required: false,
                            },
                          ],
                        };
                      } else {
                        return {
                          ...cat,
                          requiredFields: cat.requiredFields
                            ? cat.requiredFields.concat([
                                {
                                  id: addDataElement.label.trim(),
                                  label: addDataElement.label.trim(),
                                  dataType: addDataType,
                                  required: false,
                                },
                              ])
                            : [
                                {
                                  id: addDataElement.label.trim(),
                                  label: addDataElement.label.trim(),
                                  dataType: addDataType,
                                  required: false,
                                },
                              ],
                        };
                      }
                    }
                  );

                  Promise.all(updatedCatFields).then((completed) => {
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "productTypes",
                      payload: completed,
                      removeSelected: "productType",
                    });
                    setView({
                      ...view,
                      isEditType: false,
                      isAddDataElement: false,
                    });
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddDataElement: false,
                  });
                }}
              />
            ) : !view.isAddDataElement && view.isEditDataElement ? (
              <SimpleForm
                headerText={`Edit ${
                  state.selected?.itemLevelDataElement?.id || ""
                }`}
                submitBtnText={"Submit"}
                fields={
                  state.selected.itemLevelDataElement
                    ? [
                        {
                          id: "label",
                          label: "Name",
                          value: state.selected.itemLevelDataElement.label,

                          onChange: (e) => {
                            dispatchState({
                              type: "SET_SELECTED",
                              label: "itemLevelDataElement",
                              payload: {
                                ...state.selected.itemLevelDataElement,
                                label: e.target.value,
                              },
                            });
                          },
                        },
                        {
                          id: "dataType",
                          label: "Data Type",
                          isSelect: true,
                          options: [
                            { value: "Alphanumeric", label: "Alphanumeric" },
                            { value: "Numeric", label: "Number" },
                            { value: "Date", label: "Date" },
                            { value: "Currency", label: "Currency" },
                          ],
                          value: state.selected.itemLevelDataElement.dataType,
                          onChange: (e) => {
                            dispatchState({
                              type: "SET_SELECTED",
                              label: "itemLevelDataElement",
                              payload: {
                                ...state.selected.itemLevelDataElement,
                                dataType: e.target.value,
                              },
                            });
                          },
                        },
                      ]
                    : []
                }
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "itemLevelDataElements",
                    payload: state.globalSettings.itemLevelDataElements.map(
                      (el) => {
                        if (
                          el.id &&
                          el.id === state.selected.itemLevelDataElement.id
                        ) {
                          return {
                            ...state.selected.itemLevelDataElement,
                          };
                        } else {
                          return el;
                        }
                      }
                    ),
                    removeSelected: "itemLevelDataElement",
                  });
                  setView({
                    ...view,
                    isEditDataElement: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditDataElement: false,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
