import _ from "lodash";
import moment from "moment-timezone";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  snapshot: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: "30vh",
    maxHeight: "100%",
    overflow: "auto",
  },
}));
function Overview(props: any) {
  const classes = useStyles();

  const { assetData = {}, classifications, eventTypesMap = {} } = props;
  const { classificationSet = [] } = assetData;

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Asset Information</h4>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.snapshot} elevation={0}>
            <Grid container spacing={3}>
              {/* Asset Tag */}
              <Grid item xs={12} sm={4}>
                <strong>Asset Tag</strong> <br />
                {assetData.tag || "None provided"}
              </Grid>

              {/* Description */}
              <Grid item xs={12} sm={4}>
                <strong>Description</strong> <br />
                {assetData.propertiesMap?.description || "None provided"}
              </Grid>

              {/* Category */}
              <Grid item xs={12} sm={4}>
                <strong>Category</strong> <br />
                {assetData.propertiesMap?.category || "None provided"}
              </Grid>

              {/* Facilities */}
              <Grid item xs={12} sm={4}>
                <strong>Last Location</strong> <br />
                {assetData.latitude &&
                assetData.longitude &&
                props.facilities[assetData.facilityId]
                  ? props.facilities[assetData.facilityId].name
                  : "None provided"}
              </Grid>

              {/* Status */}
              <Grid item xs={12} sm={4}>
                <strong>Status</strong>
                <br />
                {eventTypesMap[assetData.lastEvent]?.color ? (
                  <Box
                    style={{
                      color: eventTypesMap[assetData.lastEvent].color,
                    }}
                  >
                    {assetData.lastEvent || "None provided"}
                  </Box>
                ) : (
                  `${assetData.lastEvent || "None provided"}`
                )}
              </Grid>

              {/* Date Created User */}
              {assetData.timeCreatedUser ? (
                <Grid item xs={12} sm={4}>
                  <strong>Date Created</strong> <br />
                  {moment(assetData.timeCreatedUser, "YYYYMMDDHHmmss").format(
                    "MM/DD/YYYY"
                  ) || "None provided"}
                </Grid>
              ) : null}

              {/* Date needed */}
              {assetData.timeNeeded ? (
                <Grid item xs={12} sm={4}>
                  <strong>Date Needed</strong> <br />
                  {moment(assetData.timeNeeded, "YYYYMMDDHHmmss").format(
                    "MM/DD/YYYY"
                  ) || "None provided"}
                </Grid>
              ) : null}

              {/* Bin Location */}
              {assetData.zone && assetData.zone.binLocation ? (
                <Grid item xs={12} sm={4}>
                  <strong>Bin Location</strong> <br />
                  {assetData.zone.binLocation}
                </Grid>
              ) : null}

              {/* Asset Data */}
              {assetData.propertiesMap && !_.isEmpty(assetData.propertiesMap)
                ? Object.keys(assetData.propertiesMap)
                    .filter(
                      (key) =>
                        key !== "distance" &&
                        key !== "binLocation" &&
                        key !== "deltaTimeHrs" &&
                        key !== "status" &&
                        key !== "description" &&
                        key !== "category" &&
                        key !== "formData" &&
                        key !== "formId" &&
                        key !== "kmPerHour"
                    )
                    .sort((a, b) => {
                      if (a === b) {
                        return 0;
                      } else if (a === "note") {
                        return 1;
                      } else if (b === "note") {
                        return -1;
                      } else {
                        return a.localeCompare(b);
                      }
                    })
                    .map((key: string, idx: number) => {
                      if (assetData.propertiesMap[key]) {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={key === "note" ? 12 : 4}
                            key={`${idx} - ${key}`}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            <strong>
                              {key === "note" ? "Notes" : _.startCase(key)}
                            </strong>{" "}
                            <br />
                            {assetData.propertiesMap[key]}
                          </Grid>
                        );
                      }
                      return null;
                    })
                : null}

              {/* Classifications */}
              {Object.keys(classifications.active).map(
                (parentClassification: any, index) => {
                  const { name, children } =
                    classifications.active[parentClassification];
                  const childClassification: any = Object.keys(children).find(
                    (child: any) => {
                      return classificationSet.includes(
                        children[child].classificationId
                      );
                    }
                  );
                  if (childClassification) {
                    return (
                      <Grid item key={`${name}-${index}`} sm={4} xs={12}>
                        <strong>{name}</strong> <br />
                        {childClassification ? childClassification : null}
                      </Grid>
                    );
                  }
                  return null;
                }
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Overview;
