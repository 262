import moment from "moment";
import { BrandColorSwatches } from "../../../styles/BrandColors";
import { uniq } from "lodash";

const defaultColorsMap: any = {
  0: BrandColorSwatches[0][0],
  1: BrandColorSwatches[0][2],
  2: BrandColorSwatches[1][0],
  3: BrandColorSwatches[1][2],
  4: BrandColorSwatches[2][0],
  5: BrandColorSwatches[2][2],
  6: BrandColorSwatches[3][0],
};

export const escapeCharacters = (input: string) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const enumerateDaysBetweenDates = (
  startDate: any,
  endDate: any,
  dateSelection: string
) => {
  var dates = [];
  var currDate = startDate;
  var lastDate = endDate;

  // inclusive first day
  dates.push(currDate.clone().format());

  const diff = lastDate.clone().diff(currDate.clone(), "days");

  switch (dateSelection) {
    case "daily":
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(currDate.clone().format());
      }
      break;
    case "weekly":
      while (currDate.add(1, "weeks").diff(lastDate) < 0) {
        dates.push(currDate.clone().format());
      }
      break;
    case "monthly":
      while (currDate.add(1, "months").diff(lastDate) < 0) {
        dates.push(currDate.clone().format());
      }
      break;
    case "custom":
      if (diff <= 14) {
        while (currDate.add(1, "days").diff(lastDate) < 0) {
          dates.push(currDate.clone().format());
        }
      } else if (diff <= 90) {
        while (currDate.add(1, "weeks").diff(lastDate) < 0) {
          dates.push(currDate.clone().format());
        }
      } else {
        while (currDate.add(1, "months").diff(lastDate) < 0) {
          dates.push(currDate.clone().format());
        }
      }

      break;
    default:
      break;
  }

  // inclusive last day
  dates.push(endDate.format());
  return dates;
};

export const formatFacetQueryResponse = (
  res: any,
  filters: any,
  eventTypesMap: any
) => {
  // event memo is for indexing events and correlating with the default colors
  let eventMemo: any = {};

  // construct eventDateCountArray for graph, this holds each date interval and count for each event
  const eventDateCountArray = Object.keys(res.count || {})
    .map((k) => {
      // split the solr query string
      const splitKey = k.split(" AND ");

      // remove special escape backslashes "\" from event
      const event = splitKey[0].split(":")[1].replace(/\\/g, "");

      // split date interval, remove brackets, split at TO and set as startDate endDate
      const dateIntervalArray = splitKey[1]
        .split("time_of_log:")[1]
        .replace(/[[\]']+/g, "")
        .split(" TO ");

      const count = res.count[k];

      const dateIntervalMap = {
        startDate: moment
          .tz(dateIntervalArray[0], filters.timeZone)
          .format("MM/DD/YYYY"),

        endDate: moment
          .tz(dateIntervalArray[1], filters.timeZone)
          .format("MM/DD/YYYY"),
      };

      eventMemo[event] = true;
      const eventMemoArr: any = Object.keys(eventMemo);
      const eventIdx = eventMemoArr.indexOf(event);

      return {
        event,
        count,
        dateIntervalMap,
        color: filters.customColors
          ? eventTypesMap[event].color || ""
          : defaultColorsMap[eventIdx],
      };
    })
    // sort to make sure we are preserving date/time order
    .sort(
      (a: any, b: any) =>
        +new Date(a.dateIntervalMap.startDate) -
        +new Date(b.dateIntervalMap.startDate)
    );

  // building event totals object with aggregated counts for each event for menu
  const eventTotals = eventDateCountArray.reduce((x: any, y: any) => {
    return {
      ...x,
      [y.event]: {
        event: y.event,
        color: y.color,
        // aggregate counts
        count: (x[y.event]?.count || 0) + y.count,
      },
    };
  }, {});

  const intervals = eventDateCountArray.reduce((x: any, y: any) => {
    const dataArray = x[y.event]?.data || [];
    dataArray.push(y.count);

    return {
      ...x,
      [y.event]: {
        backgroundColor: y.color,
        label: y.event,
        data: dataArray,
      },
    };
  }, {});

  // construct unique date interval labels, sorting by date, reformatting with moment
  const intervalLabels = uniq(
    eventDateCountArray
      .map((interval) => interval.dateIntervalMap)
      .sort((a: any, b: any) => +new Date(a.startDate) - +new Date(b.startDate))
      .map((interval: any) => `${interval.startDate} - ${interval.endDate}`)
  ).map((label: string) => {
    const splitLabel = label.split(" - ");

    if (splitLabel[0] === splitLabel[1] || filters.dateSelection === "daily") {
      return `${moment(splitLabel[0], "MM/DD/YYYY").format("MM/DD")}`;
    }
    if (filters.dateSelection === "weekly") {
      return [
        `Week of`,
        `${moment(splitLabel[0], "MM/DD/YYYY").format("MM/DD")}`,
      ];
    }
    if (filters.dateSelection === "monthly") {
      // add a couple days, set to start of month to account for leap years and end dates that fall on the last days of the month
      return moment(splitLabel[0], "MM/DD/YYYY")
        .add(2, "day")
        .startOf("month")
        .format("MMM Y");
    }

    return [`${splitLabel[0]} to`, splitLabel[1]];
  });

  const dataSets = Object.keys(intervals).map((interval) => {
    return intervals[interval];
  });

  return {
    eventTotals,
    intervals: dataSets,
    intervalLabels: intervalLabels,
  };
};

export const facetQueryEvents = async (
  props: any,
  filters: any,
  eventTypesMap: any
) => {
  const { apiUrl, token, organizationId } = props;

  let { startDate, endDate, events, dateSelection, timeZone } = filters;

  // IMPORTANT
  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate = moment(startDate).isValid()
    ? moment.tz(startDate, timeZone).startOf("day").utc()
    : null;
  endDate = moment(endDate).isValid()
    ? moment.tz(endDate, timeZone).endOf("day").utc()
    : null;

  const dates = enumerateDaysBetweenDates(startDate, endDate, dateSelection);

  const queryList = events.map((event: any) => {
    return dates
      .map((date: any, dateIdx: number) => {
        if (dates[dateIdx + 1]) {
          return `event:${escapeCharacters(
            event.value
          )} AND time_of_log:[${date} TO ${dates[dateIdx + 1]}]`;
        } else return null;
      })
      .filter((el) => el !== null);
  });

  const csmToken =
    process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
      ? "8b5f49a4-3694-4fa2-ae75-47a009c6921d"
      : process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
      ? "4e271452-e6dd-4072-809e-42f62387c1aa"
      : "84eab48e-c0c7-48cc-9612-3ffe159d5e15";

  const payload = {
    csmToken: csmToken,
    solrQuery: {
      q: `organization_id:${organizationId}`,
      facet: {
        query: queryList.flat(),
      },
    },
  };

  const results = await fetch(`${apiUrl}assetHistories/count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return formatFacetQueryResponse(res, filters, eventTypesMap);
    })
    .catch((err) => {
      console.log(err);
      return {
        error:
          "Failed to fetch data for event activity, please contact system administrator.",
      };
    });

  return results;
};

type timeZone = {
  label?: string;
  value: string;
  useDefaultTimeZone?: boolean;
};

const browserTimeZone = moment.tz.guess();

export const date_interval_util = (selection: any, timeZone: timeZone) => {
  const tz: timeZone | string = timeZone?.value || timeZone || browserTimeZone;

  const currentTime = moment().tz(tz as string);
  const mutateTime = moment().tz(tz as string);

  let startDate: any = null;
  let endDate: any = null;

  switch (selection) {
    case "daily":
      endDate = currentTime;
      startDate = mutateTime.subtract(2, "week");
      break;
    case "weekly":
      endDate = currentTime.endOf("week");
      startDate = mutateTime.subtract(12, "week").startOf("week");
      break;
    case "monthly":
      endDate = currentTime.endOf("month");
      startDate = mutateTime.subtract(12, "month").startOf("month");
      break;
    default:
      break;
  }

  return {
    startDate: new Date(startDate.format()),
    endDate: new Date(endDate.format()),
  };
};
