import { escapeCharacters } from "../../../utils/escapedCharacters";

export const retrieveStatus = async (props, settings, currentDate) => {
  const { apiUrl, token, organizationId } = props;

  const payload = {
    q: `current_owner_id:${organizationId}`,
    csmToken: process.env.REACT_APP_FACET_CSM_TOKEN,
    solrQuery: {
      facet: {
        query: [
          `(event:${escapeCharacters(
            settings.event
          )} AND category:${escapeCharacters(
            settings.category
          )} AND time_of_log:[${currentDate} TO NOW])`,
        ],
      },
    },
  };

  const results = await fetch(`${apiUrl}assetHistories/count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const saveUserWidgetSettings = async (props, settings) => {
  const { apiUrl, token, userId } = props;

  const payload = JSON.stringify({
    propertiesMap: {
      widgetSettings: {
        EventNotificationOnCategory: settings,
      },
    },
  });

  return await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: payload,
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => console.log(err));
};
