import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ForgotPassword from "./ForgotPassword";
import GrabEmail from "./GrabEmail";
import Grid from "@material-ui/core/Grid";
import InitialLogin from "./InitialLogin";
import locatorxdark from "../../assets/locatorxdark.png";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";

const useStyles = makeStyles((theme: any) => {
  return {
    card: {
      padding: "3rem",
    },
    cardContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      minWidth: 275,
    },
    logline: {
      borderLeft: "1px solid black",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "5rem 0",
      padding: '0 27rem'
    },
    logo:{
      maxWidth: '80%'
    },
    root: {
      justifyContent: "center",
    },
    terms: {
      marginTop: "1.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    termItem: {
      color: theme.palette.typography.primary,
      padding: "10px",
      textDecoration: 'none !important',
      '&:hover': {
        color:'#3D5BA9'
      }
    },
  };
});

export default function Login(props: any) {
  const [state, setState] = useState({
    fieldValues: {
      password: { error: false, label: "password", value: "" },
      userName: { error: false, label: "Email", value: "" },
    },
    grabEmail: false,
    resetPassword: false,
    resetPasswordRequestComplete: false,
    resetPasswordToken: "",
    showPassword: false,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    error: false,
    show: false,
    text: "",
  });
  const classes = useStyles();

  useEffect(() => {
    if (props.match.params.token) {
      setState((prevState) => ({
        ...prevState,
        resetPassword: true,
        resetPasswordToken: props.match.params.token,
      }));
    }
  }, [props.match.params.token]);

  return (
    <>
      <MaterialConfirmationModal
        closeModal={() => {
          setConfirmationModal({ error: false, show: false, text: "" });
        }}
        content={confirmationModal.text}
        modalOpen={confirmationModal.show}
        severity={confirmationModal.error ? "error" : "success"}
      />
      <Grid className={classes.root} container>
        <Grid className={classes.logoContainer} item xs={12}>
          <img alt="company logo" className={classes.logo} src={locatorxdark} />
        </Grid>
        <Grid className={classes.cardContainer} item xs={6}>
          <Card className={classes.card} elevation={4}>
            <CardContent>
            
            {!state.grabEmail && !state.resetPassword ? (
                <InitialLogin
                  {...props}
                  setConfirmationModal={setConfirmationModal}
                  setState={setState}
                  state={state}
                />
              ) : null}

              {state.grabEmail ? (
                <GrabEmail
                  {...props}
                  setConfirmationModal={setConfirmationModal}
                  setState={setState}
                  state={state}
                />
              ) : null}
              
              {state.resetPassword ? (
                <ForgotPassword
                  {...props}
                  changePage={setState}
                  resetPasswordToken={state.resetPasswordToken}
                  setConfirmationModal={setConfirmationModal}
                />
              ) : null}

            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.terms} item xs={12}>
          <span>
            <a
              className={classes.termItem}
              href="https://wiki.locatorx.com/privacy-policy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </span>
          <span className={classes.logline}></span>
          <span>
            <a
              className={classes.termItem}
              href="https://wiki.locatorx.com/terms-of-use/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
          </span>
        </Grid>
      </Grid>
    </>
  );
}
