import MaterialTable from "material-table";
export default function TreeTable(props: any) {
  const { columns, data, title = '' } = props;

  return (
    <div className="tree-table">
      <MaterialTable
        columns={columns}
        data={data}
        options={{
          headerStyle: {
            backgroundColor: "#3d5ba9",
            color: "white",
            padding: "12px",
          },
          search: false,
          selection: false,
          showSelectAllCheckbox: false,
          showTitle: false,
          toolbar: false,
        }}
        parentChildData={(row: any, rows: any) =>
          rows.find((a: any) => a.id === row.parentId)
        }
        title={title}
      />
    </div>
  );
}
