import { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SimpleButton from "../../components/Forms/FieldTypes/Button";

export default function ImageModal(props) {
  const { modalOpen, setModalOpen, image } = props;
  const { preview, name } = image;
  const useStyles = makeStyles((theme) => ({
    container: {
      textAlign: "center",
      padding: "1rem 0;",
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    image: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  // Modal Test stuff
  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.container}>
          {name}
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <img src={preview} className={classes.image} alt="preview" />
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <SimpleButton
            color="primary"
            content="Close"
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
    >
      {body}
    </Modal>
  );
}
