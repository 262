import { useState } from "react";

import Facilities from "./Facilities/Facilities";
import Zones from "./Zones/Zones";
import FacilitiesMap from "./FacilitiesOverviewMap/FacilitiesMap";

export default function Index(props: any) {
  const { userRoles = {}, apiUrl, token, history, location } = props;

  const [view, setView] = useState<any>({
    viewFacilitiesMap: false,
    viewZone: false,
    facility: {},
  });

  const permissions = {
    createLocation: userRoles["Create Locations"],
    deleteLocation: userRoles["Delete Locations"],
    editLocation: userRoles["Edit Locations"],
  };

  return (
    <>
      {!view.viewZones && !view.viewFacilitiesMap ? (
        <Facilities
          history={history}
          location={location}
          apiUrl={apiUrl}
          token={token}
          setView={setView}
          view={view}
          permissions={permissions}
        />
      ) : view.viewZones ? (
        <Zones
          apiUrl={apiUrl}
          token={token}
          setView={setView}
          view={view}
          permissions={permissions}
        />
      ) : (
        <FacilitiesMap apiUrl={apiUrl} token={token} setView={setView} />
      )}
    </>
  );
}
