import React from "react";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function TextFormatButtonGroup(props) {
  const { disabled = false, textFormats = [], onChange } = props;
  
  const handleFormat = (event, newFormats) => {
    onChange(newFormats);
  };

  return (
    <ToggleButtonGroup
      value={textFormats}
      exclusive
      onChange={handleFormat}
      aria-label="text formatting"
    >
      <ToggleButton disabled={disabled} value="bold" aria-label="bold">
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton disabled={disabled} value="italic" aria-label="italic">
        <FormatItalicIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
