import moment from "moment-timezone";
import { escapeCharacters } from "../utils/helpers";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const searchHistories = async (props, filters, isExport) => {
  const { token, organizationId, classifications } = props;

  let {
    allDevicesMap = {},
    assetCategories,
    assetIds,
    assetType,
    binLocation,
    endDate,
    events,
    limit,
    locals,
    locations,
    sorted,
    start,
    startDate,
    users,
    zones,
  } = filters;

  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) =>
        classifications.active[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  const classificationFiltersStrings = classificationFilters?.length
    ? classificationFilters
        ?.map((classArray) => {
          return (
            classArray
              ?.map((childClass) => {
                return `classificationset:${childClass.value}`;
              })
              .flat()
              .join(" OR ") || null
          );
        })
        .join(" AND ")
        .split(" AND ")
    : "";

  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            // case "assetType":
            //   obj.id = "asset_type";
            //   break;
            case "category":
              obj.id = "category";
              break;
            case "assetTag":
              obj.id = "asset_tag";
              break;
            case "event":
              obj.id = "event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude desc, longitude asc";
              } else {
                obj.id = "latitude asc, longitude desc";
              }
              obj.latLong = true;
              break;
            case "user":
              obj.id = "last_name";
              break;
            case "binLocation":
              obj.id = "bin_location";
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";
  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}, `;
          }
        })()
      : "";

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate =
    startDate && moment(startDate).isValid()
      ? moment(startDate).startOf("day").utc().format()
      : null;
  endDate =
    endDate && moment(endDate).isValid()
      ? moment(endDate).endOf("day").utc().format()
      : null;

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  // build filter query string from all the asset ids found in the allDevicesMap to filter out asset histories that may have come from devices
  const filterDevicesString = Object.keys(allDevicesMap)
    .map((deviceId) => {
      return `!asset_id:${deviceId}`;
    })
    .join(" OR ");

  const usersString = users?.length
    ? users
        .map((user) => {
          return `app_user_id:${user.value}`;
        })
        .join(" OR ")
    : "";

  const binLocationsString = binLocation?.length
    ? binLocation
        .map((bin) => {
          return `bin_location:${escapeCharacters(bin.value)}`;
        })
        .join(" OR ")
    : "";

  let assetIdString = "";
  if (assetIds && assetIds.length) {
    let assetIdSet = assetIds.map((e) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    assetIdString = assetIdSet.join(" OR ");
  }

  let eventString = "";
  if (events && events.length) {
    let eventSet = events.map((e) => {
      return `event:${escapeCharacters(e.value)}`;
    });
    eventString = eventSet.join(" OR ");
  }

  let localsString = "";
  if (locals && locals.length) {
    let localsSet = locals.map((e) => {
      return `state:${escapeCharacters(e.value)}`;
    });
    localsString = localsSet.join(" OR ");
  }

  let locationString = "";
  if (locations && locations.length) {
    let locationsSet = locations.map((e) => {
      return `facility_id:${e.value.facilityId}`;
    });
    locationString = locationsSet.join(" OR ");
  }

  let zonesString = "";
  if (zones && zones.length) {
    let zonesSet = zones.map((e) => {
      return `{!tuple}zone.zone_id:${e.value}`;
    });
    zonesString = zonesSet.join(" OR ");
    zonesString = "(" + zonesString + ")";
  }

  const assetCategoriesString = assetCategories?.length
    ? assetCategories
        .map((cat) => {
          return `category:${escapeCharacters(cat.value)}`;
        })
        .join(" OR ")
    : "";

  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [
        `${assetIdString ? `${assetIdString}` : ``}`,
        escapedAssetType ? `asset_type:${escapedAssetType}` : ``,
        `${eventString ? `${eventString}` : ``}`,
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
        `${locationString ? `${locationString}` : ``}`,
        `${localsString ? `${localsString}` : ``}`,
        filterDevicesString,
        usersString,
        binLocationsString,
        assetCategoriesString,
        `${zonesString ? `${zonesString}` : ``}`,
        ...classificationFiltersStrings,
      ],
      sort: `${sortedString}time_of_log desc`,
      start: start ? start : 0,
    },
    limit: isExport ? 25000 : limit || 25,
  };

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      assetHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const assetHistory = {
  searchHistories,
  saveFilterSettings,
};
