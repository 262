import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  dataItem: {
    margin: "1rem 0",
  },
  root: {
    height: 240,
    flexGrow: 1,
  },
  header: {
    color: theme.palette.typography.primary,
    fontWeight: "bold",
  },
  content: {
    color: theme.palette.typography.secondary,
    paddingLeft: ".3rem",
  },
}));

export default function BatchInformationTab(props) {
  const { batchInfo = {}, facilities = {}, timeZone } = props;
  const {
    batchOrigin,
    children = [],
    description,
    finalDestination,
    name,
    tag,
    timeCreated,
  } = batchInfo;
  const classes = useStyles();
  const timeCreatedString = `${moment
    .utc(timeCreated, "YYYYMMDDHHmmss")
    .tz(timeZone?.value || moment.tz.guess())
    .format("MM/DD/YYYY")}`;

  return (
    <Grid container>
      {/* Batch ID */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Batch ID
        </Typography>
        <Typography className={classes.content} variant="body2">
          {tag ? tag : name}
        </Typography>
      </Grid>

      {/* Batch Name */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Batch Name
        </Typography>
        <Typography className={classes.content} variant="body2">
          {name ? name : "None"}
        </Typography>
      </Grid>

      {/* Date Created */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Date Created
        </Typography>
        <Typography className={classes.content} variant="body2">
          {timeCreatedString}
        </Typography>
      </Grid>

      {/* Batch Origin */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Batch Origin
        </Typography>
        <Typography className={classes.content} variant="body2">
          {facilities[batchOrigin.value]
            ? facilities[batchOrigin.value].name
            : "None"}
        </Typography>
      </Grid>

      {/* Final Destination */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Final Destination
        </Typography>
        <Typography className={classes.content} variant="body2">
          {facilities[finalDestination.value]
            ? facilities[finalDestination.value].name
            : "None"}
        </Typography>
      </Grid>

      {/* Batch Description */}
      <Grid className={classes.dataItem} item xs={4}>
        <Typography className={classes.header} variant="h6">
          Batch Description
        </Typography>
        <Typography className={classes.content} variant="body2">
          {description ? description : "None"}
        </Typography>
      </Grid>

      {/* Unit of Measure */}
      <Grid className={classes.dataItem} item xs={12}>
        {children.map((item, index) => {
          const { assetType, count, totalItemCount } = item;
          return (
            <Grid
              className={classes.dataItem}
              container
              key={`${assetType}-${index}`}
            >
              <Grid item style={{ paddingLeft: `${index * 1}rem` }} xs={4}>
                <Typography className={classes.header} variant="h6">
                  Unit of Measure
                </Typography>
                <Typography className={classes.content} variant="body2">
                  {assetType}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header} variant="h6">
                  Unit of Measure Count
                </Typography>
                <Typography className={classes.content} variant="body2">
                  {count}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.header} variant="h6">
                  Total Item Count
                </Typography>
                <Typography className={classes.content} variant="body2">
                  {totalItemCount}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
