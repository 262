import { useState } from "react";

import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { isEmpty } from "lodash";
import { Tooltip } from "@material-ui/core";

function Mobile(props) {
  const { state, dispatchState, handleConfirmationModal } = props;
  const { globalSettings } = state;

  const eventTypes = globalSettings.eventTypesMap
    ? Object.keys(globalSettings.eventTypesMap).sort()
    : [];

  const [view, setView] = useState({
    isAddRole: false,
    isAddPermission: false,
  });

  const [addRole, setAddRole] = useState({});
  const [addPermission, setAddPermission] = useState({});

  return (
    <>
      <div className="row">
        <div className="col md-6" id="mobileRoles">
          <h4>
            <Tooltip title="These are the roles you have authorized to update assets or products via a mobile application (LX LogisticsPro or your own app).">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Mobile Roles{" "}
            <IconButton
              id="addMobileRole"
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddRole: !view.isAddRole,
                })
              }
            >
              {!view.isAddRole ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddRole ? (
              <SimpleList
                items={Object.keys(state.globalSettings.mobileRoles)
                  .map((role) => {
                    if (state.globalSettings.mobileRoles[role]) {
                      return {
                        id: role,
                        label: role,
                        actions: state.globalSettings.mobileRoles[role].actions,
                      };
                    } else {
                      return null;
                    }
                  })
                  .filter((el) => el !== null)}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "mobileRole",
                    payload: {
                      ...state.globalSettings.mobileRoles[id],
                      label: state.globalSettings.mobileRoles[id].label
                        ? state.globalSettings.mobileRoles[id].label
                        : id,
                      id: state.globalSettings.mobileRoles[id].id
                        ? state.globalSettings.mobileRoles[id].id
                        : id,
                    },
                  });
                }}
                onClickRemove={(id) => {
                  setView({
                    ...view,
                    isAddPermission: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "mobileRoles",
                    payload: {
                      ...state.globalSettings.mobileRoles,
                      [id]: null,
                    },
                    removeSelected: "mobileRole",
                  });
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Add new mobile role"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Mobile Role",
                    onChange: (e) => {
                      setAddRole({
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  if (
                    addRole.label.toLowerCase() === "no lxconnect access" ||
                    addRole.label.toLowerCase() === "no console access" ||
                    addRole.label.toLowerCase() === "admin" ||
                    addRole.label.toLowerCase() === "standard" ||
                    addRole.label.toLowerCase() === "lite"
                  ) {
                    handleConfirmationModal(
                      `${addRole.label} is a designated name for a LXConnect Role in LXConnect, and the name cannot be repeated as a mobile role. Please choose a different name for your mobile role.`,
                      true,
                      5000
                    );
                  } else {
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "mobileRoles",
                      payload: {
                        ...state.globalSettings.mobileRoles,
                        [addRole.label]: {
                          id: addRole.label.trim(),
                          label: addRole.label.trim(),
                          actions: {},
                        },
                      },
                    });
                  }
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddRole: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col md-6" id="permissions">
          <h4>
            <Tooltip title="Actions or Events that you will allows the highlighted role to perform using the mobile app.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Permissions
            {state.selected.mobileRole ? (
              <IconButton
                id="addPermission"
                style={{ padding: "0px" }}
                onClick={() =>
                  setView({
                    ...view,
                    isAddPermission: !view.isAddPermission,
                  })
                }
              >
                {!view.isAddPermission ? (
                  <AddIcon style={{ color: "#3D5BA9" }} />
                ) : (
                  <CloseIcon style={{ color: "#b71d1a" }} />
                )}
              </IconButton>
            ) : null}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddPermission ? (
              <>
                {!state.selected.mobileRole ? (
                  <p>Select a role to add or remove permissions.</p>
                ) : state.selected.mobileRole.actions &&
                  !isEmpty(state.selected.mobileRole.actions) ? (
                  <>
                    <p>
                      <strong>{state.selected.mobileRole.label}</strong>{" "}
                      permissions
                    </p>
                    <SimpleList
                      noSelectHighlight
                      onClickRemove={(id) => {
                        dispatchState({
                          type: "SET_SELECTED",
                          label: "mobileRole",
                          payload: {
                            ...state.selected.mobileRole,
                            actions: {
                              ...state.selected.mobileRole.actions,
                              [id]: null,
                            },
                          },
                        });

                        dispatchState({
                          type: "CHANGE_GLOBAL",
                          setting: "mobileRoles",
                          payload: {
                            ...state.globalSettings.mobileRoles,
                            [state.selected.mobileRole.label]: {
                              ...state.selected.mobileRole,
                              actions: {
                                ...state.selected.mobileRole.actions,
                                [id]: null,
                              },
                            },
                          },
                        });
                      }}
                      items={
                        state.selected.mobileRole &&
                        state.selected.mobileRole.actions
                          ? Object.keys(state.selected.mobileRole.actions)
                              .filter(
                                (action) =>
                                  state.selected.mobileRole.actions[action] !==
                                  null
                              )
                              .map((action) => {
                                return {
                                  id: action,
                                  label: state.selected.mobileRole.actions[
                                    action
                                  ]?.label
                                    ? state.selected.mobileRole.actions[action]
                                        .label
                                    : action,
                                };
                              })
                          : []
                      }
                    />
                  </>
                ) : (
                  <p>
                    No permissions set for {state.selected.mobileRole.label}
                  </p>
                )}
              </>
            ) : (
              <SimpleForm
                headerText={`Add permission${
                  state.selected.mobileRole && state.selected.mobileRole.label
                    ? ` for ${state.selected.mobileRole.label}`
                    : null
                }`}
                id="eventSelection"
                submitBtnText={"Add Permission"}
                fields={[
                  {
                    id: "eventType",
                    label: "Event Type",
                    isSelect: true,
                    options:
                      eventTypes && eventTypes.length
                        ? [
                            ...eventTypes.map((type) => {
                              return {
                                label: type,
                                value: type,
                              };
                            }),
                          ]
                        : [],
                    onChange: (e) => {
                      setAddPermission({
                        id: e.target.value,
                        label: e.target.value,
                        value: e.target.value,
                        formId: globalSettings.eventTypesMap[e.target.value]
                          .formId
                          ? globalSettings.eventTypesMap[e.target.value].formId
                          : null,
                      });
                    },
                    value: addPermission ? addPermission.value : "",
                  },
                ]}
                onSubmit={(e) => {
                  const updatedActions = {
                    ...state.selected.mobileRole,
                    actions: {
                      ...state.selected.mobileRole.actions,
                      [addPermission.id]: {
                        id: addPermission.id,
                        label: addPermission.label,
                        formId: addPermission.formId
                          ? addPermission.formId
                          : null,
                      },
                    },
                  };

                  dispatchState({
                    type: "SET_SELECTED",
                    label: "mobileRole",
                    payload: updatedActions,
                  });

                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "mobileRoles",
                    payload: {
                      ...state.globalSettings.mobileRoles,
                      [state.selected.mobileRole.label]: updatedActions,
                    },
                  });

                  setView({
                    ...view,
                    isAddPermission: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddPermission: false,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Mobile;
