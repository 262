const XLSX = require("xlsx");
const moment = require("moment");

function getJsDateFromExcel(excelDate: number) {
  // Excel has an idiosyncratic date type...
  // JavaScript dates can be constructed by passing milliseconds
  // since the Unix epoch (January 1, 1970) example: new Date(12312512312);

  // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1 (Google "excel leap year bug")
  // 2. Convert to milliseconds.
  // try + 2 instead of +1 if you run into off by one errors
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
}

export const downloadTemplate = (
  organization: any,
  classifications: any,
  facilities: any,
  availableDevices: any
) => {
  const customAssetFields =
    organization?.customAssetFieldsList &&
    organization?.customAssetFieldsList?.length
      ? organization.customAssetFieldsList
          .map((f: any) => {
            return f.id;
          })
          .reduce((x: any, y: string) => {
            return {
              ...x,
              [y]: "",
            };
          }, {})
      : {};

  const classificationsMap = Object.keys(classifications)
    .map((k) => {
      return {
        parentClass: k,
        children: Object.keys(classifications[k].children).map(
          (childKey: string) => ` ${childKey}`
        ),
      };
    })
    .reduce((x: any, y: any) => {
      return {
        ...x,
        [y.parentClass]: `(Optional) Delete all text and keep only one of the following: ${y.children}`,
      };
    }, {});

  const assetCats =
    organization?.assetCategoriesList &&
    organization?.assetCategoriesList?.length
      ? organization.assetCategoriesList
      : null;

  const assetCategories = assetCats
    ? assetCats.map((c: any) => {
        return ` ${c.id}`;
      })
    : "";
  const eventTypes = organization.eventTypesMap
    ? Object.keys(organization.eventTypesMap).map((t: string) => {
        return ` ${t}`;
      })
    : "";
  const facilitiesArray = facilities
    ? Object.keys(facilities).map((k: string) => {
        return ` ${facilities[k].name}`;
      })
    : "";

  const devices = availableDevices
    ? availableDevices.map((d: any, index: any) => {
        return d.tag ? ` ${d.tag}` : ` No Device Tag-${index}`;
      })
    : "";

  const template = {
    Instructions:
      "Fill out this spreadsheet and import to upload a list of new assets. Please do not alter the spreadsheet headers in any way, or add/remove headers. Please make sure to write it in each option exactly as it is shown in its respective column (case-sensitive).",
    "Asset Tag": "",
    Description: "",
    Category: `Delete all text and keep only one of the following categories: ${assetCategories}`,
    Facility: `Delete all text and keep only one of the following facilities: ${facilitiesArray}`,
    Status: `Delete all text and keep only one of the following events: ${eventTypes}`,
    "Asset Details": "",
    "AtlasLX Tag Information": `(Optional) Delete all text and keep only one of the following devices: ${devices}`,
    "Create Date": "(Optional) MM/DD/YYYY",
    "Need Date": "(Optional) MM/DD/YYYY",
    ...customAssetFields,
    ...classificationsMap,
  };

  const fileName = `AssetsImportTemplate.xlsx`;
  const ws = XLSX.utils.json_to_sheet([template]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};

export const validateAndFormatSpreadsheet = (
  spreadsheet: any,
  props: any,
  response: any,
  availableDevices: any
) => {
  const { customAssetFieldsList = [], assetCategoriesList = [] } =
    props.organization;
  const { eventTypesMap = {} } = props.organization;
  const { facilities = {}, classifications = {} } = props;

  const facilityArray = Object.keys(facilities).map((f) => {
    return facilities[f];
  });
  const eventTypesArray = Object.keys(eventTypesMap);
  const customAssetFieldsObj = customAssetFieldsList
    .map((f: any) => {
      return f.id;
    })
    .reduce((x: any, y: string) => {
      return {
        ...x,
        [y]: true,
      };
    }, {});

  let success = true;
  let message = "";

  const readFile = (file: any, onLoadCallback: any) => {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsArrayBuffer(file);
  };

  readFile(spreadsheet, (e: any) => {
    // use result in callback...
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });

    const sheet_name_list = workbook.SheetNames;
    const parsedSheet = sheet_name_list.map((sheet: string) => {
      return XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    })[0];

    // memory object for checking that asset tags are unique within the spreadsheet
    const uniqueAssetObj: any = {};

    // memory object for checking that available devices are not used twice.
    const uniqueDeviceObj: any = {};

    // Sets up our initial state for uniqueDeviceObj. If the device is available it will be true.
    availableDevices.forEach((device: any) => {
      uniqueDeviceObj[device.tag] = true;
    });

    // loop through parsed spreadsheet, validate row cells and return a formatted array of asset objects
    const payload = {
      products: [
        {
          assets: parsedSheet.map((row: any, idx: number) => {
            // check for empty required fields
            if (
              !row["Asset Tag"] ||
              !row.Description ||
              !row.Category ||
              !row.Facility ||
              !row.Status
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; please make sure asset tag, description, category, facility and status fields are not empty.`)
              );
            }

            // check asset tag isn't already present in spreadsheet
            if (uniqueAssetObj[row["Asset Tag"]]) {
              return (
                (success = false),
                (message = `Error Found on row ${idx + 1}; asset tag ${
                  row["Asset Tag"]
                } already used elsewhere in the spreadsheet, please make sure asset tags are unique!`)
              );
            }

            // Checks to see if a user entered a value for a device. We then check to see if what they entered in is a device
            // that exist and is available. We then verify the device hasnt been claimed already within this spreadsheet
            if (
              row["AtlasLX Tag Information"] !== undefined &&
              uniqueDeviceObj[row["AtlasLX Tag Information"]] === false
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; AtlasLX Tag Information ${
                  row["AtlasLX Tag Information"]
                } A device cannot be associated with two assets, please associate a single device with a single asset or item.`)
              );
            } else if (
              row["AtlasLX Tag Information"] !== undefined &&
              uniqueDeviceObj[row["AtlasLX Tag Information"]] === undefined
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; AtlasLX Tag Information ${
                  row["AtlasLX Tag Information"]
                }, please check that you have the correct Device ID.`)
              );
            }

            // check category exists on organization
            let catCopy = assetCategoriesList;
            if (
              !catCopy
                .map((c: any) => c.id.toLowerCase().trim())
                .includes(row.Category.toLowerCase().trim())
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; please make sure the asset category ${
                  row.Category
                } exists in your organization.`)
              );
            }

            // check facility exists on organization
            let facCopy = facilityArray;

            if (
              !facCopy
                .map((f) => f.name?.toLowerCase().trim())
                .includes(row.Facility.toLowerCase().trim())
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; please make sure the facility ${
                  row.Facility
                } exists in your organization.`)
              );
            }

            // check status event exists on organization, if it is present
            let eventsCopy = eventTypesArray;
            if (
              row.Status &&
              !eventsCopy
                .map((e) => e.toLowerCase().trim())
                .includes(row.Status.toLowerCase().trim())
            ) {
              return (
                (success = false),
                (message = `Error Found on row ${
                  idx + 1
                }; please make sure the event ${
                  row.Status
                } for the asset status exists in your organization.`)
              );
            }

            // loop through required fields for asset category and validate
            const category = assetCategoriesList.filter((cat: any) => {
              return (
                cat.id.toLowerCase().trim() ===
                row.Category.toLowerCase().trim()
              );
            })[0];

            if (category.requiredFields.length > 0) {
              category.requiredFields.forEach((field: any) => {
                if (field.required && !row[field.id]) {
                  return (
                    (success = false),
                    (message = `Error Found on row ${idx + 1}; ${
                      field.id
                    } is a required field for ${category.id} asset category.`)
                  );
                }
                if (row[field.id] && field.dataType === "Date") {
                  if (!moment(row[field.id]).isValid()) {
                    return (
                      (success = false),
                      (message = `Error Found on row ${idx + 1}; ${
                        field.id
                      } is not a valid date.`)
                    );
                  } else {
                    // since we're receiving excel date integers, convert using moment. Are there discrepencies here between macs and windows Excel users?
                    row[field.id] = moment(
                      getJsDateFromExcel(row[field.id])
                    ).format("MM/DD/YYYY");
                  }
                }
                const currencyCheck = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
                if (
                  row[field.id] &&
                  field.dataType === "Currency" &&
                  !currencyCheck.test(row[field.id])
                ) {
                  return (
                    (success = false),
                    (message = `Error Found on row ${idx + 1}; ${
                      field.id
                    } is not a valid currency.`)
                  );
                }
              });
            }

            if (row["Create Date"]) {
              if (!moment(row["Create Date"]).isValid()) {
                return (
                  (success = false),
                  (message = `Error Found in Create Date row ${idx + 1}; ${
                    row["Create Date"]
                  } is not a valid date.`)
                );
              } else {
                // since we're receiving excel date integers, convert using moment. Are there discrepencies here between macs and windows Excel users?
                row["Create Date"] = moment(
                  getJsDateFromExcel(row["Create Date"])
                )
                  .startOf("day")
                  .format("YYYYMMDDHHmm");
              }
            }

            if (row["Need Date"]) {
              if (!moment(row["Need Date"]).isValid()) {
                return (
                  (success = false),
                  (message = `Error Found in Need Date row ${idx + 1}; ${
                    row["Need Date"]
                  } is not a valid date.`)
                );
              } else {
                // since we're receiving excel date integers, convert using moment. Are there discrepencies here between macs and windows Excel users?
                row["Need Date"] = moment(getJsDateFromExcel(row["Need Date"]))
                  .startOf("day")
                  .format("YYYYMMDDHHmm");
              }
            }

            // build custom fields for payload
            const customFields = Object.keys(row)
              .map((key) => {
                if (customAssetFieldsObj[key]) {
                  return {
                    [key]:
                      typeof row[key] === "string" ? row[key].trim() : row[key],
                  };
                } else {
                  return null;
                }
              })
              .filter((f) => f !== null)
              .reduce((x, y) => {
                return {
                  ...x,
                  ...y,
                };
              }, {});

            // find classifcation parents in row, return classification child ID from classifcation's children in classification map
            const classificationSet = Object.keys(row)
              .map((rowKey) => {
                if (
                  typeof rowKey === "string" &&
                  classifications[rowKey.trim()]
                ) {
                  const classificationChildId =
                    classifications[rowKey.trim()].children[row[rowKey.trim()]]
                      ?.classificationId || "";

                  // if nothing is entered in classification column, return null (because classifications are optional)
                  if (!row[rowKey.trim()]?.length) {
                    return null;
                  }

                  if (!classificationChildId) {
                    return (
                      (success = false),
                      (message = `Error Found on row ${idx + 1}; ${
                        row[rowKey.trim()]
                      } is not a valid value for ${rowKey}. Are you sure you have your classification values properly configured in global settings?`)
                    );
                  }
                  return classificationChildId;
                }
                return null;
              })
              .filter((k) => typeof k === "string");

            // passes all validation checks, store tag on memory object, and build asset object and return
            uniqueAssetObj[row["Asset Tag"]] = true;

            uniqueDeviceObj[row["AtlasLX Tag Information"]] = false;
            const body = {
              location: facilityArray.filter(
                (f) =>
                  f.name?.toLowerCase().trim() ===
                  row.Facility.toLowerCase().trim()
              )[0].location,
              event: row.Status ? row.Status.toString().trim() : null,
              tag: row["Asset Tag"].toString().trim(),
              category: row.Category.toString().trim(),
              parentId: undefined,
              classificationSet: classificationSet,
              timeCreatedUser: row["Create Date"] || null,
              timeNeeded: row["Need Date"] || null,
              propertiesMap: {
                assetDetails: row["Asset Details"] || "",
                description: row.Description.toString().trim(),
                category: row.Category.toString().trim(),
                ...customFields,
              },
            };

            if (row["AtlasLX Tag Information"]) {
              const deviceAssetId = (body.parentId = availableDevices.find(
                (element: any) => element.tag === row["AtlasLX Tag Information"]
              )
                ? (body.parentId = availableDevices.find(
                    (element: any) =>
                      element.tag === row["AtlasLX Tag Information"]
                  ).assetId)
                : "");
              body.parentId = deviceAssetId;
            }
            return body;
          }),
        },
      ],
    };

    // write ArrayBuffer of file
    const rawFile = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    // construct javascript File object from Array Buffer, send in response callback
    const fileName = `AssetImports.xlsx`;
    const file: any = new File([rawFile], fileName);
    file.path = fileName;

    response({
      success: success,
      message: message,
      file: success && file ? file : "",
      payload: payload ? payload : "",
    });
  });
};
