import { escapeCharacters } from "../../../utils/escapedCharacters";

export const retrieveStatuses = async (props, list, currentDate) => {
  const {
    apiUrl,
    token,
  } = props;
  const payload = {
    csmToken:process.env.REACT_APP_FACET_CSM_TOKEN,
    solrQuery: {
      facet: {
        query: [],
      },
    },
  };

  list.forEach((item) => {
    const { category, event } = item;
    payload.solrQuery.facet.query.push(
      `category:${category} AND last_event:${escapeCharacters(
        event
      )} AND time_of_log:[${currentDate} TO NOW]`
    );
  });
  const results = await fetch(`${apiUrl}assets/count`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
