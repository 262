import { Component } from "react";
import { Form, Col } from "react-bootstrap";
import DefaultOrganizationRolesMap from "../../globalStore/utils/DefaultOrganizationRolesMap";
import StateSelect from "../../components/Selects/StateSelect";
import RequiredField from "../../components/Forms/RequiredField/RequiredField";
import request from "superagent";

export default class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      validated: false,
      ...props.organization,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const { isCreate } = this.props;
      const { name, organizationId, propertiesMap = {}, address } = this.state;
      let body = {
        address,
        name,
        organizationId,
        propertiesMap,
        rolesMap: DefaultOrganizationRolesMap,
      };
      let req = isCreate
        ? request.post(`${this.props.apiUrl}organizations`)
        : request.put(`${this.props.apiUrl}organizations/${organizationId}`);
      req
        .set("auth-token", this.props.token)
        .send(body)
        .then((response) => {
          if (response.body.success) {
            let notificationModalText = isCreate
              ? "Organization Sucessfully Created"
              : "Location Successfully Updated";
            this.props.notificationModal(notificationModalText);
            this.props.onHide();
            this.props.onSuccess();
          } else alert("Error: " + response.body.error);
        });
    }
    this.setState({ validated: true });
  }

  render() {
    const { readOnly } = this.props;
    const { address = {}, propertiesMap = {}, validated } = this.state;
    const { address1 = "", city = "", state = "", postalCode = "" } = address;
    const { organizationDetails = "" } = propertiesMap;
    const { name } = this.state;

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={(event) => {
          this.handleSubmit(event);
        }}
        className="container-fluid"
      >
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label className="mt-2 mb-0">Organization Name</Form.Label>
            <Form.Control
              required
              name="name"
              className="form-control bg-white"
              readOnly={readOnly}
              field="name"
              value={name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <RequiredField />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label className="mt-2 mb-0">Address - optional</Form.Label>
            <Form.Control
              name="address"
              className="form-control bg-white"
              field="address"
              value={address1}
              readOnly={readOnly}
              onChange={(event) => {
                const newAddress = this.state.address || {};
                newAddress.address1 = event.target.value;
                this.setState({ address: newAddress });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label className="mt-2 mb-0">City</Form.Label>
            <Form.Control
              required
              name="city"
              className="form-control bg-white"
              field="city"
              value={city}
              readOnly={readOnly}
              onChange={(event) => {
                const newAddress = this.state.address || {};
                newAddress.city = event.target.value;
                this.setState({ address: newAddress });
              }}
            />
            <RequiredField />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <StateSelect
              onChange={(state) => {
                let newAddress = this.state.address || {};
                newAddress.state = state;
                this.setState({ address: newAddress });
              }}
              readOnly={readOnly}
              required={true}
              state={state}
            />
            <RequiredField />
          </Form.Group>
          <Form.Group className="col-md-6">
            <Form.Label className="mt-2 mb-0">Zip Code - optional</Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]{5}"
              name="postalCode"
              className="form-control bg-white"
              field="postalCode"
              readOnly={readOnly}
              value={postalCode}
              onChange={(event) => {
                const newAddress = this.state.address || {};
                newAddress.postalCode = event.target.value;
                this.setState({ address: newAddress });
              }}
            />
          </Form.Group>
          <Form.Group className="col-md-12">
            <Form.Label className="mt-2 mb-0">
              Organization Details - optional
            </Form.Label>
            <Form.Control
              name="organizationDetails"
              className="form-control bg-white"
              field="organizationDetails"
              readOnly={readOnly}
              value={organizationDetails}
              onChange={(event) => {
                const newPropertiesMap = this.state.propertiesMap || {};
                newPropertiesMap.organizationDetails = event.target.value;
                this.setState({ propertiesMap: newPropertiesMap });
              }}
            />
          </Form.Group>
        </Form.Row>
        {readOnly ? null : (
          <Form.Row className="row">
            <Form.Group as={Col} md={12} className="text-center mt-1">
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </Form.Group>
          </Form.Row>
        )}
      </Form>
    );
  }
}
