import moment from "moment-timezone";
import XLSX from "xlsx";

export const handleExport = (state: any, handleConfirmationModal: Function) => {
  const timeZone = state.filters?.tz?.value || moment.tz.guess();

  const { selectedZones: selectedZonesMap = {} } = state;
  const selectedZones = Object.keys(selectedZonesMap).map((k) => {
    const { name, propertiesMap } = selectedZonesMap[k];
    const { description = "" } = propertiesMap;
    return {
      name: name,
      description: description,
    };
  });
  if (selectedZones.length === 0) {
    return handleConfirmationModal(
      `No zones selected for export.`,
      true,
      "fas fa-times-circle mr-2",
      3000
    );
  }

  const currentTime = moment()
    .tz(
      timeZone && timeZone.value
        ? timeZone.value
        : timeZone
        ? timeZone
        : moment.tz.guess()
    )
    .format("YYYY-MM-DD");

  // XLSX
  const fileName = `Export_${currentTime}.csv`;
  const ws = XLSX.utils.json_to_sheet(selectedZones);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};
