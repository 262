import moment from "moment";

export const fetchAssemblies = async (props: any) => {
  const { apiUrl, token } = props;
  return await fetch(`${apiUrl}assemblies`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      return { error: err.toString() };
    });
};

export const escapeCharacters = (input: string, isBinLocation?: boolean) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (char === " " && isBinLocation) {
      const escapedChar = " ";
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `?`);
    } else if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const fetchShortedItems = async (props: any, filters: any) => {
  const { apiUrl, token, organizationId } = props;

  let { assetIds, needDate, needDateEnd, limit, start, sorted } = filters;

  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj: any = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "timeNeeded":
              obj.id = "time_needed";
              break;
            case "tag":
              obj.id = "tag";
              break;
            default:
              obj.id = "tag";
              break;
          }
          return obj;
        })()
      : "";

  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}, `;
          }
        })()
      : "";

  // NOTE, this is different than how we do conversions for dateTime in other places because these are custom inputs and not DB generated times (like timeOfLog or timeCreated).
  // Here we are converrintg to UTC BEFORE selecting start of day... this works for custom user date inputs that dont specify HH MM, because database needs filter query to be in UTC format...
  needDate = moment(needDate).isValid()
    ? moment(needDate).utc().startOf("day").format()
    : null;
  needDateEnd = moment(needDateEnd).isValid()
    ? moment(needDateEnd).utc().endOf("day").format()
    : null;

  let assetIdString = "";

  if (assetIds && assetIds.length) {
    let assetIdSet = assetIds.map((e: any) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    assetIdString = assetIdSet.join(" OR ");
  }

  // "-{!tuple}device.platform:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        `${assetIdString ? `${assetIdString}` : ``}`,
        `{!tuple}quantity.has_shortage:true`,
        needDate || needDateEnd
          ? `time_needed:[${needDate || `*`} TO ${needDateEnd || `*`}]`
          : ``,
      ],
      sort: `${sortedString}time_of_log desc`,
      start: start || 0,
    },
    limit: limit || 25,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator",
      };
    });
  return results;
};

export const searchMOAssets = async (
  props: any,
  input: any,
  limit = 50,
  start = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        "category:MO",
        escapedInput ? `tag:${escapedInput}*` : ``,
      ],
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const searchItems = async (
  props: any,
  input: any,
  limit = 50,
  start = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        `{!tuple}quantity.has_shortage:true`,
        escapedInput ? `tag:${escapedInput}*` : ``,
      ],
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};
