import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import ReactSelect from "../../../components/Forms/FieldTypes/ReactSelect";

import { submitUpdateEvent } from "../api";
import { naturalSort } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  formArea: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  submit: {
    marginTop: theme.spacing(4),
  },
  select: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  input: {
    width: "100%",
  },
}));
function Update(props: any) {
  const {
    apiUrl,
    token,
    setModal,
    state,
    setState,
    assetsArray,
    facilities,
    eventTypes,
  } = props;
  const classes = useStyles();

  const [updateEvent, setUpdateEvent] = useState<any>({
    asset: null,
    location: "",
    event: "",
    zone: "",
    note: "",
  });

  const [zones, setZones] = useState<any>([]);

  const setFacilityZones = async (facilityId: string | null) => {
    if (!facilityId) {
      setZones([]);
    } else {
      const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }).then((res) => res.json());

      if (results.success && results.facility?.zones?.length > 0) {
        setZones(results.facility.zones);
      } else {
        setZones([]);
      }
    }
  };

  const [isValid, setIsValid] = useState<any>(false);

  useEffect(() => {
    if (
      updateEvent?.location?.value &&
      updateEvent?.event?.value &&
      updateEvent?.asset?.value
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [updateEvent]);

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Update Event Log</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const { apiUrl, token, facilities, userId } = props;

                submitUpdateEvent(
                  { apiUrl, token, facilities, userId },
                  updateEvent
                ).then((res) => {
                  if (res.error) {
                    setModal({
                      modalShow: true,
                      text: `Uh-oh! Something went wrong while updating event log... ${res.error}`,
                      isError: true,
                    });
                  } else {
                    setModal({
                      modalShow: true,
                      text: `Event update success!`,
                      isError: false,
                    });

                    setTimeout(() => {
                      setModal({
                        modalShow: false,
                        text: "",
                        isError: false,
                      });
                    }, 1500);

                    setState({
                      ...state,
                      histories: {
                        ...state.histories,
                        assetHistories: [res.assetHistory].concat(
                          state?.histories?.assetHistories || []
                        ),
                        count: state.histories.count + 1,
                      },
                    });
                  }
                });
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <span>Item</span>
                  <ReactSelect
                    isSearchable
                    isClearable
                    required
                    label={"Item"}
                    value={updateEvent.asset}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        asset: e,
                      });
                    }}
                    options={assetsArray
                      .map((ass: any) => {
                        return {
                          value: ass.assetId,
                          label: ass.tag,
                        };
                      })
                      .sort((a: any, b: any) => {
                        return naturalSort(a.label, b.label);
                      })}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <span>Facility Name</span>

                  <ReactSelect
                    isSearchable
                    isClearable
                    required
                    label={"Facility"}
                    value={updateEvent.location}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        location: e,
                        zone: null,
                      });
                      setFacilityZones(e?.value || null);
                    }}
                    options={Object.keys(facilities)
                      .map((key: any) => {
                        return {
                          value: key,
                          label: facilities[key].name,
                        };
                      })
                      .sort((a: any, b: any) => {
                        return naturalSort(a.name, b.name);
                      })}
                  />
                </Grid>

                {zones?.length > 0 ? (
                  <Grid item xs={12} sm={4}>
                    <span>Zone</span>
                    <ReactSelect
                      label={"Zone"}
                      value={updateEvent.zone?.value}
                      variant={"standard"}
                      margin="dense"
                      classes={classes}
                      onChange={(e: any) => {
                        setUpdateEvent({
                          ...updateEvent,
                          zone: e.value,
                        });
                      }}
                      options={zones.map((zone: any) => {
                        return {
                          value: zone,
                          label: zone.name,
                        };
                      })}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={4}>
                  <span>Event</span>
                  <ReactSelect
                    isSearchable
                    isClearable
                    required
                    label={"Item"}
                    value={updateEvent.event}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        event: e,
                      });
                    }}
                    options={eventTypes
                      .filter(
                        (e: any) =>
                          e !== "origin" &&
                          e !== "scan" &&
                          e !== "leaveGeoFence" &&
                          e !== "enterGeoFence"
                      )
                      .map((event: any) => {
                        return {
                          value: event,
                          label: event,
                        };
                      })
                      .sort((a: any, b: any) => {
                        return naturalSort(a.label, b.label);
                      })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type={"text"}
                    className={classes.input}
                    variant={"standard"}
                    margin="normal"
                    multiline={true}
                    rows={"1"}
                    label={"Bin Location"}
                    name={"binLocation"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        binLocation: e.target.value,
                      });
                    }}
                    value={updateEvent.binLocation}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type={"text"}
                    className={classes.input}
                    variant={"standard"}
                    margin="normal"
                    multiline={true}
                    rows={"1"}
                    label={"Notes"}
                    name={"note"}
                    autoComplete={"note"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        note: e.target.value,
                      });
                    }}
                    value={updateEvent.note}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                disabled={!isValid}
              >
                {"Submit"}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Update;
