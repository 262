import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function SimpleCheckbox(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked || false}
          onChange={(e) => {
            props.onChange(e);
          }}
          name={props.name ? props.name : ""}
          color={props.color ? props.color : "primary"}
        />
      }
      label={props.label ? props.label : ""}
    />
  );
}

export default SimpleCheckbox;
