export const parseUserInfo = (row: any, firstLast = false) => {
  if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
    return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""}${
      row.lastName || ""
    }`;
  }
  if (row.appUserId && (row.firstName || row.lastName)) {
    return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""}${
      row.firstName || ""
    }`;
  } else {
    return ``;
  }
  // else if (row.appUserId === "53ef1d1f-3e47-46e7-b444-18170051486f") {
  //   return `iOS User`;
  // } else if (row.appUserId === "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7") {
  //   return `Android User`;
  // } else {
  //   return ``;
  // }
};
