import { useRef, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SelectWithExternalLabel from "../../Forms/FieldTypes/SelectWithExternalLabel";
import TextFieldWithExternalLabel from "../../Forms/FieldTypes/TextFieldWithExternalLabel";
import MaterialUiButton from "../../Buttons/MaterialUiButton/MaterialUiButton";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1rem 0",
  },
  header:{
    fontWeight:'bold'
  },
  itemContainer: {
    margin: ".75rem 0",
  },
  widgetHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function EventNotificationOnCategorySettings(props: any) {
  const classes = useStyles();
  const { handleWidgetSettings, setPage, settings, organization = {} } = props;
  const { assetCategories = {}, eventTypesMap = {} } = organization;
  const [widgetSettings, setWidgetSettings] = useState({ ...settings });
  const widgetSettingsRef = useRef(settings);
  return (
    <>
      <Grid item xs={12}>
        <div className={classes.widgetHeader}>
          <h5 className={classes.header}>Configure</h5>
          <i
            className="fas fa-arrow-left"
            onClick={() => {
              setPage("widget");
            }}
          />
        </div>
      </Grid>
      {/* Category */}
      <Grid className={classes.itemContainer} item xs={12}>
        <SelectWithExternalLabel
          error={false}
          label="Category"
          id="category"
          onChange={(e: any) => {
            setWidgetSettings((prevState: any) => ({
              ...prevState,
              category: e.target.value,
            }));
          }}
          size="small"
          value={widgetSettings.category}
          options={assetCategories.map((item: any) => ({
            label: item.label,
            value: item.id,
          }))}
          variant="outlined"
        />
      </Grid>

      {/* Event */}
      <Grid className={classes.itemContainer} item xs={12}>
        <SelectWithExternalLabel
          error={false}
          label="Event"
          id="event"
          onChange={(e: any) => {
            setWidgetSettings((prevState: any) => ({
              ...prevState,
              event: e.target.value,
            }));
          }}
          size="small"
          value={widgetSettings.event}
          options={Object.keys(eventTypesMap).map((item: any) => ({
            label: item,
            value: item,
          }))}
          variant="outlined"
        />
      </Grid>

      {/* Title */}
      <Grid className={classes.itemContainer} item xs={12}>
        <TextFieldWithExternalLabel
          label="Set Widget Title"
          onChange={(event: any) =>
            setWidgetSettings((prevState: any) => ({
              ...prevState,
              title: event.target.value,
            }))
          }
          size="small"
          value={widgetSettings.title}
        />
      </Grid>

      {/* Buttons */}
      <Grid className={classes.itemContainer} item xs={12}>
        <div className={classes.button}>
          <MaterialUiButton
            color="cancel"
            fullWidth={true}
            label="CANCEL"
            onClick={() => {
              setPage("widget");
            }}
            size="small"
          />
        </div>
        <div className={classes.button}>
          <MaterialUiButton
            disabled={isEqual(widgetSettingsRef.current, widgetSettings)}
            fullWidth={true}
            label="SUBMIT"
            onClick={() => {
              handleWidgetSettings('EventNotificationOnCategory', widgetSettings)
              setPage("widget");
            }}
            size="small"
          />
        </div>
      </Grid>
    </>
  );
}
