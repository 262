import { useState, MouseEvent } from "react";

import { useSelector } from "react-redux";
import { isEqual, cloneDeep } from "lodash";

import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Avatar, Menu, MenuItem } from "@material-ui/core";
import AssetSelect from "./AssetSelect/AssetSelect";
import QuickLinks from "./QuickLinks";
import AddAssetButton from "./AddAssetButton";
import NotificationsByEventType from "../../Widgets/Notifications/NotificationsByEventType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: (props: any) => ({
      background: "#fafafa",
      padding: theme.spacing(1),
      height: props.appBarHeight,
      width: `calc(100% - ${props.sideNavWidth}px)`,
      marginLeft: props.sideNavWidth,
      justifyContent: "center",
    }),

    buttonContainer: {
      display: "flex",
      alignItems: "center",
    },

    line: {
      borderLeft: "1px solid #32355c",
      height: "45px",
      paddingRight: "15px",
    },

    toolbarContainer: {
      display: "flex",
      justifyContent: "space-between",
    },

    avatar: {
      cursor: "pointer",
      backgroundColor: "#3D5BA9",
      "&:hover": {
        backgroundColor: "#5884F5",
      },
    },
    menuPaper: {
      backgroundColor: "#414348",
      marginTop: "10px",
    },
    menuItem: {
      backgroundColor: "#414348",
      color: "#fefefe",
      "&:hover": {
        color: "#414348",
        backgroundColor: "#A0C46D",
      },
    },
  })
);

export default function AppBarLayout(props: any) {
  const { sideNavWidth = 120, appBarHeight = 64, apiUrl = "" } = props;

  const initials =
    localStorage.getItem("firstName")?.charAt(0).toUpperCase() &&
    localStorage.getItem("lastName")?.charAt(0).toUpperCase()
      ? `${localStorage
          .getItem("firstName")
          ?.charAt(0)
          .toUpperCase()}${localStorage
          .getItem("lastName")
          ?.charAt(0)
          .toUpperCase()}`
      : null;

  const classes = useStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userRoles = useSelector((state: any) => state.user.userActions);

  const appUserType = useSelector((state: any) => state.user.appUserType);

  const userPropertiesMap = cloneDeep(
    useSelector((state: any) => state.user.userPropertiesMap, isEqual)
  );

  const organization = useSelector(
    (state: any) => state.organization.organization,
    isEqual
  );
  const classifications = useSelector(
    (state: any) => state.organization.classifications,
    isEqual
  );
  const facilities = useSelector(
    (state: any) => state.organization.facilities,
    isEqual
  );

  const usersMap = useSelector(
    (state: any) => state.organization.usersMap,
    isEqual
  );
  const usersConsoleRole = useSelector(
    (state: any) => state.user.usersConsoleRole
  );

  const userId = useSelector((state: any) => state.user.appUserId);
  const token = useSelector((state: any) => state.user.token);
  const timeZone = useSelector(
    (state: any) => state.user.userPropertiesMap.propertiesMap.timeZone,
    isEqual
  );
  const batches = useSelector(
    (state: any) => state.organization.batches,
    isEqual
  );
  const products = useSelector(
    (state: any) => state.organization.products,
    isEqual
  );

  const extraProps = {
    apiUrl,
    appUserType,
    batches,
    usersConsoleRole,
    classifications,
    facilities,
    organization,
    organizationId: organization.organizationId,
    products,
    timeZone,
    token,
    userId,
    userPropertiesMap,
    usersMap,
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbarContainer}>
        <QuickLinks />
        <AssetSelect />

        {appUserType === "Product" ? null : (
          <NotificationsByEventType
            {...extraProps}
            useWidget={true}
            appUserType={appUserType}
          />
        )}

        {/* Only visible for asset or product/asset users */}
        {userRoles["Create Assets"] && appUserType !== "Product" ? (
          <AddAssetButton {...extraProps} />
        ) : null}

        <Avatar onClick={(e: any) => handleClick(e)} className={classes.avatar}>
          {initials}
        </Avatar>
        <Menu
          classes={{ paper: classes.menuPaper }}
          id="simple-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push("/settings/user");
              handleClose();
            }}
          >
            View Account Information
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push("/logout");
              handleClose();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
