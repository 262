import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { Grid, makeStyles } from "@material-ui/core";

import { showAlert } from "../../../../globalStore/slices/alert/alertSlice";
import ControlledTable from "../../../../components/Tables/ControlledTable/ControlledTable";

import FilterMenu from "./FilterMenu/FilterMenu";
import { fetchMOAssets } from "./api";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
}));

export default function AssemblyStatusTable(props: any) {
  const { timeZone, organization, organizationId, token, apiUrl } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatchGlobal = useDispatch();

  const [view, setView] = useState({
    filterView: false,
  });

  const filterDefaults = {
    assetIds: [],
    category: "MO",
    createDate: null,
    createDateEnd: null,
    needDate: null,
    needDateEnd: null,
    lastEvents: [],
    limit: 25,
    sorted: [
      {
        id: "tag",
        desc: true,
      },
    ],
    start: 0,

    tz: timeZone,
  };

  const [state, setState] = useState({
    filters: filterDefaults,
    assets: [],
    count: 0,
    page: 0,
  });

  const callbackFetchMOs = useCallback(
    () =>
      fetchMOAssets(props, state.filters).then((res) => {
        if (res.error) {
          return dispatchGlobal(showAlert({ type: "error", text: res.error }));
        }
        setState((s: any) => {
          return {
            ...s,
            assets: res.assets || [],
            count: res.count || 0,
          };
        });
      }),
    [dispatchGlobal, props, state.filters]
  );

  useEffect(() => {
    callbackFetchMOs();
  }, [state.filters, callbackFetchMOs]);

  const onClick = (row: any) => {
    const { assetId = "" } = row;
    if (assetId) {
      const url = `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

  const tableColumns = [
    {
      Header: "MO",
      id: "tag",
      accessor: "tag",
      Cell: (data: any) => {
        const { tag = "" } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {tag || ""}
          </div>
        );
      },
    },
    {
      Header: "Assembly",
      id: "assembly",
      disableSortBy: true,
      accessor: (row: any) => row.propertiesMap?.description || "",
      Cell: (data: any) => {
        const { propertiesMap = {} } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {propertiesMap?.description ? propertiesMap.description : ""}
          </div>
        );
      },
    },
    {
      Header: "Create Date",
      id: "timeCreatedUser",
      accessor: (row: any) =>
        row.timeCreatedUser
          ? moment(row.timeCreatedUser, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
          : "",
      Cell: (data: any) => {
        const { timeCreatedUser = "" } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {timeCreatedUser
              ? moment(timeCreatedUser, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Need Date",
      id: "timeNeeded",
      accessor: (row: any) =>
        row.timeNeeded
          ? moment(row.timeNeeded, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
          : "",
      Cell: (data: any) => {
        const { timeNeeded = "" } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {timeNeeded
              ? moment(timeNeeded, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "lastEvent",
      id: "lastEvent",
      Cell: (data: any) => {
        const { lastEvent = "" } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {lastEvent || ""}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <FilterMenu
        setState={setState}
        state={state}
        filterDefaults={filterDefaults}
        setView={setView}
        view={view}
        apiUrl={apiUrl}
        token={token}
        organizationId={organizationId}
        organization={organization}
      />
      <Grid container id={"assemblyStatusTable"}>
        <Grid item xs={12}>
          <h5>Current MO's</h5>
          <ControlledTable
            state={state}
            setState={setState}
            data={state.assets || []}
            columns={tableColumns}
            defaultSort={[{ id: "tag", desc: true }]}
            controlledCount={state.count || 0}
            controlledPageCount={Math.ceil(state.count / state.filters?.limit)}
            controlledPageIndex={state.page}
            controlledPageSize={state.filters?.limit}
            totalLabel={"Total MO's"}
            toolbarChildren={
              <Grid
                className={classes.barChildrenContainer}
                container
                alignItems={"center"}
              >
                <Grid className={classes.barChildren} item xs={3} xl={2}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setView((v: any) => {
                        return {
                          filterView: !v.filterView,
                        };
                      })
                    }
                  >
                    <div className="exportIcon">
                      {`Filters`}
                      <i className="fas fa-filter ml-2"></i>
                    </div>
                  </span>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
