import XLSX from "xlsx";
import { createPreflightCheck } from "../API/preflight";

const spreadSheetHeaders = [
  "Instructions",
  "Name",
  "Type",
  "Address",
  "City",
  "State",
  "Zip Code",
  "Notes (optional)",
  "Latitude",
  "Longitude",
  "Radius (m)",
];

export const downloadTemplate = (locationTypes) => {
  let typesArr = [];
  locationTypes.map((el) => {
    return typesArr.push(` ${el.trim()}`);
  });
  const SpreadsheetTemplate = [
    {
      Instructions:
        "Fill out this spreadsheet and import to upload a list of new facilities. Please do not alter the spreadsheet headers in any way, or add/remove headers. Please make sure to select only one facility type from the list, and to write it in exactly as it is shown (case-sensitive). If you need to add a new facility type, you can do so in the global settings page.",
      Name: "",
      Type: `Delete all text and keep only one of the following: ${typesArr}`,
      Address: "",
      City: "",
      State: "",
      "Zip Code": "",
      Latitude: "",
      Longitude: "",
      "Radius (m)": "",
      "Notes (optional)": "",
    },
  ];

  const fileName = `FacilitiesImportTemplate.xlsx`;
  const ws = XLSX.utils.json_to_sheet(SpreadsheetTemplate);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};

export const validateAndFormatSpreadsheet = (spreadsheet, props, response) => {
  const defaultFacilityRadius =
    props.organization?.propertiesMap?.defaultFacilityRadius / 1000 || 0.4;

  const XLSX = require("xlsx");

  const locationTypesList = props.locationTypes.map((el) => {
    return el.trim();
  });

  const readFile = (file, onLoadCallback) => {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsArrayBuffer(file);
  };

  readFile(spreadsheet, (e) => {
    // use result in callback...
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheet_name_list = workbook.SheetNames;

    const parsedSheet = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );

    let message = "";
    let success = true;
    let formattedSpreadsheetRows = [];

    if (
      Object.keys(parsedSheet[0]).some(
        (header) => !spreadSheetHeaders.includes(header)
      )
    ) {
      message =
        "Some header(s) found in imported spreadsheet that do not exist on the template. Please make sure the column headers in the uploaded spreadsheet mirror the template exactly (case-sensitive).";
      success = false;
    } else {
      const results = parsedSheet.map(async (row, idx) => {
        const rowNum = idx + 2;
        const name = row["Name"]
          ? row["Name"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const facilityType = row["Type"]
          ? row["Type"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r|,)/gm, "")
          : "";
        const address1 = row["Address"]
          ? row["Address"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";

        const city = row["City"]
          ? row["City"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const state = row["State"]
          ? row["State"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";

        const postalCode = row["Zip Code"]
          ? row["Zip Code"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const locationDetails = row["Notes (optional)"]
          ? row["Notes (optional)"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const latitude = row["Latitude"]
          ? row["Latitude"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : null;
        const longitude = row["Longitude"]
          ? row["Longitude"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : null;
        const radius = row["Radius (m)"]
          ? row["Radius (m)"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : null;

        // Here we check to verify the radius field only contains integers
        let isNum = /^\d+$/.test(radius);
        if (radius !== null && !isNum) {
          message = `Error on row ${rowNum}: Please make sure the radius fields are numbers only. `;
          success = false;
          return;
        }

        if (
          name === "" ||
          facilityType === "" ||
          ((!latitude || !longitude) &&
            (address1 === "" ||
              city === "" ||
              state === "" ||
              postalCode === ""))
        ) {
          message = `Error on row ${rowNum}: Please make sure the following required fields are filled out for all facilities: Name, Type. If not specifying latitude and longitude, Address, City, State, and Postal code are required.`;
          success = false;
          return;
        } else if (!locationTypesList.includes(facilityType)) {
          message = `Error on row ${rowNum}: A facility type is present in the imported document that does not exist on the template. Please make sure the facility type in the imported document mirrors the options on the template exactly (case-sensitive), and that only one option is listed. If you want to add a new facility type, you can do so in global settings.`;
          success = false;
          return;
        }
        if (address1 && (!latitude || !longitude)) {
          // address preflight check
          await createPreflightCheck(props, {
            radius: 0.01,
            name: name,
            location: { address1: address1, city: city, state: state },
          }).then((res) => {
            if (res.error) {
              message = `Error found on row ${rowNum}: ${res.error}`;
              success = false;
              return;
            }
          });
        } else if (latitude && longitude) {
          const validateLat =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          const validateLong =
            /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          if (!validateLat.test(parseInt(latitude).toFixed(5))) {
            message = `Please enter a valid latitude on row ${rowNum}.`;
            success = false;
            return;
          }
          if (!validateLong.test(parseFloat(longitude).toFixed(5))) {
            message = `Please enter a valid longitude on row ${rowNum}.`;
            success = false;
            return;
          }
          // lat long centroid string preflight check
          const centroidString = `POINT(${parseFloat(longitude).toFixed(
            5
          )} ${parseFloat(latitude).toFixed(5)})`;
          await createPreflightCheck(props, {
            radius: 0.01,
            name: name,
            centroidString: centroidString,
          }).then((res) => {
            if (res.error) {
              message = `Error found on row ${rowNum}: ${res.error}`;
              success = false;
              return;
            }
          });
        }

        // passed all tests! push row to formatted spreadsheet. Set lat/long to null since Jeff's got something on the backend to find lat/long of an address
        formattedSpreadsheetRows.push({
          name: name,
          "address 1": address1,
          "address 2": "",
          city: city,
          "state/province": state,
          "postal code": postalCode,
          country: "",
          facilityType: facilityType,
          latitude: latitude ? parseFloat(latitude).toFixed(5) : null,
          longitude: longitude ? parseFloat(longitude).toFixed(5) : null,
          radius: radius ? radius / 1000 : defaultFacilityRadius,
          locationDetails: locationDetails,
        });
      });
      Promise.all(results).then((completed) => {
        // build new spreadsheet from formattedSpreadSheetRows
        const fileName = `FacilitiesFormatted.xlsx`;
        const ws = XLSX.utils.json_to_sheet(formattedSpreadsheetRows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        // XLSX.writeFile(wb, fileName);
        // write ArrayBuffer of file
        const rawFile = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: false,
          type: "array",
        });

        // construct javascript File object from Array Buffer, send in response callback
        const file = new File([rawFile], fileName);

        file.path = fileName;

        response({
          success: success,
          message: message,
          spreadSheet: wb,
          file: file,
        });
      });
    }
  });
};
