import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { onLogout } from "../globalStore/slices/user/userSlice";
import AuthLayout from "../components/Layout/AuthLayout";
import { useSelector } from "react-redux";
import * as Pages from "../pages/index";

import { userSelectors } from "../globalStore/slices/user/userSlice";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const AuthContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setAuth } = props;
  const { appUserTypeSelector, viewPermissionsSelector, checkSessionSelector } =
    userSelectors;

  // auth and routing data
  const appUserType = useSelector(appUserTypeSelector);
  const viewPermissions = useSelector(viewPermissionsSelector);
  const checkSession = useSelector(checkSessionSelector, isEqual);

  const {
    showAssetHistory,
    showBatches,
    showDevices,
    showFacilities,
    showOrganizations,
    showProducts,
    showSettings,
    showUsers,
  } = viewPermissions;

  // KEEP THESE GLOBAL STATE OBJECTS IN TOP LEVEL AND PASS THROUGH PROPS, AS A RE-RENDER WOULD NOT BE UNDESIRABLE IF THESE EVER CHANGED
  const appUserId = useSelector((state) => state.user.appUserId);
  const token = useSelector((state) => state.user.token);
  const organizationId = useSelector((state) => state.user.organizationId);
  const userActions = useSelector((state) => state.user.userActions, isEqual);
  const usersConsoleRole = useSelector((state) => state.user.usersConsoleRole);
  const timeZone = useSelector(
    (state) => state.user.userPropertiesMap.propertiesMap.timeZone,
    isEqual
  );

  const logout = () => {
    dispatch(onLogout());
    setAuth((auth) => {
      return {
        ...auth,
        isAuthed: false,
      };
    });
    history.push("/login");
  };

  // Redux selectors take two arguments, the selector function argument (e.g., state=>state.organization.facilities[facilityId])) and an optional equality function (default equality function is shallow comparison, which doesn't work with arrays or objects)

  const PrivateRoute = ({
    component: Component,
    isBlocked = false,
    ...rest
  }) => {
    if (isBlocked) {
      return (
        <Route
          {...rest}
          render={() => (
            <div>
              You are not authorized to view {rest.location.pathname || "this"}{" "}
              in LXConnect. Please contact your system administator and inquire
              about your permissions in LXConnect.
            </div>
          )}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Component
              {...props}
              apiUrl={apiUrl}
              appUserType={appUserType}
              key={rest.location.pathname}
              organizationId={organizationId}
              timeZone={timeZone}
              token={token}
              userId={appUserId}
              userRoles={userActions}
              usersConsoleRole={usersConsoleRole}
            />
          )}
        />
      );
    }
  };

  const dashboardPicker = (role) => {
    switch (role) {
      case "Product":
        return Pages.ProductDashboard;
      case "Asset-Operations":
        return Pages.AssetOperationsDashboard;
      default:
        return Pages.Dashboard;
    }
  };

  // as long as the session is valid, render auth container
  return checkSession.sessionValid ? (
    <AuthLayout apiUrl={apiUrl}>
      <Switch>
        <PrivateRoute
          exact
          path={["/", "/home"]}
          component={dashboardPicker(appUserType)}
        />
        <PrivateRoute
          exact
          path="/generateCQR/"
          component={Pages.GenerateCQR}
        />

        <PrivateRoute
          exact
          path="/assetHistory"
          component={Pages.AssetHistory}
          isBlocked={!showAssetHistory}
        />
        <PrivateRoute
          exact
          path="/assetStatus"
          component={Pages.AssetStatus}
          isBlocked={!showAssetHistory}
        />
        <PrivateRoute
          exact
          path="/assetSnapshot/:assetId"
          component={Pages.AssetSnapshot}
          isBlocked={!showAssetHistory}
        />

        <PrivateRoute
          exact
          path="/batches"
          component={Pages.Batches}
          isBlocked={!showBatches}
        />

        <PrivateRoute
          exact
          path="/batches/:batchId/history/:assetType*"
          component={Pages.BatchHistory}
          isBlocked={!showBatches}
        />

        <PrivateRoute
          exact
          path="/organizations"
          component={Pages.Organizations}
          isBlocked={!showOrganizations}
        />

        <PrivateRoute
          exact
          path="/organizations/:organizationId"
          component={Pages.EditOrganization}
          isBlocked={!showOrganizations}
        />

        <PrivateRoute
          exact
          path="/facilities"
          component={Pages.Facilities}
          isBlocked={!showFacilities}
        />

        <PrivateRoute
          exact
          path="/products"
          component={Pages.Products}
          isBlocked={!showProducts}
        />

        <PrivateRoute
          exact
          path="/devices"
          component={Pages.Devices}
          isBlocked={!showDevices}
        />

        <PrivateRoute
          exact
          path="/settings"
          component={Pages.Settings}
          isBlocked={!showSettings}
        />

        <PrivateRoute
          exact
          path="/settings/:page"
          component={Pages.Settings}
          isBlocked={!showSettings}
        />
        <PrivateRoute
          exact
          path="/settings/:page/:tab"
          component={Pages.Settings}
          isBlocked={!showSettings}
        />

        <PrivateRoute
          exact
          path="/users"
          component={Pages.Users}
          isBlocked={!showUsers}
        />

        <PrivateRoute
          exact
          path="/users/:appUserId"
          component={Pages.EditUser}
          isBlocked={!showUsers}
        />
        <Route path="/logout" render={() => logout()} />
      </Switch>
    </AuthLayout>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};

export default AuthContainer;
