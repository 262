import { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: "2rem",
    justifyContent: "space-around",
  },
  textContainer: {
    textAlign: "center",
  },
}));

export default function GenerateCQR(props: any) {
  const classes = useStyles();
  const [isRedirect, setIsRedirect] = useState(false);
  const { assetIds } = props

  return (
    <Grid container>
      {isRedirect ? (
        <Redirect
          to={{
            pathname: "/generateCQR/",
            state: { selectedAssets: assetIds },
          }}
        />
      ) : (
        ""
      )}

      <Grid className={classes.textContainer} item xs={12}>
        <span>
          Would you like to generate CQRs for all recently imported assets?
        </span>
      </Grid>
      <Grid className={classes.buttonContainer} item xs={12}>
        <MaterialUiButton
          color="cancel"
          label="cancel"
          onClick={() => {
            props.onHide()
          }}
        />
        <MaterialUiButton
          label="YES"
          onClick={() => {
            setIsRedirect(true)
          }}
        />
      </Grid>
    </Grid>
  );
}
