import XLSX from "xlsx";
import moment from "moment-timezone";
import { getAssetsById } from "../api";
import _ from "lodash";
import { formatLatLon } from "../../../../utils/lat_lon_utils";

import { utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";

function xlSerialToJsDate(xlSerial) {
  return new Date(
    -2209075200000 + (xlSerial - (xlSerial < 61 ? 0 : 1)) * 86400000
  );
}

export const exportSpreadsheet = async (
  histories,
  facilities,
  timeZone,
  props
) => {
  const {
    apiUrl,
    classifications = {},
    organizationId,
    products = {},
    token,
    zones = {},
  } = props;

  const dateTimeHeader = `Date/Time${
    timeZone && timeZone.value
      ? ` (${timeZone.value})`
      : timeZone
      ? ` (${timeZone})`
      : moment.tz.guess()
  }`;
  let assets = {};

  let assetIds = [
    ...new Set(
      histories.map((history) => {
        if (history.assetId) {
          return `asset_id:${history.assetId}`;
        }
        return null;
      })
    ),
  ];
  if (assetIds.length >= 1000) {
    if (
      !window.confirm(
        `Can not export information for more than 1,000 assets at once. This export contains information for ${assetIds.length} assets, and so your export will not have additional asset data. Do you still wish to continue?`
      )
    ) {
      return null;
    }
  }
  assets = await getAssetsById(
    { apiUrl, token, organizationId },
    assetIds.join(" OR "),
    1000
  );

  assets = assets.assets
    ? assets.assets.reduce((x, y) => {
        return {
          ...x,
          [y.assetId]: y,
        };
      }, {})
    : {};

  const json = histories.map(async (history) => {
    const { propertiesMap = {}, classificationItemMap = {} } = history;

    const classificationOptions =
      !_.isEmpty(classifications?.active) && classificationItemMap
        ? Object.keys(classifications.active)
            .map((classKey) => {
              const { classificationId, name } =
                classifications.active[classKey];

              const mapKey = Object.keys(classificationItemMap).filter(
                (k) => classificationItemMap[k].parentId === classificationId
              )[0];

              return {
                [name]: classificationItemMap[mapKey]?.name || "",
              };
            })
            .reduce((x, y) => {
              return {
                ...x,
                ...y,
              };
            }, {})
        : {};

    let formData = {};
    let customDataFields = {};

    if (propertiesMap.formData) {
      formData = propertiesMap.formData
        .filter((f) => f.fieldKey !== "note")
        .reduce((x, y) => {
          return {
            ...x,
            [_.startCase(y.fieldKey || "NO KEY")]: y.fieldValue,
          };
        }, {});
    }

    const { assetId = "", productId = "" } = history;
    const itemLevelDataElements =
      products[productId]?.propertiesMap?.itemLevelDataElements?.reduce(
        (x, y) => {
          return {
            ...x,
            [y.id]: y,
          };
        },
        {}
      ) || {};

    const assetFields =
      assets[assetId] && assets[assetId].propertiesMap
        ? assets[assetId].propertiesMap
        : {};

    customDataFields = Object.keys(assetFields)
      .map((k) => {
        if (k === "assetDetails") {
          return { label: "Asset Details", value: assetFields[k] };
        }
        if (itemLevelDataElements[k]?.dataType === "Date") {
          return {
            label: k,
            value:
              moment
                .utc(xlSerialToJsDate(assetFields[k]))
                .format("MM/DD/YYYY") || assetFields[k],
          };
        }
        return { label: k, value: assetFields[k] };
      })
      .filter(
        (e) =>
          e.label !== "note" &&
          e.label !== "formData" &&
          e.label !== "kmPerHour" &&
          e.label !== "distance" &&
          e.label !== "deltaTimeHrs" &&
          e.label !== "formId"
      )
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    if (customDataFields.length > 0) {
      customDataFields = customDataFields.reduce((x, y) => {
        return {
          ...x,
          [_.startCase(y.label)]: y.value,
        };
      }, {});
    }

    const renderedTime = utc_to_local_12_hour_clock(
      history.timeOfLog,
      timeZone
    );

    return {
      [dateTimeHeader]: renderedTime ? renderedTime : "DNP",
      "Asset Tag": history.assetTag ? history.assetTag : "DNP",
      Event: history.event ? history.event : "DNP",
      User: `${history.lastName || ""}${
        history.firstName ? `, ${history.firstName}` : ""
      }`,
      "Facility Name":
        !history.latitude || !history.longitude
          ? "DNP"
          : history.facilityId &&
            facilities[history.facilityId] &&
            facilities[history.facilityId].name
          ? facilities[history.facilityId].name
          : "Not Registered",
      Zone: zones[history.zone?.zoneId]?.name || "",
      "Bin Location": history.zone?.binLocation || "",
      State: history.state ? history.state : "DNP",
      "Latitude, Longitude":
        history.latitude && history.longitude
          ? formatLatLon(history.latitude, history.longitude)
          : "DNP",
      Category: customDataFields.Category ? customDataFields.Category : "",
      Notes:
        history.propertiesMap && history.propertiesMap.note
          ? history.propertiesMap.note
          : "",
      ...customDataFields,
      ...formData,
      ...classificationOptions,
    };
  });

  const currentTime = moment()
    .tz(
      timeZone && timeZone.value
        ? timeZone.value
        : timeZone
        ? timeZone
        : moment.tz.guess()
    )
    .format("YYYY-MM-DD");

  return Promise.all(json).then((data) => {
    const fileName = `Asset_History_${currentTime}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName);
    return true;
  });
};
