import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { retrieveStatus, saveUserWidgetSettings } from "./api";
import { updateWidgetSettings } from "../../../globalStore/slices/user/userSlice";
import { userSelectors } from "../../../globalStore/slices/user/userSlice";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import EventNotificationOnCategorySettings from "./EventNotificationOnCategorySettings";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  countText: {
    fontSize: "5rem",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  gridContainer: {
    display: "flex",
    height: "100%",
  },
  header: {
    fontWeight: "bold",
  },
  subText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
  },
  widgetHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function EventNotificationOnCategory(props: any) {
  const classes = useStyles();
  const userPropertiesMap = useSelector(
    userSelectors.userPropertiesMapSelector,
    _.isEqual
  );
  const {
    widgetSettings = {
      category: "MO",
      event: "Shortage Identified",
      title: "",
    },
  } = userPropertiesMap.propertiesMap;
  const [settings, setSettings] = useState({
    ...widgetSettings.EventNotificationOnCategory,
  });
  const [page, setPage] = useState("widget");
  const [count, setCount]: any = useState(0);
  const dispatchGlobal = useDispatch();

  useEffect(() => {
    const currentDate = moment().startOf("day").utc().format();
    retrieveStatus({ ...props }, settings, currentDate).then((res: any) => {
      if (res.count) {
        setCount(Object.values(res.count)[0]);
      }
    });
  });

  const handleWidgetSettings = (widget: string, widgetSettings: any) => {
    saveUserWidgetSettings(props, widgetSettings).then(() => {
      dispatchGlobal(
        updateWidgetSettings({ widget, settings: widgetSettings })
      );
      setSettings(widgetSettings);
    });
  };

  return (
    <Grid className={classes.gridContainer} container>
      {page === "widget" ? (
        <>
          <Grid item xs={12}>
            <div className={classes.widgetHeader}>
              <h5 className={classes.header}>
                {settings.title && settings.title.length > 0
                  ? settings.title
                  : settings.category
                  ? `${settings.category}'s`
                  : "Asset Category"}
              </h5>
              <i
                className="fas fa-ellipsis-h pointer"
                onClick={() => {
                  setPage("settings");
                }}
              />
            </div>
            <div>
              <span>{settings.event ? settings.event : "Event"}</span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.countText}>{count}</span>
          </Grid>
          <Grid className={`${classes.dateContainer}`} item xs={12}>
            <span className={classes.subText}>Currently</span>
          </Grid>
        </>
      ) : (
        <EventNotificationOnCategorySettings
          {...props}
          handleWidgetSettings={handleWidgetSettings}
          setPage={setPage}
          settings={settings}
        />
      )}
    </Grid>
  );
}
