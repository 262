import { useState } from "react";
import request from "superagent";

import { Grid } from "@material-ui/core";
import { Dropzone } from "../../../../components/Dropzone/Dropzone";
import { downloadTemplate, validateAndFormatSpreadsheet } from "./xlsx";

import Loading from "../../../../components/Loading/Loading";

export default function ImportILDE(props: any) {
  const [accepted, setAccepted] = useState(false);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const uploadFile = (file: any) => {
    setLoading(true);

    const jsondata =
      props.newBatch.isPCA && props.pcaId
        ? {
            productId: props.newBatch.product.id,
            pcaId: props.pcaId,
          }
        : {
            productId: props.newBatch.product.id,
            pcaId: null,
          };

    request
      .post(`${props.apiUrl}assets/import`)
      .set("auth-token", props.token)
      .field("jsondata", JSON.stringify(jsondata))
      .attach("filedata", file)
      .then((response) => {
        setLoading(false);
        const { success, numAdded } = response.body;
        if (success && numAdded > 0) {
          props.setConfirm({
            modalShow: true,
            text: `Batch creation success! ${numAdded} items added.`,
            error: false,
          });
          setFile({});
          setAccepted(false);
          props.onSuccess();
        } else {
          props.setConfirm({
            modalShow: true,
            text: `Uh-oh! Something went wrong. Did you follow the example spreadsheet?`,
            error: true,
          });
        }
      });
  };

  return (
    <>
      {loading ? <Loading opaque color="#5884F5" /> : ""}

      <form
        onSubmit={(e) => {
          uploadFile(file);
          e.preventDefault();
        }}
      >
        <Grid container>
          <Grid item className="text-center mt-1">
            <p>
              Download and fill out Excel file template, and then upload the
              file below:{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  downloadTemplate(props, setLoading);
                }}
                href="/#"
              >
                ILDEImportTemplate.xlsx
              </a>
              <br />
              <sub>
                - Please define the unit of measure in the row immediately below
                the headers of the spreadsheet, this will then be the Unit of
                Measure for each row below until a different unit of measure is
                entered. This must be a unit of measure that is currently in
                LXConnect, or the upload will fail. <br />
                - The spreadsheet is designed so that it will automatically fill
                and create data for Item Tags that are left blank. Depending on
                your preferences, you can also define the individual item tags
                in a way that best suits your needs. <br />- In the red columns,
                please enter data for each unique ILDE that you would like to
                record in LXConnect, any blank cells will not be entered into
                the system.
              </sub>
            </p>
            <Dropzone
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
              acceptText="Drag 'n' drop or click to select file (only *.xlsx files will be accepted)"
              callback={(acceptedFiles: any, accepted: Function) => {
                setAccepted(false);
                validateAndFormatSpreadsheet(
                  acceptedFiles[0],
                  props,
                  (cb: any) => {
                    if (!cb.success) {
                      props.setConfirm({
                        modalShow: true,
                        text: `${cb.message}`,
                        error: true,
                      });
                      accepted(false);
                    } else {
                      setAccepted(true);
                      setFile(cb.file);
                      accepted(true);
                    }
                  }
                );
              }}
              clearFile={() => {
                setFile({});
                setAccepted(false);
              }}
            />
          </Grid>
          <Grid item xs={12} className="text-center mt-2">
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={accepted ? false : true}
            >
              Submit
            </button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
