import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme: any) => ({
  paper: {
    overflowX: "hidden",
  },
}));

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ModalDialog(props: {
  title?: string;
  handleClose: () => any;
  open: boolean;
  isTabs?: boolean;
  content: any;
  fullScreen?: boolean;
  dialogActions?: any;
}) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        onClose={(e: any) => {
          e.preventDefault();
          props.handleClose();
        }}
        aria-labelledby={props.title}
        open={props.open}
        classes={{ paper: classes.paper }}
        fullScreen={props.fullScreen}
      >
        {props.open ? (
          <>
            {!props.isTabs ? (
              <DialogTitle onClose={props.handleClose}>
                {props.title}
              </DialogTitle>
            ) : null}
            <DialogContent dividers>{props.content}</DialogContent>
            {props.dialogActions ? (
              <DialogActions>{props.dialogActions}</DialogActions>
            ) : null}
          </>
        ) : null}
      </Dialog>
    </>
  );
}

export default ModalDialog;
