import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import _ from "lodash";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

type option = {
  id: string;
  header: string;
  checked: boolean;
};

type optionObj = {
  [id: string]: option;
};

type props = {
  options: optionObj;
  setOptions: (options: any) => any;
  handleSave: (options: any, columName?: any) => any;
};

export default function DisplayColumnsDropdown(props: props) {
  const [anchorEl, setAnchorEl] = useState(null);

  // options set on props
  const { options, setOptions, handleSave } = props;

  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid className={`${classes.barChildren} pointer`} item xs={6} xl={4}>
      <div className="exportIcon"></div>
      <Typography
        variant="subtitle1"
        aria-controls="display-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className="exportIcon">
          Display Columns
          <i className={`fas fa-chevron-down ${classes.openMenuIcon}`}></i>
        </div>
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id="display-columns"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {!_.isEmpty(options)
          ? Object.keys(options).map((id: string, index: number) => {
              return (
                <MenuItem key={`${id}-${index}`}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options[id].checked || false}
                          color="primary"
                          name={id}
                          onChange={(event) => {
                            const name: string = event.target.name;
                            const checked = options[name].checked || false;
                            setOptions((s: optionObj) => {
                              //update displayColumnOptions in users properties map and return optional name string
                              handleSave(
                                {
                                  ...s,
                                  [name]: {
                                    ...s[name],
                                    checked: !checked,
                                  },
                                },
                                name
                              );

                              return {
                                ...s,
                                [name]: {
                                  ...s[name],
                                  checked: !checked,
                                },
                              };
                            });
                          }}
                        />
                      }
                      label={options[id].header}
                    />
                  </FormGroup>
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </Grid>
  );
}
