import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core";
import DailyAssemblyPieChartSettings from "./DailyAssemblyPieChartSettings";
import { retrieveStatuses } from "./api";
import moment from "moment";
import { BrandColorArray } from "../../../styles/BrandColors";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-between",
    paddingBottom: "1rem",
  },
  subText: {
    color: theme.palette.text.secondary,
  },
}));

export default function DailyAssemblyEventPieChart(props: any) {
  const { organization } = props;
  const { eventTypesMap = {} } = organization;
  const classes = useStyles();
  // const [viewSettings, setViewSettings] = useState(false);
  const [list, setList] = useState([]);
  const [data, setData] = useState({
    datasets: [
      {
        data: [2000, 4000, 2850, 666],
        backgroundColor: BrandColorArray,
      },
    ],
    labels: ["Red", "Blue", "Yellow"],
  });

  // Sets the list
  useEffect(() => {
    let set: any = [];

    Object.keys(eventTypesMap).forEach((event: any) => {
      set.push({ category: "MO", event });
    });

    setList(set);
  }, [eventTypesMap]);

  // Grabs the status
  useEffect(() => {
    const currentDate = moment().startOf("day").utc().format();
    retrieveStatuses(props, list, currentDate).then((res: any) => {
      const data: any = [];
      const labels: any = [];

      if (res.success && res.count) {
        Object.keys(res.count).forEach((item: any) => {
          const regex = /(?<=last_event:).*(?=\sAND)/;
          const match: any = item.match(regex) ? item.match(regex)[0] : [];
          if (res.count[item] > 0) {
            data.push(res.count[item]);

            // Regex replaceAll to remove any escape characters that might of been returned
            labels.push(match.replaceAll("\\ ", " "));
          }
        });
        setData((prevState: any) => ({
          datasets: [
            {
              data,
              backgroundColor: BrandColorArray,
            },
          ],
          labels,
        }));
      }
    });
  }, [list, props]);

  return (
    <Grid container>
      <Grid className={classes.header} item xs={12}>
        <div>
          <h4>MO Statuses</h4>
          <span className={classes.subText}>Today</span>
        </div>

        {/* TODO: Commenting out the settings page for the current sprint */}
        {/* <i
          className="fas fa-ellipsis-h"
          onClick={() => {
            setViewSettings((prevState) => !prevState);
          }}
        /> */}
      </Grid>
      <Grid item xs={12} style={{ height: "35vh" }}>
        {/* TODO: change this to the boolean for the settings page */}
        {false ? (
          <DailyAssemblyPieChartSettings />
        ) : (
          <Pie
            type="pie"
            data={data}
            options={{
              onHover: function (event: any) {
                event.native.target.style.cursor = "default";
              },
              hoverOffset: 4,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  onHover: function (event: any, legendItem: any) {
                    if (legendItem) {
                      event.native.target.style.cursor = "pointer";
                    }
                  },
                  position: "right",
                  labels: {
                    usePointStyle: true,
                  },
                },
              },
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
