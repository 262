import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SimpleSelect from "../../../components/Forms/FieldTypes/Select";

import { submitUpdateEvent } from "../api";

interface FacilityItem {
  value: string;
  label: string;
}
interface Option {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  formArea: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    // minHeight: "50vh",
    maxHeight: "43vh",
    overflow: "auto",
  },
  submit: {
    marginTop: theme.spacing(4),
  },
  select: {
    marginTop: theme.spacing(1),
    width: "90%",
  },
  input: {
    width: "90%",
  },
}));
function Update(props: any) {
  const {
    apiUrl,
    assetData,
    eventTypes,
    facilities,
    setModal,
    setState,
    state,
    token,
    userId,
  } = props;

  const classes = useStyles();
  const [updateEvent, setUpdateEvent] = useState<any>({
    location: "",
    event: "",
    note: "",
    zone: "",
  });

  const [facilityOptions, setFacilityOptions] = useState<any>([]);

  useEffect(() => {
    const facilityItems: FacilityItem[] = [];

    Object.keys(facilities)
      .sort((a, b) => {
        if (facilities[a].name && facilities[b].name) {
          return facilities[a].name.localeCompare(facilities[b].name);
        }
        return -1;
      })
      .forEach((key) => {
        facilityItems.push({
          value: key,
          label: facilities[key].name,
        });
      });
    setFacilityOptions(facilityItems);
  }, [facilities]);

  const [zones, setZones] = useState<any>([]);

  const setFacilityZones = async (facilityId: string | null) => {
    if (!facilityId) {
      setZones([]);
    } else {
      const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }).then((res) => res.json());

      if (results.success && results.facility?.zones?.length > 0) {
        setZones(results.facility.zones);
      } else {
        setZones([]);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Update Event</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitUpdateEvent(
                  { apiUrl, token, facilities, userId, assetData },
                  updateEvent
                ).then((res) => {
                  if (res.error) {
                    setModal({
                      modalShow: true,
                      text: `Uh-oh! Something went wrong while updating asset event... ${res.error}`,
                      isError: true,
                    });
                  } else {
                    setModal({
                      modalShow: true,
                      text: `Event update success!`,
                      isError: false,
                    });

                    setState({
                      ...state,
                      assetData: {
                        ...res.asset,
                        lastEvent: res.assetHistory.event,
                        propertiesMap: {
                          ...res.asset.propertiesMap,
                          note: res.assetHistory.propertiesMap.note,
                        },
                      },
                      histories: {
                        ...state.histories,
                        assetHistories: [res.assetHistory].concat(
                          state?.histories?.assetHistories || []
                        ),
                        count: state.histories.count + 1,
                      },
                    });
                  }
                });
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    className={classes.select}
                    id="facilityName"
                    getOptionLabel={(option: Option) => option.label}
                    onChange={(event, value: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        location: value || null,
                        zone: null,
                      });

                      setFacilityZones(value?.value || null);
                    }}
                    options={facilityOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility Name"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SimpleSelect
                    required
                    id="event"
                    label={"Event"}
                    value={updateEvent.event}
                    variant={"standard"}
                    margin="dense"
                    classes={classes}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        event: e.target.value,
                      });
                    }}
                    options={eventTypes.map((event: any) => {
                      return {
                        value: event,
                        label: event,
                      };
                    })}
                  />
                </Grid>
                {zones?.length > 0 ? (
                  <Grid item xs={12} sm={4}>
                    <SimpleSelect
                      label={"Zone"}
                      id="binLocation"
                      value={updateEvent.zone}
                      variant={"standard"}
                      margin="dense"
                      classes={classes}
                      onChange={(e: any) => {
                        setUpdateEvent({
                          ...updateEvent,
                          zone: e.target.value,
                        });
                      }}
                      options={zones.map((zone: any) => {
                        return {
                          value: zone,
                          label: zone.name,
                        };
                      })}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={4}>
                  <TextField
                    type={"text"}
                    id="binLocation"
                    className={classes.input}
                    variant={"standard"}
                    margin="dense"
                    label={"Bin Location"}
                    name={"binLocation"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        binLocation: e.target.value,
                      });
                    }}
                    value={updateEvent.binLocation}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    id="note"
                    type={"text"}
                    className={classes.input}
                    variant={"standard"}
                    margin="normal"
                    multiline={true}
                    rows={"2"}
                    label={"Notes"}
                    name={"note"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        note: e.target.value,
                      });
                    }}
                    value={updateEvent.note}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                // disabled={!changesMade || !isValid}
              >
                {"Submit"}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Update;
