import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { resetPassword } from "./utils/api";
import { showAlert } from "../../globalStore/slices/alert/alertSlice";

const useStyles = makeStyles((theme: any) => ({
  button: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    marginTop: "1.5rem",
  },
  fontWeight: {
    fontWeight: 600,
  },
  helperText: {
    color: "#B71D1A",
  },
}));

export default function ForgotPassword(props: any) {
  const { apiUrl } = props;
  const { token: resetPasswordToken } = props.match.params;

  const classes = useStyles();
  const history = useHistory();
  const dispatchGlobal = useDispatch();

  const [state, setState] = useState({
    fieldValues: {
      newPassword: { value: "", error: false, label: "New Password" },
      verifyPassword: { value: "", error: false, label: "Verify Password" },
    },
    duplication: false,
    resetPasswordComplete: false,
    showNewPassword: false,
    showVerifyPassword: false,
  });

  function validateFields() {
    let error = false;
    const { fieldValues } = state;
    const { newPassword, verifyPassword } = fieldValues;

    if (
      newPassword.value !== verifyPassword.value &&
      verifyPassword.value.length !== 0 &&
      newPassword.value.length !== 0
    ) {
      error = true;
      setState((prevState: any) => ({
        ...prevState,
        duplication: true,
        fieldValues: {
          newPassword: { ...prevState.fieldValues.newPassword, error: true },
          verifyPassword: {
            ...prevState.fieldValues.verifyPassword,
            error: true,
          },
        },
      }));
    }

    if (!error) {
      resetPassword({ apiUrl, resetPasswordToken, state }).then(
        (response: any) => {
          if (response.success) {
            setState((prevState) => ({
              ...prevState,
              resetPasswordComplete: true,
            }));
          } else if (response.error) {
            dispatchGlobal(
              showAlert({ type: "error", text: response.error.toString() })
            );
          } else {
            dispatchGlobal(
              showAlert({
                type: "error",
                text: "Something went wrong while trying to reset your password. Please contact LX Support at http://helpdesk.locatorx.com/support/home",
              })
            );
          }
        }
      );
    }
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ marginTop: "1.5rem" }}
    >
      {state.resetPasswordComplete ? (
        <>
          {" "}
          <Grid item xs={12}>
            <Typography variant="h4">Your password has been changed</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={() => history.push("/login")}
              variant="contained"
            >
              Login
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography className={classes.fontWeight} variant="h4">
              Reset Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">
                New Password
              </InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setState((prevState: any) => ({
                          ...prevState,
                          showNewPassword: !prevState.showNewPassword,
                        }));
                      }}
                      onMouseDown={(event: any) => {
                        event.preventDefault();
                      }}
                    >
                      {state.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                error={state.fieldValues.newPassword.error}
                id="password"
                onChange={(event: any) => {
                  setState((prevState: any) => ({
                    ...prevState,
                    fieldValues: {
                      ...prevState.fieldValues,
                      newPassword: {
                        error: false,
                        value: event.target.value,
                        label: "New Password",
                      },
                    },
                  }));
                }}
                type={state.showNewPassword ? "text" : "password"}
                value={state.fieldValues.newPassword.value}
              />
              {state.fieldValues.newPassword.error ? (
                <FormHelperText
                  className={classes.helperText}
                  id="new-password-helper-text"
                >
                  {state.duplication
                    ? "Passwords Do Not Match"
                    : "Required Field"}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">
                Verify Password
              </InputLabel>
              <Input
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setState((prevState: any) => ({
                          ...prevState,
                          showVerifyPassword: !prevState.showVerifyPassword,
                        }));
                      }}
                      onMouseDown={(event: any) => {
                        event.preventDefault();
                      }}
                    >
                      {state.showVerifyPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                error={state.fieldValues.verifyPassword.error}
                id="verify-password"
                onChange={(event: any) => {
                  setState((prevState: any) => ({
                    ...prevState,
                    fieldValues: {
                      ...prevState.fieldValues,
                      verifyPassword: {
                        error: false,
                        label: "Verify Password",
                        value: event.target.value,
                      },
                    },
                  }));
                }}
                type={state.showVerifyPassword ? "text" : "password"}
                value={state.fieldValues.verifyPassword.value}
              />
              {state.fieldValues.verifyPassword.error ? (
                <FormHelperText
                  className={classes.helperText}
                  id="verify-password-helper-text"
                >
                  {state.duplication
                    ? "Passwords Do Not Match"
                    : "Required Field"}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              disabled={
                state.fieldValues.newPassword.value.length === 0 ||
                state.fieldValues.verifyPassword.value.length === 0
              }
              onClick={() => {
                validateFields();
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
