import React from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { Box, Grid, Paper, makeStyles } from "@material-ui/core";

import { State } from "./interface";
import { searchHistories, saveFilterSettings } from "./api";
import { init } from "./utils";
import { handleExport } from "./xlsx";
import { Table } from "./Tables";
import * as Tab from "./Tabs/index";

import CenteredModal from "../../components/Modals/CenteredModal";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import Map from "../../components/Maps/AssetHistoryMap/AssetHistoryMap";
import Loading from "../../components/Loading/Loading";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
    height: "100%",
    maxHeight: "550px",
    minHeight: "550px",
    overflow: (props) => (props === 0 ? "inherit" : "auto"),
    padding: theme.spacing(2),
    textAlign: "center",
    width: "100%",
  },
}));

export default function BatchHistory(props: any) {
  const { apiUrl, token, userId, organizationId, timeZone, usersConsoleRole } =
    props;
  const { batchId: batchIdFromParams } = props.match.params;

  const [state, setState] = React.useState<State>();
  const [loading, setLoading] = React.useState({ loading: false });
  const [infoModal, setInfoModal] = React.useState<any>({
    modal: { modalShow: false, title: "", content: "" },
  });
  const [modal, setModal] = React.useState({
    modalShow: false,
    text: "",
    isError: false,
  });
  const [tabNavigation, setTabNavigation] = React.useState(0);
  const modalClose = () => {
    setModal({
      ...modal,
      modalShow: false,
    });
    setInfoModal({
      ...infoModal,
      modalShow: false,
    });
  };
  const isMounted = React.useRef(false);
  const classes = useStyles(tabNavigation);

  const userPropertiesMapRef = React.useRef();

  const { organization, facilities, usersMap, zones } = useSelector(
    (state: any) => state.organization,
    isEqual
  );
  // first effect, fire init function
  React.useEffect(() => {
    init({
      apiUrl,
      batchIdFromParams,
      facilities,
      organization,
      organizationId,
      token,
      userId,
      usersMap,
    }).then((res: any) => {
      if (res.error) {
        setModal({
          modalShow: true,
          text: `Uh-oh! Something went wrong while fetching initial data... ${res.error}`,
          isError: true,
        });
      } else {
        userPropertiesMapRef.current = res.userPropertiesMap;
        setState((s) => {
          return {
            ...s,
            ...res,
          };
        });
        isMounted.current = true;
      }
    });

    return () => {
      // Clears filters
      setState((prevState: any) => ({
        ...prevState,
        filters: {
          assets: null,
          binLocations: null,
          endDate: null,
          events: null,
          locals: null,
          locations: null,
          startDate: null,
          type: null,
          users: null,
          zones: null,
        },
      }));
      // save filters to user profile upon dismount
      if (!isEqual(userPropertiesMapRef.current, prevFiltersRef.current)) {
        saveFilterSettings(
          { apiUrl, token, userId },
          prevFiltersRef.current
        ).then((res: any) => {
          if (res.error) {
            console.log(res.error);
          }
        });
      }
    };
  }, [
    apiUrl,
    batchIdFromParams,
    facilities,
    organization,
    organizationId,
    token,
    userId,
    usersMap,
  ]);

  const prevFiltersRef = React.useRef(state?.filters);
  const locationRef = React.useRef(props.history.location.pathname);

  // On filter change...
  React.useEffect(() => {
    const { batchId } = props.match.params;
    const pathName = props.history.location.pathname;

    if (
      !isEqual(state?.filters, prevFiltersRef.current) ||
      !isEqual(pathName, locationRef.current)
    ) {
      prevFiltersRef.current = state?.filters;
      setLoading({ loading: true });
      searchHistories(
        { apiUrl, token, organizationId },
        batchId,
        state?.filters,
        false
      ).then((res) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching item data... ${res.error}`,
            isError: true,
          });
          setLoading({ loading: false });
        } else {
          setState((prevState: any) => {
            locationRef.current = pathName;

            return {
              ...prevState,
              histories: res,
              page: Math.floor(
                prevState.filters.start / prevState.filters.limit
              ),
            };
          });

          setLoading({ loading: false });
        }
      });
    }
  }, [state, props, apiUrl, organizationId, token]);

  const itemLevelDataElements = state?.productData[0]?.propertiesMap
    ?.itemLevelDataElements
    ? state.productData[0].propertiesMap.itemLevelDataElements
        .map((f: any) => {
          return {
            id: f.id,
            dataType: f.dataType,
          };
        })
        .reduce((x: any, y: any) => {
          return {
            ...x,
            [y.id]: {
              ...y,
            },
          };
        }, {})
    : false;

  const handleSave = async (filters: any) => {
    saveFilterSettings({ userId, apiUrl, token }, filters).then((res: any) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const { appUser = {} } = res;
        const { propertiesMap = {} } = appUser;
        const { batchHistoryTableSettings = {} } = propertiesMap;
        const { displayColumnOptions = {}, defaultColumnOrder = [] } =
          batchHistoryTableSettings;

        setState((prevState: any) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            displayColumnOptions,
            defaultColumnOrder,
          },
        }));
      }
    });
  };

  return (
    <Box className={classes.root} mt={4}>
      {state ? (
        <Grid container spacing={3}>
          <CenteredModal
            onHide={modalClose}
            show={infoModal.modalShow}
            content={
              <div className="container">
                <div className="row">
                  <div className="col-md-12">{infoModal.content}</div>
                </div>
              </div>
            }
            label={infoModal.title}
          />
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Map
                timeZone={timeZone}
                facilities={facilities}
                state={state}
                style={{
                  border: "rgba(50, 53, 93, 0.514) solid 2px",
                  borderRadius: "4px",
                }}
                eventTypesMap={
                  state.lists.eventTypesMap ? state.lists.eventTypesMap : {}
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.paper}>
              <HorizontalTabs
                customValue={tabNavigation}
                customControl={(
                  event: React.ChangeEvent<{}>,
                  newValue: number
                ) => {
                  setTabNavigation(newValue);
                }}
              >
                <Tab.Overview
                  label={"Overview"}
                  state={state}
                  setState={setState}
                  timeZone={state.filters.tz ? state.filters.tz : ""}
                />
                {usersConsoleRole === "Lite" ? null : (
                  <Tab.Update
                    state={state}
                    setState={setState}
                    setModal={setModal}
                    assetsArray={state.lists.assetsArray}
                    eventTypesMap={
                      organization ? organization.eventTypesMap : []
                    }
                    eventTypes={state.lists.eventTypes}
                    facilities={facilities}
                    apiUrl={apiUrl}
                    token={token}
                    label={"Update Event Log"}
                  />
                )}

                {/* {usersConsoleRole === "Lite" ? null : (
                  <Tab.AssignDevices
                    allDevices={state.lists.allDevices}
                    apiUrl={apiUrl}
                    availableDevices={state.lists.availableDevices}
                    deviceAssetsMap={state.lists.deviceAssetsMap}
                    label="Assign Devices"
                    setModal={setModal}
                    setState={setState}
                    setTabNavigation={setTabNavigation}
                    onSuccess={() => {
                      refreshBatchAndDeviceData({
                        apiUrl,
                        batchIdFromParams,
                        facilities,
                        organization,
                        organizationId,
                        token,
                        userId,
                        usersMap,
                        setState,
                        setModal,
                      });
                    }}
                    state={state}
                    token={token}
                    timeZone={timeZone}
                  />
                )} */}
              </HorizontalTabs>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {loading.loading ? <Loading color="#5884F5" opaque={true} /> : null}
            <Table
              apiUrl={apiUrl}
              assetsArray={state.lists.assetsArray}
              defaultColumnOrder={state.filters.defaultColumnOrder}
              facilities={facilities}
              handleExport={handleExport}
              handleSave={handleSave}
              itemLevelDataElements={itemLevelDataElements}
              match={props.match}
              organizationId={organizationId}
              page={state.page}
              searchHistories={searchHistories}
              setInfoModal={setInfoModal}
              setLoading={setLoading}
              setModal={setModal}
              setState={setState}
              state={state}
              timeZone={state.filters.tz ? state.filters.tz : ""}
              token={token}
              zones={zones}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading color="#5884F5" opaque={true} />
      )}
      <MaterialConfirmationModal
        content={modal.text}
        closeModal={() => {
          setModal({ ...modal, modalShow: false });
        }}
        modalOpen={modal.modalShow}
        severity={modal.isError ? "error" : "success"}
        variant="filled"
      />
    </Box>
  );
}
