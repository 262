import React, { useEffect, useState } from "react";

function ViewProductInformation(props) {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const productMap = props.products;
  useEffect(() => {
    const { topologiesList = [], topologyMap = {} } = props.batch;
    const productArr = [];
    const recurseTopologies = (top) => {
      const obj = {};
      if (Array.isArray(top) && top.length) {
        top.map((el) => {
          if (el.productId && productMap[el.productId] && !obj[el.productId]) {
            obj[el.productId] = true;
            productArr.push(productMap[el.productId]);
          }
          if (el.child) {
            recurseTopologies(el.child);
          }
          return null;
        });
      } else {
        if (top.productId && productMap[top.productId] && !obj[top.productId]) {
          obj[top.productId] = true;
          productArr.push(productMap[top.productId]);
        }
        if (top.child) {
          recurseTopologies(top.child);
        }
      }
    };
    recurseTopologies(topologiesList);

    setProducts(productArr);

    if (topologyMap && productMap[topologyMap.productId]) {
      setProduct(productMap[topologyMap.productId]);
    }
  }, [props, productMap]);

  return (
    <div className="col-md-6">
      {products && products.length ? (
        products.map((product, idx) => {
          const { name, productType, description, tag } =
            productMap[product.productId] || null;
          return (
            <React.Fragment key={product.productId || idx}>
              <p>
                <strong>Product Name:</strong> {name ? name : "N/A"}
                <br />
                <strong>Product Code:</strong> {tag ? tag : "N/A"}
                <br />
                <strong>Category:</strong> {productType ? productType : "N/A"}
                {description ? (
                  <>
                    <br />
                    <strong>Description: </strong> {description}
                  </>
                ) : null}
              </p>
              {products.length > 1 && idx !== products.length - 1 ? (
                <hr />
              ) : null}
            </React.Fragment>
          );
        })
      ) : product ? (
        <p>
          <strong>Product Name:</strong> {product.name ? product.name : "N/A"}
          <br />
          <strong>Product Code:</strong> {product.tag ? product.tag : "N/A"}
          <br />
          <strong>Category:</strong>{" "}
          {product.productType
            ? product.productType
            : product.propertiesMap && product.propertiesMap.productType
            ? product.propertiesMap.productType
            : "N/A"}
          {product.description ? (
            <>
              <br />
              <strong>Description: </strong> {product.description}
            </>
          ) : null}
          {product.propertiesMap &&
          product.propertiesMap.customDataFields &&
          product.propertiesMap.customDataFields.length
            ? product.propertiesMap.customDataFields.map((el, idx) => (
                <>
                  <br />
                  <strong>{el.title}</strong>: {el.value}
                </>
              ))
            : ""}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default ViewProductInformation;
