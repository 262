import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RemoveIcon from "@material-ui/icons/Remove";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleList(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {props.items && props.items.length
          ? props.items
              .filter((el) => el !== null)
              .map((item, idx) => (
                <ListItem
                  selected={
                    !props.noSelectHighlight ? selectedIndex === idx : null
                  }
                  button={!props.noSelectHighlight ? true : false}
                  key={`${idx} - ${item.id}`}
                  onClick={() => {
                    if (props.onClick) {
                      props.onClick(item.id);
                    }
                    setSelectedIndex(idx);
                  }}
                >
                  <ListItemText
                    style={
                      props.isColor && item.color
                        ? { color: `${item.color}` }
                        : null
                    }
                    primary={item.label}
                  />
                  {props.onClickEdit ? (
                    <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          props.onClickEdit(item.id);
                        }}
                      >
                        <EditIcon style={{ color: "#3D5BA9" }} />
                      </IconButton>
                    </ListItemIcon>
                  ) : null}
                  {props.onClickRemove ? (
                    <ListItemIcon>
                      <IconButton
                        onClick={(e) => {
                          props.onClickRemove(item.id);
                          setSelectedIndex(null);
                          e.stopPropagation();
                        }}
                      >
                        <RemoveIcon style={{ color: "#b71d1a" }} />
                      </IconButton>
                    </ListItemIcon>
                  ) : null}
                </ListItem>
              ))
          : null}
      </List>
    </div>
  );
}
