import { isEmpty, isEqual } from "lodash";
import { formatLatLon } from "../../../../utils/lat_lon_utils";

import {
  utc_to_local_12_hour_clock,
  utc_to_local_24_hour_clock,
} from "../../../../utils/date_time_utils";
import { parseUserInfo } from "../utils";
import { resetDisplayColumnOptions } from "../api";

export default function buildColumns(props) {
  const {
    apiUrl,
    classifications,
    displayColumnOptions,
    facilities,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  } = props;

  const classificationColumns = !isEmpty(classifications?.active)
    ? Object.keys(classifications.active).map((classKey) => {
        const { classificationId, name } = classifications.active[classKey];
        return {
          Header: name,
          accessor: (row) => {
            if (row.classificationItemMap) {
              const mapKey = Object.keys(row.classificationItemMap).filter(
                (k) =>
                  row.classificationItemMap[k].parentId === classificationId
              )[0];
              return row.classificationItemMap[mapKey]?.name || "";
            }
            return "";
          },
          id: name,
          disableSortBy: true,
          Cell: (data) => {
            const { classificationItemMap = {} } = data.row.original;

            const mapKey = Object.keys(classificationItemMap).filter(
              (k) => classificationItemMap[k].parentId === classificationId
            );

            return (
              <div
                className={"pointer"}
                onClick={() => onClick(data.row.original)}
              >
                {classificationItemMap[mapKey]?.name || ""}
              </div>
            );
          },
        };
      })
    : [];

  const historyTableColumns = [
    {
      Header: "Date/Time",
      accessor: (row) =>
        row.timeOfLogLong ||
        utc_to_local_24_hour_clock(row.timeOfLog, timeZone) ||
        "DNP",
      id: "timeOfLog",
      Cell: (data) => {
        const { timeOfLog } = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {utc_to_local_12_hour_clock(timeOfLog, timeZone) || "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Asset Tag",
      accessor: (row) => row.assetTag || "DNP",
      id: "assetTag",
      Cell: (data) => {
        const { assetTag } = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {assetTag ? assetTag : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Asset Category",
      accessor: (row) => row.category || "DNP",
      id: "category",
      Cell: (data) => {
        const { category } = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {category ? category : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Event",
      accessor: (row) => row.event || "DNP",
      id: "event",
      Cell: (data) => {
        const { event } = data.row.original;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {event ? event : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "User",
      accessor: (row) => {
        return parseUserInfo(row);
      },
      id: "user",
      // disableSortBy: true,
      Cell: (data) => {
        const row = data.row.original;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {userInfo}
          </div>
        );
      },
    },
    {
      Header: "Facility Name",

      accessor: (row) => {
        return !row.latitude || !row.longitude
          ? "DNP"
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : "Not Registered";
      },
      id: "facility",
      disableSortBy: true,
      Cell: (data) => {
        const { facilityId, latitude, longitude } = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {!latitude || !longitude
              ? "DNP"
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
    },
    {
      Header: "Zone",
      id: "zone",
      disableSortBy: true,
      accessor: (row) => (row.zone?.zoneId ? zones[row.zone.zoneId] : ""),
      Cell: (data) => {
        const { zone = {} } = data.row.original;
        const { zoneId = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {zoneId && zones[zoneId] ? zones[zoneId].name : ""}
          </div>
        );
      },
    },
    {
      Header: "Bin Location",
      id: "binLocation",
      accessor: (row) => row.binLocation || "",
      Cell: (data) => {
        const { zone = {} } = data.row.original;
        const { binLocation = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              data.row.original.zone
                ? binLocation
                : data.row.original.binLocation
                ? data.row.original.binLocation
                : data.row.original.propertiesMap &&
                  data.row.original.propertiesMap.binLocation
                ? data.row.original.propertiesMap.binLocation
                : ""
            }
          </div>
        );
      },
    },
    {
      Header: "State",
      accessor: (row) => row.state || "DNP",
      id: "state",
      Cell: (data) => {
        const { state = "DNP" } = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {state ? state : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Latitude, Longitude",
      accessor: (row) =>
        `${row.latitude ? row.latitude : "DNP"}, ${
          row.longitude ? row.longitude : "DNP"
        }`,
      id: "latLong",
      Cell: (data) => {
        const { latitude, longitude } = data.row.original;

        const formattedLatLong =
          latitude && longitude ? formatLatLon(latitude, longitude) : "DNP";

        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {formattedLatLong}
          </div>
        );
      },
    },
    {
      Header: "Quantity Needed",
      id: "quantityNeeded",
      accessor: (row) => row.quantity?.quantityNeeded || "",
      disableSortBy: true,
      Cell: (data) => {
        const row = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {row.quantity?.quantityNeeded || ""}
          </div>
        );
      },
    },
    {
      Header: "Quantity Picked",
      id: "quantityPicked",
      disableSortBy: true,
      accessor: (row) => row.quantity?.quantityPicked || "",
      Cell: (data) => {
        const row = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {row.quantity?.quantityPicked || ""}
          </div>
        );
      },
    },
    {
      Header: "Quantity Short",
      id: "quantityShorted",
      disableSortBy: true,
      accessor: (row) => row.quantity?.quantityShorted || "",
      Cell: (data) => {
        const row = data.row.original;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {row.quantity?.quantityShorted || ""}
          </div>
        );
      },
    },
  ].concat(classificationColumns);

  const onClick = (row) => {
    const { assetId = "", assetType = "", batchId = "" } = row;
    if (assetId || batchId) {
      const url = batchId
        ? `/batches/${batchId}/history/${assetType}`
        : `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };
  // sort and break column keys and displayOptionsKeys into strings for strict comparison
  const columnKeys = historyTableColumns
    .map((col) => col.id)
    .sort()
    .join(",");
  const displayOptionKeys = Object.keys(displayColumnOptions).sort().join(",");

  // if the history table columns, which will always be correct, are not strictly equal to the displayOptionsColumns, which come from the database and may be stale,
  // reubuild displayColumnOptions from historyTableColumns, else if they are the same (i.e., no new classification columns have been added in global settings),
  // use the displayColumnOptions from the database

  const isStale =
    !isEmpty(displayOptionKeys) && !isEqual(columnKeys, displayOptionKeys);

  const buildDisplayColumnOptions =
    isStale || isEmpty(displayColumnOptions)
      ? historyTableColumns
          .map((col) => {
            return {
              id: col.id,
              header: col.Header,
              checked: true,
            };
          })
          .reduce((x, y) => {
            return { ...x, [y.id]: y };
          }, {})
      : displayColumnOptions;

  if (isStale) {
    resetDisplayColumnOptions({ apiUrl, token, userId });

    // window.location.reload();
    setState((s) => {
      return {
        ...s,
        filters: {
          ...s.filters,
          displayColumnOptions: buildDisplayColumnOptions,
        },
      };
    });
  }

  return { historyTableColumns, buildDisplayColumnOptions };
}
