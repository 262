import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  label: {
    fontWeight:'500',
    marginBottom:'.2rem'
  }
}));

export default function TextFieldWithExternalLabel(props) {
  const {
    className,
    error = false,
    errorText = "Required Field",
    helperText,
    id,
    InputProps = {},
    label = "",
    multiline = false,
    onChange,
    readOnly = false,
    required,
    size,
    type = "",
    value,
    variant = "outlined",
  } = props;
  const classes = useStyles();
  return (
    <>
      {label ? (
        <div className={classes.label}>{label ? label : "Select"}</div>
      ) : null}
      <TextField
        InputLabelProps={{ required: false }}
        className={`${classes.root} ${className}`}
        error={error}
        required={required}
        helperText={error ? errorText : helperText}
        id={id}
        InputProps={{
          ...InputProps,
          readOnly,
        }}
        multiline={multiline}
        onChange={(event) => {
          onChange(event);
        }}
        size={size}
        type={type}
        value={value}
        variant={variant}
      />
    </>
  );
}
