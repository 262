import React from "react";
import { Form } from "react-bootstrap";
import "./BarGraphByEventsStyling.css";

function BarGraphsByEventAndDateSettings(props) {
  const {
    actions = {},
    event,
    setSelectedSortEvent,
    setSelectedTimePeriod,
    timePeriod,
  } = props;
  return (
    <Form className="container-fluid barGraph">
      <div className="row">
        <div className="col-md-6 mt-2">
          <label>Select Event</label>
          <Form.Group className="my-auto">
            <Form.Control
              as="select"
              className="custom-select bg-white"
              onChange={(e) => {
                setSelectedSortEvent(e.target.value);
              }}
              value={event}
            >
              <option value="" disabled>
                Select
              </option>
              {Object.keys(actions).map((element, index) => {
                return (
                  <option key={index} value={element}>
                    {element}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6 mt-2">
          <label>Select Time Period</label>
          <Form.Group className="my-auto">
            <Form.Control
              as="select"
              className="custom-select bg-white"
              onChange={(event) => {
                setSelectedTimePeriod(event.target.value);
              }}
              value={timePeriod}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="1">1 Month</option>
              <option value="3">3 Month</option>
              <option value="6">6 Month</option>
              <option value="12">1 Year</option>
            </Form.Control>
          </Form.Group>
        </div>
      </div>
    </Form>
  );
}

export default BarGraphsByEventAndDateSettings;
