import React from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { isEmpty } from "lodash";
import { Tooltip } from "@material-ui/core";

export default function ConsoleSettings(props: any) {
  const {
    state,
    dispatchState,
    handleConfirmationModal,
    quickLinkActions,
  } = props;

  const [view, setView] = React.useState<any>({
    isAddMenu: false,
    isAddActions: false,
    isEditMenu: false,
  });

  const [addMenu, setAddMenu] = React.useState<any>({});
  const [editMenuName, setEditMenuName] = React.useState<any>();
  const [addAction, setAddAction] = React.useState<any>();

  React.useEffect(() => {
    setEditMenuName(state.selected?.quickLinkMenu?.label || "");
  }, [state.selected.quickLinkMenu]);

  return (
    <>
      <div className="row">
        <div className="col col-md-6">
          <h4>
            <Tooltip title="Add up to three customizable drop down menus to keep quick links in the top bar of LXConnect.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Top Bar Dropdowns{" "}
            {!view.isEditMenu ? (
              <IconButton
                style={{ padding: "0px" }}
                onClick={() =>
                  setView({
                    ...view,
                    isAddMenu: !view.isAddMenu,
                  })
                }
              >
                {!view.isAddMenu ? (
                  <AddIcon style={{ color: "#3D5BA9" }} />
                ) : (
                  <CloseIcon style={{ color: "#b71d1a" }} />
                )}
              </IconButton>
            ) : null}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddMenu && !view.isEditMenu ? (
              <SimpleList
                items={Object.keys(
                  state.userSettings?.consoleSettings?.quickLinks || {}
                )
                  .filter((el) => el !== null)
                  .map((indexName) => {
                    if (
                      state.userSettings.consoleSettings.quickLinks[indexName]
                    ) {
                      return {
                        id: indexName,
                        label:
                          state.userSettings.consoleSettings.quickLinks[
                            indexName
                          ].label,
                        actions:
                          state.userSettings.consoleSettings.quickLinks[
                            indexName
                          ].actions,
                      };
                    } else {
                      return null;
                    }
                  })
                  .filter((el) => el !== null)}
                onClick={(id: string) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "quickLinkMenu",
                    payload: {
                      ...state.userSettings.consoleSettings.quickLinks[id],
                      label: state.userSettings.consoleSettings.quickLinks[id]
                        .label
                        ? state.userSettings.consoleSettings.quickLinks[id]
                            .label
                        : id,
                      id: state.userSettings.consoleSettings.quickLinks[id].id
                        ? state.userSettings.consoleSettings.quickLinks[id].id
                        : id,
                      actions: state.userSettings.consoleSettings.quickLinks[id]
                        .actions
                        ? state.userSettings.consoleSettings.quickLinks[id]
                            .actions
                        : {},
                    },
                  });
                }}
                onClickRemove={(id: string) => {
                  setView({
                    ...view,
                    isAddActions: false,
                  });
                  dispatchState({
                    type: "CHANGE_USER",
                    setting: "consoleSettings",
                    payload: {
                      ...state.userSettings.consoleSettings,
                      quickLinks: {
                        ...state.userSettings.consoleSettings.quickLinks,
                        [id]: null,
                      },
                    },
                    removeSelected: "quickLinkMenu",
                  });
                }}
                onClickEdit={() => {
                  setView({
                    ...view,
                    isEditMenu: true,
                  });
                }}
              />
            ) : !view.isEditMenu && view.isAddMenu ? (
              <SimpleForm
                headerText={"Add New Quick Link Dropdown Menu"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Menu Name",
                    onChange: (e: any) => {
                      setAddMenu({
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  const menuCount = state.userSettings?.consoleSettings
                    ?.quickLinks
                    ? Object.keys(
                        state.userSettings.consoleSettings.quickLinks
                      ).filter(
                        (menu) =>
                          state.userSettings.consoleSettings.quickLinks[
                            menu
                          ] !== null
                      ).length
                    : 0;

                  if (menuCount >= 3) {
                    return handleConfirmationModal(
                      `Cannot have more than 3 quick link dropdown menus in LXConnect. Please remove a dropdown menu before creating a new one.`,
                      true,
                      5000
                    );
                  }
                  const label = addMenu.label.trim();
                  dispatchState({
                    type: "CHANGE_USER",
                    setting: "consoleSettings",
                    payload: {
                      ...state.userSettings.consoleSettings,
                      quickLinks: {
                        ...state.userSettings.consoleSettings?.quickLinks,
                        [label]: {
                          id: label,
                          label: label,
                          actions: {},
                        },
                      },
                    },
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddMenu: false,
                  });
                }}
              />
            ) : view.isEditMenu && state.selected.quickLinkMenu ? (
              <SimpleForm
                headerText={`Edit ${state.selected.quickLinkMenu.label}`}
                submitBtnText={"Submit"}
                fields={
                  state.selected.quickLinkMenu.label
                    ? [
                        {
                          key: `${state.selected.quickLinkMenu.label}`,
                          id: `${state.selected.quickLinkMenu.label}`,
                          label: `Menu Name`,
                          onChange: (e: any) => {
                            setEditMenuName(e.target.value);
                          },
                          value: editMenuName,
                        },
                      ]
                    : []
                }
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_USER",
                    setting: "consoleSettings",
                    payload: {
                      ...state.userSettings.consoleSettings,
                      quickLinks: {
                        ...state.userSettings.consoleSettings.quickLinks,
                        [state.selected.quickLinkMenu.id]: null,
                        [editMenuName]: {
                          ...state.selected.quickLinkMenu,
                          label: editMenuName,
                          id: editMenuName,
                        },
                      },
                    },
                    removeSelected: "quickLinkMenu",
                  });
                  setView({
                    ...view,
                    isEditMenu: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditMenu: false,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="col col-md-6">
          <h4>
            <Tooltip title="Actions to add in your quick link menus.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Actions
            {state.selected.quickLinkMenu ? (
              <IconButton
                style={{ padding: "0px" }}
                onClick={() =>
                  setView({
                    ...view,
                    isAddActions: !view.isAddActions,
                  })
                }
              >
                {!view.isAddActions ? (
                  <AddIcon style={{ color: "#3D5BA9" }} />
                ) : (
                  <CloseIcon style={{ color: "#b71d1a" }} />
                )}
              </IconButton>
            ) : null}
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddActions ? (
              <>
                {!state.selected.quickLinkMenu ? (
                  <p>Select a dropdown menu to add or remove actions.</p>
                ) : state.selected.quickLinkMenu &&
                  !isEmpty(state.selected.quickLinkMenu.actions) ? (
                  <>
                    <p>
                      <strong>{state.selected.quickLinkMenu.label}</strong>{" "}
                      actions
                    </p>
                    <SimpleList
                      noSelectHighlight
                      onClickRemove={(id: string) => {
                        dispatchState({
                          type: "SET_SELECTED",
                          label: "quickLinkMenu",
                          payload: {
                            ...state.selected.quickLinkMenu,
                            actions: {
                              ...state.selected.quickLinkMenu.actions,
                              [id]: null,
                            },
                          },
                        });

                        dispatchState({
                          type: "CHANGE_USER",
                          setting: "consoleSettings",
                          payload: {
                            ...state.userSettings.consoleSettings,
                            quickLinks: {
                              ...state.userSettings.consoleSettings.quickLinks,
                              [state.selected.quickLinkMenu.id]: {
                                ...state.selected.quickLinkMenu,
                                actions: {
                                  ...state.selected.quickLinkMenu.actions,
                                  [id]: null,
                                },
                              },
                            },
                          },
                        });
                      }}
                      items={
                        state.selected.quickLinkMenu &&
                        state.selected.quickLinkMenu.actions
                          ? Object.keys(state.selected.quickLinkMenu.actions)
                              .filter(
                                (action) =>
                                  state.selected.quickLinkMenu.actions[
                                    action
                                  ] !== null
                              )
                              .map((action) => {
                                return {
                                  id: action,
                                  label: state.selected?.quickLinkMenu?.actions[
                                    action
                                  ]?.label
                                    ? state.selected.quickLinkMenu.actions[
                                        action
                                      ].label
                                    : action,
                                };
                              })
                          : []
                      }
                    />
                  </>
                ) : (
                  <p>
                    No permissions set for{" "}
                    {state.selected?.quickLinkMenu?.label}
                  </p>
                )}
              </>
            ) : (
              <SimpleForm
                headerText={`Add action${
                  state.selected.quickLinkMenu &&
                  state.selected.quickLinkMenu.label
                    ? ` for ${state.selected.quickLinkMenu.label}`
                    : null
                }`}
                submitBtnText={"Add Action"}
                fields={[
                  {
                    id: "action",
                    label: "Action",
                    isSelect: true,
                    options: quickLinkActions.map((action: string) => {
                      return {
                        label: action,
                        value: action,
                      };
                    }),
                    onChange: (e: any) => {
                      setAddAction({
                        id: e.target.value,
                        label: e.target.value,
                        value: e.target.value,
                      });
                    },
                    value: addAction ? addAction.value : "",
                  },
                ]}
                onSubmit={(e: any) => {
                  const updatedActions = {
                    ...state.selected.quickLinkMenu,
                    actions: {
                      ...state.selected.quickLinkMenu.actions,
                      [addAction.id]: {
                        id: addAction.id,
                        label: addAction.label,
                      },
                    },
                  };

                  dispatchState({
                    type: "SET_SELECTED",
                    label: "quickLinkMenu",
                    payload: updatedActions,
                  });

                  dispatchState({
                    type: "CHANGE_USER",
                    setting: "consoleSettings",
                    payload: {
                      ...state.userSettings.consoleSettings,
                      quickLinks: {
                        ...state.userSettings.consoleSettings.quickLinks,
                        [state.selected.quickLinkMenu.id]: updatedActions,
                      },
                    },
                  });

                  setView({
                    ...view,
                    isAddActions: false,
                  });

                  setAddAction("");
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddActions: false,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
