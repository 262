import XLSX from "xlsx";
import isEmail from "../../utils/isEmail";

const spreadSheetHeaders = [
  "Instructions",
  "Email",
  "First Name",
  "Last Name",
  "User Type",
  "LXConnect Role",
  "Mobile Role",
  "Job Title",
  "Organization",
  "Phone Number",
  "Notes",
];

export const downloadTemplate = (consoleRoles, mobileRoles, groups) => {
  let consoleRolesArr = [];
  Object.keys(consoleRoles).map((role) => {
    if (role === "No Console Access") {
      return consoleRolesArr.push(` No LXConnect Access`);
    }
    return consoleRolesArr.push(` ${role.trim()}`);
  });
  let mobileRolesArr = [];
  Object.keys(mobileRoles).map((role) => {
    return mobileRolesArr.push(` ${role.trim()}`);
  });
  let groupsArr = [];

  groups
    .filter((group) => group.name)
    .map((group) => {
      return groupsArr.push(` ${group.name.trim()}`);
    });

  const SpreadsheetTemplate = [
    {
      Instructions:
        "Fill out this spreadsheet and import to upload a list of new users. Please do not add duplicate email addresses. Please be sure to select only one option for the new user's console and mobile role, and to make sure each one match the case in the template (roles are case sensitive). First Name, Last Name, Email, LXConnect Role, and Mobile Role are required fields for each user.",
      Email: "",
      "First Name": "",
      "Last Name": "",
      "User Type": `Delete this text and keep only one of the following: Product, Asset/Product, Asset-Warehouse, Asset-Operations`,
      "LXConnect Role": `Delete this text and keep only one of the following:${consoleRolesArr}`,
      "Mobile Role": `Delete this text and keep only one of the following:${mobileRolesArr}`,
      "Job Title": "",
      Organization: `Delete this text and keep only one of the following: ${groupsArr}`,
      "Phone Number": "",
      Notes: "",
    },
  ];

  const fileName = `UsersTemplate.xlsx`;
  const ws = XLSX.utils.json_to_sheet(SpreadsheetTemplate);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};

export const validateAndFormatSpreadsheet = (spreadsheet, props, response) => {
  const XLSX = require("xlsx");
  const { consoleRoles, mobileRoles } = props;
  const { groups = [] } = props;
  const consoleRolesList = Object.keys(consoleRoles).map((role) => {
    if (role === "No Console Access") {
      return "No LXConnect Access";
    }
    return role.trim();
  });

  const mobileRolesList = Object.keys(mobileRoles).map((role) => {
    return role.trim();
  });

  const groupsList = groups
    .filter((group) => group.name)
    .map((group) => {
      return group.name.trim();
    });

  const userTypeList = [
    "Asset/Product",
    "Product",
    "Asset-Warehouse",
    "Asset-Operations",
  ];

  const readFile = (file, onLoadCallback) => {
    var reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsArrayBuffer(file);
  };

  readFile(spreadsheet, (e) => {
    // use result in callback...
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheet_name_list = workbook.SheetNames;

    const parsedSheet = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );

    let message = "";
    let success = true;
    let formattedSpreadsheetRows = [];

    if (
      Object.keys(parsedSheet[0]).some(
        (header) => !spreadSheetHeaders.includes(header)
      )
    ) {
      message =
        "Some header(s) found in imported spreadsheet that do not exist on the template. Please make sure the column headers in the uploaded spreadsheet mirror the template exactly (case-sensitive).";
      success = false;
    } else {
      let emailObj = {};

      parsedSheet.forEach((row) => {
        const email = row["Email"]
          ? row["Email"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const firstName = row["First Name"]
          ? row["First Name"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const lastName = row["Last Name"]
          ? row["Last Name"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const userType = row["User Type"]
          ? row["User Type"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const mobileRole = row["Mobile Role"]
          ? row["Mobile Role"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r|,)/gm, "")
          : "";
        const consoleRole = row["LXConnect Role"]
          ? row["LXConnect Role"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r|,)/gm, "")
          : "";
        const jobTitle = row["Job Title"]
          ? row["Job Title"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const organization = row["Organization"]
          ? row["Organization"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const phoneNumber = row["Phone Number"]
          ? row["Phone Number"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";
        const notes = row["Notes"]
          ? row["Notes"]
              .toString()
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
          : "";

        if (
          firstName === "" ||
          lastName === "" ||
          email === "" ||
          mobileRole === "" ||
          consoleRole === "" ||
          userType === ""
        ) {
          message =
            "Please make sure the following required fields are filled out for all users: 'First Name', 'Last Name', 'User Type', 'Email', 'Mobile Role', 'LXConnect Role'";
          success = false;
          return;
        } else if (email && !isEmail(email)) {
          message =
            "Some user(s) have an invalid email address, please make sure the email is formatted correctly (e.g., test@gmail.com)";
          success = false;
          return;
        } else if (emailObj[email]) {
          message =
            "One or more of your users on the spreadsheet have duplicate email addresses. Each user's email must be unique.";
          success = false;
          return;
        } else if (!userTypeList.includes(userType)) {
          message =
            "A user type is present in the imported document that do not exist on the template. Please make sure the user types in the imported document mirror the options on the template exactly (case-sensitive), and that only one option is listed for each.";
          success = false;
          return;
        } else if (organization && !groupsList.includes(organization)) {
          message =
            "An organization is present in the imported document that do not exist on the template. Please make sure the organizations in the imported document mirror the options on the template exactly (case-sensitive), and that only one option is listed for each.";
          success = false;
          return;
        } else if (
          !consoleRolesList.includes(consoleRole) ||
          !mobileRolesList.includes(mobileRole)
        ) {
          message =
            "A mobile or LXConnect role is present in the imported document that do not exist on the template. Please make sure the mobile and LXConnect Role in the imported document mirror the options on the template exactly (case-sensitive), and that only one option is listed for each.";
          success = false;
          return;
        } else if (
          (consoleRole === "No Console Access" ||
            consoleRole === "No LXConnect Access") &&
          mobileRole === "No Mobile Access"
        ) {
          message =
            "User(s) can not have no LXConnect access AND no mobile access, please make sure each user in the imported document has access to either the console and/or the mobile platform.";
          success = false;
          return;
        } else {
          // passed all tests! push to duplicate email object and then push row to formatted spreadsheet
          emailObj[email] = true;

          let permissions = [];

          if (
            consoleRole &&
            (consoleRole.toLowerCase() !== "no console access" ||
              consoleRole.toLowerCase() !== "no lxconnect access")
          ) {
            permissions.push("console");
          }
          if (mobileRole && mobileRole.toLowerCase() !== "no mobile access") {
            permissions.push("mobile");
          }
          formattedSpreadsheetRows.push({
            Email: email,
            "First Name": firstName,
            "Last Name": lastName,
            Permissions: permissions.join(","),
            Roles: `${
              consoleRole === "No LXConnect Access"
                ? "No Console Access"
                : consoleRole
            },${mobileRole}`,
            jobTitle: jobTitle,
            notes: notes,
            consoleRole: userType === "Asset-Warehouse" ? "Asset" : userType,
            memberOf: organization,
            phone: phoneNumber,
          });
        }
      });
    }

    // build new spreadsheet from formattedSpreadSheetRows
    const fileName = `UsersFormatted.xlsx`;
    const ws = XLSX.utils.json_to_sheet(formattedSpreadsheetRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    // write ArrayBuffer of file
    const rawFile = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });

    // construct javascript File object from Array Buffer, send in response callback
    const file = new File([rawFile], fileName);

    file.path = fileName;

    response({
      success: success,
      message: message,
      spreadSheet: wb,
      file: file,
    });
  });
};
