import React, { useEffect, useState } from "react";
import { exportSpreadsheet } from "./xlsx";
import { Grid, makeStyles } from "@material-ui/core";
import {
  utc_to_local_12_hour_clock,
  utc_to_local_24_hour_clock,
} from "../../utils/date_time_utils";
import { retrieveRadioIds } from "./api";
import { formatLatLon } from "../../utils/lat_lon_utils";

import _ from "lodash";
import ControlledTable from "../../components/Tables/ControlledTable/ControlledTable";
import DisplayColumnsDropdown from "../../components/Tables/ControlledTable/components/DisplayColumnsDropdown/DisplayColumnsDropdown";
import FilterMenu from "./FilterMenu/FilterMenu";
import TabbedTables from "../../components/Tables/ControlledTable/TabbedTables";
import TabbedTable from "../../components/Tables/ControlledTable/components/TabbedTable";
import DeviceLogFilterMenu from "./DeviceLogFilterMenu/DeviceLogFilterMenu";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

function Table(props: any) {
  const {
    activeTab,
    apiUrl,
    assetData,
    customControl,
    devices,
    eventTypes,
    eventTypesMap,
    facilities,
    facilityArray,
    handleSave,
    organizationId,
    setInfoModal,
    usersMap,
    setState,
    state,
    timeZone,
    token,
    treeView,
    zones,
  } = props;
  const parent = devices[assetData.parentId] || {};
  const { device = {} } = parent;
  const { hasTelemetry = false } = device;

  const historyTableColumns = [
    {
      Header: "Date/Time",
      accessor: (row: any) =>
        row.timeOfLogLong ||
        utc_to_local_24_hour_clock(row.timeOfLog, timeZone) ||
        "DNP",
      id: "timeOfLog",
      Cell: (data: any) => {
        const { timeOfLog, propertiesMap } = data.row.original;

        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {utc_to_local_12_hour_clock(timeOfLog, timeZone)
              ? utc_to_local_12_hour_clock(timeOfLog, timeZone)
              : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Asset Tag",
      accessor: "assetTag",
      id: "assetTag",
      Cell: (data: any) => {
        const { assetTag, propertiesMap } = data.row.original;

        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {assetTag ? assetTag : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Event",
      accessor: "event",
      id: "event",
      Cell: (data: any) => {
        const { event, propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {event ? event : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "User",
      accessor: (row: any) => {
        return parseUserInfo(row);
      },
      id: "user",
      // disableSortBy: true,
      Cell: (data: any) => {
        const row = data.row.original;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row.original)}
          >
            {userInfo}
          </div>
        );
      },
    },
    {
      Header: "Facility Name",
      accessor: (row: any) => {
        return !row.latitude && !row.longitude
          ? "DNP"
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : "Not Registered";
      },
      id: "facility",
      disableSortBy: true,
      Cell: (data: any) => {
        const { facilityId, propertiesMap, latitude, longitude } =
          data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {!latitude && !longitude
              ? "DNP"
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
    },
    {
      Header: "Zone",
      id: "zone",
      disableSortBy: true,
      accessor: (row: any) => zones[row.zone?.zoneId]?.name || "",
      Cell: (data: any) => {
        const { zone = {} } = data.row.original;
        const { zoneId = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {zoneId && zones[zoneId] ? zones[zoneId].name : ""}
          </div>
        );
      },
    },
    {
      Header: "Bin Location",
      id: "binLocation",
      accessor: (row: any) => row.binLocation || "",
      Cell: (data: any) => {
        const { zone = {} } = data.row.original;
        const { binLocation = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row.original)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              data.row.original.zone
                ? binLocation
                : data.row.original.binLocation
                ? data.row.original.binLocation
                : data.row.original.propertiesMap &&
                  data.row.original.propertiesMap.binLocation
                ? data.row.original.propertiesMap.binLocation
                : ""
            }
          </div>
        );
      },
    },
    {
      Header: "State",
      accessor: "state",
      id: "state",
      Cell: (data: any) => {
        const { state = "DNP", propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {state ? state : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Latitude, Longitude",
      accessor: (row: any) => {
        const { latitude, longitude } = row;
        return `${latitude || "DNP"}, ${longitude || "DNP"}`;
      },
      id: "latLong",
      Cell: (data: any) => {
        const { latitude, longitude, propertiesMap } = data.row.original;

        const formattedLatLong =
          latitude && longitude ? formatLatLon(latitude, longitude) : "DNP";

        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {formattedLatLong}
          </div>
        );
      },
    },
  ];

  const deviceTableColumns = [
    {
      Header: "Date/Time",
      accessor: (row: any) =>
        row.timeOfLogLong ||
        utc_to_local_24_hour_clock(row.timeOfLog, timeZone) ||
        "DNP",
      id: "timeOfReport",
      Cell: (data: any) => {
        const { timeOfReport, propertiesMap } = data.row.original;
        return (
          <div
            onClick={() => onClick(data.row.original)}
            className={`${
              propertiesMap && (propertiesMap.note || propertiesMap.formData)
                ? "pointer"
                : "default-cursor text-dark"
            }`}
          >
            {utc_to_local_12_hour_clock(timeOfReport, timeZone)
              ? utc_to_local_12_hour_clock(timeOfReport, timeZone)
              : "DNP"}
          </div>
        );
      },
    },
    {
      Header: "Device Identifier",
      accessor: "deviceIdentifier",
      id: "deviceIdentifier",
      Cell: (data: any) => {
        const { assetId } = data.row.original;
        const { tag } = devices[assetId];
        return (
          <div onClick={() => onClick(data.row.original)} className="pointer">
            {tag}
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      id: "type",
      Cell: (data: any) => {
        const { reportType } = data.row.original;
        return (
          <div onClick={() => onClick(data.row.original)} className="pointer">
            {reportType}
          </div>
        );
      },
    },
    {
      Header: "Node",
      accessor: "node",
      id: "node",
      Cell: (data: any) => {
        const { node } = data.row.original;
        return (
          <div onClick={() => onClick(data.row.original)} className="pointer">
            {node}
          </div>
        );
      },
    },
    {
      Header: "Radio ID",
      accessor: "radioId",
      id: "radioId",
      Cell: (data: any) => {
        const { radio } = data.row.original;
        return (
          <div onClick={() => onClick(data.row.original)} className="pointer">
            {radio}
          </div>
        );
      },
    },
  ];

  const classes = useStyles();

  const onClick = (rowInfo: any) => {
    const { event, propertiesMap = {}, timeOfLog } = rowInfo;
    if (propertiesMap.note || propertiesMap.formData) {
      const content = (
        <div className="float-left w-100">
          <div className="my-2">
            {propertiesMap.note ? (
              <>
                <span className="font-weight-bold">Notes: </span>
                <p style={{ whiteSpace: "pre-wrap" }}>{propertiesMap.note}</p>
              </>
            ) : null}
            {propertiesMap.formData && propertiesMap.formData.length
              ? propertiesMap.formData
                  .filter((f: any) => f.fieldKey !== "note")
                  .map((f: any, idx: number) => (
                    <React.Fragment key={`${f.fieldKey || ""} - ${idx}`}>
                      <span className="mr-2 font-weight-bold">
                        {_.startCase(f.fieldKey ? f.fieldKey : "NO KEY")}{" "}
                      </span>
                      <p>{f.fieldValue ? f.fieldValue : "NO VALUE"}</p>
                    </React.Fragment>
                  ))
              : null}
          </div>
        </div>
      );

      setInfoModal({
        modalShow: true,
        title: `${event ? event : ``} ${utc_to_local_12_hour_clock(
          timeOfLog,
          timeZone
        )}`,
        content: content ? content : "",
      });
    }
  };

  const parseUserInfo = (row: any, firstLast = false) => {
    if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
      return `${row.firstName || ""}${
        row.firstName && row.lastName ? " " : ""
      }${row.lastName || ""}`;
    }
    if (row.appUserId && (row.firstName || row.lastName)) {
      return `${row.lastName || ""}${
        row.firstName && row.lastName ? ", " : ""
      }${row.firstName || ""}`;
    } else {
      return ``;
    }
  };

  const [displayColumnOptions, setDisplayColumnOptions] = React.useState<any>(
    state.filters?.displayColumnOptions
      ? state.filters.displayColumnOptions
      : historyTableColumns
          .map((col: any) => {
            return {
              id: col.id,
              header: col.Header,
              checked: true,
            };
          })
          .reduce((x, y) => {
            return { ...x, [y.id]: y };
          }, {})
  );

  const [radiosAndNodes, setRadiosAndNodes] = useState({
    nodes: {},
    radios: {},
  });
  const [hierarchyStatusData, setHierarchyStatusData] = useState([]);

  useEffect(() => {
    const hierarchyArray: any = [];
    if (hasTelemetry) {
      retrieveRadioIds({ ...props }).then((response: any) => {
        if (response) {
          setRadiosAndNodes(response);
        }
      });
    }

    // Forms the Hierarchy Status table data
    // Pushes the parent asset into the array
    hierarchyArray.push({
      assetTag: assetData.tag,
      binLocation:
        assetData.zone && assetData.zone.binLocation
          ? assetData.zone.binLocation
          : null,
      facility: facilities[assetData.facilityId]?.name || "",
      id: assetData.assetId,
      lastEvent: assetData.lastEvent,
      tableData: {
        isTreeExpanded: true,
      },
      zone:
        assetData.zone && assetData.zone.zoneId && zones[assetData.zone.zoneId]
          ? zones[assetData.zone.zoneId].name
          : null,
    });

    // Sets up the treeView.children to fit the schema
    if (treeView && treeView.children) {
      const recursiveChildExtractor = (childrenToBeExtracted: any) => {
        childrenToBeExtracted.forEach((child: any) => {
          hierarchyArray.push({
            ...child,
            expanded: true,
            tableData: {
              isTreeExpanded: true,
            },
          });
          if (child.children && child.children.length > 0) {
            recursiveChildExtractor(child.children);
          }
        });
      };

      treeView.children.forEach((item: any) => {
        // Push the current item into the hierarchyArray
        hierarchyArray.push({
          ...item,
          expanded: true,
          lastEvent: item.lastEvent,
          tableData: {
            isTreeExpanded: true,
          },
        });
        // If the item has children, push them through the recursive function. Which also checks if the children have children
        if (item.children && item.children.length > 0) {
          recursiveChildExtractor(item.children);
        }
      });
    }
    setHierarchyStatusData(hierarchyArray);
  }, [assetData, facilities, hasTelemetry, props, treeView, zones]);

  return hasTelemetry ||
    (treeView && treeView.children && treeView.children.length > 0) ? (
    <TabbedTables
      activeTab={activeTab}
      className="assetSnapshotTable"
      customControl={customControl}
      hideTotalEvents={activeTab === 2}
      useGlobalFilters={false}
    >
      <TabbedTable
        changeRowsPerPage={(limit: any) => {
          setState((prevState: any) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              limit: limit,
              start: 0,
            },
          }));
        }}
        changePage={(newPage: any) => {
          setState((prevState: any) => {
            return {
              ...prevState,
              page: newPage,
              filters: {
                ...prevState.filters,
                start: newPage * prevState.filters.limit,
              },
            };
          });
        }}
        columns={historyTableColumns.filter((col: any) =>
          displayColumnOptions && displayColumnOptions[col.id]
            ? displayColumnOptions[col.id].checked
            : true
        )}
        controlledCount={state.histories?.count || 0}
        controlledPageCount={Math.ceil(
          parseInt(state.histories?.count) / parseInt(state.filters?.limit)
        )}
        controlledPageIndex={state.page}
        controlledPageSize={state.filters.limit}
        data={state.histories?.assetHistories || []}
        defaultColumnOrder={state.filters.defaultColumnOrder}
        onColumnDrop={(colOrder: string[]) => {
          if (!_.isEqual(colOrder, state.filters.defaultColumnOrder)) {
            handleSave({ defaultColumnOrder: colOrder });
          }
        }}
        defaultSort={state.filters?.sorted || [{ id: "timeOfLog" }]}
        label="Event Log"
        setState={setState}
        state={state}
        stickyHeader
        toolbarChildren={
          <Grid className={classes.barChildrenContainer} container>
            {/* Open Menu Button */}
            <DisplayColumnsDropdown
              {...props}
              handleSave={(columnOptions: any) => {
                handleSave({
                  displayColumnOptions: columnOptions,
                });
              }}
              options={displayColumnOptions}
              setOptions={setDisplayColumnOptions}
              tableState={state}
            />
            <Grid className={classes.barChildren} item xs={3} xl={2}>
              {state && state.lists && state.filters ? (
                <FilterMenu
                  apiUrl={apiUrl}
                  displayColumnOptions={displayColumnOptions}
                  facilityArray={facilityArray}
                  eventTypes={eventTypes}
                  eventTypesMap={eventTypesMap}
                  usersMap={usersMap}
                  organizationId={organizationId}
                  setState={setState}
                  state={state}
                  token={token}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid className={classes.barChildren} item xs={3} xl={2}>
              <div
                className="exportIcon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  exportSpreadsheet(
                    state.histories?.assetHistories,
                    props.facilities,
                    state.filters.tz,
                    state.assetData,
                    zones
                  );
                }}
              >
                Export
                <i className="fas fa-file-export ml-2"></i>
              </div>
            </Grid>
          </Grid>
        }
        type="table"
      />
      <TabbedTable
        changeRowsPerPage={(limit: any) => {
          setState((prevState: any) => ({
            ...prevState,
            deviceLogFilters: {
              ...prevState.filters,
              limit: limit,
              start: 0,
            },
          }));
        }}
        changePage={(newPage: any) => {
          setState((prevState: any) => {
            return {
              ...prevState,
              devicePage: newPage,
              deviceLogFilters: {
                ...prevState.deviceLogFilters,
                start: newPage * prevState.deviceLogFilters.limit,
              },
            };
          });
        }}
        columns={deviceTableColumns.filter((col: any) =>
          displayColumnOptions && displayColumnOptions[col.id]
            ? displayColumnOptions[col.id].checked
            : true
        )}
        controlledCount={state.deviceTelemetry?.count || 0}
        controlledPageCount={Math.ceil(
          parseInt(state.deviceTelemetry?.count) /
            parseInt(state.deviceLogFilters?.limit)
        )}
        controlledPageIndex={state.devicePage}
        controlledPageSize={state.deviceLogFilters.limit}
        data={state.deviceTelemetry ? state.deviceTelemetry.logs : []}
        // TODO: need to figure this out
        defaultColumnOrder={state.filters.defaultColumnOrder}
        // TODO: need to figure this out
        onColumnDrop={(colOrder: string[]) => {
          if (!_.isEqual(colOrder, state.filters.defaultColumnOrder)) {
            handleSave({ defaultColumnOrder: colOrder });
          }
        }}
        defaultSort={
          state.deviceLogFilters?.sorted || [
            { id: "timeOfLog" },
            { id: "assetTag" },
          ]
        }
        label="Device Log"
        setState={setState}
        state={state}
        stickyHeader
        toolbarChildren={
          <Grid className={classes.barChildrenContainer} container>
            {/* Open Menu Button */}
            {/* <DisplayColumnsDropdown
              {...props}
              handleSave={(columnOptions: any) => {
                handleSave({
                  displayColumnOptions: columnOptions,
                });
              }}
              options={displayColumnOptions}
              setOptions={setDisplayColumnOptions}
              tableState={state}
            /> */}
            <Grid className={classes.barChildren} item xs={3} xl={2}>
              {state && state.lists && state.filters ? (
                <DeviceLogFilterMenu
                  apiUrl={apiUrl}
                  devices={devices}
                  state={state}
                  token={token}
                  organizationId={organizationId}
                  radiosAndNodes={radiosAndNodes}
                  setState={setState}
                />
              ) : (
                ""
              )}
            </Grid>
            {/* <Grid className={classes.barChildren} item xs={3} xl={2}>
              <div
                className="exportIcon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  exportSpreadsheet(
                    state.histories?.assetHistories,
                    props.facilities,
                    state.filters.tz,
                    state.assetData,
                    zones
                  );
                }}
              >
                Export
                <i className="fas fa-file-export ml-2"></i>
              </div>
            </Grid> */}
          </Grid>
        }
        type="table"
      />
      <TabbedTable
        columns={[
          { title: "Asset Tag", field: "assetTag", sorting: false },
          { title: "Last Event", field: "lastEvent", sorting: false },
          { title: "Facility Name", field: "facility", sorting: false },
          { title: "Zone", field: "zone", sorting: false },
          { title: "Bin Location", field: "binLocation", sorting: false },
          { title: "Quantity Needed", field: "quantityNeeded", sorting: false },
          { title: "Quantity Picked", field: "quantityPicked", sorting: false },
        ]}
        data={hierarchyStatusData ? hierarchyStatusData : []}
        label="Hierarchy Status"
        options={{
          selection: true,
        }}
        parentChildData={(row: any, rows: any) =>
          rows.find((a: any) => a.id === row.parentId)
        }
        type="tree-table"
        toolbarChildren={<></>}
      />
    </TabbedTables>
  ) : (
    <ControlledTable
      columns={historyTableColumns.filter((col: any) =>
        displayColumnOptions && displayColumnOptions[col.id]
          ? displayColumnOptions[col.id].checked
          : true
      )}
      controlledCount={state.histories?.count || 0}
      controlledPageCount={Math.ceil(
        parseInt(state.histories?.count) / parseInt(state.filters?.limit)
      )}
      controlledPageIndex={state.page}
      controlledPageSize={state.filters.limit}
      data={state.histories?.assetHistories || []}
      defaultColumnOrder={state.filters.defaultColumnOrder}
      onColumnDrop={(colOrder: string[]) => {
        if (!_.isEqual(colOrder, state.filters.defaultColumnOrder)) {
          handleSave({ defaultColumnOrder: colOrder });
        }
      }}
      defaultSort={state.filters?.sorted || [{ id: "timeOfLog" }]}
      setState={setState}
      state={state}
      stickyHeader
      toolbarChildren={
        <Grid className={classes.barChildrenContainer} container>
          {/* Open Menu Button */}
          <DisplayColumnsDropdown
            {...props}
            handleSave={(columnOptions: any) => {
              handleSave({
                displayColumnOptions: columnOptions,
              });
            }}
            options={displayColumnOptions}
            setOptions={setDisplayColumnOptions}
            tableState={state}
          />
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            {state && state.lists && state.filters ? (
              <FilterMenu
                apiUrl={apiUrl}
                displayColumnOptions={displayColumnOptions}
                facilityArray={facilityArray}
                eventTypes={eventTypes}
                eventTypesMap={eventTypesMap}
                usersMap={usersMap}
                organizationId={organizationId}
                setState={setState}
                state={state}
                token={token}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid className={classes.barChildren} item xs={3} xl={2}>
            <div
              className="exportIcon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                exportSpreadsheet(
                  state.histories?.assetHistories,
                  props.facilities,
                  state.filters.tz,
                  state.assetData,
                  zones
                );
              }}
            >
              Export
              <i className="fas fa-file-export ml-2"></i>
            </div>
          </Grid>
        </Grid>
      }
    />
  );
}

export default Table;
