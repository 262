import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { onLogin } from "../globalStore/slices/user/userSlice";
import { init } from "../globalStore/slices/organization/organizationSlice";
import { initDevices } from "../globalStore/slices/devices/devicesSlice";
import { initAssets } from "../globalStore/slices/assets/thunks/init";
import { initAssetHistory } from "../globalStore/slices/assetHistory/thunks/init";

import NoAuthLayout from "../components/Layout/NoAuthLayout";

import * as Pages from "../pages/index";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export default function NoAuthContainer(props) {
  const { setAuth } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const NoAuthRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => <Component {...props} apiUrl={apiUrl} />}
      />
    );
  };

  return (
    <NoAuthLayout>
      <Switch>
        <NoAuthRoute
          exact
          path="/appUsers/:token/invitation"
          component={Pages.UserInvitation}
        />
        <NoAuthRoute
          exact
          path="/appUsers/:token/forgotPassword"
          component={Pages.ForgotPassword}
        />
        <NoAuthRoute
          exact
          path="/appUsers/:token/passwordReset"
          component={Pages.ForgotPassword}
        />
        <NoAuthRoute
          exact
          path="/users/:token/forgotPassword"
          component={Pages.ForgotPassword}
        />
        <NoAuthRoute
          path={["/login", "/"]}
          component={({ ...rest }) => (
            <Pages.Login
              {...props}
              {...rest}
              apiUrl={apiUrl}
              retrieveUserAndToken={(user, token, url) => {
                // retrieveUserAndToken is a callback function that gets fired from the login view upon login success
                // after login success, we dispatch the 'onLogin' action that initializers the user's data store
                dispatch(
                  onLogin({
                    user,
                    token,
                  })
                ).then(() => {
                  // dispatching initializing functions, init() initializes the organization's data store, then we fetch some of the initial data for some of our views
                  dispatch(init()).then(() => {
                    // these initializors fetch initial data based on user preferences for the devices, asset status, and asset history views.
                    dispatch(initDevices());
                    dispatch(initAssets());
                    dispatch(initAssetHistory());
                    setAuth((auth) => {
                      return {
                        ...auth,
                        isAuthed: true,
                      };
                    });

                    history.push(url);
                  });
                });
              }}
            />
          )}
        />
      </Switch>
    </NoAuthLayout>
  );
}
