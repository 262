import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import DailyAssemblyEventPieChart from "../../../components/Widgets/DailyAssemblyEventPieChart/DailyAssemblyEventPieChart";
import EventNotificationOnCategory from "../../../components/Widgets/EventNotificationOnCategory/EventNotificationOnCategory";
import AssemblyStatusTable from "./AssemblyStatusTable/AssemblyStatusTable";
import ItemsShortedTable from "./ItemsShortedTable/ItemsShortedTable";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: "45vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function AssetOperationsDashboard(props: any) {
  const { apiUrl, userId, token, organizationId, timeZone } = props;

  const { organization, classifications } = useSelector(
    (state: any) => state.organization,
    isEqual
  );
  const classes = useStyles();

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item md={10} xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <AssemblyStatusTable
              apiUrl={apiUrl}
              token={token}
              organization={organization}
              organizationId={organizationId}
              timeZone={timeZone}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={2}>
        <Card>
          <CardContent className={classes.cardContent}>
            <EventNotificationOnCategory
              apiUrl={apiUrl}
              organization={organization}
              organizationId={organizationId}
              token={token}
              userId={userId}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className={classes.cardContent}>
          <CardContent>
            <DailyAssemblyEventPieChart
              organization={organization}
              apiUrl={apiUrl}
              token={token}
              organizationId={organizationId}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={8} xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <ItemsShortedTable
              apiUrl={apiUrl}
              classifications={classifications}
              token={token}
              organization={organization}
              organizationId={organizationId}
              timeZone={timeZone}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
