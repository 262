import React from "react";

const TabbedTable = (props: any) => {
  const columns = React.useMemo(() => props.columns, [props.columns]);

  const data = React.useMemo(() => props.data, [props.data]);

  return (
    <TabbedTable
      // data, columns, state, setState, saveFilters function
      columns={columns}
      data={data}
      label={props.label}
      saveFilterSettings={props.saveFilterSettings}
      setState={props.setState}
      state={props.state}
      // controlled props
      controlledCount={props.controlledCount || 0}
      controlledPageCount={props.controlledPageCount || 0}
      controlledPageIndex={props.controlledPageIndex || 0}
      controlledPageSize={props.controlledPageSize || 0}
      defaultSort={props.defaultSort}
      // select checkboxes
      clearSelected={props.clearSelected}
      isSelect={props.isSelect}
      onSelect={props.onSelect}
      selectAll={props.selectAll}
      // other
      defaultColumnOrder={props.defaultColumnOrder}
      globalFilterLabel={props.globalFilterLabel}
      language={props.language || "en"}
      onColumnDrop={props.onColumnDrop}
      stickyHeader={props.stickyHeader}
      tableHeader={props.tableHeader}
      toolbarChildren={props.toolbarChildren}
      // Options props - Set here for Tree Table
      type={props.type}
      parentChildData={props.parentChildData}
      options={props.options}
    />
  );
};

export default TabbedTable;
