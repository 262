import React from "react";
import { Modal } from "react-bootstrap";

export default class CenteredModal extends React.Component {
  render() {
    const { label, content } = this.props;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100"><div className="">{label}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content}
        </Modal.Body>
      </Modal>
    );
  }
}
