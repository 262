import { useEffect, useState, useRef, useCallback } from "react";
import { isEqual, isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import { Paper, makeStyles, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { thunks } from "../../../globalStore/slices/assets/assetsSlice";
import { checkSelectedAssets } from "./utils";
import { getAssetsById } from "./api";

import Map from "./Map/Map";
import MapTable from "./Map/MapTable";
import AssignDevice from "../assignDevice/AssignDevice";
import CenteredModal from "../../../components/Modals/CenteredModal";
import ImportAssets from "../ImportAssets/ImportAssets";
import Loading from "../../../components/Loading/Loading";
// import MaterialConfirmationModal from "../../../components/Modals/MaterialConfirmationModal";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import Table from "./Table/Table";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    minHeight: "64vh",
    maxHeight: "64vh",
  },
  map: {
    padding: theme.spacing(2),
    minHeight: "62vh",
    maxHeight: "62vh",
  },
  mapTable: {
    padding: theme.spacing(2),
    minHeight: "62vh",
    maxHeight: "62vh",
    overflow: "auto",
  },
}));

export default function AssetStatus(props: any) {
  const dispatchGlobal = useDispatch();
  const saveFilterSettings: any = thunks.assets.saveFilterSettings;
  const showLastEvents: any = thunks.assets.showLastEvents;
  const parentIds = useSelector((state: any) => state.assets.parentIds);

  const classes = useStyles();
  const { apiUrl, token, userId, organizationId, usersConsoleRole, timeZone } =
    props;

  // OK to destructure, as no changes to org data takes place in this page
  const {
    organization,
    zones,
    facilities,

    devices,
    classifications,
    usersMap,
    products,
  } = useSelector((state: any) => state.organization, isEqual);

  const assetTypes =
    organization && organization.assetTypes
      ? [...organization.assetTypes].sort()
      : [];

  const eventTypes =
    organization && organization.eventTypesMap
      ? Object.keys(organization.eventTypesMap).sort()
      : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a: any, b: any) => {
        return (a.name || "").localeCompare(b.name || "");
      })
    : [];

  const allDevices = Object.keys(devices).map((k) => devices[k]);
  const availableDevices = allDevices.filter(
    (item) => item.device.status !== "assigned"
  );

  const assetsStore = useSelector((state: any) => state.assets, isEqual);

  const { filters: initFilters = {} } = assetsStore;

  // make sure dates are formatted correctly for our datepicker components
  const startDate = initFilters.startDate
    ? new Date(initFilters.startDate)
    : null;
  const endDate = initFilters.endDate ? new Date(initFilters.endDate) : null;

  const [state, setState] = useState<any>({
    ...assetsStore,
    filters: { ...initFilters, startDate, endDate },
    lists: {
      assetTypes: assetTypes,
      eventTypes: eventTypes,
      eventTypesMap: organization.eventTypesMap || {},
      facilityArray: facilityArray,
      assetCategoriesList:
        organization.assetCategoriesList || organization.assetCategories || [],
    },
    selectedAssets: {},
    allDevices,
    availableDevices,
    availableZones: Object.keys(zones).map((k) => {
      return { label: zones[k].name, value: zones[k].zoneId };
    }),
    mapTableData: [],
    page: 0,
    mounted: true,
  });

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({
    modalShow: false,
    title: "",
    content: {},
  });

  const modalClose = () =>
    setModal({ modalShow: false, title: "", content: "" });

  const [dialog, setDialog] = useState({
    dialogShow: false,
  });

  const [isRedirect, setIsRedirect] = useState(false);

  const [confirmationModalShow, setConfirmationModalShow] = useState({
    modalShow: false,
    text: "",
    error: false,
  });

  const [importAssetsModalTitle, setImportAssetsModalTitle] =
    useState("Import Assets");

  const isMountedRef = useRef(false);

  // upon first mount, this function will check for updates in the store by dispatching the showLastEvents thunk
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(showLastEvents(initFilters)).then((res: any) => {
          isMountedRef.current = true;
        });
      }
    },
    [dispatchGlobal, showLastEvents, initFilters]
  );

  // intializing effect, this is for checking for updated asset status on first mount using initial filters. Using a ref to ensure it only fires on first mount.
  useEffect(() => {
    init(isMountedRef.current);
  }, [init]);

  // this effect fires whenever filters are updated (either in the filter menu or by changing the page or row limit).
  // it checks the isMountedRef to prevent firing on first mount, sets loading to true, and then dispatches the showLastEvents thunk to our store to searchAssets and update state
  // it then takes the return from our showLastEvents thunk and updates the local state
  useEffect(() => {
    if (isMountedRef.current) {
      setLoading(true);
      dispatchGlobal(showLastEvents(state.filters)).then((results: any) => {
        setLoading(false);
        setState((prevState: any) => {
          return {
            ...prevState,
            assets: results.payload.assets,
            count: results.payload.count,
            page: Math.floor(state.filters.start / state.filters.limit),
            // reset map table data
            mapTableData: [],
          };
        });
      });
    }
  }, [state.filters, dispatchGlobal, showLastEvents]);

  const handleSave = async (filters: any) => {
    return await dispatchGlobal(saveFilterSettings(filters));
  };

  async function checkDevices() {
    let isValid = false;
    let assets;
    let assetIds = [
      ...new Set(
        Object.keys(state.selectedAssets).map((asset) => {
          return `asset_id:${asset}`;
        })
      ),
    ];

    // Retrieve up to date asset information
    assets = await getAssetsById(
      { apiUrl, token, organizationId },
      assetIds.join(" OR "),
      1000
    );

    // Checks to verify selected asset(s) do not currently have a device associated
    const hasParentId = assets.assets.find((element: any) => element.parentId);

    // Checks to verify they actually have enough devices for the amount of selected assets
    const hasEnoughDevices =
      state.availableDevices.length < Object.keys(state.selectedAssets).length;

    if (hasEnoughDevices) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected more assets than there are available devices. Please adjust your selections.",
        error: true,
      });
    } else if (hasParentId) {
      setConfirmationModalShow({
        modalShow: true,
        text: "You have selected one or more assets that already has a device assigned to it.",
        error: true,
      });
    } else isValid = true;

    if (isValid) {
      setModal({
        modalShow: true,
        title: "Assign a Device",
        content: (
          <AssignDevice
            allDevices={state.allDevices}
            availableDevices={state.availableDevices}
            apiUrl={apiUrl}
            token={token}
            selectedAssets={state.selectedAssets}
            setModal={setModal}
            setConfirmationModalShow={(value: any) => {
              setConfirmationModalShow(value);
              setTimeout(() => {
                setConfirmationModalShow({
                  modalShow: false,
                  text: "",
                  error: false,
                });
              }, 1000);
            }}
            timezone={timeZone}
          />
        ),
      });
    }
  }

  return (
    <div className="container-fluid mt-3 asset-history">
      {isRedirect ? (
        <Redirect
          to={{
            pathname: "/generateCQR/",
            state: { selectedAssets: state.selectedAssets },
          }}
        />
      ) : (
        ""
      )}

      <CenteredModal
        onHide={modalClose}
        show={modal.modalShow}
        content={
          <div className="container">
            <div className="row">
              <div className="col-md-12">{modal.content}</div>
            </div>
          </div>
        }
        label={modal.title}
      />
      <ModalDialog
        handleClose={() => {
          setDialog({
            dialogShow: false,
          });
        }}
        open={dialog.dialogShow}
        title={importAssetsModalTitle}
        content={
          <ImportAssets
            availableDevices={state.availableDevices}
            onHide={() => {
              setDialog({
                dialogShow: false,
              });
              setImportAssetsModalTitle("Import Assets");
            }}
            onSuccess={() => {
              // force refetch via useEffect
              setState({
                ...state,
                filters: {
                  ...state.filters,
                },
              });
            }}
            token={token}
            setImportAssetsModalTitle={setImportAssetsModalTitle}
            apiUrl={apiUrl}
            facilities={facilities}
            classifications={classifications?.active || {}}
            organization={organization}
            setConfirm={setConfirmationModalShow}
          />
        }
      />
      {/* <MaterialConfirmationModal
        closeModal={() => {
          setConfirmationModalShow((prevState) => ({
            ...prevState,
            modalShow: false,
          }));
        }}
        content={confirmationModalShow.text}
        modalOpen={confirmationModalShow.modalShow}
        severity={confirmationModalShow.error ? "error" : "success"}
      /> */}

      {/* My assumption is that we are going to need to change the material confirmation modal to match the snack bar but for now ill set it here */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={confirmationModalShow.modalShow}
        onClose={() => {
          setConfirmationModalShow((prevState) => ({
            ...prevState,
            modalShow: false,
          }));
        }}
      >
        <MuiAlert
          onClose={() => {
            setConfirmationModalShow((prevState) => ({
              ...prevState,
              modalShow: false,
            }));
          }}
          severity={confirmationModalShow.error ? "error" : "success"}
        >
          {confirmationModalShow.text}
        </MuiAlert>
      </Snackbar>
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-3 text-md-left text-center">
          <h3>Asset Status</h3>
        </div>
        <div className="col-12 col-md-9 text-md-right text-center">
          <button
            className={`ml-3 mb-1 btn btn-primary${
              state.displayMap ? ` active` : ``
            }`}
            onClick={() => {
              setState((s: any) => {
                return {
                  ...s,
                  displayMap: !s.displayMap,
                };
              });
            }}
          >
            {state.displayMap ? `View Assets on Table` : `View Assets on Map`}
          </button>

          {/* Lite Users are unable to manipulate data */}
          {usersConsoleRole === "Lite" || state.displayMap ? null : (
            <>
              {/* Assign a Device */}
              <button
                className={`ml-3 mb-1 btn btn-primary ${
                  !isEmpty(state.selectedAssets) ? "" : "disabled"
                }`}
                onClick={() => {
                  if (!isEmpty(state.selectedAssets)) {
                    checkDevices();
                  }
                }}
              >
                Assign a Device
              </button>
              <button
                className={`ml-3 mb-1 btn btn-primary ${
                  !isEmpty(state.selectedAssets) ? "" : "disabled"
                }`}
                type="button"
                onClick={() => {
                  if (!isEmpty(state.selectedAssets)) {
                    checkSelectedAssets(
                      { organizationId, apiUrl, token },
                      state
                    ).then((results) => {
                      const { faultyContent = [], isError = true } = results;
                      if (!isError) {
                        setIsRedirect(true);
                      } else {
                        setConfirmationModalShow({
                          modalShow: true,
                          text: `The Asset Tag(s)${faultyContent.map((item) => {
                            return ` ${item}`;
                          })} are shown as still being in circulation, therefore the CQR cannot be regenerated. If you need to reprint a CQR for an asset, please contact your organizations Admin User.`,
                          error: true,
                        });
                      }
                    });
                  }
                }}
              >
                Generate CQRs
              </button>
              <button
                className="ml-3 mb-1 btn btn-primary"
                onClick={() => {
                  setDialog({ dialogShow: true });
                }}
              >
                Import Assets
              </button>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {loading ? <Loading color="#5884F5" /> : null}
          {state.filters ? (
            <>
              {state.displayMap ? (
                <>
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12} className={classes.map}>
                        <Map
                          apiUrl={apiUrl}
                          organizationId={organizationId}
                          token={token}
                          classifications={classifications}
                          setLoading={setLoading}
                          setConfirm={setConfirmationModalShow}
                          facilities={facilities}
                          state={state}
                          setState={setState}
                          eventTypesMap={state.lists.eventTypesMap}
                          timeZone={timeZone}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.mapTable}>
                        <p>
                          Hover over marker and click "See Data" to view
                          multiple assets from the same location in the table
                          below.
                        </p>
                        <MapTable
                          mapTableData={state.mapTableData}
                          timeZone={state.filters.tz ? state.filters.tz : ""}
                          facilities={facilities}
                          zones={zones}
                          setState={setState}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              ) : (
                <Table
                  allDevices={state.allDevices}
                  apiUrl={apiUrl}
                  classifications={classifications}
                  defaultColumnOrder={state.filters?.defaultColumnOrder}
                  facilities={facilities}
                  handleSave={handleSave}
                  organizationId={organizationId}
                  page={state.page}
                  parentIds={parentIds}
                  products={products}
                  setConfirm={setConfirmationModalShow}
                  setLoading={setLoading}
                  setModal={setModal}
                  setState={setState}
                  state={state}
                  timeZone={timeZone}
                  token={token}
                  usersMap={usersMap}
                  zones={zones}
                  userId={userId}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
