import { createAsyncThunk } from "@reduxjs/toolkit";
export const initAssets = createAsyncThunk(
  "assets/init",
  async (_, thunkAPI) => {
    const { getState, extra: apiService, dispatch } = thunkAPI;
    // expected user to be initialized before initializing organization
    const {
      token = "",
      organizationId = "",
      userPropertiesMap = {},
    } = getState().user;

    const { timeZone = {} } = userPropertiesMap.propertiesMap;

    const { classifications } = getState().organization;

    const { assetStatusTableSettings = {} } = userPropertiesMap?.propertiesMap;

    const initFilters = {
      assetIds: [],
      assetCategories: [],
      assetType: null,
      defaultColumnOrder: [],
      endDate: null,
      lastEvents: [],
      limit: 25,
      locals: [],
      locations: [],

      sorted: [
        {
          id: "timeOfLog",
          desc: true,
        },
      ],
      start: 0,
      startDate: null,
      tz: timeZone,
      users: [],
      zones: null,
      ...(assetStatusTableSettings || {}),
    };

    const data = await apiService.assets
      .showLastEvents({ token, organizationId, classifications }, initFilters)
      .then(async (data) => {
        if (data.error) {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        } else if (data.success) {
          return await apiService.assets
            .retrieveParentIds({ token })
            .then((res) => {
              return {
                success: true,
                filters: initFilters,
                parentIds: res.assets.reduce(
                  (x, y) => ({
                    ...x,
                    [y.assetId]: y,
                  }),
                  {}
                ),
                assets: data.assets,
                count: data.count,
                // page: initPage,
              };
            });
        } else {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        }
      });

    // data.reduxRequestId = thunkAPI.requestId;
    return data;
  }
);
