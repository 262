import { searchDevices } from "../../../utils/api";
import AsyncReactSelectCheckboxes from "../../../../../components/Selects/AsyncReactSelectCheckbox";

export const FilterByTag = (props: any) => {
  const { apiUrl, token, organizationId, setConfirm, filters, setFilters } =
    props;

  const asyncSerialNumberSearchLoadOptions = async (
    inputValue: any = "",
    loadedOptions: any
  ) => {
    const results = await searchDevices(
      { apiUrl, token, organizationId },
      inputValue,
      loadedOptions.length > 0 ? loadedOptions.length : 0,
      300
    ).then((results: any) => {
      if (results.error) {
        return setConfirm({
          modalShow: true,
          text: results.error,
          error: true,
        });
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((device: any) => {
                return {
                  label: device.tag,
                  value: device.tag,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  return (
    <AsyncReactSelectCheckboxes
      loadOptions={asyncSerialNumberSearchLoadOptions}
      onChange={(e: any) => {
        setFilters({
          ...filters,
          tags: e,
        });
      }}
      value={filters.tags}
    />
  );
};
