import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ImageModal from "./ImageModal";
import SimpleButton from "../../components/Forms/FieldTypes/Button";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "1rem 0",
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    padding: "0 1rem",
  },
  files: {
    margin: "1rem 0",
  },
  list: {
    padding: "0",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    width: 400,
  },
}));

const CancelButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#B71D1A",
    width: "100%",
    "&:hover": {
      backgroundColor: "#FFC854",
    },
  },
}))(Button);

export default function ProductImages(props) {
  const { checkImages, images = [], readOnly, setState } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const preview = URL.createObjectURL(file);
      const { name, type } = file;
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const image = reader.result;
        setState((prevState) => ({
          ...prevState,
          images: [
            ...prevState.images,
            { name: name, imageType: type, path: name, image, preview },
          ],
        }));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/gif",
  });
  const classes = useStyles();

  const files = images.map((file, index) => {
    const { imageId, name, preview, url } = file;
    return (
      <div className={classes.root} key={`${index}`}>
        <Grid item xs={6}>
          <SimpleTextField
            error={checkImages && name.length === 0}
            id="urlBase"
            label={"Image Name"}
            onChange={(event) => {
              event.persist();
              setState((prevState) => {
                prevState.images[index].name = event.target.value;
                if (file.imageId) {
                  prevState.imagesToEdit[file.imageId] = file;
                }
                return { ...prevState };
              });
            }}
            readOnly={readOnly}
            required
            value={name}
          />
        </Grid>
        <Grid item xs={3} className={classes.buttonContainer}>
          <SimpleButton
            className={classes.button}
            color="primary"
            content="View"
            onClick={() => {
              setSelectedImage({ preview: imageId ? url : preview, name });
              setModalOpen(true);
            }}
          />
        </Grid>
        <Grid item xs={3} className={classes.buttonContainer}>
          {readOnly ? null : (
            <CancelButton
              onClick={() => {
                setState((prevState) => {
                  const newImagesArray = [...prevState.images];

                  // We grab the item we want to delete and throw it into the "imagesToDelete" array.
                  // Since we delete, only when the Submit button is clicked
                  let itemForDeletion = {};

                  itemForDeletion = newImagesArray[index];
                  newImagesArray.splice(index, 1);
                  return {
                    ...prevState,
                    images: newImagesArray,
                    imagesToDelete: [
                      ...prevState.imagesToDelete,
                      itemForDeletion,
                    ],
                  };
                });
              }}
            >
              Delete
            </CancelButton>
          )}
        </Grid>
      </div>
    );
  });

  return (
    <>
      <ImageModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        image={selectedImage}
      />
      <Grid item xs={12}>
        <section className="container">
          {readOnly ? null : (
            <div {...getRootProps({ className: "dropzone pointer" })}>
              <input {...getInputProps()} />
              <p>
                Drag 'n' drop or click to select images files (only
                .jpeg/.jpg/.png/.gif will be accepted)
              </p>
            </div>
          )}
          {images.length > 0 ? (
            <aside className={classes.files}>
              <h4>Images</h4>
              <ul className={classes.list}>{files}</ul>
            </aside>
          ) : null}
        </section>
      </Grid>
    </>
  );
}
