const escapeCharacters = (input: string) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const searchAssets = async (
  props: any,
  input: string,
  start: number = 0,
  limit: number = 300
) => {
  const { apiUrl, token = "", organizationId = "" } = props;
  let escapedInput = escapeCharacters(input);

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        escapedInput ? `tag:${escapedInput}*` : ``,
      ],
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error:
          "Failed to fetch asset data, please contact system administrator.",
      };
    });

  return results;
};
