import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import SimpleButton from "../../components/Forms/FieldTypes/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#3d5ba9",
  },
  content: {
    textAlign: "center",
  },
  button: {
    margin: "1rem 0",
  },
}));

export default function ConfirmationDialog(props) {
  const {
    changePage,
    cqrLayoutName,
    modalShow,
    onChange,
    onSubmit,
    open,
    setState,
  } = props;
  const classes = useStyles();
  // const [saveCQRLayout, setSaveCQRLayout] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={() => {
        // setSaveCQRLayout(false);
        setState((prevState) => ({
          ...prevState,
          cqrLayoutName: { value: "", error: false },
        }));
        modalShow();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className={classes.title}
        id="alert-dialog-title"
        // onClick={() => {
        //   setSaveCQRLayout((prevState) => !prevState);
        // }}
      >
        Save CQR Layout
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {/* We are removing the functionality of not forcing the user to save template. Thus,
          this value will be hard coded until we change that */}
          {true ? (
            <>
              <Grid className={classes.content} item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Define CQR Layout Name
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SimpleTextField
                  id="outlined-basic"
                  error={cqrLayoutName.error}
                  helperText={cqrLayoutName.error ? "Required Field" : ""}
                  variant="outlined"
                  value={cqrLayoutName.value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                />
              </Grid>
              <Grid className={classes.button} item xs={12}>
                <SimpleButton
                  variant="contained"
                  onClick={() => {
                    if (cqrLayoutName.value.length === 0) {
                      setState((prevState) => ({
                        ...prevState,
                        cqrLayoutName: { value: "", error: true },
                      }));
                    } else {
                      onSubmit();
                    }
                  }}
                >
                  Submit
                </SimpleButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Would you like to save as CQR Layout?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DialogActions>
                  <Button
                    // onClick={() => {
                    //   setSaveCQRLayout(true);
                    // }}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      changePage();
                    }}
                    color="primary"
                  >
                    No
                  </Button>
                </DialogActions>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
