import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { date_interval_util } from "../../../utils/date_time_utils";
import { isEqual } from "lodash";

import { Grid, IconButton, makeStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";

import { showLastEvents } from "./utils";

import Map from "../../Maps/AssetStatusMap/AssetStatusMap";
import FilterMenu from "./FilterMenu/FilterMenu";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: "35vh",
    display: "flex",
    justifyContent: "center",
  },
  filterMenuContainer: {
    padding: theme.spacing(2),
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "rgb(46, 105, 255, 0.1)",
    },
  },
}));

export default function OverviewMap(props: any) {
  const {
    setAlertModal,
    token,
    apiUrl,
    organizationId,
    classifications,
    timeZone,
  } = props;

  const mapSettingsFromStore = useSelector(
    (state: any) =>
      state.user.userPropertiesMap.propertiesMap?.widgetSettings
        ?.overviewMapSettings,
    isEqual
  );

  // setting up some default filters and date intervals
  const defaultDateIntervals = date_interval_util("weekly", timeZone);

  const filterDefaults = {
    defaultZoomLevel: 4,
    limit: 300,
    startDate: new Date(defaultDateIntervals.startDate),
    endDate: new Date(defaultDateIntervals.endDate),
    dateSelection: "weekly",
  };

  const overviewMapSettings = mapSettingsFromStore || filterDefaults;

  // make sure date interval is updated, e.g., 'daily' updates with correct time relative to user's current time, etc.
  const dateIntervals =
    overviewMapSettings.dateSelection !== "custom"
      ? date_interval_util(overviewMapSettings.dateSelection, timeZone)
      : {
          startDate: overviewMapSettings.startDate,
          endDate: overviewMapSettings.endDate,
        };

  const initFilters = useMemo(() => {
    const filters = {
      ...overviewMapSettings,
      startDate: new Date(dateIntervals.startDate),
      endDate: new Date(dateIntervals.endDate),
    };
    return filters;
  }, [overviewMapSettings, dateIntervals.startDate, dateIntervals.endDate]);

  const classes = useStyles();

  const [state, setState] = useState({
    assets: [],
  });

  const [view, setView] = useState({
    mapView: true,
  });

  const mountedRef = useRef(false);

  // wrapping searchAssets in a useCallback hook so we can use it inside the useEffect
  const searchAssets = useCallback(
    async (filters: any = initFilters) => {
      const results = await showLastEvents(
        { token, apiUrl, organizationId, classifications },
        filters
      ).then((res) => {
        if (res.error) {
          setAlertModal({
            alertModalContent: res.error,
            alertModalOpen: true,
            alertModalSeverity: "error",
          });
        } else {
          setState((s: any) => {
            mountedRef.current = true;
            return {
              assets: res.assets,
            };
          });
        }
      });

      return results;
    },
    [initFilters, token, apiUrl, organizationId, setAlertModal, classifications]
  );

  useEffect(() => {
    if (!mountedRef.current) {
      searchAssets();
    }
  }, [searchAssets]);

  return (
    <Grid container id="overviewMapWidget">
      <Grid container justify={"space-between"}>
        <h5>Overview Map</h5>
        <IconButton
          id={"overviewMapFilterViewButton"}
          className={classes.iconButton}
          onClick={() => {
            setView((v) => {
              return {
                mapView: !v.mapView,
              };
            });
          }}
        >
          {view.mapView ? <SettingsIcon /> : <CloseIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        {" "}
        {view.mapView ? (
          <div className={classes.mapContainer} id={"overviewMapMapView"}>
            <Map
              height={"100%"}
              width={"90%"}
              state={state}
              facilities={props.facilities}
              defaultZoomLevel={overviewMapSettings.defaultZoomLevel || null}
              timeZone={props.timeZone}
            />
          </div>
        ) : (
          <div className={classes.filterMenuContainer}>
            <FilterMenu
              initFilters={initFilters}
              filterDefaults={filterDefaults}
              handleSaveWidgetSettings={props.handleSaveWidgetSettings}
              setAlertModal={props.setAlertModal}
              setView={setView}
              searchAssets={searchAssets}
              {...props}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
