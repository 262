import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import { Grid, Button } from "@material-ui/core";
export default function Warningdialog(props: any) {
  const content = (
    <Grid
      container
      justify="center"
      spacing={2}
      style={{ textAlign: "center" }}
    >
      <Grid item xs={12}>
        {props.subHeader}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={props.onSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth
          color="secondary"
          variant="outlined"
          onClick={props.onDiscard}
        >
          Discard
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          fullWidth
          color="secondary"
          variant="outlined"
          onClick={props.closeModal}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <>
      <ModalDialog
        title={props.title}
        content={content}
        open={props.open}
        handleClose={props.closeModal}
      />
    </>
  );
}
