import { useState, useEffect, useCallback, useRef } from "react";
import { isEqual, omit } from "lodash";
import request from "superagent";
import ActionButtons from "../../components/Buttons/ActionButtons";
import EditProduct from "./EditProduct";
import CreateBatch from "./CreateBatch/CreateBatch";
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import CenteredModal from "../../components/Modals/CenteredModal";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import UncontrolledTable from "../../components/Tables/UncontrolledTable/UncontrolledTable";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { thunks } from "../../globalStore/slices/organization/organizationSlice";

import { handleExport } from "./utils";

export default function Products(props) {
  const { apiUrl, token, userRoles, organizationId, timeZone } = props;
  const { organization, products, facilities } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const [state, setState] = useState({
    assetTypes: [],
    confirmationText: "",
    error: false,
    isCreate: false,
    modalShow: false,
    modalTitle: "",
    itemLevelDataElements: [],
    products: [],
    productHash: {},
    productTypes: [],
    facilities: {},
    redirect: false,
    selectedProduct: [],
    selectedProducts: {},
  });

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveProducts: retrieveProductsThunk } = thunks.products;
  const { retrieveBatches: retrieveBatchesThunk } = thunks.batches;

  // onSuccess function retrieves products, patch/diff with redux store, and resets location state
  const retrieveProducts = async () => {
    location.state = {};
    return await dispatchGlobal(retrieveProductsThunk()).then((res) => {
      return res.payload?.products || {};
    });
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveProductsThunk());
      }
    },
    [dispatchGlobal, retrieveProductsThunk]
  );

  const mounted = useRef(false);
  // first render useEffect, initialization and redirect logic
  useEffect(() => {
    const { propertiesMap = {}, assetTypes = [] } = organization;
    const { productTypes = [], itemLevelDataElements = [] } = propertiesMap;

    const { state: routerState = {} } = props.location;
    const {
      isCreate = false,
      modal = "",
      modalShow = false,
      modalTitle = "",
      product,
      redirect = false,
    } = routerState;

    setState((s) => {
      return {
        ...s,
        assetTypes,
        itemLevelDataElements,
        products: Object.keys(products).map((k) => products[k]),
        productHash: products,
        productTypes: productTypes.filter((t) => t !== null),
        facilities: facilities,
      };
    });
    if (redirect && product) {
      setState((s) => {
        return {
          ...s,
          isCreate: isCreate,
          selectedProduct: product,
          modalShow: true,
          modal: "Edit Product",
          modalTitle: isCreate ? "Create Product" : "Edit Product",
          redirect: true,
        };
      });
    } else if (modalShow && modalTitle === "Create Product") {
      setState((s) => {
        return {
          ...s,
          modal,
          modalShow,
          modalTitle,
          redirect: true,
          isCreate,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This controls the quickLinks and modal behavior
    return props.history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [props, facilities, organization, products, init]);

  const selectRow = (rowValue) => {
    const selectedProducts = state.selectedProducts;
    if (selectedProducts[rowValue.productId]) {
      delete selectedProducts[rowValue.productId];
    } else {
      selectedProducts[rowValue.productId] = rowValue;
    }
    setState((s) => {
      return { ...s, selectedProducts: selectedProducts };
    });
  };

  const selectAllPageRows = (pageRows, deselectPageRows) => {
    let updatedSelection = {};

    const selectedProducts = state.selectedProducts;

    const reducedPageRows = pageRows.reduce((x, y) => {
      return {
        ...x,
        [y.productId]: y,
      };
    }, {});
    if (deselectPageRows) {
      updatedSelection = omit(selectedProducts, Object.keys(reducedPageRows));
    } else {
      updatedSelection = { ...selectedProducts, ...reducedPageRows };
    }
    setState((s) => {
      return { ...s, selectedProducts: updatedSelection };
    });
  };

  const handleDelete = (override = false) => {
    const { selectedProduct = {} } = state;
    const { productId = "" } = selectedProduct;

    request
      .delete(
        `${apiUrl}products/${productId}${override ? "?override=true" : ""}`
      )
      .set("auth-token", token)
      .then((res) => {
        if (res.body.success) {
          if (override) {
            // TODO: This needs to be removed at some point. A batch can have more than one product associated with it.
            // Thus, a batch does not need to be deleted if a product is deleted. Because of this we are only drilling one
            // level down on the topologyMap.
            retrieveProducts().then((updatedProductsMap) => {
              dispatchGlobal(retrieveBatchesThunk()).then((res) => {
                if (res?.payload?.batches) {
                  const batches = Object.keys(res.payload.batches).map(
                    (k) => res.payload.batches[k]
                  );

                  handleBatchDelete(batches, productId, updatedProductsMap);
                }
              });
            });
          } else {
            handleConfirmationModal("Product Successfully Deleted");
            retrieveProducts();
          }
        } else if (res.body.error === "There are Assets with this Product") {
          setState((s) => {
            return {
              ...s,
              modal: "Delete Batch",
              modalTitle: "Batch Deletion",
            };
          });
        }
      })
      .catch((err) => {
        handleConfirmationModal(
          "Unable to delete product, please refresh and try again.",
          true,
          5000
        );
      });
  };

  const handleBatchDelete = (batches, productId, updatedProductsMap) => {
    const batchesToDelete = [];
    batches.forEach((element) => {
      const batch = element || {};
      const { topologiesList = [] } = batch;
      let isDeletionValid = true;
      let isProductIdMatch = false;

      topologiesList.forEach((topologyMap) => {
        // Here we verify that we are not deleting a batch associated with an active product
        if (updatedProductsMap[topologyMap.productId] !== undefined) {
          isDeletionValid = false;
        }
        // We also verify that we are deleting a batch associated with the selected productId
        if (topologyMap.productId === productId) {
          isProductIdMatch = true;
        }
      });

      if (isDeletionValid && isProductIdMatch) {
        batchesToDelete.push(element.batchId);
      }
    });
    if (batchesToDelete.length > 0) {
      batchesToDelete.forEach((batchId) => {
        request
          .delete(`${apiUrl}batches/${batchId}`)
          .set("auth-token", token)
          .then((res) => {
            return null;
          })
          .catch((err) => {
            console.log("Batch Deleted Failed");
          });
      });
    }

    handleConfirmationModal("Product Successfully Deleted");
  };

  const handleConfirmationModal = (
    confirmationText,
    error = false,
    timeout = 750
  ) => {
    setState((s) => {
      return {
        ...s,
        confirmationModalShow: true,
        confirmationText,
        modalShow: false,
        error,
      };
    });
    setTimeout(function () {
      setState((s) => {
        return {
          ...s,
          confirmationModalShow: false,
          deleteErorr: false,
        };
      });
    }, timeout);
  };

  const {
    assetTypes,
    confirmationModalShow,
    confirmationText,
    error,
    isCreate,
    itemLevelDataElements,
    modal,
    modalTitle,
    productTypes,
    redirect,
    selectedProduct,
  } = state;

  const modalClose = () => {
    setState((s) => {
      return {
        ...s,
        modalShow: false,
        isCreate: false,
        selectedProduct: [],
        confirmationModalShow: false,
      };
    });
  };

  // This controls what cells are shown on each row. Also controls what actions take place for each element
  const tableColumns = [
    {
      Header: "Product Name",
      accessor: (row) => row.name || "",
      id: "name",
      Cell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {row.original.name ? row.original.name : ""}
          </div>
        );
      },
    },
    {
      Header: "Product Category",
      id: "productType",
      accessor: (row) => {
        const productCat = row.propertiesMap?.productType || "";
        const productType = row.productType || "";
        return productType || productCat || "";
      },
      Cell: (data) => {
        const row = data.row;
        const { productType = "" } = row.original;
        const productCat =
          row.original.propertiesMap && row.original.propertiesMap.productType
            ? row.original.propertiesMap.productType
            : "";
        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {productType ? productType : productCat ? productCat : ""}
          </div>
        );
      },
    },
    {
      Header: "Product Code",
      accessor: (row) => row.tag || "",
      id: "tag",
      Cell: (data) => {
        const row = data.row;
        const { tag = "" } = row.original;
        return (
          <div
            className="pointer"
            onClick={() => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "View Product",
                  modalTitle: "Product Information",
                };
              });
            }}
          >
            {tag}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "export",
      id: "export",
      disableSortBy: true,
      Cell: (data) => {
        const row = data.row;
        const buttonIcons = [];

        // This checks the results of the configureUsersActions function to determine what icons are appended

        if (userRoles["Edit Products"]) {
          buttonIcons.push({
            icon: "fas fa-edit",
            title: "Edit",
            action: () => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "Edit Product",
                  modalTitle: "Edit Product",
                };
              });
            },
          });
        }

        if (userRoles["Create Batches"]) {
          buttonIcons.push({
            icon: "fas fa-cubes",
            title: "Create Batch",
            action: () => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "Create Batch",
                  modalTitle: "Create Batch",
                };
              });
            },
          });
        }

        if (userRoles["Delete Products"]) {
          buttonIcons.push({
            icon: "fas fa-trash-alt",
            title: "Delete",
            action: () => {
              setState((s) => {
                return {
                  ...s,
                  selectedProduct: row.original,
                  modalShow: true,
                  modal: "Delete Product",
                  modalTitle: "Delete Product",
                };
              });
            },
          });
        }
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  // This controls which modal the <CenterModal /> displays
  const switchModal = (modal) => {
    switch (modal) {
      case "Create Batch":
        return (
          <CreateBatch
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            facilities={facilities}
            onHide={modalClose}
            onSuccess={() => {
              handleConfirmationModal("Batch Succesfully Created");
              retrieveProducts();
            }}
            organizationId={organizationId}
            pcaId={organization.pcaId || ""}
            pcaSettings={organization.propertiesMap?.pcaSettings || {}}
            itemLevelDataElements={
              selectedProduct.propertiesMap?.itemLevelDataElements || []
            }
            selectedProduct={selectedProduct}
            token={token}
            timeZone={timeZone}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Edit Product":
        return (
          <EditProduct
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            confirmationModal={handleConfirmationModal}
            isCreate={isCreate}
            itemLevelDataElements={itemLevelDataElements}
            onSuccess={() => {
              retrieveProducts();
              modalClose();
            }}
            organizationId={organizationId}
            productTypes={productTypes}
            redirect={redirect}
            selectedProduct={selectedProduct}
            token={token}
          />
        );
      case "View Product":
        return (
          <EditProduct
            apiUrl={apiUrl}
            assetTypes={assetTypes}
            confirmationModal={handleConfirmationModal}
            isCreate={isCreate}
            itemLevelDataElements={itemLevelDataElements}
            onSuccess={retrieveProducts}
            productTypes={productTypes}
            readOnly={true}
            selectedProduct={selectedProduct}
            token={token}
          />
        );
      case "Delete Batch":
        return (
          <ConfirmationModalContent
            content={`There might be batches associated with ${selectedProduct.name}. (All batches and data will be deleted) This action cannot be undone`}
            handleSubmit={() => {
              handleDelete(true);
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Delete Product":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedProduct.name}? This action cannot be
              undone.`}
            handleSubmit={() => {
              handleDelete();
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="container-fluid mt-3">
      <CenteredModal
        content={switchModal(modal)}
        label={modalTitle}
        onHide={modalClose}
        show={state.modalShow}
      />
      <MaterialConfirmationModal
        content={confirmationText}
        closeModal={modalClose}
        modalOpen={confirmationModalShow}
        severity={error ? "error" : "success"}
        variant="filled"
      />
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>Products</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          {userRoles["Create Products"] ? (
            <button
              onClick={() =>
                setState((s) => {
                  return {
                    ...s,
                    isCreate: true,
                    modalShow: true,
                    modal: "Edit Product",
                    modalTitle: "Create Product",
                  };
                })
              }
              type="button"
              className="btn btn-primary ml-3 mb-1 font-weight-bold"
            >
              Create Product
            </button>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <UncontrolledTable
            stickyHeader
            totalCountLabel={"Products"}
            data={Array.from(state.products)}
            columns={tableColumns}
            isSelect={true}
            selectAllPageRows={selectAllPageRows}
            onSelect={selectRow}
            defaultSort={[{ id: "name" }]}
            toolbarChildren={
              <span
                className={"exportIcon"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleExport(state, { timeZone }, handleConfirmationModal)
                }
              >
                Export
                <i className="fas fa-file-export ml-2"></i>
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
}
