import { buildDeviceAssetsMap } from "../../devices/utils/utils";

export async function init(props) {
  const { availableDevices, allDevices, selectedAssets } = props;
  const itemList = {};
  const deviceOptions = [];
  const deviceAssetsMap = await buildDeviceAssetsMap(props, allDevices);

  // Build up the list for Assigned Items. This list allows us to track
  // values and error states.
  Object.keys(selectedAssets).forEach((asset) => {
    const { assetId } = selectedAssets[asset];
    itemList[assetId] = { deviceId: null, error: false };
  });

  availableDevices.forEach((device) => {
    deviceOptions.push({
      label: device.tag,
      value: device.assetId,
    });
  });

  return { deviceAssetsMap, itemList, deviceOptions };
}
