import { useState } from "react";
import SimpleList from "../../../../components/Lists/SimpleList.js";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import { Tooltip } from "@material-ui/core";

export default function Zones(props) {
  const { state, dispatchState } = props;

  const [addZoneCategory, setAddZoneCategory] = useState("");

  const [view, setView] = useState({
    isAddZoneCategory: false,
    isEditZoneCategory: false,
  });
  return (
    <>
      <div className="row">
        <div className="col md-6">
          <h4>
            <Tooltip title="User-identified zone naming convention.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Zone Categories{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddZoneCategory: !view.isAddZoneCategory,
                })
              }
            >
              {!view.isAddZoneCategory ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddZoneCategory && !view.isEditZoneCategory ? (
              <SimpleList
                noSelectHighlight
                items={state.globalSettings.zoneCategories.map((type) => {
                  return {
                    id: type,
                    label: type,
                  };
                })}
                onClick={(id) => {
                  dispatchState({
                    type: "SET_SELECTED",
                    label: "zoneCategory",
                    payload: {
                      id: id,
                      label: id,
                    },
                  });
                }}
                onClickEdit={() => {
                  setView({
                    ...view,
                    isEditZoneCategory: true,
                  });
                }}
                onClickRemove={(id) => {
                  setView({
                    isAddZoneCategory: false,
                  });
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "zoneCategories",
                    payload: state.globalSettings.zoneCategories.filter(
                      (type) => type !== id
                    ),
                    removeSelected: "zoneCategory",
                  });
                }}
              />
            ) : !view.isEditZoneCategory && view.isAddZoneCategory ? (
              <SimpleForm
                headerText={"Add new Zone Category"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Zone Category",
                    onChange: (e) => {
                      setAddZoneCategory({
                        ...addZoneCategory,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "zoneCategories",
                    payload: state.globalSettings.zoneCategories.concat([
                      addZoneCategory.label.trim(),
                    ]),
                  });
                }}
                goBack={() => {
                  setView({
                    isAddZoneCateogory: false,
                  });
                }}
              />
            ) : view.isEditZoneCategory && state.selected.zoneCategory ? (
              <SimpleForm
                headerText={`Edit ${state.selected.zoneCategory.label}`}
                submitBtnText={"Submit"}
                fields={[
                  {
                    id: "label",
                    label: "Zone Category",
                    value: state.selected.zoneCategory.label,
                    onChange: (e) => {
                      dispatchState({
                        type: "SET_SELECTED",
                        label: "zoneCategory",
                        payload: {
                          ...state.selected.zoneCategory,
                          label: e.target.value,
                        },
                      });
                    },
                  },
                ]}
                onSubmit={() => {
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "zoneCategories",
                    payload: state.globalSettings.zoneCategories.map((cat) => {
                      if (cat === state.selected.zoneCategory.id) {
                        return state.selected.zoneCategory.label.trim();
                      }
                      return cat;
                    }),
                    removeSelected: "zoneCategory",
                  });
                  setView({
                    ...view,
                    isEditZoneCategory: false,
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isEditZoneCategory: false,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
