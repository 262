import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: "#FFFFFF !important",
    border:'1px solid',
    color: "#6D6E70 !important",
    "&:hover": {
      backgroundColor: "#A6A8AB !important",
      border:'1px #FFFFFF',
      color: "#FFFFFF !important",
    },
  },
  delete: {
    backgroundColor: "#B71D1A !important",
    color: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: "#EB4F35 !important",
      color: "#FFFFFF !important",
    },
  },
  disabled: {
    backgroundColor: "#6D6E70 !important",
    color: "#E6E7E8 !important",
    "&:hover": {
      backgroundColor: "#6D6E70 !important",
      color: "#E6E7E8 !important",
    },
  },
}));

export default function MaterialUiButton(props: any) {
  const {
    color = "primary",
    disabled = false,
    fullWidth = false,
    label = "Submit",
    onClick,
    size,
    variant = "contained",
  } = props;
  const classes = useStyles();

  return (
    <Button
      className={`${color === "cancel" ? classes.cancel : ""} ${
        color === "delete" ? classes.delete : ""
      } ${disabled ? classes.disabled : ""} `}
      color={color === "cancel" ? '' : color}
      disabled={disabled || color === 'disabled' ? true : false}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      variant={variant}
    >
      {label}
    </Button>
  );
}
