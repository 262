import request from "superagent";

export async function onSubmit(props: any) {
  const { apiUrl, state = {} } = props;
  const { userName, password } = state.fieldValues;

  let csmToken: string | undefined = "";

  // if prod, use secure CSM token method
  if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
    const origin = (process.env.REACT_APP_HOST || window.location.href)
      .split("/")
      .slice(0, 3)
      .join("/");

    const apiLabUrl: string | undefined =
      process.env.REACT_APP_API_LAB_ENDPOINT;

    csmToken = await getCsmToken(apiLabUrl as string, origin);
  } else {
    // else in dev / staging / testing, use csm token from environment variable
    csmToken = process.env.REACT_APP_CSM_TOKEN;
  }
  const body = {
    csmToken: csmToken,
    email: userName.value,
    password: password.value,
  };

  const result = await fetch(`${apiUrl}auth/console`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      return { error: err };
    });

  return result;
}

export const getCsmToken = async (apiUrl: string, url: string) => {
  // REACT_APP_CSM_TOKEN is now the handshake token needed to get the CSM token
  const body = {
    csmToken: process.env.REACT_APP_CSM_HANDSHAKE_TOKEN,
    url: url,
  };
  const results = await fetch(`${apiUrl}data/csm/getCredential`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.csmToken;
    })

    .catch((error) => {
      console.log(`Error: ${error}`);
    });
  return results;
};

export async function forgotPasswordRequest(props: any) {
  const { apiUrl, setConfirmationModal, setState, state } = props;
  const { userName } = state.fieldValues;

  let csmToken: string | undefined = "";

  // if prod, use secure CSM token method
  if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
    const origin = (process.env.REACT_APP_HOST || window.location.href)
      .split("/")
      .slice(0, 3)
      .join("/");

    const apiLabUrl: string | undefined =
      process.env.REACT_APP_API_LAB_ENDPOINT;

    csmToken = await getCsmToken(apiLabUrl as string, origin);
  } else {
    // else in dev / staging / testing, use csm token from environment variable
    csmToken = process.env.REACT_APP_CSM_TOKEN;
  }

  const body = {
    csmToken: csmToken,
    email: userName.value,
  };

  request
    .post(`${apiUrl}appUsers/forgotPasswordRequest`)
    .send(body)
    .then((response) => {
      if (response.body.success === true) {
        setState((prevState: any) => ({
          ...prevState,
          resetPasswordRequestComplete: true,
        }));
      } else {
        setConfirmationModal({
          error: true,
          show: true,
          text: `Authentication failed: ${response.body.error}`,
        });
      }
    })
    .catch((err) => {
      setConfirmationModal({
        error: true,
        show: true,
        text: `Error: ${err.message} ${err.response}`,
      });
    });
}

export async function resetPassword(props: any) {
  const { apiUrl, resetPasswordToken, state = {} } = props;

  const { newPassword, verifyPassword } = state.fieldValues;

  const body = {
    newPassword: newPassword.value,
    verifyPassword: verifyPassword.value,
  };
  const results = await request
    .post(`${apiUrl}appUsers/${resetPasswordToken}/forgotPassword`)
    .send(body)
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return { success: true };
    })
    .catch((err) => {
      return { error: err };
    });
  return results;
}
