import { useState, useEffect, useRef, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { Grid } from "@material-ui/core";

import {
  updateOrganization,
  updateClassifications,
} from "../../globalStore/slices/organization/organizationSlice";
import { updateUserSettings } from "../../globalStore/slices/user/userSlice";

import UserSettings from "./UserSettings/UserSettings";
import GlobalSettings from "./GlobalSettings/GlobalSettings";
import { initGlobalSettings, initUserSettings, settingsReducer } from "./utils";
import {
  saveGlobalSettings,
  saveUserSettings,
  resetUserWidgetSettings,
} from "./api";
import { isEqual, isEmpty } from "lodash";
import { Redirect, useHistory } from "react-router-dom";
import ConfirmUnsavedChanges from "./ConfirmUnsavedChanges/ConfirmUnsavedChanges";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";

import "./styles.css";
function Settings(props) {
  const {
    apiUrl,
    appUserType,
    location = {},
    organizationId,
    timeZone = {},
    token,
    userId,
    userRoles,
    usersConsoleRole,
  } = props;
  const dispatchGlobal = useDispatch();

  const consoleSettings = useSelector(
    (state) => state.user.userPropertiesMap.propertiesMap.consoleSettings,
    isEqual
  );
  const email = useSelector((state) => state.user.email);

  const {
    classifications = {},
    organization = {},
    mobileRoles = {},
  } = cloneDeep(useSelector((state) => state.organization, isEqual));

  let history = useHistory();
  // check for permissions to use global settings
  const globalSettingsPermission =
    userRoles["View Global Settings"] !== undefined ? true : false;
  // useState switch for switching active pages and setting permissions
  const [settingsView, setSettingsView] = useState({
    globalSettingsPermission: globalSettingsPermission,
    activePage:
      props.match.params.page === "user"
        ? "user"
        : globalSettingsPermission
        ? "global"
        : "user",
  });
  // Currently we only redirect from the settings page, when the user comes in from the
  // edit product modal
  const [enableRedirect, setEnableRedirect] = useState(false);

  // initialize state with org and user settings passed down through App.js props

  const initGlobalSettingsResponse = initGlobalSettings({
    organization,
    location,
    mobileRoles,
    classifications,
  });

  const initUserSettingsResponse = initUserSettings(
    { timeZone, email },
    consoleSettings
  );

  const { redirect, product, isCreate } = initGlobalSettingsResponse;
  // refs
  const globalSettingsRef = useRef(initGlobalSettingsResponse);
  const userSettingsRef = useRef(initUserSettingsResponse);

  const tabRef = useRef({});

  // see settingsReducer in utils.js for dispatch syntax
  const [state, dispatchState] = useReducer(settingsReducer, {
    globalSettings: {
      ...initGlobalSettingsResponse,
      settingsUpdated: false,
    },
    userSettings: {
      ...initUserSettingsResponse,
      settingsUpdated: false,
    },
    selected: {},
  });

  // confirmation modal
  const [modal, setModal] = useState({
    modalShow: false,
  });

  const handleConfirmationModal = async (
    confirmationText,
    error,
    timeout = 1800
  ) => {
    setModal({
      modalShow: true,
      confirmationText: confirmationText,
      error: error ? error : "",
    });

    return await new Promise((resolve) =>
      setTimeout(function () {
        resolve(
          setModal({
            confirmationModalShow: false,
            modalShow: false,
            confirmationText,
            error: error ? error : "",
          })
        );
      }, timeout)
    );
  };

  // save settings handler
  const handleSaveSettings = async (fromUserSettings = false) => {
    // userSettings is from the user settings submit
    if (state.globalSettings.settingsUpdated && !fromUserSettings) {
      const results = await saveGlobalSettings(
        { organization, organizationId, apiUrl, token, userId },
        state.globalSettings,
        globalSettingsRef
      );

      if (!results.success) {
        handleConfirmationModal(
          `${results.error ? results.error : ""}`,
          true,
          7000
        );
      }
      if (results.success) {
        // hack for resetting widget settings if event types map is empty, prevents events that don't exist from being loaded into widget filters
        if (isEmpty(state.globalSettings.eventTypesMap)) {
          resetUserWidgetSettings({ apiUrl, token, userId });
        }

        // dispatch to reset settings updated bool
        dispatchState({ type: "RESET_SETTINGS_UPDATED" });
        // reset settings ref to reflect updated settings
        globalSettingsRef.current = state.globalSettings;

        handleConfirmationModal(
          `Settings successfully updated!`,
          false,
          1200
        ).then(() => {
          if (results.classifications) {
            dispatchGlobal(updateClassifications(results.classifications));
            history.go(0);
          } else if (results.organization) {
            dispatchGlobal(updateOrganization(results.organization));
          }
          setTimeout(() => {
            if (redirect) {
              setEnableRedirect(true);
            }
          }, 250);
        });
      }
    }

    if (state.userSettings.settingsUpdated && fromUserSettings) {
      const results = await saveUserSettings(
        { apiUrl, userId, token },
        state.userSettings
      );
      if (!results.success) {
        handleConfirmationModal(`${results.error ? results.error : ""}`, true);
      }
      if (results.success) {
        // dispatch to reset settings updated bool
        dispatchState({ type: "RESET_SETTINGS_UPDATED" });
        // reset settings ref to reflect updated settings
        userSettingsRef.current = state.userSettings;

        handleConfirmationModal(
          `Settings successfully updated!`,
          false,
          1200
        ).then(() => {
          dispatchGlobal(updateUserSettings(results.appUser));
          setTimeout(() => {
            if (redirect) {
              setEnableRedirect(true);
            }
          }, 250);
        });
      }
    }
  };

  // side effect hack for changing the active view component when the location pathname changes... this enables us to add movement between the users and global views in the confirmUnsavedChanges prompt listener
  useEffect(() => {
    const url = history.location.pathname;
    if (url === "/settings/user") {
      setSettingsView((v) => {
        return {
          ...v,
          activePage: "user",
        };
      });
    }
    if (url === "/settings/global") {
      setSettingsView((v) => {
        return {
          ...v,
          activePage: "global",
        };
      });
    }
  }, [history.location.pathname]);

  return (
    <Grid container spacing={3} justify="center">
      {enableRedirect ? (
        <Redirect
          to={{
            pathname: "/products",
            state: { product, redirect: true, isCreate: isCreate },
          }}
        />
      ) : null}
      <MaterialConfirmationModal
        content={modal.confirmationText}
        closeModal={() => setModal({ ...modal, modalShow: false })}
        modalOpen={modal.modalShow}
        severity={modal.error ? "error" : "success"}
        variant="filled"
      />

      {settingsView.globalSettingsPermission ? (
        <Grid
          xs={12}
          sm={6}
          item
          style={{ textAlign: "center" }}
          onClick={() => {
            if (settingsView.activePage !== "global") {
              history.push("/settings/global");
            }
          }}
        >
          <span>
            <i
              className={`fas fa-globe fa-3x globalButton ${
                settingsView.activePage === "global" ? "active" : null
              }`}
            ></i>
          </span>
          <div className="settingsButton">Global Settings</div>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        sm={6}
        style={{ textAlign: "center" }}
        onClick={() => {
          if (settingsView.activePage !== "user") {
            history.push("/settings/user");
          }
        }}
      >
        <span>
          <i
            className={`fas fa-user fa-3x userButton ${
              settingsView.activePage === "user" ? "active" : null
            }`}
          ></i>
        </span>
        <div className="settingsButton">User Settings</div>
      </Grid>
      <Grid item xs={12}>
        {settingsView.activePage === "user" ? (
          <UserSettings
            state={state}
            dispatchState={dispatchState}
            handleSaveSettings={(e) => {
              handleSaveSettings(e);
            }}
            handleConfirmationModal={handleConfirmationModal}
            userSettingsRef={userSettingsRef}
            match={{ params: props.match.params }}
            tabRef={tabRef}
            usersConsoleRole={usersConsoleRole}
            appUserType={appUserType}
          />
        ) : null}
        {settingsView.activePage === "global" ? (
          <GlobalSettings
            apiUrl={apiUrl}
            dispatchState={dispatchState}
            handleConfirmationModal={handleConfirmationModal}
            state={state}
            token={token}
            userRoles={userRoles}
            handleSaveSettings={handleSaveSettings}
            globalSettingsRef={globalSettingsRef}
            match={{ params: props.match.params }}
            tabRef={tabRef}
          />
        ) : null}
      </Grid>
      <Grid item>
        <button
          className={`mt-3 btn btn-primary ${
            state.globalSettings.settingsUpdated ||
            state.userSettings.settingsUpdated
              ? ``
              : `active`
          }`}
          onClick={() =>
            handleSaveSettings(
              state.userSettings.settingsUpdated ? true : false
            )
          }
          disabled={
            state.globalSettings.settingsUpdated ||
            state.userSettings.settingsUpdated
              ? false
              : true
          }
        >
          SAVE SETTINGS
        </button>
      </Grid>

      <ConfirmUnsavedChanges
        handleSaveSettings={() =>
          handleSaveSettings(state.userSettings.settingsUpdated ? true : false)
        }
        when={
          state.globalSettings.settingsUpdated ||
          state.userSettings.settingsUpdated
        }
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location) => {
          if (
            state.globalSettings.settingsUpdated ||
            state.userSettings.settingsUpdated
          ) {
            return true;
          }
          return false;
        }}
        globalSettingsRef={globalSettingsRef}
        userSettingsRef={userSettingsRef}
        dispatchState={dispatchState}
        state={state}
      />
    </Grid>
  );
}

export default Settings;
