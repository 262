import moment from "moment";

export const escapeCharacters = (input: string, isBinLocation?: boolean) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (char === " " && isBinLocation) {
      const escapedChar = " ";
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `?`);
    } else if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};

export const showLastEvents = async (props: any, filters: any) => {
  const { apiUrl, token, organizationId, classifications } = props;

  let {
    assetIds,
    binLocation,
    startDate,
    endDate,
    assetType,
    locations,
    locals,
    lastEvents,
    limit,
    zones,
  } = filters;

  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) =>
        classifications.active[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  const classificationFiltersStrings = classificationFilters?.length
    ? classificationFilters
        ?.map((classArray) => {
          return (
            classArray
              ?.map((childClass: any) => {
                return `classificationset:${childClass.value}`;
              })
              .flat()
              .join(" OR ") || null
          );
        })
        .join(" AND ")
        .split(" AND ")
    : "";

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate = moment(startDate).isValid()
    ? moment(startDate).startOf("day").utc().format()
    : null;
  endDate = moment(endDate).isValid()
    ? moment(endDate).endOf("day").utc().format()
    : null;

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  let assetIdString = "";
  if (assetIds && assetIds.length) {
    let assetIdSet = assetIds.map((e: any) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    assetIdString = assetIdSet.join(" OR ");
  }

  const binLocationsString = binLocation?.length
    ? "(" +
      binLocation
        .map((bin: any) => {
          return `{!tuple}zone.bin_location:${escapeCharacters(
            bin.value,
            true
          )}`;
        })
        .join(" OR ") +
      ")"
    : "";

  let lastEventsString = "";
  if (lastEvents && lastEvents.length) {
    let lastEventsSet = lastEvents.map((e: any) => {
      return `last_event:${escapeCharacters(e.value)}`;
    });
    lastEventsString = lastEventsSet.join(" OR ");
  }

  let localsString = "";
  if (locals && locals.length) {
    let localsSet = locals.map((e: any) => {
      return `state:${escapeCharacters(e.value)}`;
    });
    localsString = localsSet.join(" OR ");
  }

  let locationsString = "";
  if (locations && locations.length) {
    let locationsSet = locations.map((e: any) => {
      return `facility_id:${e.value?.facilityId || e}`;
    });
    locationsString = locationsSet.join(" OR ");
  }

  let zonesString = "";
  if (zones && zones.length) {
    let zonesSet = zones.map((e: any) => {
      return `{!tuple}zone.zone_id:${e.value}`;
    });
    zonesString = zonesSet.join(" OR ");
    zonesString = "(" + zonesString + ")";
  }

  // "-{!tuple}device.platform:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        `${assetIdString ? `${assetIdString}` : ``}`,
        escapedAssetType ? `asset_type:${escapedAssetType}` : ``,
        `${lastEventsString ? `${lastEventsString}` : ``}`,
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
        `${locationsString ? `${locationsString}` : ``}`,
        binLocationsString ? `(${binLocationsString})` : ``,
        `${localsString ? `${localsString}` : ``}`,
        `${zonesString ? `${zonesString}` : ``}`,
        ...classificationFiltersStrings,
      ],
      sort: `time_of_log desc`,
      start: 0,
    },
    limit: limit || 25,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const getAssetsById = async (props: any, input: any, limit = 1000) => {
  const { apiUrl, token, organizationId } = props;

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: input,
      sort: `tag desc, asset_type asc`,
      start: 0,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props: any, filters: any) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: filters,
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const searchBins = async (
  props: any,
  input: any,
  limit: number | string = 50,
  start: number | string = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);
  // "-{!tuple}device.platform:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [`bin_location:${escapedInput || ""}*`],
      sort: `time_of_log asc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const searchAssets = async (
  props: any,
  input: any,
  limit: number | string = 50,
  start: number | string = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);
  // "-{!tuple}device.platform:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.platform:*",
        escapedInput ? `tag:${escapedInput}*` : ``,
      ],
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};
