import XLSX from "xlsx";
import moment from "moment-timezone";
import _ from "lodash";

import { formatLatLon } from "../../utils/lat_lon_utils";
import { utc_to_local_12_hour_clock } from "../../utils/date_time_utils";

interface ExportFunc {
  (
    histories: any,
    facilities: any,
    timeZone: any,
    assetData: any,
    zones: any
  ): Promise<boolean | null>;
}
export const exportSpreadsheet: ExportFunc = async (
  histories,
  facilities,
  timeZone,
  assetData,
  zones
) => {
  const dateTimeHeader = `Date/Time${
    timeZone && timeZone.value
      ? ` (${timeZone.value})`
      : timeZone
      ? ` (${timeZone})`
      : moment.tz.guess()
  }`;

  const json = histories.map(async (history: any) => {
    let { propertiesMap = {} } = history;
    const assetPropertiesMap = assetData.propertiesMap;
    let assetFields: any = {};

    if (!_.isEmpty(propertiesMap)) {
      propertiesMap = Object.keys(propertiesMap)
        .map((k) => {
          return { label: k, value: propertiesMap[k] };
        })
        .filter(
          (e) =>
            e.label !== "formData" &&
            e.label !== "kmPerHour" &&
            e.label !== "distance" &&
            e.label !== "deltaTimeHrs" &&
            e.label !== "formId"
        )
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        })
        .reduce((x: any, y: any) => {
          return {
            ...x,
            [_.startCase(y.label)]: y.value,
          };
        }, {});
    }

    if (assetPropertiesMap) {
      assetFields = Object.keys(assetPropertiesMap)
        .map((k) => {
          return { label: k, value: assetPropertiesMap[k] };
        })
        .filter(
          (e) =>
            e.label !== "note" &&
            e.label !== "formData" &&
            e.label !== "kmPerHour" &&
            e.label !== "distance" &&
            e.label !== "deltaTimeHrs" &&
            e.label !== "formId" &&
            e.label !== "binLocation"
        )
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        })
        .reduce((x: any, y: any) => {
          return {
            ...x,
            [_.startCase(y.label)]: y.value,
          };
        }, {});
    }

    const renderedTime = utc_to_local_12_hour_clock(
      history.timeOfLog,
      timeZone
    );

    return {
      [dateTimeHeader]: renderedTime ? renderedTime : "DNP",
      "Asset Tag": history.assetTag ? history.assetTag : "DNP",
      Event: history.event ? history.event : "DNP",
      User: `${history.lastName || ""}${
        history.firstName ? `, ${history.firstName}` : ""
      }`,
      "Facility Name":
        !history.latitude || !history.longitude
          ? "DNP"
          : history.facilityId &&
            facilities[history.facilityId] &&
            facilities[history.facilityId].name
          ? facilities[history.facilityId].name
          : "Not Registered",
      Zone: zones[history.zone?.zoneId]?.name || "",
      "Bin Location": history.zone?.binLocation || "",
      State: history.state ? history.state : "DNP",
      "Latitude, Longitude":
        history.latitude && history.longitude
          ? formatLatLon(history.latitude, history.longitude)
          : "DNP",
      Category: assetFields.Category ? assetFields.Category : "",
      ...propertiesMap,
      ...assetFields,
    };
  });

  const currentTime = moment()
    .tz(
      timeZone && timeZone.value
        ? timeZone.value
        : timeZone
        ? timeZone
        : moment.tz.guess()
    )
    .format("YYYY-MM-DD");

  return Promise.all(json).then((data) => {
    const fileName = `Asset_History_${currentTime}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName);
    return true;
  });
};
