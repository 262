import React, { useState } from "react";
import CustomDataField from "./CustomDataField";
import SimpleTextField from "../Forms/FieldTypes/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  pRight: { paddingRight: "8px" },
  pLeft: { paddingLeft: "8px" },
  root: { marginTop: "1rem" },
}));

const SaveButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#A0C46D",
    width: "100%",
    "&:hover": {
      backgroundColor: "#36562F",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#B71D1A",
    width: "100%",
    "&:hover": {
      backgroundColor: "#FFC854",
    },
  },
}))(Button);

export default function CustomDataFields(props) {
  const [state, setState] = useState({ newFieldTitle: "", newFieldData: "" });
  const classes = useStyles();

  function handleChange(value, count) {
    const { customDataFields, updateCustomFields } = props;
    customDataFields[count].value = value;
    updateCustomFields(customDataFields);
  }

  function removeCustomDataField(count) {
    const { customDataFields, updateCustomFields, onRemove } = props;
    const customFields = [...customDataFields];

    // This is being added incase the parent component needs another action to occur upon data field
    // deletion. This is being added due to products and customDataFields. The label builder is expecting
    // values from the propertiesMap object. We store other things in the propertiesMap that does not
    // qualify as a customDataField.
    if (onRemove !== undefined) {
      onRemove(customFields[count]);
    }

    customFields.splice(count, 1);
    updateCustomFields(customFields);
  }

  const {
    addField,
    setAddProductLevelDataElement,
    readOnly = false,
    customDataFields = [],
    updateCustomFields,
  } = props;
  const { newFieldTitle, newFieldData } = state;
  return (
    <Grid item xs={12}>
      <Grid container>
        {/* This renders any customDataFields provided from the props */}
        {customDataFields.map((element, index) => {
          return (
            <CustomDataField
              handleChange={handleChange}
              removeField={removeCustomDataField}
              key={index}
              count={index}
              title={customDataFields[index].title}
              value={customDataFields[index].value}
              readOnly={readOnly}
            />
          );
        })}
        {/* The code below appears if the user clicks the + to add a new field. 
          Promps for the title and content of a new data field. Once the check is 
          clicked the customDataFields of the parent component is updated.*/}
        {addField ? (
          <>
            {/* Product Level Data Label */}
            <Grid className={`${classes.pRight} ${classes.root}`} item xs={6}>
              <SimpleTextField
                id="newFieldTitle"
                label="Product Level Data Label"
                onChange={(event) => {
                  event.persist();
                  setState((prevState) => ({
                    ...prevState,
                    newFieldTitle: event.target.value,
                  }));
                }}
                readOnly={readOnly}
                required
                value={newFieldTitle}
              />
            </Grid>

            {/* Product Level Data */}
            <Grid className={`${classes.pLeft} ${classes.root}`} item xs={6}>
              <SimpleTextField
                id="newFieldData"
                label="Product Level Data"
                onChange={(event) => {
                  event.persist();
                  setState((prevState) => ({
                    ...prevState,
                    newFieldData: event.target.value,
                  }));
                }}
                value={newFieldData}
                readOnly={readOnly}
              />
            </Grid>

            {/* Are you sure you want to add a custom data field? */}
            <Grid className={`${classes.pRight} ${classes.root}`} item xs={6}>
              <SaveButton
                className={classes.margin}
                onClick={() => {
                  const customFields = customDataFields ? [...customDataFields] : [];
                  customFields.push({
                    title: newFieldTitle,
                    value: newFieldData,
                  });
                  updateCustomFields(customFields);
                  setState({
                    newFieldTitle: "",
                    newFieldData: "",
                  });
                  setAddProductLevelDataElement(false);
                }}
                variant="contained"
              >
                Save
              </SaveButton>
            </Grid>
            <Grid className={`${classes.pLeft} ${classes.root}`} item xs={6}>
              {/* The cancel clears out all fields and sets addField back to
              false */}
              <CancelButton
                className={classes.margin}
                onClick={() => {
                  setState({
                    newFieldData: "",
                    newFieldTitle: "",
                  });
                  setAddProductLevelDataElement(false);
                }}
                variant="contained"
              >
                Cancel
              </CancelButton>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
