import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Paper,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import RecursiveTreeView from "../../../../components/TreeView/RecursiveTreeView";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  formArea: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: "43vh",
    maxHeight: "43vh",
    overflow: "auto",
  },
}));

export default function Hierarchy(props: any) {
  const { treeView } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (treeView?.complete) {
      setLoading(false);
    }
  }, [treeView]);

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Hierarchical View</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.formArea} elevation={0}>
            {!loading ? (
              <RecursiveTreeView data={treeView} />
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
