import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormGroup } from "@material-ui/core";

import SimpleSelect from "./FieldTypes/Select";
import SimpleCheckbox from "./FieldTypes/Checkbox";
import ReactSelect from "./FieldTypes/ReactSelect";
import Slider from "./FieldTypes/Slider";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "75%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  select: {
    width: "100%",
  },
  reactSelect: {
    width: "100%",
  },
}));

export default function SimpleForm(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <div
        className={classes.paper}
        style={
          props.leftAlign
            ? { justifyContent: "start", alignItems: "start" }
            : {}
        }
      >
        {props.children ? (
          // child components of the SimpleForm will be rendered as "avatars", use this for adding material UI icons to top of form
          <Avatar className={classes.avatar}>{props.children}</Avatar>
        ) : null}

        {props.headerText ? <h5> {props.headerText}</h5> : null}

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            props.onSubmit(e);
            if (!props.noBackButton) {
              props.goBack();
            }
          }}
        >
          <FormGroup>
            {props.fields && props.fields.length ? (
              props.fields.map((field, idx) => {
                if (field.isSelect && field.options) {
                  return (
                    <SimpleSelect
                      id={field.id}
                      required
                      selected={props.selected}
                      key={`${idx} - ${field.id}`}
                      disabled={field.disabled}
                      classes={classes}
                      label={field.label ? field.label : "Select"}
                      variant={field.variant ? field.variant : "standard"}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      options={
                        field.options && field.options.length
                          ? field.options
                          : []
                      }
                      helperText={field.helperText}
                    />
                  );
                } else if (field.isReactSelect) {
                  return (
                    <ReactSelect
                      id={props.id}
                      disabled={props.disabled || props.isDisabled}
                      className={classes.reactSelect}
                      key={`${idx} - ${field.id}`}
                      isSearchable
                      value={field.value}
                      options={
                        field.options && field.options.length
                          ? field.options
                          : []
                      }
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  );
                } else if (field.isCheckbox) {
                  return (
                    <SimpleCheckbox
                      id={props.id}
                      disabled={field.disabled}
                      key={`${idx} - ${field.id}`}
                      checked={field.checked}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      label={field.label ? field.label : "Select"}
                    />
                  );
                } else if (field.isSlider) {
                  return (
                    <Slider
                      key={`${idx} - ${field.id}`}
                      step={field.step}
                      min={field.min}
                      max={field.max}
                      onChange={(e) => field.onChange(e)}
                      value={field.value}
                    />
                  );
                } else {
                  return (
                    <TextField
                      required
                      inputProps={field.inputProps}
                      type={field.type}
                      disabled={field.disabled}
                      error={field.error}
                      helperText={field.helperText}
                      key={`${idx} - ${field.id}`}
                      size={"small"}
                      variant={field.variant ? field.variant : "standard"}
                      margin="normal"
                      fullWidth
                      id={field.id}
                      label={field.label}
                      name={field.id}
                      autoComplete={field.id}
                      autoFocus
                      onChange={(e) => field.onChange(e)}
                      value={field.value}
                    />
                  );
                }
              })
            ) : (
              <p>Nothing to edit yet!</p>
            )}
          </FormGroup>
          <div style={{ width: "66%", margin: "auto" }}>
            {props.noSubmitButton ? null : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {props.submitBtnText ? props.submitBtnText : "Submit"}
              </Button>
            )}

            {props.noBackButton ? null : (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => {
                  props.goBack();
                }}
              >
                Go Back
              </Button>
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}
