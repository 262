import React from "react";
import { useSelector } from "react-redux";

import { Form, Col } from "react-bootstrap";
import request from "superagent";
import StateSelect from "../../../../components/Selects/StateSelect";
import RequiredField from "../../../../components/Forms/RequiredField/RequiredField";

import { createPreflightCheck } from "../API/preflight";
import { validateLat, validateLong } from "../utils/utils";

export default function CreateFacility(props: any) {
  const defaultFacilityRadius = useSelector(
    (state: any) =>
      state.organization.organization.propertiesMap?.defaultFacilityRadius
  );

  const [createState, setCreateState] = React.useState({
    validated: false,
    ...props.facility,
    latValid: true,
    longValid: true,
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !createState.latValid ||
      !createState.longValid
    ) {
      props.notificationModal(
        `Please make sure all required fields are filled out properly.`,
        true
      );
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const { apiUrl, notificationModal, onHide, onSuccess, token } = props;

      const { location, facilityType, name, propertiesMap } = createState;

      let body = {
        location,
        facilityType,
        name,
        propertiesMap,
        radius: defaultFacilityRadius / 1000 || 0.4,
      };

      const req = request.post(`${apiUrl}facilities`);

      const centroidString =
        location.latitude && location.longitude
          ? `POINT(${location.longitude} ${location.latitude})`
          : null;

      createPreflightCheck(
        { apiUrl, token },
        {
          name: name,
          centroidString: centroidString,
          radius: defaultFacilityRadius / 1000 || 0.4,
          location: location,
        }
      ).then((res: any) => {
        if (res.error) {
          notificationModal(`${res.error}`, true);
        } else if (res.success) {
          req
            .set("auth-token", token)
            .send(body)
            .then((response) => {
              if (response.body.success) {
                onHide();
                notificationModal("Facility Sucessfully Created", false);
                onSuccess();
              } else {
                notificationModal(`${response.body.error}`, true);
              }
            });
        }
      });
    }
    setCreateState({ ...createState, validated: true });
  };

  const { locationTypes = [] } = props;
  const {
    location = {},
    facilityType = "",
    name = "",
    propertiesMap = {},
    validated,
  } = createState;

  const {
    address1,
    city = "",
    postalCode = "",
    state = "",
    latitude,
    longitude,
  } = location;
  const { locationDetails = "" } = propertiesMap;

  return (
    <Form
      className="container-fluid"
      noValidate
      onSubmit={(event: any) => {
        handleSubmit(event);
      }}
      validated={validated}
    >
      <Form.Row>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Facility Name</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="name"
            onChange={(event: any) => {
              setCreateState({ ...createState, name: event.target.value });
            }}
            required
            value={name || ""}
          />
          <RequiredField label="Required Field" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Facility Type</Form.Label>
          <Form.Control
            as="select"
            className="custom-select bg-white"
            name="facilityType"
            onChange={(event: any) => {
              setCreateState({
                ...createState,
                facilityType: event.target.value,
              });
            }}
            required
            value={facilityType || ""}
          >
            <option value="" disabled>
              Select
            </option>
            {locationTypes.map((element: any, index: number) => {
              return (
                <option key={index} value={element}>
                  {element}
                </option>
              );
            })}
          </Form.Control>
          <RequiredField label="Required Field" />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label className="mt-2 mb-0">Address</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="address1"
            onChange={(event: any) => {
              let newAddress = createState.location || {};
              newAddress.address1 = event.target.value;
              setCreateState({ ...createState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={address1 || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">City</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="city"
            onChange={(event: any) => {
              let newAddress = createState.location || {};
              newAddress.city = event.target.value;
              setCreateState({ ...createState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={city || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <StateSelect
            onChange={(state: string) => {
              let newAddress = createState.location || {};
              newAddress.state = state;
              setCreateState({ ...createState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            requiredFieldMessage={"Required Field if no Lat/Long set"}
            state={state || ""}
          />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Zip Code</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="postalCode"
            onChange={(event: any) => {
              let newAddress = createState.location || {};
              newAddress.postalCode = event.target.value;
              setCreateState({ ...createState, location: newAddress });
            }}
            required={location.latitude || location.longitude ? false : true}
            value={postalCode || ""}
          />
          <RequiredField label="Required Field if no Lat/Long set" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Latitude</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="latitude"
            isInvalid={
              createState.location?.latitude?.length > 0 &&
              !validateLat.test(
                parseFloat(createState.location?.latitude).toFixed(5)
              )
            }
            required={
              location.latitude?.length > 0 || location.longitude?.length > 0
                ? true
                : false
            }
            onChange={(event: any) => {
              const hasLength = event.target.value?.length > 0;

              const latValid = hasLength
                ? validateLat.test(parseFloat(event.target.value).toFixed(5))
                : true;

              setCreateState({
                ...createState,
                location: {
                  ...createState.location,
                  latitude: hasLength ? event.target.value : null,
                  lat1000: hasLength
                    ? parseInt(event.target.value) * 1000
                    : null,
                },
                latValid: latValid,
              });
            }}
            value={latitude || ""}
          />
          <RequiredField label="Please enter a properly formatted latitude" />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label className="mt-2 mb-0">Longitude</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="longitude"
            isInvalid={
              createState.location?.longitude?.length > 0 &&
              !validateLong.test(
                parseFloat(createState.location?.longitude).toFixed(5)
              )
            }
            required={
              location.latitude?.length > 0 || location.longitude?.length > 0
                ? true
                : false
            }
            onChange={(event: any) => {
              const hasLength = event.target.value?.length > 0;
              const longValid = hasLength
                ? validateLong.test(parseFloat(event.target.value).toFixed(5))
                : true;
              setCreateState({
                ...createState,
                location: {
                  ...createState.location,
                  longitude: hasLength ? event.target.value : null,
                  lon1000: hasLength
                    ? parseInt(event.target.value) * 1000
                    : null,
                },
                longValid: longValid,
              });
            }}
            value={longitude || ""}
          />
          <RequiredField label="Please enter a properly formatted longitude" />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label className="mt-2 mb-0">
            Facility Details - optional
          </Form.Label>
          <Form.Control
            className="form-control bg-white"
            onChange={(event: any) => {
              let newPropertiesMap = createState.propertiesMap || {};
              newPropertiesMap.locationDetails = event.target.value;
              setCreateState({
                ...createState,
                propertiesMap: newPropertiesMap,
              });
            }}
            name="locationDetails"
            value={locationDetails || ""}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md="12" className="text-center mt-1">
          <button type="submit" className="btn btn-primary w-100">
            Submit
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
