import { ExportToCsv } from "export-to-csv";
import moment from "moment-timezone";
import _ from "lodash";

export const handleExport = (state, props, handleConfirmationModal) => {
  const { timeZone } = props;
  const browserTz = moment.tz.guess();
  const currentTime = moment()
    .tz(timeZone && timeZone.value ? timeZone.value : browserTz)
    .format("YYYY-MM-DD");
  // select batches from component mounted state
  const { selectedBatches = [] } = state;

  // break into array of value objects
  const raw = Object.values(selectedBatches);

  // function to pull only the fields we want for our CSV file
  const filterBatches = (batches) => {
    const allowed = [
      "name",
      "tag",
      "topologiesList",
      "topologyMap",
      "originLocation",
      "timeCreated",
    ];

    // return only allowed fields
    let filtered = batches.map((el) =>
      Object.keys(el)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = el[key];
          return obj;
        }, {})
    );

    // instantiate an array that we'll build into an array of objects for our csv
    let tableArray = [];
    for (let i = 0; i < filtered.length; i++) {
      // the batch.topologiesList object is a child object that has valuable data. We'll need to traverse the object again to pull these fields
      if (
        filtered[i].topologiesList &&
        filtered[i].topologiesList.length !== 0
      ) {
        // the labels for the product name live in the products state object.
        filtered[i].products = [
          ...new Set(
            filtered[i].topologiesList.map((top) => {
              return top.productId && state.products[top.productId]
                ? state.products[top.productId].name
                : "";
            })
          ),
        ];

        // and the unit of measure are deep nested as children in the topologiesList array. We'll use a recursive function here to loop through the nested objects and then construct a string
        let assetTypes = {};
        const iterateTopologies = (topologiesList) => {
          if (Array.isArray(topologiesList) && topologiesList.length) {
            topologiesList.forEach((el) => {
              if (el.assetType && el.count) {
                assetTypes[el.assetType] = el.count;
              }
              if (el.child) {
                return iterateTopologies(el.child);
              }
            });
          } else {
            if (topologiesList.assetType && topologiesList.count) {
              assetTypes[topologiesList.assetType] = topologiesList.count;
            }
            if (topologiesList.child) {
              return iterateTopologies(topologiesList.child);
            }
          }
        };

        iterateTopologies(filtered[i].topologiesList);

        let str = "";
        Object.entries(assetTypes).forEach((a) => {
          str = str.concat(`Type: ${a[0]}, Count: ${a[1]}\n\b\n`);
        });
        if (str && typeof str === "string") {
          filtered[i].assetTypes = str;
        }
      } else if (!_.isEmpty(filtered[i].topologyMap)) {
        // the labels for the product name live in the products state object.
        filtered[i].product =
          filtered[i].topologyMap.productId &&
          state.products[filtered[i].topologyMap.productId]
            ? state.products[filtered[i].topologyMap.productId].name
            : "";

        // and the unit of measure are deep nested as children in the topologiesList array. We'll use a recursive function here to loop through the nested objects and then construct a string
        let assetTypes = {};
        const iterateTopologies = (topologiesList) => {
          if (Array.isArray(topologiesList) && topologiesList.length) {
            topologiesList.forEach((el) => {
              if (el.assetType && el.count) {
                assetTypes[el.assetType] = el.count;
              }
              if (el.child) {
                return iterateTopologies(el.child);
              }
            });
          } else {
            if (topologiesList.assetType && topologiesList.count) {
              assetTypes[topologiesList.assetType] = topologiesList.count;
            }
            if (topologiesList.child) {
              return iterateTopologies(topologiesList.child);
            }
          }
        };

        iterateTopologies(filtered[i].topologyMap);

        let str = "";
        Object.entries(assetTypes).forEach((a) => {
          str = str.concat(`Type: ${a[0]}, Count: ${a[1]}\n\b\n`);
        });
        if (str && typeof str === "string") {
          filtered[i].assetTypes = str;
        }
      }

      // constructing a new obj for our CSV that coordinates with the headers defined in the options object... each obj here is a new row for our CSV
      let obj = {
        batchId: filtered[i].name
          ? filtered[i].name
          : filtered[i].tag
          ? filtered[i].tag
          : "",
        products:
          filtered[i].products && filtered[i].products.length > 1
            ? filtered[i].products.join(", ")
            : filtered[i].products && filtered[i].products.length
            ? filtered[i].products.join("")
            : filtered[i].product
            ? filtered[i].product
            : "",
        assetTypes: filtered[i].assetTypes ? filtered[i].assetTypes : "",
        originLocation: filtered[i].originLocation?.facility
          ? filtered[i].originLocation.facility
          : filtered[i].originLocation?.city
          ? `${filtered[i].originLocation.city}, ${filtered[i].originLocation.country}`
          : "",
        timeCreated: moment
          .utc(filtered[i].timeCreated, "YYYY-MM-DD-HHmmss")
          .tz(timeZone && timeZone.value ? timeZone.value : browserTz)
          .format("YYYY-MM-DD-hh:mm a"),
      };
      // pushing to table array
      tableArray.push(obj);
    }
    return tableArray;
  };

  const tableArray = filterBatches(raw);

  if (tableArray.length === 0) {
    return handleConfirmationModal(
      `No batches selected for export.`,
      true,
      3000
    );
  }
  const options = {
    filename: `Batches_${currentTime}.csv`,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: `Products for ${props.organization.name}`,
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    // instead of useKeysAsHeaders, you can specify headers instead:
    headers: [
      "Batch ID",
      "Products",
      "Unit(s) of Measure",
      "Origin",
      "Date created",
    ],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(tableArray);
};
