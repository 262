import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SimpleButton from "../../../components/Forms/FieldTypes/Button";
import request from "superagent";
import { exportSpreadsheet } from "../../devices/utils/xlsx";
import { init } from "./api";

const useStyles = makeStyles((theme) => ({
  combobox: {
    marginLeft: "1rem",
    width: "65%",
  },
  header: {
    margin: "auto",
  },
  itemContainer: {
    display: "flex",
    margin: "1rem 0",
  },
  root: {
    padding: "1rem 0",
  },
}));

function AssignDevice(props) {
  const {
    allDevices = [],
    apiUrl,
    availableDevices = [],
    selectedAssets = {},
    setConfirmationModalShow,
    setModal,
    token,
    timeZone,
  } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    assignedItems: {},
    devices: [],
    errorText: "",
  });
  const listItem = (item) => {
    const { assetId, assetTag, tag } = item;
    const isError =
      state.assignedItems[assetId] && state.assignedItems[assetId].error;
    return (
      <Grid className={classes.itemContainer} item key={assetId} xs={12}>
        <Typography className={classes.header} variant="subtitle1">
          {assetTag ? assetTag : tag}
        </Typography>
        <Autocomplete
          id="device"
          size="small"
          className={classes.combobox}
          filterSelectedOptions
          getOptionLabel={(option) =>
            option.label ? option.label : "No Device Tag"
          }
          onChange={(event, value) => {
            setState((prevState) => ({
              ...prevState,
              assignedItems: {
                ...prevState.assignedItems,
                [assetId]: { deviceId: value ? value.value : "", error: false },
              },
            }));
          }}
          options={state.devices}
          renderInput={(params) => (
            <TextField
              {...params}
              error={isError}
              helperText={isError ? state.errorText : ""}
              label="Select Device"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  useEffect(() => {
    init(props).then((res) => {
      const { deviceAssetsMap, deviceOptions, itemList } = res;

      setState((prevState) => ({
        ...prevState,
        devices: deviceOptions,
        deviceAssetsMap,
        assignedItems: itemList,
      }));
    });
  }, [props, selectedAssets]);

  function onSubmit() {
    Object.keys(state.assignedItems).forEach((item) => {
      const deviceId = state.assignedItems[item].deviceId;
      const body = {
        action: "topology",
        propertiesMap: {
          formId: true,
          formData: [
            {
              fieldType: "scannedAssets",
              fieldValues: [item],
            },
          ],
        },
      };
      if (deviceId) {
        request
          .post(`${apiUrl}assets/${deviceId}/action`)
          .set("auth-token", token)
          .send(body)
          .then((response) => {
            request
              .put(`${apiUrl}assets/${deviceId}`)
              .set("auth-token", token)
              .send({
                device: {
                  status: "assigned",
                },
              })
              .then((response) => {
                setConfirmationModalShow({
                  modalShow: true,
                  text: "Asset(s) Succesfully Assigned",
                });
                setModal({
                  modal: { modalShow: false, title: "", text: "" },
                });
              });
          });
      }
    });
  }

  function verifyFields() {
    let hasIssue = false;
    Object.keys(state.assignedItems).forEach((element) => {
      const deviceId = state.assignedItems[element].deviceId || "";
      const hasDuplicate =
        Object.keys(state.assignedItems).find(
          (asset) =>
            element !== asset &&
            state.assignedItems[asset].deviceId === deviceId
        ) || "";
      // We verify each selected item has an deviceId, we also check that there are no duplicate selecitons
      if (deviceId.length === 0) {
        setState((prevState) => ({
          ...prevState,
          assignedItems: {
            ...prevState.assignedItems,
            [element]: { deviceId: "", error: true },
          },
          errorText: "Required Field",
        }));
        hasIssue = true;
      } else if (hasDuplicate.length > 0) {
        setState((prevState) => ({
          ...prevState,
          assignedItems: {
            ...prevState.assignedItems,
            [element]: { deviceId: "", error: true },
          },
          errorText: "Duplicate Devices",
        }));
        hasIssue = true;
      }
    });

    if (!hasIssue) {
      onSubmit();
    } else {
      console.log("else");
      return null;
    }
  }

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <span
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() => {
            if (!_.isEmpty(availableDevices)) {
              exportSpreadsheet(
                Object.keys(allDevices).map((k) => {
                  return allDevices[k];
                }),
                state.deviceAssetsMap,
                timeZone
              );
            }
          }}
        >
          <div className="exportIcon">
            Download Spreadsheet with Device Information and Associated Asset
            Tags
            <i className="fas fa-file-export ml-2"></i>
          </div>
        </span>
      </Grid>
      <Grid item xs={12}>
        {Object.keys(selectedAssets).map((element) =>
          listItem(selectedAssets[element])
        )}
      </Grid>
      <Grid item xs={12}>
        <SimpleButton
          onClick={() => {
            verifyFields();
          }}
        />
      </Grid>
    </Grid>
  );
}

export default AssignDevice;
