import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";

import { putCentroid } from "../../API/API";
import { validateLat, validateLong } from "../../utils/utils";

import CentroidRadiusMap from "../../Maps/CentroidRadiusMap/CentroidRadiusMap";

const useStyles = makeStyles((theme) => ({
  centroid: {},
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    width: "33%",
    marginTop: theme.spacing(1),
  },
}));

function Centroid(props: any) {
  const initLong =
    props.selectedLocation?.centroidJSON?.x ||
    props.selectedLocation?.location?.longitude ||
    0;
  const initLat =
    props.selectedLocation?.centroidJSON?.y ||
    props.selectedLocation?.location?.latitude ||
    0;

  const initRadius = props.selectedLocation?.radius;
  const classes = useStyles();

  const [centroid, setCentroid] = React.useState<any>({
    latitude: {
      value: initLat,
      valid: true,
    },
    longitude: {
      value: initLong,
      valid: true,
    },
    radius: initRadius * 1000 || 100,
    changesMade: false,
  });

  React.useEffect(() => {
    if (validateLat.test(centroid.latitude.value.toFixed(5))) {
      setCentroid((c: any) => {
        return {
          ...c,
          latitude: {
            value: centroid.latitude.value,
            valid: true,
          },
        };
      });
    } else {
      setCentroid((c: any) => {
        return {
          ...c,
          latitude: {
            value: centroid.latitude.value,
            valid: false,
          },
        };
      });
    }
  }, [centroid.latitude.value]);

  React.useEffect(() => {
    if (validateLong.test(centroid.longitude.value.toFixed(5))) {
      setCentroid((c: any) => {
        return {
          ...c,
          longitude: {
            value: centroid.longitude.value,
            valid: true,
          },
        };
      });
    } else {
      setCentroid((c: any) => {
        return {
          ...c,
          longitude: {
            value: centroid.longitude.value,
            valid: false,
          },
        };
      });
    }
  }, [centroid.longitude.value]);

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          putCentroid({ ...props }, centroid).then((res) => {
            props.onHide();
            if (res?.error) {
              props.notificationModal(`${res?.error}`, true);
            } else {
              props.notificationModal(
                `Facility area sucessfully updated.`,
                false
              );
              props.onSuccess(res);
            }
          });
        }}
        className={classes.form}
      >
        <h5 style={{ textAlign: "center" }}>
          Revise Area by Centroid and Radius{" "}
          <Tooltip title="Adjust the default attributes of the radius created by LXConnect around your address or lat/long coordinates (default is 400m).">
            <InfoIcon style={{ color: "#3D5BA9" }} fontSize="small" />
          </Tooltip>
        </h5>

        <Grid
          container
          className={classes.centroid}
          spacing={2}
          justify="center"
        >
          <Grid item xs={12}>
            {initLat && initLong ? (
              <div style={{ width: "80%", height: "65vh", margin: "auto" }}>
                <CentroidRadiusMap
                  // width="80%"
                  // height="40vh"
                  // margin="auto"
                  radius={centroid.radius / 1000}
                  latitude={centroid.latitude.value}
                  longitude={centroid.longitude.value}
                  setCentroid={setCentroid}
                  location={props.selectedLocation}
                />
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type={"number"}
              error={!centroid.latitude.valid ? true : false}
              helperText={
                !centroid.latitude.valid ? "Must be a valid Latitude" : ""
              }
              size={"small"}
              variant={"outlined"}
              margin="normal"
              fullWidth
              id={"latitude"}
              label={"Latitude"}
              name={"latitude"}
              autoFocus
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  latitude: {
                    ...centroid.latitude,
                    // default to zero, if NaN
                    value: parseFloat(e.target.value) || 0,
                  },
                  changesMade: true,
                })
              }
              value={centroid.latitude.value}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type={"number"}
              error={!centroid.longitude.valid ? true : false}
              helperText={
                !centroid.longitude.valid ? "Must be a valid Longitude" : ""
              }
              size={"small"}
              variant={"outlined"}
              margin="normal"
              fullWidth
              id={"longitude"}
              label={"Longitude"}
              name={"longitude"}
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  longitude: {
                    ...centroid.longitude,
                    // default to zero, if NaN
                    value: parseFloat(e.target.value) || 0,
                  },
                  changesMade: true,
                })
              }
              value={centroid.longitude.value}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type={"number"}
              inputProps={{ step: 10, min: 10, max: 1600 }}
              size={"small"}
              variant={"outlined"}
              margin="normal"
              fullWidth
              id={"radius"}
              label={"Radius (meters)"}
              name={"meters"}
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  radius: e.target.value,
                  changesMade: true,
                })
              }
              value={centroid.radius}
            />
          </Grid>

          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={
              centroid.latitude.valid &&
              centroid.longitude.valid &&
              centroid.radius &&
              centroid.changesMade
                ? false
                : true
            }
            fullWidth
            className={classes.button}
          >
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
}

export default Centroid;
