import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ProductImages from "../../products/ProductImages";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  dataItem: {
    margin: "1rem 0",
  },
  header: {
    color: theme.palette.typography.primary,
    fontWeight: "bold",
  },
  content: {
    color: theme.palette.typography.secondary,
    paddingLeft: ".5rem",
  },
}));

export default function ProductInformationTab(props) {
  const { children = [], products = {} } = props;
  const classes = useStyles();

  // Im writing this knowing that a batch can have more than one product.
  // But because we currently are not using this modal, this component grabs the
  // first product in the batch.topologyMap and runs with that. We can easily refactor
  // this code to iterate
  const initialProduct = children.reverse()[0]
    ? children.reverse()[0].productId
    : {};
  const {
    description,
    images,
    name,
    productType,
    propertiesMap = {},
    tag,
  } = products[initialProduct] || {};
  const { customDataFields = [] } = propertiesMap;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          {/* Product */}
          <Grid className={classes.dataItem} item xs={4}>
            <Typography className={classes.header} variant="h6">
              Product
            </Typography>
            <Typography className={classes.content} variant="body2">
              {initialProduct && name ? name : "None"}
            </Typography>
          </Grid>

          {/* Product Code */}
          <Grid className={classes.dataItem} item xs={4}>
            <Typography className={classes.header} variant="h6">
              Product Code
            </Typography>
            <Typography className={classes.content} variant="body2">
              {initialProduct && tag ? tag : "None"}
            </Typography>
          </Grid>

          {/* Product Category */}
          <Grid className={classes.dataItem} item xs={4}>
            <Typography className={classes.header} variant="h6">
              Product Category
            </Typography>
            <Typography className={classes.content} variant="body2">
              {initialProduct && productType ? productType : "None"}
            </Typography>
          </Grid>

          {/* Product Description */}
          <Grid className={classes.dataItem} item xs={4}>
            <Typography className={classes.header} variant="h6">
              Product Description
            </Typography>
            <Typography className={classes.content} variant="body2">
              {initialProduct && description ? description : "None"}
            </Typography>
          </Grid>

          {/* Custom Data Fields */}
          {customDataFields.map((element, index) => {
            const { title, value } = element;
            return (
              <Grid
                className={classes.dataItem}
                item
                key={`${title}-index`}
                xs={4}
              >
                <Typography className={classes.header} variant="h6">
                  {title}
                </Typography>
                <Typography className={classes.content} variant="body2">
                  {value}
                </Typography>
              </Grid>
            );
          })}

          {/* Product Images */}
          <Grid item xs={12}>
            <ProductImages
              images={images}
              productId={initialProduct}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
