import { Location } from "history";
import { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import WarningDialog from "./WarningDialog";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string | number) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  handleSaveSettings: () => void;
  dispatchState: Function;
  globalSettingsRef: any;
  userSettingsRef: any;
  state: any;
}

const ConfirmUnsavedChanges = ({
  when,
  navigate,
  shouldBlockNavigation,
  handleSaveSettings,
  dispatchState,
  globalSettingsRef,
  userSettingsRef,
  state,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleSave = () => {
    setModalVisible(false);
    handleSaveSettings();
    setTimeout(() => {
      setConfirmedNavigation(true);
    }, 1600);
  };

  const handleDiscard = (isUserSettings: boolean) => {
    dispatchState({
      type: isUserSettings ? "RESET_USER" : "RESET_GLOBAL",
      payload: isUserSettings
        ? userSettingsRef.current
        : globalSettingsRef.current,
    });
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);

      // Navigate to the previous blocked location with your navigate function
      setTimeout(() => {
        navigate(lastLocation.pathname);
      }, 250);
    }
    return () => {
      setConfirmedNavigation(false);
    };
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <WarningDialog
        open={modalVisible}
        title={`You have unsaved changes in ${
          state.userSettings.settingsUpdated
            ? `User Settings.`
            : `Global Settings.`
        }`}
        subHeader="Would you like to save or discard your changes?"
        closeModal={closeModal}
        onSave={handleSave}
        onDiscard={() => handleDiscard(state.userSettings.settingsUpdated)}
      />
    </>
  );
};
export default ConfirmUnsavedChanges;
