import { useState } from "react";
import { exportSpreadsheet } from "../xlsx/xlsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Grid, Typography } from "@material-ui/core";

import { showLastEvents } from "../api";
import ControlledTable from "../../../../components/Tables/ControlledTable/ControlledTable";
import DisplayColumnsDropdown from "../../../../components/Tables/ControlledTable/components/DisplayColumnsDropdown/DisplayColumnsDropdown";
import FilterMenu from "../FilterMenu/FilterMenu";
import buildColumns from "./columns";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

export default function Table(props: any) {
  const {
    apiUrl,
    classifications,
    facilities,
    handleSave,
    organizationId,
    parentIds,
    products,
    setConfirm,
    setLoading,
    setState,
    state,
    token,
    userId,
    usersMap,
    zones,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const timeZone = props.timeZone?.value || null;

  const { historyTableColumns, buildDisplayColumnOptions } = buildColumns({
    apiUrl,
    classifications,
    displayColumnOptions: state.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    setState,
    parentIds,
    timeZone,
    token,
    userId,
    zones,
  });

  const [displayColumnOptions, setDisplayColumnOptions] = useState<any>(
    buildDisplayColumnOptions
  );

  const [defaultColumnOrder, setDefaultColumnOrder] = useState<any>(
    state.filters?.defaultColumnOrder
  );

  return (
    <>
      <ControlledTable
        data={state.assets || []}
        columns={historyTableColumns.filter((col: any) =>
          displayColumnOptions[col.id]
            ? displayColumnOptions[col.id].checked
            : true
        )}
        controlledCount={state.count || 0}
        controlledPageCount={Math.ceil(
          parseInt(state.count) / parseInt(state.filters?.limit)
        )}
        controlledPageIndex={state.page}
        controlledPageSize={state.filters?.limit}
        defaultColumnOrder={defaultColumnOrder}
        onColumnDrop={(colOrder) => {
          handleSave({
            ...state.filters,
            defaultColumnOrder: colOrder,
          });
          setDefaultColumnOrder(colOrder);
        }}
        state={state}
        setState={setState}
        stickyHeader
        defaultSort={state.filters?.sorted || [{ id: "timeOfLog" }]}
        isSelect
        onSelect={(row: any) => {
          const { assetId } = row;
          setState((s: any) => {
            if (!s.selectedAssets[assetId]) {
              return {
                ...s,
                selectedAssets: {
                  ...s.selectedAssets,
                  [assetId]: row,
                },
              };
            }
            delete s.selectedAssets[assetId];
            return {
              ...s,
              selectedAssets: s.selectedAssets,
            };
          });
        }}
        selectAll={() => {
          const data = state.assets ? state.assets : [];
          setState((s: any) => {
            return {
              ...s,
              selectedAssets: !s.selectAll
                ? data.reduce((x: any, y: any) => {
                    return { ...x, [y.assetId]: y };
                  }, {})
                : {},
              selectAll: !s.selectAll,
            };
          });
        }}
        clearSelected={() => {
          setState((s: any) => {
            return {
              ...s,
              selectedAssets: {},
              selectAll: false,
            };
          });
        }}
        saveFilterSettings={handleSave}
        globalFilterLabel={"assets"}
        toolbarChildren={
          <Grid
            className={classes.barChildrenContainer}
            container
            alignItems={"center"}
          >
            {/* Open Menu Button */}
            <DisplayColumnsDropdown
              handleSave={(columnOptions: any, name: string) => {
                // set column filter to null if its key is active / not null in filters when removing column
                if (state.filters[name]) {
                  setState((s: any) => {
                    return {
                      ...s,
                      filters: {
                        ...s.filters,
                        [name]: null,
                      },
                    };
                  });
                }
                handleSave({
                  displayColumnOptions: columnOptions,
                });
              }}
              options={displayColumnOptions}
              setOptions={setDisplayColumnOptions}
            />

            {state.displayMap && state.count - state.filters?.limit > 0 ? (
              <Grid className={classes.barChildren} item xs={3} xl={2}>
                <span>
                  <Tooltip title="Change row and page in table view to paginate through assets.">
                    <div>
                      Showing {state.filters?.start + 1} -{" "}
                      {state.filters?.limit + state.filters?.start > state.count
                        ? state.count
                        : state.filters?.limit + state.filters?.start}{" "}
                      assets out of {state.count}.
                    </div>
                  </Tooltip>
                </span>
              </Grid>
            ) : null}

            <Grid className={classes.barChildren} item xs={3} xl={2}>
              {state && state.lists && state.filters ? (
                <FilterMenu
                  apiUrl={apiUrl}
                  classifications={classifications}
                  defaultColumnOrder={defaultColumnOrder}
                  displayColumnOptions={displayColumnOptions}
                  handleSave={handleSave}
                  organizationId={organizationId}
                  parentIds={parentIds}
                  setConfirm={setConfirm}
                  setState={setState}
                  state={state}
                  token={token}
                  usersMap={usersMap}
                />
              ) : (
                ""
              )}
            </Grid>
            {state.displayMap ? null : (
              <Grid
                className={classes.barChildren}
                item
                xs={3}
                xl={2}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoading(true);

                  if (state.count >= 1000) {
                    if (
                      !window.confirm(
                        `Can not export information for more than 1,000 assets at once. This export will contain information for the first 1000 out of ${state.count} assets. Do you still wish to continue?`
                      )
                    ) {
                      setLoading(false);
                      return;
                    }
                  }

                  showLastEvents(
                    { apiUrl, token, organizationId, classifications },
                    state.filters,
                    true
                  ).then((results) => {
                    if (results.error) {
                      setConfirm({
                        modalShow: true,
                        text: results.error,
                        error: true,
                      });
                    } else {
                      exportSpreadsheet(
                        results.assets || [],
                        facilities,
                        timeZone,
                        products,
                        zones,
                        classifications
                      ).then((finished) => {
                        setLoading(false);
                      });
                    }
                  });
                }}
              >
                <Typography className="exportIcon" variant="subtitle1">
                  Export
                  <i className="fas fa-file-export ml-2"></i>
                </Typography>
              </Grid>
            )}
          </Grid>
        }
      />
    </>
  );
}
