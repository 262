import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, isEqual } from "lodash";

import { Card } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import "./styles.css";

import { updateWidgetSettings } from "../../../globalStore/slices/user/userSlice";

import LocationsMap from "../../../components/Widgets/LocationsMap/LocationsMap";
import HeatMap from "../../../components/Widgets/HeatMap/HeatMap";
import BarGraphsByEventAndDate from "../../../components/Widgets/BarGraphsByEventAndDate/BarGraphsByEventAndDate";
import NotificationsByEventType from "../../../components/Widgets/Notifications/NotificationsByEventType";

import { saveUserWidgetSettings } from "./utils";

export default function ProductDashboard(props: any) {
  const dispatchGlobal = useDispatch();
  const { facilities, organization, usersMap, products, batches } = cloneDeep(
    useSelector((state: any) => state.organization, isEqual)
  );

  const {
    apiUrl,
    token,
    userId = "",
    organizationId,
    timeZone,
    appUserType,
  } = props;

  let sortedProductTypes: any = {};
  let sortedProductTypesCount: any = [];

  // Seperates the different product types
  Object.values(products).forEach((element: any) => {
    const { productType } = element;
    if (productType === undefined) {
      if (sortedProductTypes.untagged === undefined) {
        sortedProductTypes.untagged = [];
      }
      sortedProductTypes.untagged.push(element);
    } else if (sortedProductTypes[productType] === undefined) {
      sortedProductTypes[productType] = [element];
    } else {
      sortedProductTypes[productType].push(element);
    }
  });

  // Throws the product type counts into an array that the bargraph is expecting
  Object.keys(sortedProductTypes).forEach((element) => {
    sortedProductTypesCount.push(sortedProductTypes[element].length);
  });

  const handleWidgetSettings = (widget: string, settings: any) => {
    saveUserWidgetSettings({ apiUrl, userId, token }, widget, settings).then(
      (res) => {
        dispatchGlobal(updateWidgetSettings({ widget, settings }));
      }
    );
  };

  const { usersConsoleRole } = props;

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");

  return (
    <div className="container-fluid dashMain">
      <div className="row">
        <div className="col-12 mt-3 mb-3">
          <div className="welcome d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h3>Welcome {`${firstName} ${lastName}`} to LXConnect!</h3>
            </div>
            <Card
              onClick={() => {
                props.history.push("/products");
              }}
              className="circle shadow pointer"
            >
              <Tooltip title="Total Number of Products" placement="left">
                <Card.Body className="text-center dashboardCircles">
                  <h5>
                    {Object.values(products).length} <br /> Products
                  </h5>
                </Card.Body>
              </Tooltip>
            </Card>
            <Card
              onClick={() => {
                props.history.push("/batches");
              }}
              className="circle shadow pointer"
            >
              <Tooltip title="Total Number of Batches" placement="left">
                <Card.Body className="text-center dashboardCircles">
                  <h5>
                    {Object.values(batches).length} <br /> Batches
                  </h5>
                </Card.Body>
              </Tooltip>
            </Card>
            {usersConsoleRole === "Lite" ? null : (
              <Card
                onClick={() => {
                  props.history.push("/facilities");
                }}
                className="circle shadow pointer"
              >
                <Tooltip title="Total Number of Facilities" placement="left">
                  <Card.Body className="text-center dashboardCircles">
                    <h5>
                      {Object.values(facilities).length} <br /> Facilities
                    </h5>
                  </Card.Body>
                </Tooltip>
              </Card>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-md-6">
              <Card className="dashWidget map-wrapper locations-widget">
                <Card.Body>
                  {organization ? (
                    <LocationsMap
                      facilities={facilities}
                      apiUrl={apiUrl}
                      token={token}
                      timeZone={timeZone}
                      organization={organization}
                      handleWidgetSettings={handleWidgetSettings}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="dashWidget notification-widget">
                <Card.Body className="h-100">
                  {organization ? (
                    <NotificationsByEventType
                      appUserType={appUserType}
                      apiUrl={apiUrl}
                      userId={userId}
                      timeZone={timeZone}
                      token={token}
                      organization={organization}
                      usersMap={usersMap}
                      organizationId={organizationId}
                      batches={Object.values(batches)}
                      products={Object.values(products)}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <Card className="dashWidget">
                <Card.Body>
                  {organization ? (
                    <BarGraphsByEventAndDate
                      apiUrl={apiUrl}
                      token={token}
                      organization={organization}
                      timeZone={timeZone}
                      handleWidgetSettings={handleWidgetSettings}
                      onHandleSubmit={(event: any) => {
                        event.preventDefault();
                      }}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="dashWidget map-wrapper heat-map">
                <Card.Body>
                  {organization ? (
                    <HeatMap
                      apiUrl={apiUrl}
                      token={token}
                      handleWidgetSettings={handleWidgetSettings}
                      products={Object.values(products)}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
