import UncontrolledTable from "../../../../components/Tables/UncontrolledTable/UncontrolledTable";
import ActionButtons from "../../../../components/Buttons/ActionButtons";
import { omit } from "lodash";
import { handleExport } from "../utils/xlsx";

export default function ZonesTable(props: any) {
  const {
    state,
    setState,
    dialog,
    setDialog,
    permissions,
    handleConfirmationModal,
  } = props;
  const { editLocation, deleteLocation } = permissions;

  const viewDetail = (row: any) =>
    setDialog({
      ...dialog,
      dialogShow: true,
      selectedZone: row.original,
      dialogTitle: "Zone Detail",
    });

  const tableColumns = [
    {
      Header: "Zone Name",
      accessor: "name",
      id: "name",
      Cell: (data: any) => (
        <div onClick={() => viewDetail(data.row)} className="pointer">
          {data.row.original.name || ""}
        </div>
      ),
    },
    {
      Header: "Zone Description",
      accessor: (row: any) => row.propertiesMap?.description || "",
      id: "description",
      Cell: (data: any) => (
        <div onClick={() => viewDetail(data.row)} className="pointer">
          {data.row.original.propertiesMap?.description || ""}
        </div>
      ),
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: "actions",
      id: "actions",
      Cell: (data: any) => {
        const row = data.row;
        const buttonIcons: any = [];
        ["Edit Zone", "Delete Zone"].forEach((element) => {
          switch (element) {
            case "Edit Zone":
              if (editLocation) {
                buttonIcons.push({
                  icon: "fas fa-edit",
                  title: "Edit Zone",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedZone: row.original,
                      dialogTitle: "Edit Zone",
                    });
                  },
                });
              }
              break;
            case "Delete Zone":
              if (deleteLocation) {
                buttonIcons.push({
                  icon: "fas fa-trash-alt",
                  title: "Delete Zone",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedZone: row.original,
                      dialogTitle: "Delete Zone",
                    });
                  },
                });
              }
              break;
            default:
              break;
          }
        });
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  const selectAllPageRows = (pageRows: any, deselectPageRows: boolean) => {
    let updatedSelection = {};

    const selectedZones = state.selectedZones;

    const reducedPageRows = pageRows.reduce((x: any, y: any) => {
      return {
        ...x,
        [y.zoneId]: y,
      };
    }, {});
    if (deselectPageRows) {
      updatedSelection = omit(selectedZones, Object.keys(reducedPageRows));
    } else {
      updatedSelection = { ...selectedZones, ...reducedPageRows };
    }
    setState((s: any) => {
      return {
        ...s,
        selectedZones: updatedSelection,
      };
    });
  };

  const selectRow = (row: any) => {
    const { selectedZones: newSelectedZones = {} } = state;
    if (newSelectedZones[row.zoneId]) {
      delete newSelectedZones[row.zoneId];
    } else {
      newSelectedZones[row.zoneId] = row;
    }
    setState((s: any) => {
      return {
        ...s,
        selectedZones: newSelectedZones,
      };
    });
  };

  return (
    <>
      <UncontrolledTable
        stickyHeader
        totalCountLabel={"Zones"}
        data={state.zones || []}
        defaultSort={[{ id: "name" }]}
        columns={tableColumns}
        isSelect
        selectAllPageRows={selectAllPageRows}
        onSelect={selectRow}
        toolbarChildren={
          <div className="d-flex">
            <div className="p-2">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleExport(state, handleConfirmationModal);
                }}
              >
                <div className="exportIcon">
                  Export
                  <i className="fas fa-file-export ml-2"></i>
                </div>
              </span>
            </div>
          </div>
        }
      />
    </>
  );
}
