import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import { onSubmit } from "./utils/api";

const useStyles = makeStyles((theme: any) => ({
  button: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#45793A",
      color: "#FFF",
    },
  },
  fontWeight: {
    fontWeight: 600,
  },
  formControl: {
    width: "100%",
  },
  input: {
    margin: "2rem 0",
  },
  root: {
    width: "100%",
  },
  textCenter: {
    textAlign: "center",
  },
}));

export default function InitialLogin(props: any) {
  const {
    retrieveUserAndToken,
    setConfirmationModal,
    setState,
    state = {},
  } = props;
  const { fieldValues = {}, showPassword = false } = state;
  const { password = {}, userName = {} } = fieldValues;
  const classes = useStyles();

  function validateFields() {
    let error: Boolean = false;
    if (userName.value.length === 0) {
      error = true;
      setState((prevState: any) => ({
        ...prevState,
        fieldValues: {
          ...prevState.fieldValues,
          userName: {
            ...prevState.fieldValues.userName,
            error: true,
          },
        },
      }));
    }

    if (password.value.length === 0) {
      error = true;
      setState((prevState: any) => ({
        ...prevState,
        fieldValues: {
          ...prevState.fieldValues,
          password: {
            ...prevState.fieldValues.password,
            error: true,
          },
        },
      }));
    }
    if (!error) {
      onSubmit(props).then((response) => {
        if (response?.success === true) {
          const { token, user } = response;
          // eslint-disable-next-line no-restricted-globals
          const params = new URLSearchParams(location.search.substring(1));
          const batchId = params.get("batchId");
          const assetType = params.get("assetType");
          const {
            appUserId,
            email,
            firstName,
            lastName,
            organizationId,
            roles = [],
          } = user;

          if (roles.includes("No Console Access")) {
            setConfirmationModal({
              error: true,
              show: true,
              text: "Your administrator has not configured your account to have access to LXConnect. If you believe this is a mistake, please contact your organization's administrative user.",
            });
            return;
          }

          // Sets values in local storage to survive a hard refresh
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem("organizationId", organizationId);
          localStorage.setItem("token", token);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("userId", appUserId);

          if (batchId !== null && assetType !== null) {
            retrieveUserAndToken(
              user,
              token,
              `/batches/${batchId}/history/${assetType}`
            );
          } else {
            retrieveUserAndToken(user, token, `/`);
          }
        } else if (response?.error) {
          setConfirmationModal({
            error: true,
            show: true,
            text: `A problem has occured: ${response.error}`,
          });

          return false;
        }
      });
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validateFields();
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className={`${classes.textCenter} ${classes.fontWeight}`}
            variant="h4"
          >
            Welcome to LXConnect
          </Typography>
        </Grid>
        <Grid className={classes.input} item xs={12}>
          <FormControl className={classes.formControl}>
            <SimpleTextField
              error={userName.error}
              label="Email"
              id="username"
              onChange={(event: any) => {
                setState((prevState: any) => ({
                  ...prevState,
                  fieldValues: {
                    ...prevState.fieldValues,
                    userName: { error: false, value: event.target.value },
                  },
                }));
              }}
              value={userName.value}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid className={classes.input} item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              className={classes.root}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setState((prevState: any) => ({
                        ...prevState,
                        showPassword: !prevState.showPassword,
                      }));
                    }}
                    onMouseDown={(event: any) => {
                      event.preventDefault();
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={password.error}
              id="password"
              onChange={(event: any) => {
                setState((prevState: any) => ({
                  ...prevState,
                  fieldValues: {
                    ...prevState.fieldValues,
                    password: { error: false, value: event.target.value },
                  },
                }));
              }}
              type={state.showPassword ? "text" : "password"}
              value={password.value}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.input} item xs={6}>
          <Button className={classes.button} type="submit" variant="contained">
            Login
          </Button>
        </Grid>
        <Grid className={classes.input} item xs={6}>
          <Typography
            className={`${classes.textCenter} ${classes.fontWeight} pointer primary-text`}
            onClick={() => {
              setState((prevState: any) => ({ ...prevState, grabEmail: true }));
            }}
            variant="h6"
          >
            Forgot password?
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
