import React from "react";
import { TwitterPicker } from "react-color";
import { VerticalBrandColorSwatches } from "./BrandColors";

function ColorPicker(props) {
  return (
    <TwitterPicker
      colors={VerticalBrandColorSwatches}
      onChangeComplete={(color) => {
        props.setColor(color.hex);
      }}
      width={"250px"}
      color={props.color}
    />
  );
}

export default ColorPicker;
