import { useState, Fragment } from "react";

import moment from "moment";
import { Button, Switch, Grid, makeStyles } from "@material-ui/core";

import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import NotificationModal from "../../../components/Modals/NotificationModal";

import { NewBatch } from "./interface";
import ConfirmPCA from "./ConfirmPCA/ConfirmPCA";
import ImportILDE from "./ImportILDE/ImportILDE";
import { postBatch } from "./api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function CreateBatch(props: any) {
  const classes = useStyles();
  const timeZone =
    props.timeZone && props.timeZone.value
      ? props.timeZone.value
      : moment.tz.guess();
  // get ILDE and PCA settings from org, if allBatches or default are set, then the batch is a PCA batch, otherwise show toggle for setting PCA
  // if ILDE exist, require import
  const { pcaSettings = {}, itemLevelDataElements = [] } = props;
  const isPCA = pcaSettings.default || pcaSettings.allBatches ? true : false;
  const isILDE = itemLevelDataElements.length ? true : false;
  const [confirmPCA, setConfirmPCA] = useState(false);
  const [importDialog, setImportDialog] = useState({
    dialogShow: false,
    uploadSuccess: false,
    uploadedElements: "",
  });
  const [confirm, setConfirm] = useState({
    modalShow: false,
    text: "",
    error: false,
  });

  const [newBatch, setNewBatch] = useState<NewBatch>({
    product: {
      id: props.selectedProduct.productId,
      name: props.selectedProduct.name,
    },
    timeCreated: moment().tz(timeZone).format("MM/DD/YYYY"),
    topologiesList: [
      {
        assetType: "",
        count: 1,
        productId: props.selectedProduct.productId,
        pcaKeyType: "distribution",
      },
    ],
    customDataFields: [],
    isPCA: isPCA,
    isILDE: isILDE,
  });

  return (
    <>
      <form
        onSubmit={(e: any) => {
          if (isILDE) {
            e.preventDefault();
            setImportDialog({
              ...importDialog,
              dialogShow: true,
            });
          } else {
            e.preventDefault();
            postBatch(props, newBatch).then((res) => {
              if (res?.error) {
                props.notificationModal(`${res?.error}`, true, 5000);
              } else {
                props.notificationModal(
                  `Batch sucessfully created.`,
                  false,
                  2000
                );
              }
            });
          }
        }}
      >
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={6}>
            <SimpleTextField
              // error={tag.error}
              required
              id="tag"
              label="Batch ID"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  tag: e.target.value,
                });
              }}
              value={newBatch.tag || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleTextField
              // error={name.error}
              id="name"
              label="Batch Name - optional"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  name: e.target.value,
                });
              }}
              value={newBatch.name || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleTextField
              id="product"
              label="Product Name"
              readOnly
              value={newBatch.product.name}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleTextField
              id="timeCreated"
              label="Date Created"
              readOnly
              value={newBatch.timeCreated}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleSelect
              required
              id="originLocation"
              label="Batch Origin"
              variant="outlined"
              options={Object.keys(props.facilities).map((key) => {
                return {
                  label: props.facilities[key].name,
                  value: key,
                };
              })}
              value={newBatch.originLocation || ""}
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  originLocation: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleSelect
              id="destinationLocation"
              label="Final Destination - optional"
              variant="outlined"
              options={Object.keys(props.facilities).map((key) => {
                return {
                  label: props.facilities[key].name,
                  value: key,
                };
              })}
              value={newBatch.destinationLocation || ""}
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  destinationLocation: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleTextField
              // error={name.error}
              multiline
              id="description"
              label="Batch Description - optional"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  description: e.target.value,
                });
              }}
              value={newBatch.description || ""}
            />
          </Grid>
          {/* Units of measure */}
          {!isILDE && newBatch.topologiesList && newBatch.topologiesList.length
            ? newBatch.topologiesList.map((top: any, idx: number) => (
                <Fragment key={`${idx}`}>
                  <Grid item xs={6}>
                    <SimpleSelect
                      // error={name.error}
                      required
                      id={`${idx} - assetType`}
                      label="Unit of Measure"
                      variant="outlined"
                      options={props.assetTypes.map((type: string) => {
                        return {
                          label: type,
                          value: type,
                        };
                      })}
                      onChange={(e: any) => {
                        // const assetType = e.target.value;
                        let newArr = newBatch.topologiesList || [];
                        newArr[idx] = {
                          ...newArr[idx],
                          assetType: e.target.value,
                        };
                        setNewBatch({
                          ...newBatch,
                          topologiesList: newArr,
                        });
                      }}
                      value={newBatch.topologiesList[idx].assetType}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleTextField
                      required
                      type="number"
                      id={`${idx} - count`}
                      label={`Count`}
                      InputProps={{ inputProps: { min: 1, max: 100000 } }}
                      onChange={(e: any) => {
                        let newArr = newBatch.topologiesList || [];
                        newArr[idx] = { ...newArr[idx], count: e.target.value };
                        setNewBatch({
                          ...newBatch,
                          topologiesList: newArr,
                        });
                      }}
                      value={newBatch.topologiesList[idx].count}
                    />
                  </Grid>
                </Fragment>
              ))
            : null}
          {/* Add / remove units of measure */}
          {!isILDE ? (
            <>
              {" "}
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    let newArr = newBatch.topologiesList;
                    newArr.push({
                      assetType: "",
                      count: 1,
                      productId: props.selectedProduct.productId,
                      pcaKeyType: "distribution",
                    });
                    setNewBatch({
                      ...newBatch,
                      topologiesList: newArr,
                    });
                  }}
                >
                  Add new unit of measure
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={newBatch.topologiesList.length <= 1}
                  variant="contained"
                  onClick={(e) => {
                    let newArr = newBatch.topologiesList;
                    newArr.pop();
                    setNewBatch({
                      ...newBatch,
                      topologiesList: newArr,
                    });
                  }}
                >
                  Remove unit of measure
                </Button>
              </Grid>
            </>
          ) : null}

          {!pcaSettings.allBatches ? (
            <Grid item xs={6}>
              Apply PCA to the batch
              <Switch
                checked={newBatch.isPCA}
                onChange={(e) => {
                  setNewBatch({
                    ...newBatch,
                    isPCA: e.target.checked,
                  });
                  if (e.target.checked) {
                    setConfirmPCA(true);
                  }
                }}
                color="primary"
                name="pca"
                inputProps={{ "aria-label": "PCA setting" }}
              />
            </Grid>
          ) : null}

          {isILDE ? (
            <>
              <Grid item xs={12}>
                This batch includes products that require item-level data
                elements. Please upload these data elements.
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Next
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      </form>

      <ConfirmPCA
        confirmPCA={confirmPCA}
        setConfirmPCA={setConfirmPCA}
        cancel={() => {
          setNewBatch({
            ...newBatch,
            isPCA: false,
          });
        }}
      />
      <ModalDialog
        handleClose={() => {
          setImportDialog({
            ...importDialog,
            dialogShow: false,
          });
        }}
        open={importDialog.dialogShow}
        title={"Upload Item Level Data Elements"}
        content={
          <ImportILDE
            onSuccess={() => {
              setImportDialog({
                ...importDialog,
                dialogShow: false,
                uploadSuccess: true,
              });
              setTimeout(() => {
                props.onHide();
              }, 2000);
            }}
            pcaId={props.pcaId}
            itemLevelDataElements={itemLevelDataElements}
            selectedProduct={props.selectedProduct}
            newBatch={newBatch}
            token={props.token}
            apiUrl={props.apiUrl}
            facilities={props.facilities}
            setConfirm={setConfirm}
            assetTypes={props.assetTypes}
          />
        }
      />
      <NotificationModal
        confirmationModalShow={confirm.modalShow}
        modalClose={() => {
          setConfirm({ ...confirm, modalShow: false });
        }}
        color={confirm.error ? `bg-danger` : `bg-success`}
        confirmationText={confirm.text}
        icon={confirm.error ? "fas fa-times mr-2" : "fas fa-check-circle mr-2"}
      />
    </>
  );
}
