import VerticalTabs from "../../../components/Tabs/VerticalTabs";
import * as Tab from "./Tabs/index";

import { rolesBasedAccess } from "./utils";

function UserSettings(props) {
  const {
    state,
    dispatchState,
    handleConfirmationModal,
    usersConsoleRole = "",
    appUserType = "Asset/Product",
  } = props;

  const { userInfo = {}, consoleSettings = {} } = rolesBasedAccess({
    usersConsoleRole,
    appUserType,
  });

  return (
    <>
      <VerticalTabs
        panelStyle={{ width: "62vw" }}
        containerStyle={{ width: "80vw", height: "65vh" }}
      >
        {userInfo.view ? (
          <Tab.UserInfo
            label="User Information"
            state={state}
            dispatchState={dispatchState}
          />
        ) : null}
        {consoleSettings.view && consoleSettings.quickLinkActions ? (
          <Tab.ConsoleSettings
            state={state}
            dispatchState={dispatchState}
            label={"LXConnect Settings"}
            quickLinkActions={consoleSettings.quickLinkActions}
            handleConfirmationModal={handleConfirmationModal}
          />
        ) : null}
      </VerticalTabs>
    </>
  );
}

export default UserSettings;
