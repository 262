import { useState } from "react";
import { Form, Col } from "react-bootstrap";

import { editZone } from "../API/api";
import RequiredField from "../../../../components/Forms/RequiredField/RequiredField";

import { Button } from "@material-ui/core";

export default function EditZone(props: any) {
  const {
    selectedZone = {},
    notificationModal,
    onHide,
    onSuccess,
    zones,
    zoneCategories,
  } = props;

  const [editState, setEditState] = useState<any>({
    validated: false,
    name: selectedZone.name,
    description: selectedZone.propertiesMap.description,
    abbreviation: selectedZone.abbreviation || "",
    floor: selectedZone.floor || "",
    category: selectedZone.category || "",
    zoneType: selectedZone.zoneType || "",
    parentId: selectedZone.parentId || "",
  });

  const {
    validated,
    name,
    description,
    abbreviation,
    floor,
    category,
    zoneType,
    parentId,
  } = editState;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      props.notificationModal(
        `Please make sure all required fields are filled out properly.`,
        true,
        "fas fa-times-circle mr-2",
        3000
      );
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const {
        name,
        description,
        abbreviation,
        floor,
        category,
        zoneType,
        parentId,
      } = editState;

      const payload = {
        name,
        abbreviation,
        floor,
        category,
        parentId,
        zoneType,
        propertiesMap: {
          description: description,
        },
      };

      editZone(props, selectedZone.zoneId, payload).then((editResponse) => {
        if (editResponse.success) {
          onSuccess(selectedZone.zoneId, editResponse.zone);
          onHide();
          notificationModal(
            "Zone Sucessfully Updated",
            false,
            "fas fa-check-circle mr-2",
            3000
          );
        } else {
          notificationModal(
            `${
              editResponse.error ||
              `Something went wrong when trying to add a zone to the facility. Please contact your system administrator.`
            }`,
            true,
            "fas fa-times-circle mr-2",
            3000
          );
        }
      });
    }
  };

  return (
    <Form
      className="container-fluid"
      noValidate
      onSubmit={(event: any) => {
        handleSubmit(event);
      }}
      validated={validated}
    >
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Zone Name</Form.Label>
          <Form.Control
            className="form-control bg-white"
            name="name"
            onChange={(event: any) => {
              setEditState({ ...editState, name: event.target.value });
            }}
            required
            value={name || ""}
          />
          <RequiredField label="Required Field" />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Zone Abbreviation - optional</Form.Label>
          <Form.Control
            className="form-control bg-white"
            onChange={(event: any) => {
              setEditState({
                ...editState,
                abbreviation: event.target.value,
              });
            }}
            name="abbreviation"
            value={abbreviation || ""}
          />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Zone Description - optional</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            className="form-control bg-white"
            onChange={(event: any) => {
              setEditState({ ...editState, description: event.target.value });
            }}
            name="description"
            value={description || ""}
          />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Floor - optional</Form.Label>
          <Form.Control
            className="form-control bg-white"
            onChange={(event: any) => {
              setEditState({
                ...editState,
                floor: event.target.value,
              });
            }}
            name="floor"
            value={floor || ""}
          />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Category - optional</Form.Label>
          <Form.Control
            as="select"
            className="custom-select bg-white"
            onChange={(event: any) => {
              setEditState({
                ...editState,
                category: event.target.value,
              });
            }}
            name="category"
            value={category || ""}
          >
            <option value="">Select</option>
            {zoneCategories.map((element: string, index: number) => {
              return (
                <option key={index} value={element}>
                  {element}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Zone Type - optional</Form.Label>
          <Form.Control
            className="form-control bg-white"
            onChange={(event: any) => {
              setEditState({
                ...editState,
                zoneType: event.target.value,
              });
            }}
            name="zoneType"
            value={zoneType || ""}
          />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <Form.Label className="mb-0">Parent Zone - optional</Form.Label>
          <Form.Control
            as="select"
            className="custom-select bg-white"
            onChange={(event: any) => {
              setEditState({
                ...editState,
                parentId: event.target.value,
              });
            }}
            name="parentId"
            value={parentId || ""}
          >
            <option value="">Select</option>
            {zones
              .filter((z: any) => z.zoneId !== selectedZone.zoneId)
              .map((element: any, index: number) => {
                return (
                  <option key={index} value={element.zoneId}>
                    {element.name}
                  </option>
                );
              })}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6" className="text-center mt-1">
          <Button type="submit" color="primary" fullWidth variant="contained">
            {" "}
            Submit
          </Button>
        </Form.Group>
        <Form.Group as={Col} md="6" className="text-center mt-1">
          <Button
            color="secondary"
            fullWidth
            variant="contained"
            onClick={() => onHide()}
          >
            {" "}
            Cancel
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
