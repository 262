import React from "react";
import { Form, Select, Option } from "informed";
import { Redirect } from "react-router-dom";

export default class SelectCQR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetType: "",
      avilableAssetTypes: [],
      batchId: "",
      isRedirect: false,
      productId: "",
      redirect: false,
    };
  }

  componentDidMount() {
    const { topologiesList = [], topologyMap = {} } = this.props.selectedBatch;

    const selectedBatchAssetTypes = {};

    let extractAssettypes = (tops) => {
      if (Array.isArray(tops) && tops.length) {
        tops.forEach((element) => {
          if (
            element.assetType &&
            !selectedBatchAssetTypes[element.assetType]
          ) {
            selectedBatchAssetTypes[element.assetType] = element.assetType;
          }
          if (element.child) {
            extractAssettypes(element.child);
          }
          return;
        });
      } else {
        if (tops.assetType && !selectedBatchAssetTypes[tops.assetType]) {
          selectedBatchAssetTypes[tops.assetType] = tops.assetType;
        }
        if (tops.child) {
          extractAssettypes(tops.child);
        }
      }
    };
    if (topologiesList.length > 0) {
      extractAssettypes(topologiesList);
    } else {
      extractAssettypes(topologyMap);
    }
    this.setState({ avilableAssetTypes: selectedBatchAssetTypes });
  }

  redirectToTarget(values) {
    const {
      batchId,
      topologiesList = [],
      topologyMap = {},
    } = this.props.selectedBatch;
    // TODO TOPOLOGY
    const { productId } =
      topologiesList.length > 0 ? topologiesList[0] : topologyMap;
    const { assetType } = values;
    this.setState({ assetType, batchId, isRedirect: true, productId });
  }

  render() {
    const { topologiesList = [], topologyMap = {} } = this.props.selectedBatch;
    const { assetType } =
      topologiesList.length > 0 ? topologiesList[0] : topologyMap;
    const { batchId, isRedirect, productId } = this.state;
    return (
      <div className="container-fluid">
        {isRedirect ? (
          <Redirect
            to={{
              pathname: "/generateCQR/",
              state: {
                assetType: this.state.assetType,
                batchAsset: true,
                batchId,
                productId,
              },
            }}
          />
        ) : (
          ""
        )}
        <div className="row justify-content-center">
          <div className="col-md-8 form-group text-center">
            <div className="my-2 text-left font-weight-bold">
              Unit of Measure
            </div>
            <Form
              onSubmit={(values) => {
                this.redirectToTarget(values);
              }}
            >
              <Select
                initialValue={assetType}
                field="assetType"
                className="custom-select"
              >
                {Object.keys(this.state.avilableAssetTypes).map(
                  (element, index) => {
                    return (
                      <Option value={element} key={index}>
                        {element}
                      </Option>
                    );
                  }
                )}
              </Select>
              <button className="btn btn-primary w-50 mt-3" type="submit">
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
