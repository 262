import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  inputField: {
    backgroundColor: 'white',
    width: "100%",
  },
}));

export default function DatePicker(props: any) {
  const {
    allowKeyboardControl = false,
    disableToolbar = true,
    disabled = false,
    format = "MM/DD/yyyy",
    id = "date-picker-inline-timeCreatedUser",
    inputVariant = 'outlined',
    label = "Create Date",
    onChange,
    size,
    value
  } = props;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        allowKeyboardControl={allowKeyboardControl}
        className={classes.inputField}
        disableToolbar={disableToolbar}
        disabled={disabled}
        format={format}
        id={id}
        inputVariant={inputVariant}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        label={label}
        onChange={(event) => {
          onChange(event)
        }}
        size={size}
        value={value}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
}
