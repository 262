import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import ReactDropZoneForImages from "../../components/Dropzone/ReactDropZoneForImages";
import { uploadTemplateImages } from "./api";

const uploadImageTooltipText = (
  <p>
    Once an image has been uploaded, check the image preview while using the QR
    Size Adjustment, Version Adjustment, and Template Size Adjustment selections
    to make a corresponding QR Label best suit your or your companies needs:{" "}
    <br />
    <br />
    <ul>
      <li>
        For Small Templates LocatorX recommends images in the range of 70px x
        30px.
      </li>
      <li>
        For Medium Templates LocatorX recommends images in the range of 150px x
        50px.
      </li>
      <li>
        For Large Templates LocatorX recommends images in the range of 270px x
        60px.
      </li>
    </ul>
    *Other sized images can also be used with adjustments.
  </p>
);

export default function SelectionTool(props) {
  const {
    disabled = false,
    aboveImages,
    belowImages,
    onChange,
    position = "",
    setState,
    type,
    value = "",
  } = props;
  const selectSelector = () => {
    switch (type) {
      case "item":
        const itemLevelDataOptions = [{ label: "Item Tag", value: "tag" }];
        return (
          <>
            <Typography variant="h6">Item Level Data</Typography>
            <Select
              isDisabled={disabled}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={value}
              menuPortalTarget={document.body}
              variant="outlined"
              onChange={(event) => {
                onChange(event);
              }}
              options={itemLevelDataOptions}
            />
          </>
        );
      case "batch":
        return (
          <>
            <Typography variant="h6">Batch Level Data</Typography>
            <Select
              isDisabled={disabled}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={value}
              variant="outlined"
              onChange={(event) => {
                onChange(event);
              }}
              menuPortalTarget={document.body}
              options={[
                { disabled: true, label: "Please Select", value: "" },
                { label: "Batch ID", value: "tag" },
                { label: "Batch Name", value: "name" },
                { label: "Batch Origin", value: "originString" },
                { label: "Final Destination", value: "destinationString" },
                { label: "Date Created", value: "timeCreated" },
              ]}
            />
          </>
        );
      case "product":
        const productLevelDataOptions = [
          { label: "Product Name", value: "name" },
          { label: "Product Code", value: "tag" },
          { label: "Product Category", value: "productType" },
        ];

        // Commenting out code. Was expecting to be able to render custom data fields
        // But this has been put on hold

        // if (customDataFields.length > 0) {
        //   customDataFields.forEach((element) => {
        //     productLevelDataOptions.push({
        //       value: element.value,
        //       label: element.title,
        //     });
        //   });
        // }

        return (
          <>
            <Typography variant="h6">Product Level Data</Typography>
            <Select
              isDisabled={disabled}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={value}
              menuPortalTarget={document.body}
              variant="outlined"
              onChange={(event) => {
                onChange(event);
              }}
              options={productLevelDataOptions}
            />
          </>
        );
      case "images":
        return (
          <ReactDropZoneForImages
            tooltipText={uploadImageTooltipText}
            readOnly={disabled}
            images={position === "above" ? aboveImages : belowImages}
            limitOne={true}
            position={position}
            multiple={false}
            handleChange={(element) => {
              // This is a seperate function to update the session images
              uploadTemplateImages(
                {
                  ...props,
                  qrBackgroundId:
                    props.qrBackgroundSession.qrBackgroundSessionId,
                },
                element
              );

              setState((prevState) => {
                const body = { ...prevState, images: element };
                if (position === "above") {
                  body.aboveImages = element;
                } else {
                  body.belowImages = element;
                }
                return body;
              });
            }}
          />
        );
      case "userDefinedText":
        return (
          <>
            <Typography variant="h6">User Defined Text</Typography>
            <TextField
              disabled={disabled}
              id="outlined-multiline-static"
              label="Multiline"
              inputProps={{
                maxLength: 150,
              }}
              multiline
              rows={4}
              value={value.value}
              onChange={(event) => {
                onChange({
                  label: "userDefinedText",
                  value: event.target.value || "",
                });
              }}
              variant="outlined"
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return selectSelector();
}
