import React from "react";

import SimpleForm from "../../../../components/Forms/SimpleForm";
import moment from "moment-timezone";

import { Button } from "@material-ui/core";
function UserInfo(props) {
  const timeZones = moment.tz.names();
  const browserTime = moment.tz.guess();
  const { state, dispatchState } = props;
  const { userInfo = {} } = state.userSettings;

  const [view, setView] = React.useState({
    editInfo: false,
  });

  const [editInfo, setEditInfo] = React.useState({
    timeZone: userInfo.timeZone,
    browserTimezone: false,
    email: userInfo.email,
  });

  const [changePassword, setChangePassword] = React.useState({
    show: false,
    oldEmail: userInfo.email,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  React.useEffect(() => {
    if (
      changePassword.newPassword.split("").length &&
      changePassword.newPassword === changePassword.confirmPassword
    ) {
      setChangePassword((prev) => {
        return {
          ...prev,
          passwordMatch: true,
        };
      });
    } else {
      setChangePassword((prev) => {
        return {
          ...prev,
          passwordMatch: false,
        };
      });
    }
  }, [changePassword.newPassword, changePassword.confirmPassword]);

  return (
    <>
      <div className="row">
        <div className="col md-12">
          <h4>User Information </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.editInfo ? (
              <>
                <h5>Email</h5>

                <p>{editInfo.email}</p>
                <hr />
                <h5>Timezone</h5>
                <p>{editInfo.timeZone?.value || browserTime}</p>
                <Button
                  onClick={() => {
                    setView({ editInfo: true });
                  }}
                  color="primary"
                  variant="contained"
                >
                  {" "}
                  Edit
                </Button>
              </>
            ) : (
              <>
                {" "}
                <SimpleForm
                  headerText={"Edit Information"}
                  submitBtnText={"Confirm"}
                  fields={
                    changePassword.show
                      ? [
                          {
                            id: "email",
                            label: "Email",
                            value:
                              editInfo && editInfo.email ? editInfo.email : "",
                            onChange: (e) => {
                              setEditInfo({
                                ...editInfo,
                                email: e.target.value,
                              });
                            },
                          },
                          {
                            id: "timeZone",
                            label: "Timezone",
                            isReactSelect: true,
                            disabled:
                              editInfo && editInfo.browserTimezone
                                ? editInfo.browserTimezone
                                : false,
                            options:
                              timeZones && timeZones.length
                                ? timeZones.map((tz) => {
                                    return {
                                      label: tz,
                                      value: tz,
                                    };
                                  })
                                : [],
                            value: editInfo.browserTimezone
                              ? {
                                  label: browserTime,
                                  value: browserTime,
                                }
                              : editInfo.timeZone
                              ? editInfo.timeZone
                              : "",
                            onChange: (e) => {
                              setEditInfo({
                                ...editInfo,
                                timeZone: {
                                  label: e.value,
                                  value: e.value,
                                },
                              });
                            },
                          },
                          {
                            id: "browserTimezone",
                            label: "Use Browser's Timezone",
                            isCheckbox: true,
                            checked: editInfo.browserTimezone,
                            onChange: () => {
                              setEditInfo({
                                ...editInfo,
                                browserTimezone: !editInfo.browserTimezone,
                                timeZone: {
                                  label: browserTime,
                                  value: browserTime,
                                },
                              });
                            },
                          },
                          {
                            id: "changePassword",
                            label: "Change Password",
                            isCheckbox: true,
                            checked: changePassword.show,
                            onChange: () => {
                              setChangePassword({
                                ...changePassword,
                                show: !changePassword.show,
                              });
                            },
                          },
                          {
                            id: "oldPassword",
                            required: true,
                            type: "password",
                            label: "Old Password",
                            onChange: (e) => {
                              setChangePassword({
                                ...changePassword,
                                oldPassword: e.target.value,
                              });
                            },
                          },
                          {
                            id: "password",
                            required: true,
                            type: "password",
                            label: "New Password",
                            onChange: (e) => {
                              setChangePassword({
                                ...changePassword,
                                newPassword: e.target.value,
                              });
                            },
                          },
                          {
                            id: "confirmPassword",
                            required: true,
                            type: "password",
                            error: !changePassword.passwordMatch,
                            helperText: changePassword.passwordMatch
                              ? "Passwords match!"
                              : "Passwords must match.",
                            label: "Confirm New Password",
                            onChange: (e) => {
                              setChangePassword({
                                ...changePassword,
                                confirmPassword: e.target.value,
                              });
                            },
                          },
                        ]
                      : [
                          {
                            id: "email",
                            label: "Email",
                            value:
                              editInfo && editInfo.email ? editInfo.email : "",
                            onChange: (e) => {
                              setEditInfo({
                                ...editInfo,
                                email: e.target.value,
                              });
                            },
                          },
                          {
                            id: "timeZone",
                            label: "Timezone",
                            isReactSelect: true,
                            disabled:
                              editInfo && editInfo.browserTimezone
                                ? editInfo.browserTimezone
                                : false,
                            options:
                              timeZones && timeZones.length
                                ? timeZones.map((tz) => {
                                    return {
                                      label: tz,
                                      value: tz,
                                    };
                                  })
                                : [],
                            value: editInfo.browserTimezone
                              ? {
                                  label: browserTime,
                                  value: browserTime,
                                }
                              : editInfo.timeZone
                              ? editInfo.timeZone
                              : "",
                            onChange: (e) => {
                              setEditInfo({
                                ...editInfo,
                                timeZone: {
                                  label: e.value,
                                  value: e.value,
                                },
                              });
                            },
                          },
                          {
                            id: "browserTimezone",
                            label: "Use Browser's Timezone",
                            isCheckbox: true,
                            checked: editInfo.browserTimezone,
                            onChange: () => {
                              setEditInfo({
                                ...editInfo,
                                browserTimezone: !editInfo.browserTimezone,
                                timeZone: {
                                  label: browserTime,
                                  value: browserTime,
                                  useDefaultTimeZone: !editInfo.browserTimezone,
                                },
                              });
                            },
                          },
                          {
                            id: "changePassword",
                            label: "Change Password",
                            isCheckbox: true,
                            checked: changePassword.show,
                            onChange: () => {
                              setChangePassword({
                                ...changePassword,
                                show: !changePassword.show,
                              });
                            },
                          },
                        ]
                  }
                  onSubmit={() => {
                    const payload = {
                      timeZone: editInfo.timeZone,
                      email: editInfo.email,
                      changePassword: changePassword.show
                        ? {
                            ...changePassword,
                          }
                        : false,
                    };

                    dispatchState({
                      type: "CHANGE_USER",
                      setting: "userInfo",
                      payload: payload,
                    });
                  }}
                  goBack={() => {
                    setView({
                      editInfo: false,
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserInfo;
