import DefaultOrganizationRolesMap from "./DefaultOrganizationRolesMap";

export function FlattenUserRoles(appUserRoles, userType) {
  // e.g., consoleRoles = org.allRolesMap, appUserRoles = ["Admin", "someMobileRole"], userType = "Asset/Product"
  // filtering the appUser roles because I don't trust depending on the index of the array type

  const consoleRoles = DefaultOrganizationRolesMap.console;
  const usersConsoleRole = appUserRoles.filter((role) => consoleRoles[role])[0];
  const availableActions = { ...consoleRoles[usersConsoleRole]?.actions } || {};

  if (usersConsoleRole === "No Console Access") {
    return;
  }
  // This will append and remove the appropiate actions based on the userType
  switch (userType) {
    case "Product":
      ["View Product Home"].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Devices"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Asset":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Asset/Product":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;

    case "Asset-Operations":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });

      break;
    case "SuperAdmin":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "No Console Access":
      Object.keys(availableActions).forEach(
        (key) => delete availableActions[key]
      );
      break;
    default:
      break;
  }
  let viewPermissions = {};
  // resolving roles in main init function using promises so we can get everything resolved and setState only once when the component mounts
  Object.keys(availableActions).forEach((action) => {
    switch (action) {
      case "View Products":
        viewPermissions.showProducts = true;
        break;
      case "View Batches":
        viewPermissions.showBatches = true;
        break;
      case "View Devices":
        viewPermissions.showDevices = true;
        break;
      case "View Organizations":
        viewPermissions.showOrganizations = true;
        break;
      case "View Users":
        viewPermissions.showUsers = true;
        break;
      case "View Settings":
        viewPermissions.showSettings = true;
        break;
      case "View Product Home":
        viewPermissions.showProductHome = true;
        break;
      case "View Asset History":
        viewPermissions.showAssetHistory = true;
        break;
      case "View Facilities":
        viewPermissions.showFacilities = true;
        break;
      default:
        break;
    }
  });

  return { availableActions, viewPermissions, usersConsoleRole };
}

export const formatClassifications = (res) => {
  let classifications = { active: {}, inactive: {} };

  const {
    classifications: classificationsResponse = {},
    classificationChildren = {},
  } = res;

  classificationsResponse.forEach((classification) => {
    const {
      classificationId: parentId,
      status = "active",
      name = "",
    } = classification;
    // active children
    const children = (classificationChildren[parentId] || [])
      .filter((child) => child.status === "active")
      .reduce((x, childClassification) => {
        return {
          ...x,
          [childClassification.name]: childClassification,
        };
      }, {});
    // inactive children
    const inactiveChildren = (classificationChildren[parentId] || [])
      .filter((child) => child.status === "inactive")
      .reduce((x, childClassification) => {
        return {
          ...x,
          [childClassification.name]: childClassification,
        };
      }, {});

    if (status === "active") {
      classifications.active[name] = {
        ...classification,
        children: children,
        inactiveChildren: inactiveChildren || {},
      };
    } else {
      classifications.inactive[name] = {
        ...classification,
        children: children,
        inactiveChildren: inactiveChildren || {},
      };
    }
  });
  return classifications;
};

// gets our exported thunks into the right shape for the extraReducers in our slices, see thunks and extraReducers
// commenting out the API status for now
export const thunkSpreader = (thunks) =>
  Object.keys(thunks)
    .map((category) => {
      return Object.keys(thunks[category])
        .map((thunkKey) => {
          return {
            [thunks[category][thunkKey].pending]: (state, action) => {
              // state[category].status = "loading";
            },
            [thunks[category][thunkKey].fulfilled]: (state, action) => {
              return {
                ...state,
                ...action.payload,
              };
            },
            [thunks[category][thunkKey].rejected]: (state, action) => {
              // error handling here

              state.error.show = true;
              state.error.text = action.payload.error;
            },
          };
        })
        .reduce((x, y) => {
          return {
            ...x,
            ...y,
          };
        }, {});
    })
    .reduce((x, y) => {
      return {
        ...x,
        ...y,
      };
    }, {});

export const escapeCharacters = (input) => {
  let escapedInput = input;
  const specialCharacters = [
    "+",
    "-",
    "!",
    "(",
    ")",
    "{",
    "}",
    "[",
    "]",
    "^",
    '"',
    "~",
    "*",
    "?",
    ":",
    "/",
    " ",
    "&&",
    "||",
  ];
  const pipesRx = /(\|{2})/g;
  const ampsRx = /(&{2})/g;

  specialCharacters.forEach((char, idx) => {
    // last two characters in special charactere array (len = 18) require a different regex format
    if (idx < 17) {
      const escapedChar = `\\${char}`;
      const re = new RegExp(escapedChar, "g");
      escapedInput = escapedInput.replace(re, `\\${char}`);
    } else if (char === "||") {
      escapedInput = escapedInput.replace(pipesRx, `\\||`);
    } else if (char === "&&") {
      escapedInput = escapedInput.replace(ampsRx, `\\&&`);
    }
  });

  return escapedInput;
};
