const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const checkSession = async (token) => {
  let csmToken = "";

  // if prod, use secure CSM token method
  if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
    const origin = (process.env.REACT_APP_HOST || window.location.href)
      .split("/")
      .slice(0, 3)
      .join("/");

    const apiLabUrl = process.env.REACT_APP_API_LAB_ENDPOINT;

    csmToken = await getCsmToken(apiLabUrl, origin);
  } else {
    // else in dev / staging / testing, use csm token from environment variable
    csmToken = process.env.REACT_APP_CSM_TOKEN;
  }
  const results = await fetch(`${apiUrl}auth`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      csm_token: csmToken || "",
      userSessionId: token,
    }),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });
  return results;
};

const getCsmToken = async (apiUrl, url) => {
  // REACT_APP_CSM_TOKEN is now the handshake token needed to get the CSM token
  const body = {
    csmToken: process.env.REACT_APP_CSM_HANDSHAKE_TOKEN,
    url: url,
  };
  const results = await fetch(`${apiUrl}data/csm/getCredential`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.csmToken;
    })

    .catch((error) => {
      console.log(error);
      return {
        error,
      };
    });
  return results;
};

export const user = {
  checkSession,
};
