import { escapeCharacters } from "../utils/helpers";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function searchDevices(props, input, start = 0, limit = 300) {
  const { token, organizationId } = props;

  const {
    tags = [],
    external_id = "",
    status = [],
    last_event = [],
    // battery = {},
    asset_type = [],
  } = input;

  const eventArray =
    last_event && last_event.length > 0
      ? last_event
          .map((el) => {
            return `last_event:${escapeCharacters(el)}`;
          })
          .join(" OR ")
      : null;

  const statusString = status?.length
    ? "(" +
      status
        .map((item) => {
          if (item === "unassigned") {
            return `NOT {!tuple}device.status:assigned`;
          }
          return `{!tuple}device.status:${item.value}` || "";
        })
        .join(" OR ") +
      ")"
    : "";

  // let batteryFilter = null;

  // if (battery?.level && battery.equality) {
  //   let battLevel = parseInt(battery.level as string);
  //   switch (battery.equality) {
  //     case "=":
  //       batteryFilter = `${battLevel}`;
  //       break;
  //     case ">":
  //       batteryFilter = `[${battLevel + 1} TO *]`;
  //       break;
  //     case "≥":
  //       batteryFilter = `[${battLevel} TO *]`;
  //       break;
  //     case "<":
  //       batteryFilter = `[* TO ${battLevel - 1}]`;
  //       break;
  //     case "≤":
  //       batteryFilter = `[* TO ${battLevel}]`;
  //       break;
  //     default:
  //       batteryFilter = `${battLevel}`;
  //   }
  // }

  let tagString = "";
  if (tags && tags.length) {
    let tagSet = tags.map((e) => {
      return `tag:${escapeCharacters(e.value)}`;
    });
    tagString = tagSet.join(" OR ");
  }

  const deviceTypeArray =
    asset_type && asset_type.length > 0
      ? asset_type
          .map((el) => {
            return `asset_type:${escapeCharacters(el)}`;
          })
          .join(" OR ")
      : null;

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        `${tagString ? `${tagString}` : ``}`,
        `{!tuple}device.platform:*`,
        // tag ? `tag:${escapeCharacters(tag)}` : ``,
        external_id
          ? `{!tuple}device.external_id:${escapeCharacters(external_id)}`
          : ``,
        statusString,
        eventArray ? eventArray : ``,
        // batteryFilter ? `{!tuple}properties_map.battery:${batteryFilter}` : ``,
        deviceTypeArray ? deviceTypeArray : ``,
      ],
      sort: `time_of_log asc, tag desc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function searchDeviceAssets(
  props,
  joined_device_asset_ids = "",
  start = 0,
  limit = 300
) {
  const { token, organizationId } = props;
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: joined_device_asset_ids,
      sort: `time_of_log asc, tag desc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function buildDeviceAssetsMap(props, deviceList) {
  const deviceAssetIds =
    deviceList.length > 0
      ? deviceList
          .map((d) => {
            if (d.assetId) {
              return `parent_id:${d.assetId}`;
            }
            return null;
          })
          .filter((el) => el !== null)
          .join(" OR ")
      : null;

  if (!deviceAssetIds) {
    return {};
  }
  const deviceAssetsMap = await searchDeviceAssets(props, deviceAssetIds).then(
    (res) => {
      if (res.error) {
        return { error: "Error fetching device assets." };
      }

      let deviceAssetsMap = {};
      res.assets.forEach((ass) => {
        if (!deviceAssetsMap[ass.parentId]) {
          deviceAssetsMap[ass.parentId] = [];
        }

        deviceAssetsMap[ass.parentId].push(ass);
      }, {});

      return {
        ...deviceAssetsMap,
      };
    }
  );
  return deviceAssetsMap;
}

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      deviceTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const devices = {
  searchDevices,
  buildDeviceAssetsMap,
  searchDeviceAssets,
  saveFilterSettings,
};
