const rolesBasedAccess = (props) => {
  const { usersConsoleRole = "", appUserType = "Asset/Product" } = props;

  const quickLinkActions =
    appUserType === "Asset"
      ? [
          "Add User",
          "Create Facility",
          "Create Organization",
          "Import Assets",
          "Import Facilities",
          "Import Users",
        ]
      : appUserType === "Product"
      ? [
          "Add User",
          "Create Facility",
          "Create Product",
          "Create Organization",
          "Import Batches",
          "Import Facilities",
          "Import Users",
        ]
      : appUserType === "Asset/Product"
      ? [
          "Add User",
          "Create Facility",
          "Create Product",
          "Create Organization",
          "Import Assets",
          "Import Batches",
          "Import Facilities",
          "Import Users",
        ]
      : appUserType === "Asset-Operations"
      ? [
          "Add User",
          "Create Facility",
          "Create Organization",
          "Import Assets",
          "Import Facilities",
          "Import Users",
        ]
      : [];

  return {
    userInfo: {
      view: true,
    },
    consoleSettings: {
      view: usersConsoleRole !== "Lite",
      quickLinkActions:
        usersConsoleRole === "Standard"
          ? quickLinkActions.filter(
              (action) => action !== "Add User" && action !== "Import Users"
            )
          : quickLinkActions,
    },
  };
};

export { rolesBasedAccess };
