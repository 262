import React from "react";
import request from "superagent";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";

import { showAlert } from "../../../globalStore/slices/alert/alertSlice";
import { thunks } from "../../../globalStore/slices/organization/organizationSlice";

import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";

import FacilityTable from "./FacilityTable/FacilityTable";
import FacilityArea from "./FacilityArea/FacilityArea";
import FacilityDetail from "./FacilityDetail/FacilityDetail";
import CreateFacility from "./CreateFacility/CreateFacility";
import EditFacility from "./EditFacility/EditFacility";
import ImportLocations from "./ImportLocations/ImportLocations";

import { Tooltip } from "@material-ui/core";

export default function Facilities(props: any) {
  const { apiUrl, token, permissions = {}, setView = {} } = props;

  const { facilities, organization } = useSelector(
    (state: any) => state.organization,
    isEqual
  );

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const [state, setState] = React.useState<any>({
    facilities: [],
  });

  const [fullScreenModal, setFullScreenModal] = React.useState(false);

  const [dialog, setDialog] = React.useState<any>({
    dialogTitle: "",
    dialogShow: false,
    selectedLocation: {},
  });

  const { retrieveFacilities: retrieveFacilitiesThunk } = thunks.facilities;

  const retrieveFacilities = React.useCallback(() => {
    dispatchGlobal(retrieveFacilitiesThunk());
    location.state = {};
  }, [dispatchGlobal, location, retrieveFacilitiesThunk]);

  // wrapping init up in useCallback to prevent useEffect loop
  const init = React.useCallback(
    (isMounted: boolean) => {
      if (!isMounted) {
        retrieveFacilities();
      }
    },
    [retrieveFacilities]
  );

  const mounted = React.useRef(false);
  // first render useEffect, retrieve facilities; retrieve facilities function is memoized via useCallback so it can be used in the useEffect dep array without creating an infinite loop
  React.useEffect(() => {
    const { state: routerState = {} } = props.location;
    const { modalShow = false, modal = "" } = routerState;

    setState((s: any) => {
      return {
        ...s,
        facilities: Object.keys(facilities).map((k) => facilities[k]),
      };
    });

    if (modalShow) {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    }

    init(mounted.current);
    mounted.current = true;
    // This controls the quickLinks and modal behavior
    return props.history.listen((location: any) => {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    });
  }, [props, facilities, init]);

  const handleDelete = (id: string) => {
    request
      .delete(`${apiUrl}facilities/${id}`)
      .set("auth-token", token)
      .then((res: any) => {
        if (res.status === 200) {
          retrieveFacilities();
          handleConfirmationModal("Facility Successfully Deleted");
          dialogClose();
        }
      })
      .catch((err: any) => {
        handleConfirmationModal("There was a problem", true);
      });
  };

  const handleConfirmationModal = (text: string, error?: boolean) => {
    dispatchGlobal(
      showAlert({ type: error ? "error" : "success", text: text })
    );
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedLocation: {},
    });

  const { createLocation } = permissions;

  const { dialogShow, selectedLocation, dialogTitle } = dialog;
  const { propertiesMap = {} } = organization;
  const { locationTypes = [] } = propertiesMap;

  const switchDialog = (dialog: any) => {
    switch (dialog) {
      case "Facility Detail":
        return (
          <FacilityDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            facility={selectedLocation}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Facility Area":
        return (
          <FacilityArea
            token={token}
            apiUrl={apiUrl}
            facilityId={selectedLocation.facilityId}
            selectedLocation={selectedLocation}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={retrieveFacilities}
            setFullScreenModal={(bool: boolean) => setFullScreenModal(bool)}
          />
        );
      case "Create Facility":
        return (
          <CreateFacility
            onHide={dialogClose}
            onSuccess={retrieveFacilities}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Import Facilities":
        return (
          <ImportLocations
            onHide={dialogClose}
            onSuccess={retrieveFacilities}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            organization={organization}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Edit Facility":
        return (
          <EditFacility
            onHide={dialogClose}
            onSuccess={retrieveFacilities}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            facility={selectedLocation}
            notificationModal={handleConfirmationModal}
            match={{
              params: { facilityId: selectedLocation.facilityId },
            }}
          />
        );
      case "Delete Facility":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedLocation.name}? This action cannot be
            undone.`}
            handleSubmit={() => {
              handleDelete(selectedLocation.facilityId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="container-fluid mt-3">
      <ModalDialog
        handleClose={dialogClose}
        open={dialogShow}
        title={`${dialogTitle}${
          selectedLocation?.name ? ` - ${selectedLocation?.name}` : ""
        }`}
        content={switchDialog(dialogTitle)}
        fullScreen={fullScreenModal}
      />
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>Facilities</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          {createLocation ? (
            <>
              <Tooltip title="Create a new facility based on an address or intersection of latitude and longitude coordinates. When creating a new facility, it will default to a 400m radius around the center point of the address or lat/long coordinates.  You will have the option of adjusting that radius or creating a polygon, showing the perimeter of your facility.  Your facility will be created using the default radius unless you adjust it. If you enter a polygon, that will override all radius usage entirely.">
                <button
                  onClick={() =>
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      dialogTitle: "Create Facility",
                    })
                  }
                  type="button"
                  className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
                >
                  Create Facility
                </button>
              </Tooltip>
              <button
                onClick={() =>
                  setDialog({
                    ...dialog,
                    dialogTitle: "Import Facilities",
                    dialogShow: true,
                  })
                }
                type="button"
                className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
              >
                Import Facilities
              </button>
              <button
                onClick={() =>
                  setView({
                    viewFacilitiesMap: true,
                  })
                }
                type="button"
                className="btn btn-sm btn-primary ml-3 mb-1 font-weight-bold"
              >
                Facilities Map
              </button>
            </>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FacilityTable
            state={state}
            diaog={dialog}
            setDialog={setDialog}
            permissions={permissions}
            setView={setView}
          />
        </div>
      </div>
    </div>
  );
}
