import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { cloneDeep, isEqual } from "lodash";

import BargraphByEvent from "./BargraphByEvent";
import BarGraphsByEventAndDateSettings from "./BarGraphsByEventAndDateSettings";
import { RetrieveAssetHistorysByEvent } from "../api";
import "moment-timezone";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import "./BarGraphByEventsStyling.css";

function BarGraphsByEventAndDate(props) {
  const { handleWidgetSettings, organization, timeZone, apiUrl, token } = props;

  const barGraphsByEventAndDate = cloneDeep(
    useSelector(
      (state) =>
        state.user.userPropertiesMap.propertiesMap?.widgetSettings
          ?.barGraphsByEventAndDate || {},
      isEqual
    )
  );

  const { eventTypesMap = {} } = organization;

  const [page, setPage] = useState("graph");
  const [assetHistories, setassetHistories] = useState([]);

  const defaultAction = Object.keys(eventTypesMap)[0];

  const [selectedSortEvent, setSelectedSortEvent] = useState(
    barGraphsByEventAndDate.event !== undefined
      ? barGraphsByEventAndDate.event
      : defaultAction
  );
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    barGraphsByEventAndDate.timePeriod
      ? barGraphsByEventAndDate.timePeriod
      : "12"
  );
  const mounted = useRef(false);

  // Retrieves assetHistory
  useEffect(() => {
    const date = moment().format("YYYY-MM-DD");

    let sort = selectedSortEvent;
    let period = selectedTimePeriod;

    // Retrieves Asset Histories
    RetrieveAssetHistorysByEvent(
      {
        apiUrl,
        token,
        currentDate: date,
        event: sort,
        rangeOfMonths: period,
      },
      (events) => {
        setassetHistories(events);
      }
    );
    // useEffect clean up function
    return () => {
      mounted.current = true;
    };
  }, [apiUrl, token, selectedSortEvent, selectedTimePeriod]);

  return (
    <>
      <i
        className={`fa ${
          page === "graph" ? "fa-cog" : "fa-times"
        } fa-lg widget-cog`}
        onClick={() => {
          setPage(page === "settings" ? "graph" : "settings");
          if (page === "settings") {
            setPage("graph");
            handleWidgetSettings("barGraphsByEventAndDate", {
              event: selectedSortEvent,
              timePeriod: selectedTimePeriod,
            });
          } else {
            setPage("settings");
          }
        }}
      />
      {page === "graph" ? (
        <h5 className="widget-header widgetTitle">{selectedSortEvent}</h5>
      ) : (
        <h3>Customize Bar Graph</h3>
      )}
      {page === "graph" ? (
        <BargraphByEvent
          timeZone={timeZone}
          data={assetHistories}
          sort={selectedTimePeriod}
        />
      ) : (
        <BarGraphsByEventAndDateSettings
          actions={eventTypesMap}
          event={selectedSortEvent}
          setSelectedSortEvent={setSelectedSortEvent}
          setSelectedTimePeriod={setSelectedTimePeriod}
          timePeriod={selectedTimePeriod}
        />
      )}
    </>
  );
}

export default BarGraphsByEventAndDate;
