import { useState, useEffect } from "react";
import { IconButton, Button, Tooltip, Typography } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import ModalDialog from "../../../../components/Modals/ModalDialog/ModalDialog";
import IconPicker from "../../../../components/IconPicker/IconPicker";
import DynamicIconRender from "../../../../components/IconPicker/DynamicIconRender";

import SimpleList from "../../../../components/Lists/SimpleList.js";
import SimpleForm from "../../../../components/Forms/SimpleForm";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";

const specialCharacters = ["$", "&", "+", ",", "/", "@", ";", "?", ":", "="];

function Events(props) {
  const { dispatchState, handleConfirmationModal, state } = props;

  const [view, setView] = useState({
    isAddEvent: false,
  });

  const [forms, setForms] = useState();
  useEffect(() => {
    const getForms = async () => {
      const forms = await fetch(`${props.apiUrl}forms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": props.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          return res.forms ? res.forms : [];
        })
        .catch((err) => {
          console.log(err);
        });
      return forms;
    };
    getForms().then((res) => {
      setForms(res);
    });
  }, [props]);

  const [addEvent, setAddEvent] = useState({});
  const [color, setColor] = useState("");
  const [customIcon, setCustomIcon] = useState({
    displaySelectIconModal: false,
    icon: "",
  });

  return (
    <>
      <div className="row">
        <div className="col md-6">
          <h4>
            <Tooltip title="User-identified actions or events that you will allow authorized users to effect on your products or assets.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Event/Status{" "}
            <IconButton
              style={{ padding: "0px" }}
              onClick={() =>
                setView({
                  ...view,
                  isAddEvent: !view.isAddEvent,
                })
              }
            >
              {!view.isAddEvent ? (
                <AddIcon style={{ color: "#3D5BA9" }} />
              ) : (
                <CloseIcon style={{ color: "#b71d1a" }} />
              )}
            </IconButton>
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!view.isAddEvent ? (
              <SimpleList
                isColor
                items={Object.keys(state.globalSettings.eventTypesMap)
                  .map((e) => {
                    if (state.globalSettings.eventTypesMap[e]) {
                      return {
                        id: e,
                        label: e,
                        color: state.globalSettings.eventTypesMap[e].color,
                        icon: state.globalSettings.eventTypesMap[e].icon,
                      };
                    } else {
                      return null;
                    }
                  })
                  .filter((el) => el !== null)}
                onClick={(id) => {
                  setColor(
                    state.globalSettings.eventTypesMap[id].color
                      ? state.globalSettings.eventTypesMap[id].color
                      : ""
                  );

                  setCustomIcon({
                    ...customIcon,
                    icon: state.globalSettings.eventTypesMap[id].icon
                      ? state.globalSettings.eventTypesMap[id].icon
                      : "",
                  });

                  dispatchState({
                    type: "SET_SELECTED",
                    label: "eventType",
                    payload: {
                      ...state.globalSettings.eventTypesMap[id],
                      name: state.globalSettings.eventTypesMap[id].name
                        ? state.globalSettings.eventTypesMap[id].name
                        : id,
                      color: state.globalSettings.eventTypesMap[id].color
                        ? state.globalSettings.eventTypesMap[id].color
                        : "",
                      icon: state.globalSettings.eventTypesMap[id].icon
                        ? state.globalSettings.eventTypesMap[id].icon
                        : "",
                      formId: state.globalSettings.eventTypesMap[id].formId
                        ? state.globalSettings.eventTypesMap[id].formId
                        : null,
                    },
                  });
                }}
                onClickRemove={(id) => {
                  if (id === "Tag Destroyed") {
                    handleConfirmationModal(
                      "Tag Destroyed is a mandatory function in LXConnect. It cannot be removed from the system.",
                      true,
                      5000
                    );
                  } else {
                    setView({
                      ...view,
                      isAddEvent: false,
                    });
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "eventTypesMap",
                      payload: {
                        ...state.globalSettings.eventTypesMap,
                        [id]: null,
                      },
                      removeSelected: "eventType",
                    });

                    const mobileRoles = state.globalSettings.mobileRoles;
                    const updateExistingRoles = Object.keys(mobileRoles)
                      .map((role) => {
                        if (
                          mobileRoles[role] &&
                          mobileRoles[role].actions &&
                          mobileRoles[role].actions[id]
                        ) {
                          return {
                            ...mobileRoles[role],
                            id: mobileRoles[role].id
                              ? mobileRoles[role].id
                              : role,
                            label: mobileRoles[role].label
                              ? mobileRoles[role].label
                              : role,
                            actions: {
                              ...mobileRoles[role].actions,
                              [id]: null,
                            },
                          };
                        } else if (mobileRoles[role]) {
                          return {
                            ...mobileRoles[role],
                            id: mobileRoles[role].id
                              ? mobileRoles[role].id
                              : role,
                            label: mobileRoles[role].label
                              ? mobileRoles[role].label
                              : role,
                          };
                        } else {
                          return null;
                        }
                      })
                      .filter((el) => el !== null)
                      .reduce((x, y) => {
                        return {
                          ...x,
                          [y.id]: y,
                        };
                      }, {});

                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "mobileRoles",
                      payload: {
                        ...updateExistingRoles,
                      },
                    });
                  }
                }}
              />
            ) : (
              <SimpleForm
                headerText={"Add new Event/Status"}
                submitBtnText={"Add"}
                fields={[
                  {
                    id: "label",
                    label: "Event/Status",
                    onChange: (e) => {
                      setAddEvent({
                        ...addEvent,
                        [e.target.id]: e.target.value,
                      });
                    },
                  },
                  {
                    id: "form",
                    label: "Form",
                    isSelect: true,
                    options:
                      forms && forms.length
                        ? [
                            { label: "None", value: "None" },
                            ...forms.map((f) => {
                              return {
                                label: f.name,
                                value: f.formId,
                              };
                            }),
                          ]
                        : [{ label: "None", value: "None" }],
                    onChange: (e) => {
                      setAddEvent({
                        ...addEvent,
                        formId: e.target.value,
                      });
                    },
                    value: addEvent.formId ? addEvent.formId : "",
                  },
                ]}
                onSubmit={() => {
                  if (
                    addEvent.label
                      .split("")
                      .some((el) => specialCharacters.indexOf(el) >= 0)
                  ) {
                    return alert(
                      `Event types cannot contain the following characters: ${specialCharacters.join(
                        " "
                      )}`
                    );
                  }
                  dispatchState({
                    type: "CHANGE_GLOBAL",
                    setting: "eventTypesMap",
                    payload: {
                      ...state.globalSettings.eventTypesMap,
                      [addEvent.label.trim()]: {
                        name: addEvent.label.trim(),
                        color: addEvent.color ? addEvent.color : "",
                        icon: addEvent.icon ? addEvent.icon : "",
                        formId:
                          addEvent.formId === "None"
                            ? ""
                            : addEvent.formId
                            ? addEvent.formId
                            : "",
                      },
                    },
                  });
                }}
                goBack={() => {
                  setView({
                    ...view,
                    isAddEvent: false,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col md-6">
          <h4>
            <Tooltip title="Provides the ability to color-code events and attach data collection forms to those events.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
            Event/Status Detail
          </h4>
          <hr />
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            {!state.selected || !state.selected.eventType ? (
              <p>Select an event/status to view/edit its detail</p>
            ) : (
              <>
                <p>
                  <strong>{state.selected.eventType.name}</strong>
                  &nbsp;&nbsp;&nbsp;
                  <i
                    className="fas fa-tint fa-lg"
                    style={{
                      color: color,
                    }}
                  />
                </p>
                <ColorPicker
                  color={color}
                  setColor={(color) => {
                    setColor(color);
                    dispatchState({
                      type: "SET_SELECTED",
                      label: "eventType",
                      payload: {
                        ...state.selected.eventType,
                        color: color,
                      },
                    });
                    dispatchState({
                      type: "CHANGE_GLOBAL",
                      setting: "eventTypesMap",
                      payload: {
                        ...state.globalSettings.eventTypesMap,
                        [state.selected.eventType.name]: {
                          ...state.selected.eventType,
                          color: color,
                        },
                      },
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setCustomIcon({
                        ...customIcon,
                        displaySelectIconModal: true,
                      });
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Select Custom Icon
                  </Button>{" "}
                  <Typography>
                    Icon:{" "}
                    {customIcon?.icon ? (
                      <>
                        <DynamicIconRender
                          iconName={customIcon.icon}
                          style={color ? { fill: color } : {}}
                        />

                        {customIcon.icon}
                      </>
                    ) : (
                      "None selected."
                    )}
                  </Typography>
                </div>
                <ModalDialog
                  open={customIcon.displaySelectIconModal}
                  handleClose={() =>
                    setCustomIcon({
                      ...customIcon,
                      displaySelectIconModal: false,
                    })
                  }
                  title="Custom Icon"
                  content={
                    <IconPicker
                      icon={customIcon.icon}
                      setIcon={(icon) => {
                        setCustomIcon({
                          displaySelectIconModal: false,
                          icon: icon,
                        });

                        dispatchState({
                          type: "SET_SELECTED",
                          label: "eventType",
                          payload: {
                            ...state.selected.eventType,
                            icon: icon,
                          },
                        });

                        dispatchState({
                          type: "CHANGE_GLOBAL",
                          setting: "eventTypesMap",
                          payload: {
                            ...state.globalSettings.eventTypesMap,
                            [state.selected.eventType.name]: {
                              ...state.selected.eventType,
                              icon: icon,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
                <hr />
                <SimpleForm
                  fields={[
                    {
                      Sid: "form",
                      isSelect: true,
                      label: "Form",
                      options:
                        forms && forms.length
                          ? [
                              { label: "None", value: "None" },
                              ...forms.map((f) => {
                                return {
                                  label: f.name,
                                  value: f.formId,
                                };
                              }),
                            ]
                          : [{ label: "None", value: "None" }],
                      onChange: (e) => {
                        dispatchState({
                          type: "SET_SELECTED",
                          label: "eventType",
                          payload: {
                            ...state.selected.eventType,
                            formId: e.target.value,
                          },
                        });
                        dispatchState({
                          type: "CHANGE_GLOBAL",
                          setting: "eventTypesMap",
                          payload: {
                            ...state.globalSettings.eventTypesMap,
                            [state.selected.eventType.name]: {
                              ...state.selected.eventType,
                              formId:
                                e.target.value === "None"
                                  ? null
                                  : e.target.value,
                            },
                          },
                        });
                        // logic for updating existing roles in globalSettings after changing event forms
                        const mobileRoles = state.globalSettings.mobileRoles;
                        const updateExistingRoles = Object.keys(mobileRoles)
                          .map((role) => {
                            if (
                              mobileRoles[role].actions !== undefined &&
                              mobileRoles[role].actions[
                                state.selected.eventType.name
                              ]
                            ) {
                              return {
                                ...mobileRoles[role],
                                id: mobileRoles[role].id
                                  ? mobileRoles[role].id
                                  : role,
                                label: mobileRoles[role].label
                                  ? mobileRoles[role].label
                                  : role,
                                actions: {
                                  ...mobileRoles[role].actions,
                                  [state.selected.eventType.name]: {
                                    ...state.selected.eventType,
                                    formId:
                                      e.target.value === "None"
                                        ? null
                                        : e.target.value,
                                  },
                                },
                              };
                            }
                            return {
                              ...mobileRoles[role],
                              id: mobileRoles[role].id
                                ? mobileRoles[role].id
                                : role,
                              label: mobileRoles[role].label
                                ? mobileRoles[role].label
                                : role,
                            };
                          })
                          .reduce((x, y) => {
                            return {
                              ...x,
                              [y.id]: y,
                            };
                          }, {});
                        dispatchState({
                          type: "CHANGE_GLOBAL",
                          setting: "mobileRoles",
                          payload: {
                            ...updateExistingRoles,
                          },
                        });
                      },
                      selected: state.selected.eventType.formId
                        ? state.selected.eventType.formId
                        : "None",

                      value: state.selected.eventType.formId
                        ? state.selected.eventType.formId
                        : "None",
                    },
                  ]}
                  headerText={""}
                  leftAlign
                  noBackButton
                  noSubmitButton
                  submitBtnText={"Set Form"}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
