import { unassignDevices } from "../utils/utils";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";

export default function UnassignDevicesDialog(props: any) {
  const { dialog, setDialog, state, handleConfirmationModal, history } = props;

  return (
    <ModalDialog
      handleClose={() => {
        setDialog({ ...dialog, dialogShow: false });
      }}
      open={dialog.dialogShow}
      title={"Unassign Devices"}
      content={
        <ConfirmationModalContent
          content={`Are you sure you want to unassign ${
            Object.keys(state.selectedDevices).length
          } device(s)? This action cannot be
          undone.`}
          handleSubmit={async () => {
            setDialog({ ...dialog, dialogShow: false });
            await unassignDevices(
              props,
              state.selectedDevices,
              state.deviceAssetsMap
            ).then((res: any) => {
              if (res.error) {
                handleConfirmationModal(
                  `There was a problem disassociating your devices: ${res.error}`,
                  true,
                  "fas fa-times-circle mr-2",
                  5000
                );
              } else {
                handleConfirmationModal(
                  `${
                    Object.keys(state.selectedDevices).length
                  } device(s) unassigned.`,
                  false,
                  "fas fa-check mr-2",
                  1000
                );
                setTimeout(() => {
                  history.go(0);
                }, 1250);
              }
            });
          }}
          handleCancel={() => {
            setDialog((d: any) => {
              return {
                ...d,
                dialogShow: false,
              };
            });
          }}
        />
      }
    />
  );
}
