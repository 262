import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";

import { updateUserPropertiesMap } from "../../globalStore/slices/user/userSlice";
import {
  getAssetProps,
  getTelemetry,
  saveFilterSettings,
  searchHistories,
} from "./api";
import { State } from "./interface";

import Table from "./Table";

import * as Tab from "./Tabs/index";
import Map from "../../components/Maps/AssetHistoryMap/AssetHistoryMap";

import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import Loading from "../../components/Loading/Loading";
import CenteredModal from "../../components/Modals/CenteredModal";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    width: "100%",
    minHeight: "66vh",
    maxHeight: "100%",
  },
  pipe: {
    fontWeight: 100,
    borderRight: "1px #A6A8AB solid",
    margin: 10,
  },
  button: {
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

interface RenderTree {
  id: string;
  label: string | JSX.Element;
  children?: RenderTree[];
  complete: boolean;
}

function AssetSnapshot(props: any) {
  const { apiUrl, organizationId, token, usersConsoleRole, userId, timeZone } =
    props;
  const { assetId = "" } = props.match.params;

  const dispatchGlobal = useDispatch();
  // OK to destructure organization data because it's not going to change in the snapshot page
  const {
    facilities,
    devices,
    classifications,
    organization,
    zones,
    usersMap,
  } = useSelector((state: any) => state.organization, isEqual);

  const assetSnapshotSettings = useSelector(
    (state: any) =>
      state.user.userPropertiesMap.propertiesMap?.assetSnapshotSettings
  );

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [treeView, setTreeView] = useState<RenderTree | undefined>();

  const [infoModal, setInfoModal] = useState<any>({
    modal: { modalShow: false, title: "", content: "" },
  });

  const modalClose = () => {
    setModal({
      ...modal,
      modalShow: false,
    });
    setInfoModal({
      ...infoModal,
      modalShow: false,
    });
  };

  const [modal, setModal] = useState({
    modalShow: false,
    text: "",
    isError: false,
  });

  // Array.from() creates a new reference from the read-only redux state objects
  const assetTypes =
    organization && organization.assetTypes
      ? Array.from(organization.assetTypes).sort()
      : [];

  // If the user is NOT an admin, we remove the Tag Destroyed event
  const eventTypes = organization?.eventTypesMap
    ? Object.keys(organization.eventTypesMap)
        .sort()
        .filter((item) => {
          if (usersConsoleRole !== "Admin") {
            return item !== "Tag Destroyed";
          }
          return item;
        })
    : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return -1;
      })
    : [];

  // The "limit", "start", and "sort" filters are controlled from the "rows", "page" index, and column sorting headers on the table itself, not the filter drop down
  const initFilters = {
    assetId: "",
    assetIds: null,
    binLocation: null,
    defaultColumnOrder: [],
    endDate: null,
    events: null,
    limit: 25,
    locals: null,
    locations: null,
    startDate: null,
    tz: timeZone,
    users: null,
    sorted: [
      {
        id: "timeOfLog",
        desc: true,
      },
    ],
    zones: null,
    // spreading the saved filter settings under the default init filters will override any of the defaults, if they are present in the settings
    ...(assetSnapshotSettings || {}),
  };

  const deviceLogInitFilters = {
    devices: null,
    limit: 25,
  };

  const [state, setState] = useState<State>({
    filters: {
      ...initFilters,
      start: 0,
      tz: timeZone,
    },
    deviceLogFilters: {
      ...deviceLogInitFilters,
      start: 0,
      tz: timeZone,
    },
    devicePage: 0,
    page: 0,
    lists: {
      assetCategories: assetTypes,
      eventTypes: eventTypes,
      facilityArray: facilityArray,
      eventTypesMap: organization.eventTypesMap,
      usersMap: usersMap,
    },
    assetData: {},
    activeTab: 0,
    availableZones: zones,
    mounted: false,
    deviceData: {},
    showDeviceTab: false,
  });

  // first effect, get asset props and set mounted to true
  useEffect(() => {
    getAssetProps({ apiUrl, token, organizationId }, assetId).then((res) => {
      if (res.error) {
        setModal({
          modalShow: true,
          text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
          isError: true,
        });
      } else {
        const parentId = res.assets[0] ? res.assets[0].parentId : "";
        // TODO: Insert the device call here and return the device data with the setState
        // initialize state, changing the state.filters will fire useEffect to retrieve data

        const device: any =
          Object.values(devices).find(
            (element: any) => element.assetId === parentId
          ) || {};
        const showDeviceTab: boolean = device ? true : false;
        setState((s: any) => {
          return {
            ...s,
            assetData: {
              ...res.assets[0],
            },
            mounted: true,
            deviceData: device.propertiesMap,
            showDeviceTab,
          };
        });
      }
    });

    return () => {
      // Resets the users Filters
      setState((prevState: any) => ({
        ...prevState,
        filters: {
          assetIds: null,
          endDate: null,
          events: null,
          locals: null,
          locations: null,
          startDate: null,
          users: null,
        },
      }));
    };
  }, [apiUrl, organizationId, userId, token, assetId, devices]);

  // refs work to control our runaway use effects... if the history or device filters have changed, it will fire an effect that searches for data.
  // In the effects, we check to make sure that the previous filters are different than the incoming filters by using refs.
  // If they are different, we then reset the ref.current to the incoming filters. That way, we can control runaway use effects.
  const prevFiltersRef: any = useRef();
  const prevDeviceFiltersRef = useRef();

  const locationRef = useRef(props.history.location.pathname);
  const pathName = props.history.location.pathname;
  const history = useHistory();

  useEffect(() => {
    if (
      !isEqual(state?.filters, prevFiltersRef.current) ||
      !isEqual(pathName, locationRef.current)
    ) {
      prevFiltersRef.current = state?.filters;
      setLoading(true);
      searchHistories(
        { apiUrl, token, organizationId },
        assetId,
        state?.filters
      ).then((res) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
            isError: true,
          });
          setLoading(false);
        } else {
          setState((prevState: any) => {
            locationRef.current = pathName;

            return {
              ...prevState,
              page: Math.floor(
                prevState.filters.start / prevState.filters.limit
              ),
              histories: res,
            };
          });
          setLoading(false);
        }
      });
    }
  }, [
    pathName,
    assetId,
    apiUrl,
    token,
    organizationId,
    userId,
    state?.filters,
  ]);

  // Seperated this useEffect, that way this only fires off when the device log table filters change.
  useEffect(() => {
    const parent = devices[state?.assetData.parentId] || {};
    const { device = {} } = parent;
    const { hasTelemetry = false } = device;

    if (
      hasTelemetry &&
      (!isEqual(state?.deviceLogFilters, prevDeviceFiltersRef.current) ||
        !isEqual(pathName, locationRef.current))
    ) {
      prevDeviceFiltersRef.current = state.deviceLogFilters;
      getTelemetry(
        { apiUrl },
        state?.assetData?.parentId,
        state?.deviceLogFilters
      ).then((res: any) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
            isError: true,
          });
        } else {
          setState((prevState: any) => {
            locationRef.current = pathName;
            return {
              ...prevState,
              page: Math.floor(
                prevState.deviceLogFilters.start /
                  prevState.deviceLogFilters.limit
              ),
              deviceTelemetry: res,
            };
          });
        }
      });
    }
  }, [
    devices,
    pathName,
    apiUrl,
    state?.assetData.parentId,
    state?.deviceLogFilters,
  ]);

  // Handles the Hierarchy Tab and Hierarchy Status Table
  useEffect(() => {
    const viewSnapshot = (assetId: string) => {
      history.push(`/assetSnapshot/${assetId}`);
      history.go(0);
    };

    const retrieveAssetChildren = async (assetId: string) => {
      const payload = {
        solrQuery: {
          q: `organization_id:${organizationId}`,
          fq: [`parent_id:${assetId}`],
          sort: `time_of_log desc`,
          start: 0,
        },
        limit: 1000,
      };

      const results = await fetch(`${apiUrl}assets/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          return (
            res.assets?.map((asset: any) => {
              const zone =
                asset.zone && asset.zone.zoneId && zones[asset.zone.zoneId]
                  ? zones[asset.zone.zoneId].name
                  : null;
              const binLocation =
                asset.zone && asset.zone.binLocation
                  ? asset.zone.binLocation
                  : null;
              return {
                assetTag: asset.tag,
                binLocation,
                facility: facilities[asset.facilityId]
                  ? facilities[asset.facilityId].name
                  : "",
                id: asset.assetId,
                lastEvent: asset.lastEvent,
                parentId: asset.parentId,
                quantityNeeded: asset.quantity
                  ? asset.quantity.quantityNeeded
                  : null,
                quantityPicked: asset.quantity
                  ? asset.quantity.quantityPicked
                  : null,
                zone,
              };
            }) || []
          );
        });
      return results;
    };

    const recursiveFetch = async (assetId: string) => {
      const children = await retrieveAssetChildren(assetId);
      children.map(async (child: any) => {
        child.children = await recursiveFetch(child.id);
      });
      return children;
    };

    const pipe = <span className={classes.pipe}></span>;
    const assetIdFromProps = state?.assetData?.assetId;
    getAssetProps({ assetId, token, apiUrl }, assetIdFromProps).then((res) => {
      const assetData = res.assets[0];
      recursiveFetch(assetData.assetId).then((children) => {
        const recurseAndCount = async (node: any) => {
          setTimeout(() => {
            if (Array.isArray(node)) {
              node.map((child: any) => {
                return setTimeout(() => {
                  if (child.children?.length > 0) {
                    child.label = (
                      <>
                        <span>
                          <strong>Child Asset Tag:</strong> {child.assetTag}
                          {pipe}
                          <strong>Last Event:</strong> {child.lastEvent}
                          {pipe}
                          <strong>Children:</strong> {child.children.length}
                          {pipe}
                          <span
                            className={classes.button}
                            onClick={() => viewSnapshot(child.id)}
                          >
                            View Snapshot{" "}
                            <VisibilityIcon
                              className={classes.button}
                              color="primary"
                              fontSize="small"
                            />
                          </span>
                        </span>
                      </>
                    );
                    recurseAndCount(child.children);
                  } else {
                    child.label = (
                      <>
                        <strong>Child Asset Tag:</strong> {child.assetTag}
                        {pipe}
                        <strong>Last Event:</strong> {child.lastEvent}
                        {pipe}
                        <span
                          className={classes.button}
                          onClick={() => {
                            viewSnapshot(child.id);
                          }}
                        >
                          View Snapshot{" "}
                          <VisibilityIcon
                            className={classes.button}
                            color="primary"
                            fontSize="small"
                          />
                        </span>
                      </>
                    );
                  }
                }, 150);
              });
            }
          }, 50);
        };

        recurseAndCount(children);
        setTimeout(() => {
          const parentChildrenCount = Array.isArray(children)
            ? children.length
            : 0;
          const parentLabel = parentChildrenCount ? (
            <>
              <strong>Parent Asset Tag:</strong> {assetData.tag}
              {pipe}
              <strong>Last Event:</strong> {assetData.lastEvent}
              {pipe}
              <strong>Children:</strong> {parentChildrenCount}
            </>
          ) : (
            <>
              <strong>Parent Asset Tag:</strong> {assetData.tag}
              {pipe}
              <strong>Last Event:</strong> {assetData.lastEvent}
            </>
          );
          setTreeView((treeView: any) => {
            return {
              ...treeView,
              id: "root",
              label: parentLabel,
              children,
              complete: true,
            };
          });
        }, 1000);
      });
    });

    return () => {
      setTreeView((prevState: any) => {
        return {
          ...prevState,
          complete: false,
        };
      });
    };
  }, [
    classes.button,
    classes.pipe,
    facilities,
    history,
    assetId,
    organizationId,
    apiUrl,
    token,
    zones,
    state?.assetData?.assetId,
  ]);

  const handleSave = (filters: any) => {
    saveFilterSettings({ apiUrl, token, userId }, filters).then((res: any) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const { appUser = {} } = res;
        const { propertiesMap = {} } = appUser;
        const { assetSnapshotSettings = {} } = propertiesMap;

        dispatchGlobal(
          updateUserPropertiesMap({
            assetSnapshotSettings,
          })
        );
      }
    });
  };

  // CustomControl is to help manage which tab is selected on the tabbed tables
  const customControl = (e: any, index: any) => {
    setState((prevState: any) => ({ ...prevState, activeTab: index }));
  };

  return (
    <Box className={classes.root} mt={4}>
      {state && state.mounted ? (
        <Grid container spacing={3}>
          <CenteredModal
            onHide={modalClose}
            show={infoModal.modalShow}
            content={
              <div className="container">
                <div className="row">
                  <div className="col-md-12">{infoModal.content}</div>
                </div>
              </div>
            }
            label={infoModal.title}
          />
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Map
                timeZone={timeZone}
                facilities={facilities}
                state={state}
                style={{
                  border: "rgba(50, 53, 93, 0.514) solid 2px",
                  borderRadius: "4px",
                }}
                eventTypesMap={
                  state.lists.eventTypesMap ? state.lists.eventTypesMap : {}
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.paper}>
              <HorizontalTabs>
                <Tab.Overview
                  assetData={state.assetData}
                  classifications={classifications}
                  eventTypesMap={organization.eventTypesMap}
                  facilities={facilities}
                  label={"Asset Snapshot"}
                  setState={setState}
                  state={state}
                />
                <Tab.Hierarchy
                  label={"Hierarchy Snapshot"}
                  assetData={state.assetData}
                  token={token}
                  apiUrl={apiUrl}
                  treeView={treeView}
                  organizationId={organizationId}
                />
                {usersConsoleRole === "Lite" ? null : (
                  <Tab.EditAsset
                    apiUrl={apiUrl}
                    assetData={state.assetData}
                    classifications={classifications}
                    facilities={facilities}
                    label={"Edit Asset"}
                    organization={organization}
                    setModal={setModal}
                    setState={setState}
                    state={state}
                    token={token}
                    userId={userId}
                  />
                )}

                {usersConsoleRole === "Lite" ? null : (
                  <Tab.Update
                    state={state}
                    setState={setState}
                    assetData={state.assetData}
                    setModal={setModal}
                    eventTypesMap={organization.eventTypesMap}
                    eventTypes={state.lists.eventTypes}
                    facilities={facilities}
                    apiUrl={apiUrl}
                    token={token}
                    userId={userId}
                    label={"Update Event"}
                  />
                )}

                {state.assetData.parentId &&
                devices[state.assetData.parentId] &&
                devices[state.assetData.parentId].device.hasTelemetry ? (
                  <Tab.DeviceOverview
                    assetData={state.assetData}
                    devices={devices}
                    facilities={facilities}
                    label="Device Overview"
                    state={state}
                    timeZone={state.filters.tz ? state.filters.tz : ""}
                  />
                ) : null}
              </HorizontalTabs>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {loading ? <Loading color="#5884F5" /> : null}{" "}
            <Table
              activeTab={state.activeTab}
              apiUrl={apiUrl}
              assetData={state.assetData}
              customControl={customControl}
              defaultColumnOrder={state.filters.defaultColumnOrder}
              devices={devices}
              eventTypes={state.lists.eventTypes}
              eventTypesMap={organization.eventTypesMap}
              facilities={facilities}
              facilityArray={state.lists.facilityArray}
              handleSave={handleSave}
              organizationId={organizationId}
              setInfoModal={setInfoModal}
              setState={setState}
              state={state}
              timeZone={state.filters.tz ? state.filters.tz : ""}
              token={token}
              treeView={treeView}
              usersMap={usersMap}
              zones={zones}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading color="#5884F5" opaque={true} />
      )}
      <MaterialConfirmationModal
        content={modal.text}
        closeModal={() => {
          setModal({ ...modal, modalShow: false });
        }}
        modalOpen={modal.modalShow}
        severity={modal.isError ? "error" : "success"}
        variant="filled"
      />
    </Box>
  );
}

export default AssetSnapshot;
