import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { cloneDeep, isEqual } from "lodash";

import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  LinearInterpolator,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { easeCubic } from "d3-ease";
import DatePicker from "../../Forms/FieldTypes/DatePicker";
import moment from "moment-timezone";

import { useHistory } from "react-router-dom";

import "./location-map-style.css";
import Loading from "../../Loading/Loading";

import { utc_to_local_12_hour_clock } from "../../../utils/date_time_utils";
import { getHistories, getAssetById } from "../api";
import DynamicIconRender from "../../IconPicker/DynamicIconRender";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function LocationsMap(props) {
  let history = useHistory();

  const mapToken =
    "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

  const {
    apiUrl,
    token,
    facilities,
    timeZone,
    organization,
    handleWidgetSettings,
  } = props;

  const userPropertiesMap = cloneDeep(
    useSelector((state) => state.user.userPropertiesMap.propertiesMap),
    isEqual
  );

  const eventTypes =
    organization && organization.eventTypesMap
      ? organization.eventTypesMap
      : {};

  const assetTypes =
    organization && organization.assetTypes ? organization.assetTypes : [];

  const [showSettings, setShowSettings] = useState(false);

  const [viewport, setViewport] = useState({});

  const [hotSpot, setHotSpot] = useState(null);

  const { widgetSettings = {} } = userPropertiesMap;

  // create new date for today and a week ago for default date range
  let currentTime = new Date();
  let weekAgo = new Date();
  weekAgo.setDate(currentTime.getDate() - 7);

  let initFilters = widgetSettings["locationsMap"]
    ? widgetSettings["locationsMap"]
    : {
        startDate: weekAgo,
        endDate: currentTime,
        eventType: Object.keys(eventTypes)[0] || "",
        assetType: assetTypes[0] || "",
      };
  // make sure incoming dates are formatted correctly
  initFilters.startDate = new Date(initFilters.startDate);
  initFilters.endDate = new Date(initFilters.endDate);

  const [state, setState] = useState({
    darkMode: false,
    histories: [],
    filters: initFilters,
    eventTypes: eventTypes,
    assetTypes: assetTypes,
    mounted: true,
  });

  const validateLat =
    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const validateLong =
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  // fetch effect, whenever filters change...
  useEffect(() => {
    getHistories({ apiUrl, token }, state.filters).then((histories) => {
      setState((s) => {
        return {
          ...s,
          histories: histories,
        };
      });
    });
  }, [apiUrl, token, state.filters]);

  useEffect(() => {
    const validateLat =
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const validateLong =
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (
      state.histories &&
      state.histories.length &&
      validateLat.test(state.histories[0]?.latitude) &&
      validateLong.test(state.histories[0]?.longitude)
    ) {
      setViewport({
        zoom: 4,
        width: "100%",
        height: "100%",
        latitude: state.histories[0]?.latitude || 0,
        longitude: state.histories[0]?.longitude || 0,
        transitionDuration: 1800,
        transitionInterpolator: new LinearInterpolator(),
        transitionEasing: easeCubic,
      });
    }
  }, [state.histories]);

  const handleFilterChange = () => {
    handleWidgetSettings("locationsMap", state.filters);
  };

  const handleViewBatch = async (hotSpot) => {
    let asset = await getAssetById({ apiUrl, token }, hotSpot.assetId);
    history.push(`/batches/${asset.batchId}/history/${hotSpot.assetType}`);
  };

  const renderMarkers =
    state.histories && state.histories.length
      ? state.histories
          .map((event, idx) => {
            if (
              event &&
              validateLat.test(event.latitude) &&
              validateLong.test(event.longitude)
            ) {
              return event;
            }
            return null;
          })
          .filter((event) => event !== null)
          .sort((a, b) => a.timeOfLog - b.timeOfLog)
          .map((event, idx) => {
            return (
              <Marker
                key={`${event.assetId} - ${idx} - ${event.timeOfLog}`}
                latitude={event.latitude}
                longitude={event.longitude}
                offsetLeft={-20}
                offsetTop={-10}
              >
                {eventTypes[event.event] && eventTypes[event.event].icon ? (
                  <DynamicIconRender
                    iconName={eventTypes[event.event].icon || "LocationOn"}
                    style={{
                      cursor: "pointer",
                      fill:
                        eventTypes[event.event] && eventTypes[event.event].color
                          ? eventTypes[event.event].color
                          : state.darkMode
                          ? "#A0C46D"
                          : "#32355C",
                    }}
                    onMouseEnter={() => setHotSpot(event)}
                    onClick={() => setHotSpot(event)}
                  />
                ) : (
                  <i
                    className="fas fa-map-marker"
                    // marker colors are rendered by checking the eventTypes object for the event action color
                    style={{
                      cursor: "pointer",
                      color:
                        eventTypes[event.event] && eventTypes[event.event].color
                          ? eventTypes[event.event].color
                          : state.darkMode
                          ? "#A0C46D"
                          : "#32355C",
                    }}
                    onMouseEnter={() => setHotSpot(event)}
                    onClick={() => setHotSpot(event)}
                  ></i>
                )}
              </Marker>
            );
          })
      : null;

  const renderPopups =
    hotSpot !== null ? (
      <Popup
        latitude={parseFloat(hotSpot.latitude)}
        longitude={parseFloat(hotSpot.longitude)}
        onClose={() => {
          setHotSpot(null);
        }}
        className={"popup"}
      >
        {hotSpot.event ? (
          <h5 style={{ marginTop: "5px" }}>
            {hotSpot.event} | {hotSpot.assetType}
          </h5>
        ) : (
          ""
        )}{" "}
        {hotSpot.timeOfLog ? (
          <span>
            Date: {utc_to_local_12_hour_clock(hotSpot.timeOfLog, timeZone)}
          </span>
        ) : (
          ""
        )}
        <br />
        {hotSpot.facilityId && facilities[hotSpot.facilityId] ? (
          <span>Facility: {facilities[hotSpot.facilityId].name}</span>
        ) : (
          ""
        )}
        <br />
        <button
          className="btn btn-secondary mt-2 w-100"
          style={{ width: "10vw" }}
          onMouseDown={() => handleViewBatch(hotSpot)}
        >
          View Batch History
        </button>
      </Popup>
    ) : null;

  const fullscreenControlStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const darkModeStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    padding: "10px",
  };

  return (
    <>
      {state.mounted && state.filters ? (
        <>
          <div>
            <i
              className={
                showSettings
                  ? "fa fa-times fa-lg widget-cog"
                  : "fa fa-cog fa-lg widget-cog"
              }
              onClick={() => {
                if (showSettings) {
                  handleFilterChange();
                }
                setShowSettings(!showSettings);
                setHotSpot(null);
              }}
            ></i>
            {!showSettings ? (
              <>
                {state.filters.eventType ? (
                  <h5 className="widget-header widgetTitle">
                    {" "}
                    {state.filters.eventType}
                  </h5>
                ) : null}

                <span style={{ lineHeight: "2.5" }}>
                  {state.filters.assetType
                    ? `${state.filters.assetType} | `
                    : " "}
                  {state.filters.startDate
                    ? moment(state.filters.startDate).format("YYYY/MM/DD")
                    : null}{" "}
                  -{" "}
                  {state.filters.endDate
                    ? moment(state.filters.endDate).format("YYYY/MM/DD")
                    : null}
                  {state.histories.length && state.histories.length > 1
                    ? ` | ${state.histories.length} events`
                    : state.histories.length === 1
                    ? ` | ${state.histories.length} event`
                    : ` | No events`}
                </span>
              </>
            ) : null}
            <div
              className="settings-layer"
              style={showSettings ? {} : { display: "none" }}
            >
              <h3>Customize Map</h3>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    {state.filters.assetType ? (
                      <>
                        <label>Select Unit of Measure</label>
                        <select
                          className="custom-select mb-2"
                          onChange={(e) => {
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                assetType: e.target.value,
                              },
                            });
                          }}
                          value={
                            state.filters && state.filters.assetType
                              ? state.filters.assetType
                              : ""
                          }
                        >
                          {assetTypes && assetTypes.length
                            ? assetTypes.map((type, idx) => (
                                <option key={`${idx} - ${type}`} value={type}>
                                  {type}
                                </option>
                              ))
                            : null}
                        </select>
                      </>
                    ) : null}
                    {eventTypes && state.filters ? (
                      <>
                        <label>Select Event</label>
                        <select
                          className="custom-select"
                          onChange={(e) => {
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                eventType: e.target.value,
                              },
                            });
                          }}
                          value={
                            state.filters && state.filters.eventType
                              ? state.filters.eventType
                              : ""
                          }
                        >
                          {eventTypes && Object.keys(eventTypes).length
                            ? Object.keys(eventTypes).map((type) => (
                                <option value={type} key={type}>
                                  {type}
                                </option>
                              ))
                            : null}
                        </select>
                      </>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    {state.filters.startDate ? (
                      <>
                        <label>Select Start Date</label>
                        <DatePicker
                          format="yyyy/MM/DD"
                          label="Start Date"
                          onChange={(date) => {
                            if (
                              state.filters.endDate &&
                              date > state.filters.endDate
                            ) {
                              alert("Can't set start date later than end date");
                              return;
                            }

                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                startDate: date,
                              },
                            });
                          }}
                          size="small"
                          value={state.filters.startDate}
                        />
                      </>
                    ) : null}

                    <br />
                    {state.filters.endDate ? (
                      <>
                        <label>Select End Date</label>
                        <DatePicker
                          format="yyyy/MM/DD"
                          label="End Date"
                          onChange={(date) => {
                            if (
                              state.filters.startDate &&
                              date < state.filters.startDate
                            ) {
                              alert(
                                "Can't set end date earlier than start date"
                              );
                              return;
                            }
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                endDate: date,
                              },
                            });
                          }}
                          size="small"
                          value={state.filters.endDate}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map-container">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={mapToken}
              width="100%"
              height="100%"
              mapStyle={
                state.darkMode
                  ? "mapbox://styles/mapbox/dark-v10"
                  : "mapbox://styles/mapbox/light-v10"
              }
              style={{
                border: "rgba(50, 53, 93, 0.514) solid 2px",
                borderRadius: "4px",
                display: showSettings ? "none" : null,
              }}
              onViewportChange={(viewport) => setViewport(viewport)}
            >
              <div style={fullscreenControlStyle}>
                <FullscreenControl />
              </div>
              <div style={navStyle}>
                <NavigationControl />
              </div>
              <div style={scaleControlStyle}>
                <ScaleControl />
              </div>
              <div style={darkModeStyle}>
                <i
                  className={
                    state.darkMode
                      ? "fa fa-sun darkmode-icon"
                      : "fa fa-moon darkmode-icon"
                  }
                  style={
                    state.darkMode ? { color: "#FFC854" } : { color: "#32355B" }
                  }
                  onClick={() => {
                    setState({ ...state, darkMode: !state.darkMode });
                  }}
                ></i>
              </div>
              {renderMarkers}
              {renderPopups}
            </ReactMapGL>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
