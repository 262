import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/Forms/FieldTypes/Button";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    margin: "1rem 0",
  },
}));

export default function DeleteTemplateDialog(props) {
  const { open, modalShow, template, onCancel, onSubmit } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={modalShow}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {`Are you sure you want to delete ${template.label}? This action cannot
            be undone`}
          </Grid>
          <div className={classes.buttonContainer}>
            <Grid item xs={5}>
              <Button
                color="primary"
                content="Yes"
                onClick={() => {
                  onSubmit(template.value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Button
                color="secondary"
                content="No"
                onClick={() => {
                  onCancel();
                }}
              />
            </Grid>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
