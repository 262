import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import "./BarGraphByEventsStyling.css";

function BargraphByEvent(props) {
  const { data, sort = "12", timeZone = {} } = props;

  useEffect(() => {
    let currentTime = moment()
      .tz(timeZone?.value || moment.tz.guess())
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY/MM/DD");

    let timeAgo = moment()
      .tz(timeZone?.value || moment.tz.guess())
      .subtract(sort, "months")
      .startOf("month")
      .format("YYYY/MM/DD");

    setDateRange(`${timeAgo} - ${currentTime}`);
  }, [sort, timeZone?.value]);

  const [dateRange, setDateRange] = useState("");
  const [sortedEvents, setSortedEvents] = useState({
    labels: [],
    datasets: [],
  });
  const barGraphData = {
    labels: sortedEvents.labels,
    datasets: [
      {
        label: "Event Count",
        backgroundColor: "#3d5ba9",
        borderColor: "#3d5ba9",
        borderWidth: 1,
        hoverBackgroundColor: "#5884f5",
        hoverBorderColor: "#5884f5",
        data: sortedEvents.datasets,
      },
    ],
  };
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    const sortString = sort.toString();
    if (sortString === "1" || sortString === "3") {
      breakAssetHistoriesIntoWeeks(data, sort);
    } else if (sortString === "6" || sortString === "12") {
      breakAssetHistoriesIntoMonths(data, sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sort]);

  // This function seperates an array of assetHistories into weeks
  const breakAssetHistoriesIntoWeeks = (assets, sort) => {
    const sortedEvents = { labels: [], datasets: [] };
    let label = "";
    let weeksObject = {};
    let defaultMonths = [];

    // Grabs the previous months. Depending on what the sort is
    for (let i = 0; i < sort; i++) {
      const modifiedDate = moment()
        .tz(timeZone?.value || moment.tz.guess())
        .subtract(i + 1, "months")
        .format("MM/DD/YYYY");
      defaultMonths.push(modifiedDate);
    }

    // Builds the default values for the weeksObject
    defaultMonths.forEach((month) => {
      const currentMonth = moment(month, "MM/DD/YYYY").format("MM");
      const lastDayOfMonth = moment(month, "MM/DD/YYYY")
        .endOf("month")
        .format("MM/DD/YYYY");
      let firstDayOfWeek = moment(month, "MM/DD/YYYY")
        .startOf("month")
        .format("MM/DD/YYYY");
      let lastDayOfWeek = moment(firstDayOfWeek, "MM/DD/YYYY")
        .endOf("week")
        .format("MM/DD/YYYY");

      // Here we Iterate through each week in the month.
      for (let i = 0; lastDayOfMonth !== lastDayOfWeek; i++) {
        const label = `${moment(firstDayOfWeek, "MM/DD/YYYY").format(
          "MM/DD"
        )}-${moment(lastDayOfWeek, "MM/DD/YYYY").format("MM/DD")}`;

        firstDayOfWeek = moment(lastDayOfWeek, "MM/DD/YYYY")
          .add(1, "days")
          .format("MM/DD/YYYY");

        lastDayOfWeek =
          moment(firstDayOfWeek, "MM/DD/YYYY").endOf("week").format("MM") !==
          currentMonth
            ? moment(firstDayOfWeek, "MM/DD/YYYY")
                .endOf("month")
                .format("MM/DD/YYYY")
            : moment(firstDayOfWeek, "MM/DD/YYYY")
                .endOf("week")
                .format("MM/DD/YYYY");

        weeksObject[label] = { count: 0 };
      }
      // This If statement deals with the last week of each month
      if (lastDayOfMonth === lastDayOfWeek) {
        const label = `${moment(firstDayOfWeek, "MM/DD/YYYY").format(
          "MM/DD"
        )}-${moment(lastDayOfWeek, "MM/DD/YYYY").format("MM/DD")}`;
        firstDayOfWeek = moment(lastDayOfWeek, "MM/DD/YYYY")
          .add(1, "days")
          .format("MM/DD/YYYY");

        lastDayOfWeek = moment(firstDayOfWeek, "MM/DD/YYYY")
          .endOf("month")
          .format("MM/DD/YYYY");
        weeksObject[label] = { count: 0 };
      }
    });

    const buildWeeksObject = (assetsArray) => {
      // So you could notate the month of the item, and then say if the range is not in that month.
      // dont go to end of week.
      assetsArray.forEach((element) => {
        // Grabs the current month
        const currentMonth = moment
          .utc(element.timeOfLogString, "MM/DD/YYYY")
          .tz(timeZone?.value || moment.tz.guess())
          .format("YYYY-MM");

        // Grabs the elements start of week
        const startOfWeek = moment
          .utc(element.timeOfLogString, "MM/DD/YYYY")
          .tz(timeZone?.value || moment.tz.guess())
          .startOf("week")
          .format("MM/DD");

        // Grabs the elements end of week. If the end of week is not within the month, this will
        // default to the end of the month.
        const endOfWeek =
          moment
            .utc(element.timeOfLogString, "MM/DD/YYYY")
            .tz(timeZone?.value || moment.tz.guess())
            .endOf("week")
            .format("YYYY-MM") === currentMonth
            ? moment
                .utc(element.timeOfLogString, "MM/DD/YYYY")
                .tz(timeZone?.value || moment.tz.guess())
                .format("MM/DD")
            : moment
                .utc(element.timeOfLogString, "MM/DD/YYYY")
                .tz(timeZone?.value || moment.tz.guess())
                .endOf("month")
                .format("MM/DD");

        label = `${startOfWeek}-${endOfWeek}`;
        if (weeksObject[label] === undefined) {
          weeksObject[label] = { count: 1 };
        } else if (weeksObject[label] !== undefined) {
          weeksObject[label].count++;
        }
      });
    };
    buildWeeksObject(assets);

    // Sets the count
    Object.keys(weeksObject)
      .sort()
      .forEach((element) => {
        sortedEvents.datasets.push(weeksObject[element].count);
      });
    // Sets the labels
    sortedEvents.labels = Object.keys(weeksObject).sort();
    setSortedEvents(sortedEvents);
  };

  // This function seperates an array of assetHistories into months
  const breakAssetHistoriesIntoMonths = (assets, sort) => {
    const sortedEvents = { labels: [], datasets: [] };
    let monthsObject = {};
    let defaultMonths = [];

    // This loops and builds the default months array.
    // That way months within the range with no activity still get displayed
    for (let i = 0; i < sort; i++) {
      const modifiedDate = moment()
        .tz(timeZone?.value || moment.tz.guess())
        .subtract(i + 1, "months")
        .format("MM/DD/YYYY");
      defaultMonths.push({ timeOfLogString: modifiedDate });
    }

    const buildMonthsObject = (assetsArray, buildDefault = false) => {
      assetsArray.forEach((element) => {

        // Grabs the provided year and month
        const yearAndMonth = moment
          .utc(element.timeOfLogString, "MM/DD/YYYY")
          .tz(timeZone?.value || moment.tz.guess())
          .format("YYYY-MM");

        // Grabs the month
        const month = moment
          .utc(element.timeOfLogString, "MM/DD/YYYY")
          .tz(timeZone?.value || moment.guess())
          .format("MM");

        // Grabs the last day in the given month
        const lastDayInMonth = moment(yearAndMonth, "YYYY-MM").daysInMonth();

        let label = `${month}/01-${month}/${lastDayInMonth}`;
        if (monthsObject[label] === undefined) {
          monthsObject[label] = { count: buildDefault ? 0 : 1 };
        } else if (monthsObject[label].count !== undefined) {
          monthsObject[label].count++;
        }
      });
    };

    buildMonthsObject(defaultMonths, true);
    buildMonthsObject(assets);

    // Sets the count
    Object.keys(monthsObject).forEach((element) => {
      sortedEvents.datasets.unshift(monthsObject[element].count);
    });
    // Sets the labels
    sortedEvents.labels = Object.keys(monthsObject).reverse();
    // Assigns the values to state
    setSortedEvents(sortedEvents);
  };

  return (
    <>
      <span className="widgetData">{dateRange}</span>
      <Bar data={barGraphData} options={barChartOptions} />
    </>
  );
}

export default BargraphByEvent;
