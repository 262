import { useEffect, useState, useCallback, useRef } from "react";
import { isEmpty, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table } from "./components/Table";
import { State, Filters } from "./interface";
import { exportSpreadsheet } from "./utils/xlsx";

import { thunks } from "../../globalStore/slices/devices/devicesSlice";

import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import UnassignDevicesDialog from "./components/UnassignDevicesDialog";

export default function Devices(props: any) {
  const { apiUrl, token, organizationId, timeZone } = props;
  const history = useHistory();

  const dispatchGlobal = useDispatch();
  const deviceStore = useSelector((state: any) => state.devices, isEqual);
  const organization = useSelector(
    (state: any) => state.organization.organization,
    isEqual
  );
  const saveFilterSettings: any = thunks.devices.saveFilterSettings;
  const searchDevices: any = thunks.devices.searchDevices;

  const { filters: initFilters = {} } = deviceStore;

  const [state, setState] = useState<State>({
    numDevices: deviceStore.count,
    ...deviceStore,
    selectedDevices: {},
    selectAll: false,
  });

  const [confirm, setConfirm] = useState<any>({
    confirmShow: false,
    text: "",
    color: "",
    icon: "",
  });
  const [dialog, setDialog] = useState<any>({
    dialogShow: false,
  });
  const handleConfirmationModal = (
    confirmationText: string,
    color?: boolean,
    icon?: string,
    delay: number = 750
  ) => {
    setConfirm((c: any) => {
      return {
        ...c,
        confirmShow: true,
        text: confirmationText,
        color: color,
        icon: icon,
      };
    });
    setTimeout(function () {
      setConfirm((c: any) => {
        return {
          ...c,
          confirmShow: false,
          text: "",
          color: "",
          icon: "",
        };
      });
    }, delay);
  };

  const isMounted = useRef(false);

  const init = useCallback(
    (isMounted: boolean) => {
      if (!isMounted) {
        dispatchGlobal(searchDevices(initFilters));
      }
    },
    [dispatchGlobal, searchDevices, initFilters]
  );

  // intializing effect, this is for checking for updated devices on first mount. Using a ref to ensure it only fires on first mount.
  useEffect(() => {
    init(isMounted.current);
    isMounted.current = true;
  }, [init]);

  // when devices store updates, update local state as well
  useEffect(() => {
    setState((s) => {
      return {
        ...s,
        ...deviceStore,
      };
    });
  }, [deviceStore]);

  const handleSave = (filters: any) => {
    dispatchGlobal(saveFilterSettings(filters));
  };

  const filterSearch = (filters: Filters) => {
    dispatchGlobal(searchDevices(filters));
  };

  return (
    <div className="container-fluid mt-3">
      <MaterialConfirmationModal
        content={confirm.text}
        closeModal={() => {
          setConfirm({ ...confirm, confirmShow: false });
        }}
        modalOpen={confirm.confirmShow}
        severity={confirm.color ? "error" : "success"}
        variant="filled"
      />
      <UnassignDevicesDialog
        apiUrl={apiUrl}
        token={token}
        organizationId={organizationId}
        dialog={dialog}
        setDialog={setDialog}
        state={state}
        handleConfirmationModal={handleConfirmationModal}
        history={history}
      />
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>Devices</h3>
        </div>

        <div className="col-12 col-md-6 text-md-right text-center">
          <button
            onClick={() => {
              setDialog({ ...dialog, dialogShow: true });
            }}
            type="button"
            className={`btn btn-primary ml-3 mb-1 font-weight-bold`}
            // if no devices are selected, or the selected devices do not have associated assets
            disabled={
              isEmpty(state.selectedDevices) ||
              Object.keys(state.selectedDevices).some(
                (device) => !Object.keys(state.deviceAssetsMap).includes(device)
              )
            }
          >
            Unassign Devices
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {state.filters ? (
            <Table
              apiUrl={apiUrl}
              token={token}
              organization={organization}
              organizationId={organizationId}
              timeZone={timeZone}
              data={state.devices}
              deviceAssetsMap={state.deviceAssetsMap}
              defaultColumnOrder={(state.filters as any).defaultColumnOrder}
              exportSpreadsheet={exportSpreadsheet}
              filterSearch={filterSearch}
              handleConfirmationModal={handleConfirmationModal}
              handleSave={handleSave}
              setState={setState}
              state={state}
              saveFilterSettings={saveFilterSettings}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
